import {
  ADMIN_ROLE,
  DEALER_ROLE,
  PARENT_ROLE,
  SALES_REP_ROLE,
} from "@constants/commons";
import {
  CANCELED_STATUS,
  CANCELED_STATUS_REQUEST,
  COMPLETED_STATUS,
  COMPLETED_STATUS_REQUEST,
  IN_PROGRESS_STATUS,
  IN_PROGRESS_STATUS_REQUEST,
  MEASURED,
  MEASURED_STATUS,
  MEASUREMENT_REQUIRED,
  MEASUREMENT_REQUIRED_STATUS,
  SCHEDULED_STATUS,
  SCHEDULED_STATUS_REQUEST,
} from "@constants/events";
import {
  EndUserEventStatusRequest,
  EventLocation,
  EventStatusRequest,
  IEvent,
  IEventList,
  IFilterEventValues,
} from "@models/common/events";
import { UserRole } from "@models/common/user";
import { http } from "@services/http";
import { AppDispatch } from "@store/store";

export const listOfEvents = async (dispatch: AppDispatch) =>
  await http.post<IEventList, null>(
    "/api/v1/measurement/event/filter",
    null,
    dispatch
  );

export const listOfFilteredEvents = async (
  dispatch: AppDispatch,
  page: number,
  size: number,
  sort: string,
  eventName?: string,
  salesRepId?: string
) =>
  await http.post<
    IEventList,
    { salesRepIdIn: string | null; searchKey: string | null }
  >(
    `/api/v1/measurement/event/filter?page=${page}&size=${size}&sort=${sort}`,
    { salesRepIdIn: salesRepId || null, searchKey: eventName || null },
    dispatch
  );

export const findEventById = async (dispatch: AppDispatch, eventId: string) =>
  await http.get<IEvent>(`/api/v1/measurement/event/${eventId}`, dispatch);

export const getEventsByFilterTable = async ({
  dispatch,
  upcomingDate,
  startDate,
  endDate,
  eventLocation,
  eventStatus,
  endUserEventStatus,
  productTypeId,
  eventCreator,
  page,
  size,
  sort,
  salesRepId,
  endUserId,
  dealerId,
}: IFilterEventValues) => {
  const filteredEventProductType = productTypeId
    ? productTypeId?.split(", ")
    : undefined;

  const filteredEventCreator = eventCreator
    ? (eventCreator
        ?.split(", ")
        ?.map((eventCreator) =>
          eventCreator === "Admin"
            ? [ADMIN_ROLE]
            : eventCreator === "Parent"
            ? [PARENT_ROLE]
            : eventCreator === "Dealer"
            ? [DEALER_ROLE]
            : eventCreator === "Sales representative"
            ? [SALES_REP_ROLE]
            : [ADMIN_ROLE, PARENT_ROLE, DEALER_ROLE, SALES_REP_ROLE]
        )
        .flat() as UserRole[])
    : undefined;

  const eventStatusesForEndUsers = [
    COMPLETED_STATUS_REQUEST,
    SCHEDULED_STATUS_REQUEST,
    IN_PROGRESS_STATUS_REQUEST,
  ] as EventStatusRequest[];

  const filteredEventStatus = eventStatus
    ? (eventStatus
        ?.split(", ")
        ?.map((eventStatus) =>
          eventStatus === COMPLETED_STATUS
            ? [COMPLETED_STATUS_REQUEST]
            : eventStatus === CANCELED_STATUS
            ? [CANCELED_STATUS_REQUEST]
            : eventStatus === SCHEDULED_STATUS
            ? [SCHEDULED_STATUS_REQUEST]
            : eventStatus === IN_PROGRESS_STATUS
            ? [IN_PROGRESS_STATUS_REQUEST]
            : [
                COMPLETED_STATUS_REQUEST,
                CANCELED_STATUS_REQUEST,
                SCHEDULED_STATUS_REQUEST,
                IN_PROGRESS_STATUS_REQUEST,
              ]
        )
        .flat() as EventStatusRequest[])
    : undefined;

  const filteredEndUserEventStatus = endUserEventStatus
    ? (endUserEventStatus
        ?.split(", ")
        ?.map((endUserEventStatus) =>
          endUserEventStatus === MEASUREMENT_REQUIRED_STATUS
            ? [MEASUREMENT_REQUIRED]
            : endUserEventStatus === MEASURED_STATUS
            ? [MEASURED]
            : [MEASUREMENT_REQUIRED_STATUS, MEASURED]
        )
        .flat() as EndUserEventStatusRequest[])
    : undefined;

  return await http.post<
    IEventList,
    {
      activePeriodStart: string | null;
      activePeriodEnd: string | null;
      startsAtAfter: string | null;
      eventTypeIn: EventLocation[] | null;
      statusIn: EventStatusRequest[] | null;
      productTypeIdIn: string[] | null;
      createdBy: {
        roleIn: UserRole[];
      } | null;
      salesRepIdIn: string | null;
      dealerIdIn: string | null;
      eventParticipation:
        | {
            endUser: { userIdIn: string[] };
          }
        | { measurementStatusIn: EndUserEventStatusRequest[] }
        | null;
    }
  >(
    `/api/v1/measurement/event/filter?page=${page}&size=${size}&sort=${sort}`,
    {
      activePeriodStart: startDate || null,
      activePeriodEnd: endDate || null,
      startsAtAfter:
        !startDate && !endDate && upcomingDate
          ? new Date().toISOString()
          : null,
      eventTypeIn: eventLocation ? [eventLocation] : null,
      statusIn: endUserId
        ? eventStatusesForEndUsers
        : filteredEventStatus || null,
      productTypeIdIn: filteredEventProductType || null,
      createdBy: filteredEventCreator
        ? {
            roleIn: filteredEventCreator,
          }
        : null,
      salesRepIdIn: salesRepId || null,
      dealerIdIn: dealerId || null,
      eventParticipation:
        endUserId && !filteredEndUserEventStatus
          ? {
              endUser: { userIdIn: [endUserId] },
            }
          : !endUserId && filteredEndUserEventStatus
          ? {
              measurementStatusIn: filteredEndUserEventStatus,
            }
          : endUserId && filteredEndUserEventStatus
          ? {
              endUser: { userIdIn: [endUserId] },
              measurementStatusIn: filteredEndUserEventStatus,
            }
          : null,
    },
    dispatch
  );
};

export const updateEvent = async (
  dispatch: AppDispatch,
  eventId: string,
  eventData: {
    productTypesIds: string[] | undefined;
    orderNumber: string;
    isCanceled: boolean;
    eventName: string;
    startsAt: string;
    location: string;
    eventType: string;
    salesRepId: string | undefined;
    endsAt: string;
  }
) =>
  await http.post<
    IEvent,
    {
      salesRepId: string | undefined;
      productTypesIds: string[] | undefined;
      eventName: string;
      eventType: EventLocation | "";
      location: string;
      startsAt: string;
      endsAt: string;
      orderNumber: string;
      isCanceled: boolean;
    }
  >(
    `/api/v1/measurement/event/${eventId}`,
    {
      salesRepId: eventData.salesRepId,
      productTypesIds: eventData.productTypesIds,
      eventName: eventData.eventName,
      eventType: eventData.eventType as EventLocation,
      location: eventData.location,
      startsAt: eventData.startsAt,
      endsAt: eventData.endsAt,
      orderNumber: eventData.orderNumber,
      isCanceled: eventData.isCanceled,
    },
    dispatch
  );

export const createEvent = async (
  dispatch: AppDispatch,
  eventData: {
    productTypesIds: string[] | undefined;
    orderNumber: string;
    isCanceled: boolean;
    eventName: string;
    startsAt: string;
    location: string;
    eventType: string;
    salesRepId: string | undefined;
    endsAt: string;
  }
) =>
  await http.post<
    IEvent,
    {
      salesRepId: string | undefined;
      productTypesIds: string[] | undefined;
      eventName: string;
      eventType: EventLocation;
      location: string;
      startsAt: string;
      endsAt: string;
      orderNumber: string | null;
      isCanceled: boolean;
    }
  >(
    "/api/v1/measurement/event",
    {
      salesRepId: eventData.salesRepId,
      productTypesIds: eventData.productTypesIds,
      eventName: eventData.eventName,
      eventType: eventData.eventType as EventLocation,
      location: eventData.location,
      startsAt: eventData.startsAt,
      endsAt: eventData.endsAt,
      orderNumber: eventData.orderNumber,
      isCanceled: eventData.isCanceled,
    },
    dispatch
  );

export const cancelEvent = async (
  dispatch: AppDispatch,
  eventId: string,
  isCanceled: boolean
) =>
  await http.put<IEvent, { isCanceled: boolean }>(
    `/api/v1/measurement/event/${eventId}`,
    {
      isCanceled,
    },
    dispatch
  );

export const deleteEvent = async (dispatch: AppDispatch, eventId: string) =>
  await http.delete(`/api/v1/measurement/event/${eventId}`, null, dispatch);

export const listOfEndUserEvents = async (
  dispatch: AppDispatch,
  page: number,
  size: number,
  sort: string,
  eventName?: string,
  endUserId?: string
) =>
  await http.post<
    IEventList,
    {
      searchKey: string | null;
      eventParticipation: {
        endUser: { userIdIn: string[] };
      } | null;
      statusIn: string[];
    }
  >(
    `/api/v1/measurement/event/filter?page=${page}&size=${size}&sort=${sort}`,
    {
      eventParticipation: endUserId
        ? {
            endUser: { userIdIn: [endUserId] },
          }
        : null,
      searchKey: eventName || null,
      statusIn: [
        COMPLETED_STATUS_REQUEST,
        SCHEDULED_STATUS_REQUEST,
        IN_PROGRESS_STATUS_REQUEST,
      ],
    },
    dispatch
  );
