import login from "@assets/img/login.jpg";
import { FC, memo, useEffect, useState } from "react";
import { Button } from "@components/Button";
import { Input } from "@components/Input";
import { useLogin } from "@hooks/common/useLogin";
import { ICredentials } from "@models/auth/data";
import { FormProvider, useForm } from "react-hook-form";
import { validationSchemas } from "@services/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { selectUserInfo } from "@store/selectors/user";
import { useNavigate } from "react-router-dom";
import { FAILED_PASSWORD_AUTH, NOT_SHOWN_ERRORS } from "@constants/errors";
import { IPasswordConfirmation } from "@models/components/main/passwordconfirmation";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { cognitoUserSlice } from "@store/reducers/CognitoUserSlice";
import {
  getDefaultRouteFromHeaders,
  getHeadersByUserRole,
  publicRoutes,
} from "@services/routes";
import {
  SIGN_IN_FORGOT_PASSWORD,
  SIGN_IN_PASSWORD,
  SOMETHING_WENT_WRONG_NOTIFICATION,
} from "@constants/commons";
import { cognitoUserCodeExpiresAt } from "@services/auth";
import { selectTokens } from "@store/selectors/tokens";
import { withoutSpaces } from "@services/regexps";
import { userInfoSlice } from "@store/reducers/UserSlice";
import { getUserRoleFromToken } from "@services/common";

export const PasswordConfirmation: FC = memo(() => {
  const [submitType, setSubmitType] = useState<string>("");

  const formMethods = useForm<IPasswordConfirmation>({
    resolver: yupResolver(
      submitType === SIGN_IN_FORGOT_PASSWORD
        ? validationSchemas.forgotPass
        : validationSchemas.passwordConf
    ),
    mode: "onSubmit",
    shouldFocusError: false,
  });

  const expiresAt = cognitoUserCodeExpiresAt(useAppSelector);
  const tokensInfo = useAppSelector(selectTokens);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector(selectUserInfo);
  const { handleSubmit, formState, clearErrors, setError, watch } = formMethods;
  const { errors, isSubmitting, isSubmitSuccessful } = formState;
  const { password } = watch();

  const [credentials, setCredentials] = useState<ICredentials>();

  const onSubmit = (data: ICredentials) => {
    clearError();
    if (userInfo?.username?.includes("@") && submitType === SIGN_IN_PASSWORD) {
      setCredentials({
        email: userInfo.username?.trim(),
        password: data.password,
      });
    } else if (
      userInfo?.username?.startsWith("+") &&
      submitType === SIGN_IN_PASSWORD
    ) {
      setCredentials({
        phone_number: userInfo.username?.trim(),
        password: data.password,
      });
    } else if (
      userInfo?.username?.includes("@") &&
      submitType === SIGN_IN_FORGOT_PASSWORD
    ) {
      setCredentials({
        email: userInfo.username?.trim(),
      });
    } else if (
      userInfo?.username?.startsWith("+") &&
      submitType === SIGN_IN_FORGOT_PASSWORD
    ) {
      setCredentials({
        phone_number: userInfo.username?.trim(),
      });
    } else {
      setCredentials(undefined);
    }
  };

  const { loginError, isAuthenticating, clearError, isAuthenticated } =
    useLogin(credentials, !submitType ? true : false);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  useEffect(() => {
    if (isSubmitting || isAuthenticating) {
      clearErrors();
      clearError();
    }

    if (loginError && !NOT_SHOWN_ERRORS.includes(loginError)) {
      if (loginError === "Incorrect username or password.") {
        setError("password", {
          type: "custom",
          message: FAILED_PASSWORD_AUTH,
        });
      } else {
        setError("password", {
          type: "custom",
          message: SOMETHING_WENT_WRONG_NOTIFICATION,
        });
      }
    }
  }, [loginError]);

  useEffect(() => {
    if (
      submitType === SIGN_IN_PASSWORD &&
      isSubmitSuccessful &&
      tokensInfo?.idToken
    ) {
      navigate(
        getDefaultRouteFromHeaders(
          getHeadersByUserRole(getUserRoleFromToken(tokensInfo))
        )
      );
    }
    if (
      submitType === SIGN_IN_FORGOT_PASSWORD &&
      isSubmitSuccessful &&
      expiresAt > 0
    ) {
      navigate(publicRoutes.forgot_password_code.path);
    }
  }, [isAuthenticated, isSubmitSuccessful, submitType, expiresAt]);

  const isPasswordConfirmationBtnDisabled =
    !userInfo || isAuthenticating || isSubmitting || !password?.length;

  const goToPreviousPage = () => {
    dispatch(
      userInfoSlice.actions.setUser({
        username: userInfo?.username || "",
        verified: false,
      })
    );
    dispatch(cognitoUserSlice.actions.clearCognitoUser());
    navigate(publicRoutes.signin.path);
    navigate(0);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className={`login ${isAuthenticating && "login-loading"}`}>
        <img src={login} alt="login" className="login-img" />
        <div className="login-container login-back">
          <button className="btn-link btn" onClick={goToPreviousPage}>
            <i className="btn-link-icon icon icon-back" /> Go back
          </button>
          <h1 className="login-title login-title-space">
            Welcome to <b>{process.env.REACT_APP_PROJECT_NAME}</b>
          </h1>
          <div className="login-form">
            <FormProvider {...formMethods}>
              <Input
                name="password"
                label="Password*"
                subLabel={errors.password?.message}
                error={!!errors.password?.message}
                type="password"
                pattern={withoutSpaces}
                disable={isAuthenticating}
              />
            </FormProvider>
            <Button
              type="submit"
              className="btn-link login-recover-link"
              disable={isAuthenticated}
              onClick={() => setSubmitType(SIGN_IN_FORGOT_PASSWORD)}
              text="Forgot Password?"
            />
            <Button
              type="submit"
              text="Sign in"
              className="btn-main btn-big"
              disable={isPasswordConfirmationBtnDisabled}
              onClick={() => setSubmitType(SIGN_IN_PASSWORD)}
            />
          </div>
        </div>
      </div>
    </form>
  );
});
