import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EventState, IEvent } from "@models/common/events";

const initialState: EventState = {
  type: "",
  payload: undefined,
};

export const eventInfoSlice = createSlice({
  name: "eventInfo",
  initialState,
  reducers: {
    setEventData: (state, { payload }: PayloadAction<IEvent>) => {
      state.payload = payload;
    },
    clearEventData: (state) => {
      state.payload = undefined;
    },
  },
});

export default eventInfoSlice.reducer;
