import { FC } from "react";
import { IRadio } from "@models/components/secondary/radio";
import { useFormContext } from "react-hook-form";

export const Radio: FC<IRadio> = ({
  id,
  label,
  name,
  value,
  checked,
  onClick,
}) => {
  const formData = useFormContext();

  return (
    <label htmlFor={id} className="form-radio-label">
      <input
        checked={checked}
        {...(formData?.register ? formData.register(name) : {})}
        type="radio"
        value={value.toString()}
        id={id}
        name={name}
        className="form-radio-input"
        onClick={onClick}
      />
      <i className="filter-radio" />
      {label}
    </label>
  );
};
