import { FC, useEffect, useRef, useState } from "react";
import { IParentDropdown } from "@models/components/secondary/dropdownmenu";
import { useFormContext } from "react-hook-form";
import { REQUIRED_FIELD } from "@constants/validation";
import { DebounceInput } from "react-debounce-input";
import { setDropdownItems } from "@services/common";
import { ASSIGN_PARENT_TITLE } from "@constants/users";
import { IParent } from "@models/common/parent";

export const ParentDropdown: FC<IParentDropdown> = (props) => {
  const {
    name,
    pattern,
    id,
    error,
    subLabel,
    limit,
    defaultValue,
    dropdownList,
    searchedValue,
    handleSearchedValue,
    disable,
    required,
    clear,
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [parentsDropdownItems, setParentsDropdownItems] = useState(
    defaultValue || []
  );

  const [isOpen, setOpen] = useState(false);
  const [focusedFields, setFocused] = useState({
    [name]: false,
  });

  const focuseField = (fieldName: string) => {
    setFocused({ [fieldName]: true });
  };

  const toggleDropdown = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpen(!isOpen);
  };

  const formData = useFormContext();

  const { watch, clearErrors, setError, formState, setValue } = formData;
  const { errors } = formState;

  useEffect(() => {
    if (!parentsDropdownItems.length && defaultValue) {
      setParentsDropdownItems(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (clear) {
      setParentsDropdownItems([]);
      setOpen(false);
    }
  }, [clear]);

  useEffect(() => {
    if (focusedFields?.[name]) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [focusedFields]);

  useEffect(() => {
    if (!!watch()?.[name]?.length) {
      clearErrors(name);
    }
  }, [watch()?.[name]]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        isOpen &&
        ref.current &&
        !ref.current.contains(e.target as HTMLDivElement)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    setValue(name, parentsDropdownItems.map((item) => item.userId).join(", "));

    if (!parentsDropdownItems?.length && focusedFields[name]) {
      setError(name, { type: "custom", message: REQUIRED_FIELD });
    } else {
      clearErrors(name);
    }
  }, [parentsDropdownItems]);

  useEffect(() => {
    if (focusedFields[name]) {
      clearErrors(name);
    }
  }, [focusedFields]);

  const handleParentDropdownItems = (items: IParent[]) => {
    setParentsDropdownItems(items);
  };

  const handleDropdownItems = (value: IParent) => {
    setDropdownItems({
      title: ASSIGN_PARENT_TITLE,
      limit,
      parents: parentsDropdownItems,
      handleParents: handleParentDropdownItems,
      value,
      handleSearchedValue,
    });
  };

  return (
    <>
      <div className={`dropdown form ${isOpen && "isActive"}`}>
        <label htmlFor="dropdown" className="form-label">
          {ASSIGN_PARENT_TITLE} {required && <sup>*</sup>}
        </label>
        <div ref={ref}>
          <div className="dropdown-form">
            <div
              className={`dropdown-input-container ${
                !!errors?.[name]?.message &&
                !focusedFields[name] &&
                "form-input-error"
              }`}
            >
              {parentsDropdownItems.map((item) => (
                <button
                  disabled={disable}
                  className="dropdown-selected"
                  key={item.userId}
                  onClick={(e) => e.preventDefault()}
                >
                  {item.firstName} {item.lastName}
                  <i
                    className="dropdown-selected-icon icon icon-round-close "
                    onClick={() => {
                      setParentsDropdownItems((prevItems) => [
                        ...prevItems.filter(
                          (prevItem) => prevItem.userId !== item.userId
                        ),
                      ]);
                    }}
                  />
                </button>
              ))}
              <DebounceInput
                id={id}
                {...(formData?.register
                  ? formData.register(name, { pattern })
                  : {})}
                name={name}
                type="text"
                placeholder="Select parent"
                className={`dropdown-input ${
                  !!errors?.[name]?.message &&
                  !focusedFields[name] &&
                  "form-input-error"
                }`}
                onFocus={() => focuseField(name)}
                debounceTimeout={500}
                onChange={(event) => {
                  handleSearchedValue(event.target.value);
                }}
                value={searchedValue}
                disabled={disable}
              />
            </div>
            <button
              className="dropdown-btn"
              onClick={toggleDropdown}
              disabled={disable}
            >
              <i className={`dropdown-icon icon icon-arrow`} />
            </button>
          </div>
          <ul className="dropdown-list">
            {!!dropdownList?.length ? (
              dropdownList.map((value) => (
                <li
                  className={`dropdown-item ${
                    value.userId ===
                      parentsDropdownItems.find(
                        (item) => item.userId === value.userId
                      )?.userId && "isActive"
                  }`}
                  onClick={() => handleDropdownItems(value)}
                  key={value.userId}
                >
                  {value.firstName} {value.lastName}
                </li>
              ))
            ) : (
              <li className="dropdown-item">
                <span className="dropdown-no-result">No result found.</span>
              </li>
            )}
          </ul>
        </div>
      </div>
      {subLabel && (error || errors?.[name]?.message) && (
        <label
          htmlFor={id}
          className={`form-sublabel ${
            (error || !!errors?.[name]?.message) && "form-input-error"
          }`}
        >
          <>{subLabel || errors?.[name]?.message}</>
        </label>
      )}
    </>
  );
};
