import {
  GenderMeasurementParameter,
  IMeasurementParameter,
} from "@models/common/measurements";
import { genders } from "@constants/commons";
import { GenderToMeasurementParamRefs } from "@models/common/events";
import {
  getProductTypeGender,
  sortGenderMeasurementParameters,
} from "@services/common";

const processMeasurementsForSubmit = ({
  gender,
  selectedMeasurement,
  allItems,
}: {
  gender: "male" | "female";
  selectedMeasurement?: Record<string, IMeasurementParameter[] | undefined>;
  allItems: IMeasurementParameter[] | undefined;
}) => {
  const selectedMeasurementItems = selectedMeasurement?.[
    gender
  ] as unknown as IMeasurementParameter[];
  const sortedSelectedMeasurementItemsRelyingOnAllItems = allItems?.filter(
    ({ measurementParamId }) =>
      selectedMeasurementItems?.some(
        (selectedMeasurementItem) =>
          selectedMeasurementItem.measurementParamId === measurementParamId
      )
  );

  return sortedSelectedMeasurementItemsRelyingOnAllItems?.map(
    (measurement: IMeasurementParameter, index) => ({
      displayOrder: index,
      measurementParamId: measurement.measurementParamId,
    })
  );
};

const getGenderToMeasurementParamRefs = ({
  selectedGender,
  selectedMeasurement,
  measurementParametersMale,
  measurementParametersFemale,
}: {
  selectedGender: string;
  selectedMeasurement?: Record<
    "male" | "female",
    IMeasurementParameter[] | undefined
  >;
  measurementParametersMale: IMeasurementParameter[] | undefined;
  measurementParametersFemale: IMeasurementParameter[] | undefined;
}) => {
  switch (selectedGender) {
    case "Male":
      return {
        MALE: processMeasurementsForSubmit({
          gender: "male",
          selectedMeasurement,
          allItems: measurementParametersMale,
        }),
      };
    case "Female":
      return {
        FEMALE: processMeasurementsForSubmit({
          gender: "female",
          selectedMeasurement,
          allItems: measurementParametersFemale,
        }),
      };
    default:
      return {
        MALE: processMeasurementsForSubmit({
          gender: "male",
          selectedMeasurement,
          allItems: measurementParametersMale,
        }),
        FEMALE: processMeasurementsForSubmit({
          gender: "female",
          selectedMeasurement,
          allItems: measurementParametersFemale,
        }),
      };
  }
};

const genderOptions = [...genders.slice(0, 2), { id: 3, value: "Unisex" }];
const [maleOption, femaleOption, unisexOption] = genderOptions;
const getGenderOption = (
  genderToMeasurementParams?: GenderToMeasurementParamRefs
) => {
  const productTypeGender = getProductTypeGender(genderToMeasurementParams);

  switch (productTypeGender) {
    case "Female":
      return femaleOption;
    case "Unisex":
      return unisexOption;
    default:
      return maleOption;
  }
};

const moveField = ({
  prevState,
  hoverIndex,
  dragIndex,
}: {
  hoverIndex: number;
  dragIndex: number;
  prevState?: IMeasurementParameter[];
}) => {
  if (!prevState) return prevState;

  const updatedParameters = [...prevState];
  const [draggedItem] = updatedParameters.splice(dragIndex, 1);
  updatedParameters.splice(hoverIndex, 0, draggedItem);

  return updatedParameters;
};

const getInitialMeasurementParameters = ({
  rawItems,
  selectedIds,
}: {
  rawItems: IMeasurementParameter[];
  selectedIds?: string[];
}) => {
  const selected = selectedIds
    ?.map((measurementParamId) =>
      rawItems?.find((item) => item.measurementParamId === measurementParamId)
    )
    .filter(Boolean);
  const restItems = rawItems?.filter(
    (item) => !selectedIds?.includes(item.measurementParamId)
  );

  return [
    ...((selected || []) as IMeasurementParameter[]),
    ...(restItems || []),
  ];
};

const getInitiallySelectedMeasurementParamIds = (
  params?: GenderMeasurementParameter[]
) => {
  return sortGenderMeasurementParameters(params).map(
    ({ measurementParam }: GenderMeasurementParameter) =>
      measurementParam.measurementParamId
  );
};

export {
  getGenderToMeasurementParamRefs,
  getGenderOption,
  genderOptions,
  moveField,
  getInitialMeasurementParameters,
  getInitiallySelectedMeasurementParamIds,
};
