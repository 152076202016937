import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import CognitoUserReducer from "@store/reducers/CognitoUserSlice";
import UserReducer from "@store/reducers/UserSlice";
import TokensReducer from "@store/reducers/TokensSlice";
import EventReducer from "@store/reducers/EventSlice";
import { loadLocalStorageState } from "@services/common";

export const store = configureStore({
  reducer: {
    cognitoUser: CognitoUserReducer,
    userInfo: UserReducer,
    tokensInfo: TokensReducer,
    eventData: EventReducer,
  },
  preloadedState: loadLocalStorageState(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
