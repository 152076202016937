import { FC } from "react";
import { Header } from "@components/Header";
import { EndUserEventDetails } from "@components/EndUsers/EndUserEventDetails/EndUserEventDetails";
import { ISelectedEndUserEvent } from "@models/components/main/enduserevent";

export const SelectedEndUserEvent: FC<ISelectedEndUserEvent> = ({ type }) => {
  return (
    <>
      <Header />
      <EndUserEventDetails type={type} />
    </>
  );
};
