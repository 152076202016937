import { FC, memo } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useRef, useCallback, useState } from "react";
import { DraggableMeasurementProps } from "@models/common/measurements";

export const DraggableMeasurement: FC<DraggableMeasurementProps> = memo(
  ({
    param,
    index,
    type,
    moveField,
    selectedMeasurement,
    handleSelectMeasurementParam,
    onHoverIndexChange,
    hoverIndex,
  }) => {
    const dragRef = useRef<HTMLLIElement | null>(null);
    const dropRef = useRef<HTMLLIElement | null>(null);
    const [isHovering, setIsHovering] = useState(false);

    const onDrop = useCallback(
      (item: { index: number; type: "male" | "female" }) => {
        if (hoverIndex === null) {
          return;
        }
        moveField(item.index, hoverIndex);
        item.index = hoverIndex;
      },
      [index, moveField, hoverIndex]
    );

    const [{ isDragging }, drag, preview] = useDrag(
      () => ({
        type: "measurement",
        item: { index, type },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
        end() {
          onHoverIndexChange(null);
          setIsHovering(false);
        },
      }),
      [
        index,
        param.measurementParamId,
        selectedMeasurement,
        type,
        onHoverIndexChange,
      ]
    );

    const isHovered = hoverIndex === index;
    const opacity = isDragging ? 0.5 : 1;
    const isActiveDrop = Boolean(dropRef.current) && isHovering;

    const [, drop] = useDrop(
      () => ({
        accept: "measurement",
        hover: (item: { index: number; type: "male" | "female" }) => {
          if (item.index === index) {
            onHoverIndexChange(null);
            return;
          }

          if (item.index !== index || item.type !== type) {
            onHoverIndexChange(index);
            setIsHovering(true);
          }
        },
        drop: (item: { index: number; type: "male" | "female" }) => {
          onDrop(item);
        },
      }),
      [index, type, onDrop]
    );

    drag(drop(dragRef));

    const isSelected =
      selectedMeasurement?.some(
        (measurement) =>
          measurement.measurementParamId === param.measurementParamId
      ) || false;

    const handleClick = () => {
      const updatedSelectedMeasurement = isSelected
        ? selectedMeasurement?.filter(
            (measurement) =>
              measurement.measurementParamId !== param.measurementParamId
          ) || []
        : [...(selectedMeasurement || []), param];

      handleSelectMeasurementParam(updatedSelectedMeasurement, type);
    };

    return (
      <>
        <li
          ref={(node) => {
            preview(node);
            drag(drop(node));
            dragRef.current = node;
          }}
          className={`modal-tips-item ${isSelected ? "isActive" : ""} ${
            isActiveDrop ? "isDropping" : ""
          } ${isHovered ? "isHovered" : ""}`}
          style={{ opacity }}
          onClick={handleClick}
        >
          {param.name}
        </li>
      </>
    );
  }
);
