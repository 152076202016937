import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CognitoUserState, ICognitoUser } from "@models/common/user";

const initialState: CognitoUserState = {
  type: "",
  payload: undefined,
};

export const cognitoUserSlice = createSlice({
  name: "cognitoUser",
  initialState,
  reducers: {
    setCognitoUser: (state, { payload }: PayloadAction<ICognitoUser>) => {
      state.payload = payload;
    },
    clearCognitoUser: (state) => {
      state.payload = undefined;
    },
  },
});

export default cognitoUserSlice.reducer;
