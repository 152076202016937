import { FC, useEffect, useState } from "react";
import { TableHeader } from "@components/TableHeader/TableHeader";
import { salesRepTableHeaders } from "@constants/headers";
import { ISalesRepTable } from "@models/components/main/salesreptable";
import {
  formateCapitalizeStr,
  getUserRoleFromToken,
  hideWindowScroll,
  showWindowScroll,
} from "@services/common";
import { StatusBar } from "@components/StatusBar";
import { DropdownMenu } from "@components/Dropdown/DropdownMenu";
import {
  ACCEPTED_USER_STATUS,
  DEALER_ROLE,
  DELETED_USER_STATUS,
  DELETE_USER_NOTIFICATION,
  PENDING_USER_STATUS,
  SOMETHING_WENT_WRONG_NOTIFICATION,
} from "@constants/commons";
import { UserStatus } from "@models/common/user";
import { IManageUserInfo } from "@models/components/secondary/manualadduser";
import {
  deleteSalesRep,
  getSalesRepById,
  resendInviteSalesRep,
} from "@api/salesRep";
import {
  DEALER_INFO_ROUTE,
  MAIN_ROUTE,
  SALES_REP_INFO_ROUTE,
} from "@constants/routes";
import { ManageSalesRepModal } from "@pages/Modals/Users/ManageSalesRepModal";
import {
  DELETE_USER_BODY,
  DELETE_USER_TITLE,
  EDIT_SALES_REP_TITLE,
} from "@constants/users";
import { AlertModal } from "@components/AlertModal";
import { useNotification } from "@hooks/common/useNotification";
import { Notification } from "@components/Notification";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { selectTokens } from "@store/selectors/tokens";
import { useLocation, useNavigate } from "react-router-dom";
import { handleSalesRepUpdate } from "@api/common";
import { getFormattedError } from "@services/http";

export const SalesRepTable: FC<ISalesRepTable> = ({
  salesRep,
  handleSorting,
  sort,
  handleModalOpen: handleEditModalOpen,
  handleDeleteModal,
  deleteWindowOpen,
  handleManaged,
}) => {
  const [userInfo, setUserInfo] = useState<IManageUserInfo | undefined>();
  const [openSalesRepModal, setOpenSalesRepModal] = useState<boolean>(false);
  const [isOpened, setOpened] = useState<boolean>(false);
  const [salesRepId, setSalesRepId] = useState("");

  const dispatch = useAppDispatch();
  const tokensInfo = useAppSelector(selectTokens);
  const userRole = getUserRoleFromToken(tokensInfo);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    isActive,
    message: notificationMessage,
    type: notificationType,
    showNotification,
    closeNotification,
  } = useNotification();

  const toggleDropdown = (salesRepId: string) => {
    setOpened(!isOpened);
    setSalesRepId(salesRepId);
  };

  const handleDropdownMenu = (dropdownState: boolean) => {
    setOpened(dropdownState);
  };

  const handleModalOpen = () => {
    setOpenSalesRepModal(!openSalesRepModal);
    if (openSalesRepModal) {
      setUserInfo(undefined);
    }
    handleEditModalOpen();
  };

  const handleSalesRepEdit = async (salesRepId: string) => {
    try {
      const salesRep = await getSalesRepById(dispatch, salesRepId);
      setUserInfo({
        salesRepId: salesRep.salesRepId,
        email: salesRep?.user.email || "",
        phone_number: salesRep?.user.phoneNumber || "",
        firstName: salesRep?.user.firstName || "",
        lastName: salesRep?.user.lastName || "",
        dealersInfo: salesRep?.dealer ? [salesRep.dealer] : [],
      });

      handleModalOpen();
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    }
  };

  const handleSalesRepResendInvite = async (salesRepId: string) => {
    try {
      await resendInviteSalesRep(dispatch, salesRepId);
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    } finally {
      setOpened(false);
    }
  };

  const handleSalesRepDelete = async (salesRepId: string) => {
    try {
      if (salesRep?.length === 1) {
        await deleteSalesRep(dispatch, salesRepId);
        showNotification(DELETE_USER_NOTIFICATION, "success");
        handleManaged(true);
        navigate(
          `/${location.pathname.split(MAIN_ROUTE)[1]}/${
            typeof +location.pathname.split(MAIN_ROUTE)[2] === "number" &&
            +location.pathname.split(MAIN_ROUTE)[2] - 1 > 0
              ? +location.pathname.split(MAIN_ROUTE)[2] - 1
              : 1
          }${location.search}`
        );
      } else {
        await deleteSalesRep(dispatch, salesRepId);
        showNotification(DELETE_USER_NOTIFICATION, "success");
        handleManaged(true);
      }
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    } finally {
      setOpened(false);
    }
  };

  const handleDeleteWindowOpen = () => {
    handleDeleteModal();
    setOpened(false);
  };

  useEffect(() => {
    if (!openSalesRepModal) {
      setSalesRepId("");
    }
  }, [openSalesRepModal]);

  useEffect(() => {
    if (openSalesRepModal && salesRepId && userInfo) {
      showWindowScroll();
    } else {
      hideWindowScroll();
    }
  }, [openSalesRepModal, salesRepId, userInfo]);

  const salesRepTableDropdownItems = (
    salesRepId: string,
    salesRepStatus: UserStatus
  ) =>
    [
      {
        text: "Edit personal information",
        onClick: async () => {
          await handleSalesRepEdit(salesRepId).finally(() =>
            handleDropdownMenu(false)
          );
        },
      },
      salesRepStatus === PENDING_USER_STATUS
        ? {
            text: "Resend invite",
            onClick: async () => {
              await handleSalesRepResendInvite(salesRepId).finally(() =>
                handleDropdownMenu(false)
              );
            },
          }
        : null,
      salesRepStatus === PENDING_USER_STATUS ||
      salesRepStatus === ACCEPTED_USER_STATUS
        ? {
            text: "Delete",
            onClick: async () => {
              handleDeleteWindowOpen();
            },
          }
        : null,
    ].filter((item) => !!item);

  return (
    <>
      <Notification
        type={notificationType}
        message={notificationMessage}
        isActive={isActive}
        closeNotification={closeNotification}
      />
      {deleteWindowOpen && (
        <AlertModal
          type="delete"
          title={DELETE_USER_TITLE}
          body={DELETE_USER_BODY}
          handleOpen={handleDeleteWindowOpen}
          handleConfirm={handleSalesRepDelete}
          id={salesRepId}
        />
      )}
      {openSalesRepModal && salesRepId && userInfo && (
        <ManageSalesRepModal
          title={EDIT_SALES_REP_TITLE}
          handleModalOpen={handleModalOpen}
          userInfo={userInfo}
          handleUser={handleSalesRepUpdate}
        />
      )}
      <table className="event-table" cellSpacing={0} cellPadding={0} border={0}>
        <TableHeader
          sort={sort}
          headers={salesRepTableHeaders}
          handleSorting={handleSorting}
        />
        <tbody className="event-tbody">
          {salesRep.map((sr) => (
            <tr className="event-tr" key={sr.salesRepId}>
              <td
                className="event-td cursor-pointer event-link"
                data-label="Name"
              >
                <a
                  className="header-link"
                  href={`${SALES_REP_INFO_ROUTE}/${sr.salesRepId}/1`}
                >
                  {sr.user.firstName + " " + sr.user.lastName}
                </a>
              </td>
              <td className="event-td" data-label="Email">
                {sr.user.email}
              </td>
              <td className="event-td" data-label="Phone Number">
                {sr.user.phoneNumber}
              </td>
              <td className="event-td" data-label="System Status">
                <span
                  className={`status ${
                    sr.user.status === ACCEPTED_USER_STATUS
                      ? "status-success"
                      : sr.user.status === DELETED_USER_STATUS
                      ? "status-danger"
                      : sr.user.status === PENDING_USER_STATUS
                      ? "status-warning"
                      : ""
                  }`}
                >
                  {formateCapitalizeStr(sr.user.status)}
                </span>
              </td>
              <td className="event-td" data-label="Event's completeness">
                <div className="event-progress">
                  <StatusBar
                    value={
                      sr.assignedEventsTotal > 0
                        ? Math.ceil(
                            (sr.assignedEventsFinished /
                              sr.assignedEventsTotal) *
                              100
                          )
                        : 0
                    }
                  />
                  {sr.assignedEventsFinished}/{sr.assignedEventsTotal}
                </div>
              </td>
              {userRole !== DEALER_ROLE &&
                !location.pathname.includes(DEALER_INFO_ROUTE) && (
                  <td className="event-td" data-label="Dealer">
                    {sr.dealer.dealerName}({sr.dealer.user.firstName}{" "}
                    {sr.dealer.user.lastName})
                  </td>
                )}
              <td className="event-td" data-label="Actions">
                {!!salesRepTableDropdownItems(sr.salesRepId, sr.user.status)
                  ?.length && (
                  <div className="header-dropdown">
                    <button
                      className={`header-dropdown-name ${
                        isOpened && "isActive"
                      } event-table-btn`}
                      onClick={() => toggleDropdown(sr.salesRepId)}
                    >
                      <i className="event-table-icon icon icon-kebab" />
                    </button>
                    {isOpened && salesRepId === sr.salesRepId && (
                      <DropdownMenu
                        dropdownItems={salesRepTableDropdownItems(
                          sr.salesRepId,
                          sr.user.status
                        )}
                        dropdownOpened={isOpened}
                        setDropdownOpened={handleDropdownMenu}
                      />
                    )}
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
