export const AUTH_EXPIRED_ERROR = "401: Failed to authenticate";
export const SIGN_IN_ERROR = "Failed to sign in";
export const SIGN_UP_ERROR = "Failed to sign up";
export const FORGOT_PASSWORD_ERROR = "Failed to change password";

export const INCORRECT_CODE = "The provided code is not valid or has expired";

export const FAIL_CHANGE_PASS =
  "Failed to change your password, please try again";

export const NOT_SHOWN_ERRORS = ["Pending sign-in attempt already in progress"];

export const FAILED_PASSWORD =
  "The password is not secure. Try a different password";

export const FAILED_PASSWORD_AUTH =
  "Email/Phone Number or Password is not correct";

export const FAILED_TEMP_CODE =
  "The code is wrong. Please, click on the 'Resend code' button and try with another code";

export const FAILED_LOAD_MESSAGE =
  "Failed to load page. Try reloading the page or logging in again";

export const NO_EVENTS_PLUG_ERROR = "There are no events yet";
export const NO_SALES_REP_PLUG_ERROR = "There are no Sales Representatives yet";
export const NO_DEALERS_PLUG_ERROR = "There are no Dealers yet";
export const NO_PARENTS_PLUG_ERROR = "There are no Parents yet";
export const NO_FOUND_PLUG_ERROR = "Sorry, no matches found";
export const NO_END_USERS_PLUG_ERROR = "There are no end-users yet";
export const NO_PRODUCT_TYPES_PLUG_ERROR = "There are no product types yet";

export const EMAIL_ALREADY_USED_ERROR =
  "This email address is already being used";
export const PHONE_NUMBER_ALREADY_USED_ERROR =
  "This phone number is already being used";
export const EMAIL_PHONE_NUMBER_ALREADY_USED_ERROR =
  "This email address and phone number are already being used";
export const EMAIL_OR_PHONE_NUMBER_ALREADY_USED_ERROR =
  "An account with such email or phone number is already being used";

export const BULK_ERROR_TIMEOUT = "The time of uploading is out";
export const BULK_ERROR_SIZE = "The file size is bigger than maximum size";
export const BULK_ERROR_CONNECTION =
  "Something went wrong. Please, check the internet connection";
export const BULK_ERROR_REPORT =
  "The data in the file has critical errors. Please, download the error report to view errors and then try again";
