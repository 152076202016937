import { FC, useEffect, useState } from "react";
import { StatusBar } from "@components/StatusBar";
import { Loader } from "@components/Loader";
import { ISalesRep } from "@models/common/salesRep";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getSalesRepById } from "@api/salesRep";
import { formateCapitalizeStr, getPage, getSortTypes } from "@services/common";
import {
  ACCEPTED_USER_STATUS,
  DELETED_USER_STATUS,
  NO_EVENTS_PLUG_TYPE,
  NO_FOUND_PLUG_TYPE,
  PENDING_USER_STATUS,
  SALES_REPS_BREADCRUMBS,
  SEARCH_QUERY_KEY,
  TABLE_ROWS_LIMIT,
} from "@constants/commons";
import { EventLocation, EventStatus, IEventList } from "@models/common/events";
import { getEventsByFilterTable, listOfFilteredEvents } from "@api/events";
import { ADD_EVENT_ROUTE, MAIN_ROUTE } from "@constants/routes";
import { HeaderBarTable } from "@components/HeaderBarTable";
import { EventTable } from "@components/Events/EventTable";
import { Pagination } from "@components/Pagination";
import { Plug } from "@components/Plug";
import { NO_EVENTS_PLUG_ERROR, NO_FOUND_PLUG_ERROR } from "@constants/errors";
import { Breadcrumbs } from "@components/Breadcrumbs";
import { ISortType } from "@models/common/app";
import queryString from "query-string";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { MiniLoader } from "@components/Loader/MiniLoader";
import { getFormattedError } from "@services/http";

export const SalesRepInfo: FC = () => {
  const location = useLocation();
  const parsedSearchedQuery = queryString.parse(location.search)?.[
    SEARCH_QUERY_KEY
  ];

  const [salesRepData, setSalesRepData] = useState<ISalesRep>();
  const [salesRepEvents, setSalesRepEvents] = useState<IEventList>();
  const [searchedValue, setSearchedValue] = useState<string | undefined>(
    typeof parsedSearchedQuery === "string" ? parsedSearchedQuery : undefined
  );
  const [sortType, setSortType] = useState<ISortType>();
  const [filtered, setFiltered] = useState(false);
  const [searchParams, setSearchParams] = useState<
    queryString.ParsedQuery<string>
  >(queryString.parse(location.search));
  const [deleteWindowOpen, setDeleteWindowOpen] = useState(false);
  const [cancelWindowOpen, setCancelWindowOpen] = useState(false);
  const [eventManaged, setEventManaged] = useState(false);
  const [paginationChanged, setPaginationChanged] = useState(false);
  const [searchFieldFocused, setSearchFieldFocused] = useState(false);

  const { salesRepId, page } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const pageId = getPage(page);

  const handleSearchFieldFocus = () => {
    setSearchFieldFocused(true);
  };

  const handleEventManaged = (state: boolean) => {
    setEventManaged(state);
  };

  const handleCancelWindowOpen = () => {
    setCancelWindowOpen(!cancelWindowOpen);
  };

  const handleDeleteWindowOpen = () => {
    setDeleteWindowOpen(!deleteWindowOpen);
  };

  const addBtnHandler = () => {
    navigate(ADD_EVENT_ROUTE);
  };

  const handleFilteredEvents = (events: IEventList) => {
    setSalesRepEvents(events);
  };

  const handleSearchedEvents = (searchedValue: string | undefined) => {
    setSearchedValue(searchedValue);
  };

  const handleFilter = (state: boolean) => {
    setFiltered(state);
  };

  const handleSorting = (parameter: string) => {
    let filteredParameter;

    if (parameter === "Event Name") {
      filteredParameter = "name";
    }
    if (parameter === "Start/End of the Event") {
      filteredParameter = "endsAt";
    }
    if (parameter === "Status") {
      filteredParameter = "status";
    }

    setSortType(getSortTypes(sortType, filteredParameter, parameter));
  };

  useEffect(() => {
    if (salesRepId) {
      (async function asyncWrapper() {
        try {
          const salesRep = await getSalesRepById(dispatch, salesRepId);
          setSalesRepData(salesRep);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [salesRepId]);

  useEffect(() => {
    if (
      !deleteWindowOpen &&
      !cancelWindowOpen &&
      !searchParams?.[SEARCH_QUERY_KEY] &&
      !filtered
    ) {
      (async function asyncWrapper() {
        try {
          const events = await listOfFilteredEvents(
            dispatch,
            pageId,
            TABLE_ROWS_LIMIT,
            sortType ? sortType.filteredParameter : "endsAt,desc",
            searchedValue,
            salesRepId
          );
          handleFilteredEvents(events);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [
    pageId,
    sortType,
    deleteWindowOpen,
    cancelWindowOpen,
    eventManaged,
    searchParams?.[SEARCH_QUERY_KEY],
    filtered,
  ]);

  useEffect(() => {
    if (!filtered && searchFieldFocused) {
      if (!!searchedValue) {
        if (pageId + 1 === 1) {
          navigate(`?${SEARCH_QUERY_KEY}=${searchedValue}`);
        } else {
          navigate(
            `/${
              location.pathname.split(MAIN_ROUTE)[1]
            }/1?${SEARCH_QUERY_KEY}=${searchedValue}`
          );
        }
      } else {
        navigate("");
      }
    }
  }, [filtered, searchedValue]);

  useEffect(() => {
    if (searchedValue && !filtered) {
      (async function asyncWrapper() {
        try {
          const events = await listOfFilteredEvents(
            dispatch,
            pageId,
            TABLE_ROWS_LIMIT,
            sortType ? sortType.filteredParameter : "endsAt,desc",
            searchedValue,
            salesRepId
          );
          handleFilteredEvents(events);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [pageId, sortType, searchedValue, filtered]);

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    if (!!Object.keys(searchParams).length) {
      if (filtered) {
        paginationChanged && setPaginationChanged(false);
        const filteredSearchParams = { ...searchParams };
        if (SEARCH_QUERY_KEY in filteredSearchParams) {
          delete filteredSearchParams[SEARCH_QUERY_KEY];
        }
        setSearchParams(filteredSearchParams);
      } else {
        setSearchParams(searchParams);
      }
    } else {
      setSearchParams({});
    }
  }, [location.search, filtered]);

  useEffect(() => {
    if (!!searchParams?.[SEARCH_QUERY_KEY] && !filtered) {
      handleSearchedEvents(searchParams[SEARCH_QUERY_KEY].toString());
    }
  }, [searchParams?.[SEARCH_QUERY_KEY], filtered]);

  useEffect(() => {
    const filteredSearchParams = { ...searchParams };
    if (SEARCH_QUERY_KEY in filteredSearchParams) {
      delete filteredSearchParams[SEARCH_QUERY_KEY];
    }
    if (
      filtered &&
      !!Object.keys(filteredSearchParams).length &&
      !deleteWindowOpen &&
      !cancelWindowOpen
    ) {
      if (!paginationChanged) {
        navigate(
          `/${location.pathname.split(MAIN_ROUTE)[1]}/${salesRepId}/1${
            location.search
          }`
        );
        setPaginationChanged(true);
      }

      const checkString = (str: string | (string | null)[] | null) => {
        return typeof str === "string" ? str : undefined;
      };

      const upcomingDate = checkString(filteredSearchParams?.upcomingDate);

      (async function asyncWrapper() {
        try {
          const events = await getEventsByFilterTable({
            dispatch,
            upcomingDate: upcomingDate ? JSON.parse(upcomingDate) : undefined,
            startDate: checkString(filteredSearchParams?.startDate),
            endDate: checkString(filteredSearchParams?.endDate),
            eventLocation: checkString(filteredSearchParams?.eventLocation) as
              | EventLocation
              | undefined,
            eventStatus: checkString(filteredSearchParams?.eventStatus) as
              | EventStatus
              | undefined,
            productTypeId: checkString(filteredSearchParams?.productTypeId),
            eventCreator: checkString(filteredSearchParams?.eventCreator),
            page: pageId,
            size: TABLE_ROWS_LIMIT,
            sort: sortType ? sortType.filteredParameter : "endsAt,desc",
            salesRepId,
          });
          handleFilteredEvents(events);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [
    filtered,
    searchParams,
    pageId,
    sortType,
    salesRepId,
    cancelWindowOpen,
    deleteWindowOpen,
  ]);

  return (
    <>
      {salesRepData ? (
        <main className="main-container info-container">
          <Breadcrumbs
            title={`Sales representative - ${salesRepData.user.firstName} 
            ${salesRepData.user.lastName}`}
            type={SALES_REPS_BREADCRUMBS}
          />
          <h2 className="event-header event-title">
            Sales representative - {salesRepData.user.firstName}{" "}
            {salesRepData.user.lastName}
          </h2>
          <ul className="sales">
            <li className="sales-item">
              Email:{" "}
              <span className="sales-val">
                {salesRepData.user.email ? salesRepData.user.email : "-"}
              </span>
            </li>
            <li className="sales-item">
              Phone number:{" "}
              <span className="sales-val">
                {salesRepData.user.phoneNumber
                  ? salesRepData.user.phoneNumber
                  : "-"}
              </span>
            </li>
            <li className="sales-item">
              System status:{" "}
              <span
                className={`sales-status status ${
                  salesRepData.user.status === ACCEPTED_USER_STATUS
                    ? "status-success"
                    : salesRepData.user.status === DELETED_USER_STATUS
                    ? "status-danger"
                    : salesRepData.user.status === PENDING_USER_STATUS
                    ? "status-warning"
                    : ""
                }`}
              >
                {formateCapitalizeStr(salesRepData.user.status)}
              </span>
            </li>
            <li className="sales-item">
              Event's completeness:{" "}
              <div className="event-progress sales-val sales-bar">
                <StatusBar
                  value={
                    salesRepData.assignedEventsTotal > 0
                      ? Math.ceil(
                          (salesRepData.assignedEventsFinished /
                            salesRepData.assignedEventsTotal) *
                            100
                        )
                      : 0
                  }
                />
                {salesRepData.assignedEventsFinished}/
                {salesRepData.assignedEventsTotal}
              </div>
            </li>
            <li className="sales-item">
              Dealer name:{" "}
              <span className="sales-val">
                {salesRepData.dealer.dealerName}(
                {salesRepData.dealer.user.firstName}{" "}
                {salesRepData.dealer.user.lastName})
              </span>
            </li>
          </ul>
        </main>
      ) : (
        <Loader />
      )}
      <>
        {salesRepEvents ? (
          <main className="main-container">
            <HeaderBarTable
              searchedValue={searchedValue}
              handleFilteredValues={handleFilteredEvents}
              handleSearchedValues={handleSearchedEvents}
              handleFilter={handleFilter}
              title="Events"
              btnTitles="Add new event"
              btnHandlers={[addBtnHandler]}
              sort={sortType}
              filtered={filtered}
              handleSearchFieldFocus={handleSearchFieldFocus}
            />
            {!!salesRepEvents.content.length ? (
              <div className="event-table-container">
                <EventTable
                  sort={sortType}
                  events={salesRepEvents.content}
                  handleSorting={handleSorting}
                  deleteWindowOpen={deleteWindowOpen}
                  handleDeleteModal={handleDeleteWindowOpen}
                  cancelWindowOpen={cancelWindowOpen}
                  handleCancelModal={handleCancelWindowOpen}
                  handleEventManaged={handleEventManaged}
                  eventManaged={eventManaged}
                />
                <Pagination
                  dataLength={salesRepEvents.totalElements}
                  pageId={pageId}
                />
              </div>
            ) : (
              <MiniLoader
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5vh",
                }}
                time={3}
                afterload={
                  <Plug
                    message={
                      searchParams && !!Object.keys(searchParams)?.length
                        ? NO_FOUND_PLUG_ERROR
                        : NO_EVENTS_PLUG_ERROR
                    }
                    type={
                      searchParams && !!Object.keys(searchParams)?.length
                        ? NO_FOUND_PLUG_TYPE
                        : NO_EVENTS_PLUG_TYPE
                    }
                  />
                }
              />
            )}
          </main>
        ) : (
          <Loader />
        )}
      </>
    </>
  );
};
