import { FC, useEffect, useState } from "react";
import { LOADER_MAX_TIMEOUT, NO_NETWORK_PLUG_TYPE } from "@constants/commons";
import { Plug } from "@components/Plug";
import { FAILED_LOAD_MESSAGE } from "@constants/errors";

export const Loader: FC = () => {
  const [seconds, setSeconds] = useState(LOADER_MAX_TIMEOUT);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(0);
    }
  }, [seconds]);

  return (
    <main className="main-container">
      {seconds > 0 ? (
        <Plug load />
      ) : (
        <Plug message={FAILED_LOAD_MESSAGE} type={NO_NETWORK_PLUG_TYPE} />
      )}
    </main>
  );
};
