import { FC, useEffect, useRef, useState } from "react";
import { Button } from "@components/Button";
import { IFilters, IFiltersForm } from "@models/components/secondary/filters";
import { FormProvider, useForm } from "react-hook-form";
import { FilterButtonset } from "./FilterButtonset";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchemas } from "@services/validation";
import {
  ADMIN_ROLE,
  CREATOR_FILTERS_TITLE,
  DATES_FILTERS_TITLE,
  DEALER_ROLE,
  filterCreatorButtons,
  LOCATION_FILTERS_TITLE,
  PARENT_ROLE,
  PRODUCT_FILTERS_TITLE,
  STATUS_FILTERS_TITLE,
} from "@constants/commons";
import {
  IFilterDateRadios,
  IFilterLocationRadios,
} from "@models/components/secondary/filterfieldset";
import {
  EndUserEventStatus,
  EventCreator,
  EventStatus,
  ProductType,
} from "@models/common/events";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { selectTokens } from "@store/selectors/tokens";
import {
  ALL_STATUS,
  IN_PERSON_EVENT_TYPE,
  REMOTE_EVENT_TYPE,
} from "@constants/events";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { FilterDateFieldset, FilterLocationFieldset } from "./FilterFieldset";
import { getUserRoleFromToken } from "@services/common";

export const Filters: FC<IFilters> = ({
  modalOpen,
  handleModalOpen,
  handleFilter,
  type,
}) => {
  const location = useLocation();

  const filtersMethods = useForm<IFiltersForm>({
    mode: "onSubmit",
    shouldFocusError: false,
    resolver: yupResolver(validationSchemas.filters),
    defaultValues: {
      allDate: "true",
      upcomingDate: "false",
      rangeDate: "false",
      allLocations: "true",
      locationOnly: "false",
      locationRemote: "false",
    },
  });

  const [dateRadios, setDateRadios] = useState<IFilterDateRadios>({
    allDate: true,
    upcomingDate: false,
    rangeDate: false,
  });
  const [locationRadios, setLocationRadios] = useState<IFilterLocationRadios>({
    allLocations: true,
    locationOnly: false,
    locationRemote: false,
  });
  const [filterStatus, setFilterStatus] = useState<
    Array<{
      id: number;
      value: EventStatus | EndUserEventStatus;
    }>
  >([{ id: 1, value: ALL_STATUS }]);
  const [productType, setProductType] = useState<ProductType[]>([
    {
      productTypeId: "all",
      name: ALL_STATUS,
      genderToMeasurementParams: { MALE: undefined, FEMALE: undefined },
    },
  ]);
  const [productTypes, setProductTypes] = useState<ProductType[]>([
    {
      productTypeId: "all",
      name: ALL_STATUS,
      genderToMeasurementParams: { FEMALE: undefined, MALE: undefined },
    },
  ]);
  // const test = filterCreatorButtons;
  const [filterCreator, setFilterCreator] = useState<
    Array<{
      id: number;
      value: EventCreator;
    }>
  >([{ id: 1, value: ALL_STATUS }]);
  const [applied, setApplied] = useState(false);
  const [searchParams, setSearchParams] = useState<
    queryString.ParsedQuery<string> | undefined
  >(queryString.parse(location.search));

  const { handleSubmit, setValue, watch } = filtersMethods;
  const {
    endDate,
    startDate,
    upcomingDate,
    rangeDate,
    allLocations,
    filterCreator: watchFilterCreator,
    filterProductType,
    filterStatus: watchFilterStatus,
    locationOnly,
  } = watch();

  const tokensInfo = useAppSelector(selectTokens);
  const userRole = getUserRoleFromToken(tokensInfo);
  const navigate = useNavigate();
  const handleDateRadios = (value: IFilterDateRadios) => {
    setDateRadios(value);
  };

  const handleLocationRadios = (value: IFilterLocationRadios) => {
    setLocationRadios(value);
  };

  const handleApply = (state: boolean) => {
    setApplied(state);
    handleFilter(state);
  };

  const handleClearAll = () => {
    handleDateRadios({
      allDate: true,
      upcomingDate: false,
      rangeDate: false,
    });
    handleLocationRadios({
      allLocations: true,
      locationOnly: false,
      locationRemote: false,
    });
    handleFilterStatus({ id: 1, value: ALL_STATUS });
    handleFilterProductType({
      productTypeId: "all",
      name: "All",
      genderToMeasurementParams: { MALE: undefined, FEMALE: undefined },
    });
    handleFilterCreator({ id: 1, value: ALL_STATUS });
  };

  const handleFilterStatus = (status: {
    id: number;
    value: EventStatus | EndUserEventStatus;
  }) => {
    if (status.id === 1) {
      setFilterStatus([status]);
    } else {
      const filteredStatuses = filterStatus.filter(
        (s) => s.id !== 1 && s.id !== status.id
      );
      if (filterStatus.some((s) => s.id === status.id)) {
        setFilterStatus(
          !filteredStatuses.length
            ? [{ id: 1, value: ALL_STATUS }]
            : [...filteredStatuses]
        );
      } else {
        setFilterStatus([...filteredStatuses, status]);
      }
    }
  };

  const handleFilterProductType = (type: ProductType) => {
    if (type.productTypeId === "all") {
      setProductType([type]);
    } else {
      const filteredTypes = productType.filter(
        (t) =>
          t.productTypeId !== "all" && t.productTypeId !== type.productTypeId
      );
      if (productType.some((pt) => pt.productTypeId === type.productTypeId)) {
        setProductType(
          !filteredTypes.length
            ? [
                {
                  productTypeId: "all",
                  name: ALL_STATUS,
                  genderToMeasurementParams: {
                    MALE: undefined,
                    FEMALE: undefined,
                  },
                },
              ]
            : [...filteredTypes]
        );
      } else {
        setProductType([...filteredTypes, type]);
      }
    }
  };

  const handleFilterProductTypes = (types: ProductType[]) => {
    setProductTypes(types);
  };

  const handleFilterCreator = (creator: {
    id: number;
    value: EventCreator;
  }) => {
    if (creator.id === 1) {
      setFilterCreator([creator]);
    } else {
      const filteredCreators = filterCreator.filter(
        (c) => c.id !== 1 && c.id !== creator.id
      );
      if (filterCreator.some((c) => c.id === creator.id)) {
        setFilterCreator(
          !filteredCreators.length
            ? [{ id: 1, value: ALL_STATUS }]
            : [...filteredCreators]
        );
      } else {
        setFilterCreator([...filteredCreators, creator]);
      }
    }
  };

  useEffect(() => {
    const searchQuery = queryString.parse(location.search);
    if (!!Object.keys(searchQuery).length) {
      setSearchParams(searchQuery);
    } else {
      setSearchParams({});
    }
  }, [location.search]);

  useEffect(() => {
    if (searchParams?.upcomingDate === "true") {
      handleDateRadios({
        allDate: false,
        upcomingDate: true,
        rangeDate: false,
      });
    }
    if (
      typeof searchParams?.startDate === "string" &&
      typeof searchParams?.endDate === "string" &&
      !!searchParams?.startDate &&
      !!searchParams?.endDate
    ) {
      handleDateRadios({
        allDate: false,
        upcomingDate: false,
        rangeDate: true,
      });
      setValue("startDate", new Date(searchParams.startDate) || new Date());
      setValue("endDate", new Date(searchParams.endDate) || new Date());
    }
    if (searchParams?.eventLocation === IN_PERSON_EVENT_TYPE) {
      handleLocationRadios({
        allLocations: false,
        locationOnly: true,
        locationRemote: false,
      });
    }
    if (searchParams?.eventLocation === REMOTE_EVENT_TYPE) {
      handleLocationRadios({
        allLocations: false,
        locationOnly: false,
        locationRemote: true,
      });
    }
    if (
      searchParams?.eventStatus &&
      typeof searchParams.eventStatus === "string"
    ) {
      const eventStatuses = searchParams.eventStatus.split(
        ", "
      ) as EventStatus[];
      const filteredEventStatuses = eventStatuses.map((status, index) =>
        status === "All"
          ? { id: 1, value: status }
          : { id: index + 2, value: status }
      );
      setFilterStatus(filteredEventStatuses);
    }
    if (
      searchParams?.measurementStatus &&
      typeof searchParams.measurementStatus === "string"
    ) {
      const userStatuses = searchParams.measurementStatus.split(
        ", "
      ) as EndUserEventStatus[];
      const filteredUsersStatuses = userStatuses.map((status, index) =>
        status === "All"
          ? { id: 1, value: status }
          : { id: index + 2, value: status }
      );
      setFilterStatus(filteredUsersStatuses);
    }
    if (
      searchParams?.eventCreator &&
      typeof searchParams.eventCreator === "string"
    ) {
      const eventCreators = searchParams.eventCreator.split(
        ", "
      ) as EventCreator[];
      const filteredEventCreators = eventCreators.map((creator, index) =>
        creator === "All"
          ? { id: 1, value: creator }
          : {
              id: filterCreatorButtons.find((el) => el.value === creator)
                ?.id as number,
              value: creator,
            }
      );
      setFilterCreator(filteredEventCreators);
    }
    if (
      searchParams?.productTypeId &&
      typeof searchParams.productTypeId === "string" &&
      !!productTypes.length
    ) {
      const searchedProductTypes = searchParams.productTypeId.split(", ");
      const selectedProductTypes = searchedProductTypes
        .map((productTypeId) =>
          productTypes.find(
            (productType) => productType.productTypeId === productTypeId
          )
        )
        .filter((productType) => !!productType);

      setProductType(
        !!selectedProductTypes.length
          ? (selectedProductTypes as ProductType[])
          : [
              {
                productTypeId: "all",
                name: ALL_STATUS,
                genderToMeasurementParams: {
                  MALE: undefined,
                  FEMALE: undefined,
                },
              },
            ]
      );
    }
  }, [searchParams, productTypes]);

  useEffect(() => {
    setValue(
      "filterStatus",
      filterStatus?.map((status) => status.value).join(", ") || ""
    );
  }, [filterStatus]);

  useEffect(() => {
    setValue(
      "filterProductType",
      productType?.map((pt) => pt.productTypeId).join(", ") || ""
    );
  }, [productType]);

  useEffect(() => {
    setValue(
      "filterCreator",
      filterCreator?.map((creator) => creator.value).join(", ") || ""
    );
  }, [filterCreator]);

  useEffect(() => {
    setValue("allDate", dateRadios.allDate?.toString());
  }, [dateRadios.allDate]);

  useEffect(() => {
    setValue("upcomingDate", dateRadios.upcomingDate?.toString());
    if (dateRadios.upcomingDate) {
      setValue("startDate", undefined);
      setValue("endDate", undefined);
    }
  }, [dateRadios.upcomingDate]);

  useEffect(() => {
    setValue("rangeDate", dateRadios.rangeDate?.toString());
  }, [dateRadios.rangeDate]);

  useEffect(() => {
    setValue("allLocations", locationRadios.allLocations?.toString());
  }, [locationRadios.allLocations]);

  useEffect(() => {
    setValue("locationOnly", locationRadios.locationOnly?.toString());
  }, [locationRadios.locationOnly]);

  useEffect(() => {
    setValue("locationRemote", locationRadios.locationRemote?.toString());
  }, [locationRadios.locationRemote]);

  useEffect(() => {
    if (startDate && endDate) {
      if (Date.parse(endDate.toString()) < Date.parse(startDate.toString())) {
        startDate &&
          setValue(
            "endDate",
            new Date((startDate || new Date()).getTime() + 24 * 60 * 60 * 1000)
          );
      }
    }
  }, [startDate, endDate]);

  const onSubmit = async () => {
    if (applied) {
      let filterString = "";
      if (JSON.parse(upcomingDate)) {
        filterString += `?upcomingDate=true`;
      } else {
        filterString += `?upcomingDate=false`;
      }
      if (JSON.parse(rangeDate)) {
        filterString += `&startDate=${startDate?.toISOString()}`;
        filterString += `&endDate=${endDate?.toISOString()}`;
      }
      if (!JSON.parse(allLocations)) {
        filterString += JSON.parse(locationOnly)
          ? `&eventLocation=${IN_PERSON_EVENT_TYPE}`
          : `&eventLocation=${REMOTE_EVENT_TYPE}`;
      }
      if (watchFilterStatus !== ALL_STATUS && type === "all") {
        filterString += `&eventStatus=${watchFilterStatus}`;
      }
      if (watchFilterStatus !== ALL_STATUS && type === "user") {
        filterString += `&measurementStatus=${watchFilterStatus}`;
      }
      if (filterProductType !== "all") {
        filterString += `&productTypeId=${filterProductType}`;
      }
      if (watchFilterCreator !== ALL_STATUS) {
        filterString += `&eventCreator=${watchFilterCreator}`;
      }

      navigate(filterString);
      setApplied(false);
      handleModalOpen();
    }
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        modalOpen &&
        ref.current &&
        !ref.current.contains(e.target as HTMLDivElement)
      ) {
        handleModalOpen();
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [modalOpen]);

  if (!modalOpen) {
    return <></>;
  }

  const isApplyBtnDisabled =
    dateRadios.rangeDate && (!startDate || !endDate) ? true : false;

  return (
    <>
      <div className="filter isActive shadow" ref={ref}>
        <FormProvider {...filtersMethods}>
          <form
            className="filter-form-wrapper"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="filter-header">
              <h2 className="filter-title ">Filters</h2>
              <Button
                text="Clear all"
                className="filter-clear btn-secondary"
                onClick={handleClearAll}
              />
            </div>
            <div>
              <FilterDateFieldset
                title={DATES_FILTERS_TITLE}
                handleDateRadios={handleDateRadios}
                dateRadios={dateRadios}
              />
              <FilterLocationFieldset
                title={LOCATION_FILTERS_TITLE}
                handleLocationRadios={handleLocationRadios}
                locationRadios={locationRadios}
              />
              <FilterButtonset
                title={STATUS_FILTERS_TITLE}
                filterStatus={filterStatus}
                handleFilterStatus={handleFilterStatus}
                type={type}
              />
              {(userRole === ADMIN_ROLE ||
                userRole === PARENT_ROLE ||
                userRole === DEALER_ROLE) &&
                type === "all" && (
                  <FilterButtonset
                    title={CREATOR_FILTERS_TITLE}
                    filterCreator={filterCreator}
                    handleFilterCreator={handleFilterCreator}
                  />
                )}
              {type === "all" && (
                <FilterButtonset
                  title={PRODUCT_FILTERS_TITLE}
                  productType={productType}
                  handleProductType={handleFilterProductType}
                  handleProductTypes={handleFilterProductTypes}
                  searchParams={searchParams}
                />
              )}
            </div>
            <div className="filter-controls">
              <Button
                text="Apply"
                className="btn-main"
                type="submit"
                disable={isApplyBtnDisabled}
                onClick={() => handleApply(true)}
              />
              <Button
                text="Cancel"
                className="btn-primary"
                onClick={() => {
                  handleModalOpen();
                  handleApply(false);
                }}
              />
            </div>
          </form>
        </FormProvider>
      </div>
      <div className="modal-back" />
    </>
  );
};
