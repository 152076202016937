import { useCallback, useEffect, useState } from "react";
import { IError } from "@models/common/app";
import { ICredentials } from "@models/auth/data";
import {
  SIGN_IN_EMAIL_CODE,
  SIGN_IN_PHONE_CODE,
  SIGN_IN_PASSWORD,
  SIGN_IN_SUBMIT_CODE,
  SIGN_IN_NEW_PASSWORD,
  OTP_VERIFIER,
  SIGN_IN_FORGOT_PASSWORD,
} from "@constants/commons";
import { INCORRECT_CODE } from "@constants/errors";
import {
  getAuthErrorType,
  getAuthFlowType,
  getLoginCredentials,
  getLoginType,
} from "@services/auth";
import { Auth } from "aws-amplify";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { cognitoUserSlice } from "@store/reducers/CognitoUserSlice";
import { tokensInfoSlice } from "@store/reducers/TokensSlice";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { selectUserInfo } from "@store/selectors/user";
import { selectCognitoUser } from "@store/selectors/cognitoUser";
import { ICognitoUser } from "@models/common/user";

export const useLogin = (
  credentials: ICredentials | undefined,
  skip?: boolean,
  resendCode?: boolean
) => {
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [errorCount, setErrorCount] = useState<number>(0);

  const dispatch = useAppDispatch();
  const cognitoUser = useAppSelector(selectCognitoUser);
  const userInfo = useAppSelector(selectUserInfo);

  const loginHandler = useCallback(async () => {
    if (credentials && !skip) {
      try {
        setIsAuthenticating(true);

        const { username, code, password } = getLoginCredentials(
          credentials,
          userInfo
        );

        const type = getLoginType(credentials);
        const authFlow = getAuthFlowType(credentials?.confirmTerms);

        let response: ICognitoUser;

        if (type === SIGN_IN_PASSWORD) {
          Auth.configure({
            userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
          });
          response = await Auth.signIn({
            username,
            password,
          });
        } else if (type === SIGN_IN_PHONE_CODE) {
          Auth.configure({
            userPoolWebClientId:
              process.env.REACT_APP_PHONE_USER_POOL_WEB_CLIENT_ID,
          });
          response = await Auth.signIn(username, undefined, {
            authFlow,
          });

          dispatch(cognitoUserSlice.actions.setCognitoUser(response));
        } else if (type === SIGN_IN_EMAIL_CODE) {
          Auth.configure({
            userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
          });
          response = await Auth.signIn(username);

          dispatch(cognitoUserSlice.actions.setCognitoUser(response));
        } else if (type === SIGN_IN_SUBMIT_CODE) {
          response = await Auth.sendCustomChallengeAnswer(cognitoUser, code, {
            authFlow,
          });

          if (response?.challengeParam?.challengeType === OTP_VERIFIER) {
            response = await Auth.sendCustomChallengeAnswer(cognitoUser, code, {
              authFlow,
            });
          }

          if (response?.challengeParam?.challengeType === OTP_VERIFIER) {
            throw new Error(INCORRECT_CODE);
          }
        } else if (
          type === SIGN_IN_NEW_PASSWORD ||
          type === SIGN_IN_FORGOT_PASSWORD
        ) {
          response = await Auth.sendCustomChallengeAnswer(
            cognitoUser,
            password,
            {
              authFlow,
            }
          );
        } else {
          throw new Error(getAuthErrorType(authFlow));
        }

        if (!resendCode) {
          setIsAuthenticated(true);
        }

        if (response?.signInUserSession) {
          dispatch(
            tokensInfoSlice.actions.setTokens(response.signInUserSession)
          );
          localStorage.setItem(
            `${process.env.REACT_APP_TOKENS_STATE}`,
            JSON.stringify(response.signInUserSession)
          );
        }

        return response;
      } catch (err) {
        const error = err as IError;

        setError(error.message);
        setErrorCount((prevCount) => prevCount + 1);
      } finally {
        setIsAuthenticating(false);
      }
    }
  }, [credentials, skip]);

  useEffect(() => {
    !error && !isAuthenticated && loginHandler();
  }, [error, isAuthenticated, loginHandler]);

  return {
    loginError: error,
    clearError: () => setError(""),
    clearAuthentificated: () => {
      setIsAuthenticated(false);
      setErrorCount(0);
    },
    isAuthenticating,
    isAuthenticated,
    errorCount,
  };
};
