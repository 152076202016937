import React, { FC, useEffect, useState } from "react";
import { Button } from "@components/Button";
import { Input } from "@components/Input";
import { PortalModal } from "@components/PortalModal";
import {
  createMeasurementParam,
  getMeasurementsBySearchKey,
  updateMeasurementParam,
} from "@api/measurements";
import { IMeasurementParameter } from "@models/common/measurements";
import { FormProvider, useForm } from "react-hook-form";
import {
  IViewMeasurementsForm,
  IViewMeasurementsModal,
} from "@models/components/secondary/measurementsmodal";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchemas } from "@services/validation";
import { useFilePicker } from "use-file-picker";
import {
  measurementsImageExtensions,
  SOMETHING_WENT_WRONG_NOTIFICATION,
} from "@constants/commons";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { useNotification } from "@hooks/common/useNotification";
import { Notification } from "@components/Notification";
import { MiniLoader } from "@components/Loader/MiniLoader";
import { getFormattedError } from "@services/http";

export const ViewMeasurementsModal: FC<IViewMeasurementsModal> = ({
  title,
  handleModalOpen,
}) => {
  const [isMeasurementsLoading, setMeasurementsLoading] = useState(false);
  const [measurementParameters, setMeasurementParameters] =
    useState<IMeasurementParameter[]>();
  const [selectedMeasurement, setSelectedMeasurement] =
    useState<IMeasurementParameter>();
  const [isEditMeasurement, setEditMeasurement] = useState(false);
  const [isAddMeasurement, setAddMeasurement] = useState(false);
  const [isPhotoDeleted, setPhotoDeleted] = useState(false);
  const [openFileSelector, { filesContent, clear: clearLoadedPhoto }] =
    useFilePicker({
      accept: measurementsImageExtensions,
      limitFilesConfig: { max: 1 },
      readAs: "DataURL",
    });

  const formMethods = useForm<IViewMeasurementsForm>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    shouldFocusError: false,
    resolver: yupResolver(validationSchemas.viewMeasurements),
  });

  const { handleSubmit, watch, setValue, formState, reset } = formMethods;
  const { isSubmitted } = formState;
  const { measurementName } = watch();

  const dispatch = useAppDispatch();

  const {
    isActive,
    message: notificationMessage,
    type: notificationType,
    showNotification,
    closeNotification,
  } = useNotification();

  const handleSetListMeasurements = (data: IMeasurementParameter[]) => {
    setMeasurementParameters(data);
  };

  const handleSelectMeasurementParam = (param: IMeasurementParameter) => {
    setPhotoDeleted(false);
    setEditMeasurement(false);
    setAddMeasurement(false);
    setSelectedMeasurement(param);
    clearLoadedPhoto();
  };

  const activeAddMeasurement = () => {
    setPhotoDeleted(false);
    setEditMeasurement(false);
    setAddMeasurement(true);
    setSelectedMeasurement(undefined);
    clearLoadedPhoto();
  };

  const activeEditMeasurement = () => {
    setPhotoDeleted(false);
    setEditMeasurement(true);
    setAddMeasurement(false);
    clearLoadedPhoto();
  };

  const clearImage = () => {
    setPhotoDeleted(true);
    clearLoadedPhoto();
  };

  const onSubmit = async () => {
    try {
      reset({}, { keepValues: true });

      if (filesContent?.[0]?.content) {
        const response = await fetch(filesContent[0].content);
        const blob = await response.blob();
        if (isAddMeasurement) {
          const measurement = await createMeasurementParam(
            dispatch,
            measurementName,
            blob
          );
          handleSelectMeasurementParam(measurement);
        }
        if (isEditMeasurement && selectedMeasurement?.measurementParamId) {
          const measurement = await updateMeasurementParam(
            dispatch,
            selectedMeasurement.measurementParamId,
            measurementName,
            true,
            blob
          );
          handleSelectMeasurementParam(measurement);
        }
      } else {
        if (isAddMeasurement) {
          const measurement = await createMeasurementParam(
            dispatch,
            measurementName
          );
          handleSelectMeasurementParam(measurement);
        }
        if (isEditMeasurement && selectedMeasurement?.measurementParamId) {
          const measurement = await updateMeasurementParam(
            dispatch,
            selectedMeasurement.measurementParamId,
            measurementName,
            isPhotoDeleted ? true : false
          );
          handleSelectMeasurementParam(measurement);
        }
      }
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    }
  };

  useEffect(() => {
    setMeasurementsLoading(true);
    (async function asyncWrapper() {
      try {
        const measurements = await getMeasurementsBySearchKey(dispatch);
        handleSetListMeasurements(measurements.content);
        !selectedMeasurement &&
          setSelectedMeasurement(measurements.content?.[0]);
      } catch (error) {
        showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
        handleModalOpen();
        return Promise.reject(getFormattedError(error));
      } finally {
        setMeasurementsLoading(false);
      }
    })();
  }, [isSubmitted]);

  useEffect(() => {
    if (isEditMeasurement && selectedMeasurement?.name) {
      setValue("measurementName", selectedMeasurement.name);
      activeEditMeasurement();
    }
    if (isAddMeasurement) {
      setValue("measurementName", "");
      activeAddMeasurement();
    }
  }, [isAddMeasurement, isEditMeasurement]);

  useEffect(() => {
    if (!!filesContent.length) {
      setPhotoDeleted(false);
    }
  }, [filesContent]);

  const isSaveBtnDisabled = !measurementName?.trim()?.length;

  return (
    <>
      <Notification
        type={notificationType}
        message={notificationMessage}
        isActive={isActive}
        closeNotification={closeNotification}
      />
      <PortalModal
        title={title}
        isOpen={true}
        onClose={handleModalOpen}
        className="modal-user modal-measurement-types"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...formMethods}>
            <div className="modal-wrapper">
              <div className="form modal-form form-start-label">
                {isMeasurementsLoading ? (
                  <MiniLoader />
                ) : (
                  <>
                    <span className="modal-tips-title">
                      {!measurementParameters?.length ? (
                        "No measurements yet"
                      ) : (
                        <>
                          Measurements
                          <span className="form-label-star">*</span>
                        </>
                      )}
                    </span>
                    <div className="measurement-list">
                      <ul className="modal-tips-list">
                        {(measurementParameters || []).map((param) => (
                          <li
                            className={`modal-tips-item ${
                              selectedMeasurement?.measurementParamId ===
                                param.measurementParamId && "isActive"
                            }`}
                            key={param.measurementParamId}
                            onClick={() => {
                              handleSelectMeasurementParam(param);
                            }}
                          >
                            {param.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                )}
                {!!measurementParameters?.length && (
                  <button
                    className="btn-edit-measurement"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.preventDefault();
                      if (!isEditMeasurement) {
                        if (!selectedMeasurement) {
                          handleSelectMeasurementParam(
                            measurementParameters?.[0]
                          );
                        }
                        activeEditMeasurement();
                      }
                    }}
                  >
                    <i className="icon icon-edit-action--purple"></i>Edit
                    Current Measurement
                  </button>
                )}
                <button
                  className="btn-edit-measurement"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    !isAddMeasurement && activeAddMeasurement();
                  }}
                >
                  <i className="icon icon-plus-purple"></i>New Measurement
                </button>
                {(isAddMeasurement || isEditMeasurement) && (
                  <Input label="Measurement name*" name="measurementName" />
                )}
                <span className="modal-tips-title">Tips to measure</span>
                {(filesContent?.[0]?.content ||
                  selectedMeasurement?.imageUrl) &&
                  !isPhotoDeleted &&
                  !isAddMeasurement &&
                  !isEditMeasurement && (
                    <div className="modal-image">
                      <img
                        className="modal-image-tips"
                        src={
                          filesContent?.[0]?.content ||
                          selectedMeasurement?.imageUrl ||
                          undefined
                        }
                        alt={filesContent?.[0]?.name}
                      />
                    </div>
                  )}
                {(filesContent?.[0]?.content ||
                  (isEditMeasurement && selectedMeasurement?.imageUrl)) &&
                  !isPhotoDeleted &&
                  (isAddMeasurement || isEditMeasurement) && (
                    <>
                      <div className="modal-image">
                        <img
                          className="modal-image-tips"
                          src={
                            filesContent?.[0]?.content ||
                            selectedMeasurement?.imageUrl ||
                            undefined
                          }
                          alt={filesContent?.[0]?.name}
                        />
                      </div>
                      <div className="modal-image-delete" onClick={clearImage}>
                        <i className="icon icon-delete"></i>
                      </div>
                    </>
                  )}
                {((((!filesContent?.[0]?.content &&
                  !selectedMeasurement?.imageUrl) ||
                  isPhotoDeleted) &&
                  isEditMeasurement) ||
                  (!filesContent?.[0]?.content && isAddMeasurement)) && (
                  <div
                    className="modal-image modal-add-image"
                    onClick={openFileSelector}
                  >
                    <i className="icon icon-add-photo"></i>
                    <span className="modal-image-text">Click to add photo</span>
                  </div>
                )}
                {!selectedMeasurement?.imageUrl &&
                  !isAddMeasurement &&
                  !isEditMeasurement && (
                    <div className="modal-image modal-no-image">
                      <i className="icon icon-no-photo"></i>
                      <span className="modal-image-text">No tips yet</span>
                    </div>
                  )}
              </div>
            </div>
            {(isAddMeasurement || isEditMeasurement) && (
              <div className="modal-footer">
                <Button
                  text="Cancel"
                  className="btn btn-primary"
                  onClick={() => {
                    if (selectedMeasurement) {
                      reset({}, { keepValues: true });
                      handleSelectMeasurementParam(selectedMeasurement);
                    } else if (measurementParameters?.[0]) {
                      reset({}, { keepValues: true });
                      handleSelectMeasurementParam(measurementParameters[0]);
                    }
                  }}
                />
                <Button
                  text="Save"
                  className="btn btn-main"
                  type="submit"
                  disable={isSaveBtnDisabled}
                />
              </div>
            )}
          </FormProvider>
        </form>
      </PortalModal>
    </>
  );
};
