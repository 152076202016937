import { FC, useState, useEffect } from "react";
import { Button } from "@components/Button";
import { GenderSegment } from "@components/GenderSegment";
import { Input } from "@components/Input";
import { PortalModal } from "@components/PortalModal";
import { GenderValue } from "@models/components/secondary/gendersegment";
import { SOMETHING_WENT_WRONG_NOTIFICATION } from "@constants/commons";
import {
  IManageProductTypeForm,
  IManageProductTypeModal,
} from "@models/components/secondary/manageproducttypemodal";
import { FormProvider, useForm } from "react-hook-form";
import { validationSchemas } from "@services/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Loader } from "@components/Loader";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { useNotification } from "@hooks/common/useNotification";
import { Notification } from "@components/Notification";
import { handleProductTypesUpdate } from "@api/common";
import { getFormattedError } from "@services/http";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DraggableMeasurementList } from "@components/DraggableMeasurementList";
import { getGenderOption, genderOptions } from "./helper";
import { useDraggableMeasurementList } from "@pages/Modals/ProductTypes/hooks/use-draggable-measurement-list";

export const ManageProductTypeModal: FC<IManageProductTypeModal> = ({
  handleModalOpen,
  title,
  productTypeInfo,
  handleManaged,
}) => {
  const [selectedGender, setSelectedGender] = useState<GenderValue>(
    getGenderOption(productTypeInfo?.genderToMeasurementParams)
  );

  const formMethods = useForm<IManageProductTypeForm>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    shouldFocusError: false,
    resolver: yupResolver(validationSchemas.manageMeasurements),
  });

  const { handleSubmit, watch, setValue } = formMethods;
  const { productTitle } = watch();

  const dispatch = useAppDispatch();

  const {
    isActive,
    message: notificationMessage,
    type: notificationType,
    showNotification,
    closeNotification,
  } = useNotification();

  const {
    draggableMeasurementListLoading,
    draggableMeasurementListProps,
    genderToMeasurementParamRefs,
  } = useDraggableMeasurementList({
    selectedGender: selectedGender.value,
    productTypeInfo,
  });

  const onSubmit = async (data: IManageProductTypeForm) => {
    try {
      await handleProductTypesUpdate(dispatch, {
        productTypeId: productTypeInfo?.productTypeId || "",
        name: data.productTitle,
        genderToMeasurementParamRefs,
      });
      handleManaged(true);
      handleModalOpen();
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    }
  };

  useEffect(() => {
    if (productTypeInfo) {
      setValue("productTitle", productTypeInfo.name);
    }
  }, [productTypeInfo]);

  const isSaveBtnDisabled = !productTitle?.trim()?.length;

  return (
    <DndProvider backend={HTML5Backend}>
      <>
        <Notification
          type={notificationType}
          message={notificationMessage}
          isActive={isActive}
          closeNotification={closeNotification}
        />
        {draggableMeasurementListLoading ? (
          <Loader />
        ) : (
          <PortalModal
            title={title}
            isOpen={true}
            onClose={handleModalOpen}
            className="modal-user modal-product-type"
          >
            <form
              className="form modal-form form-start-label"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormProvider {...formMethods}>
                <div className="modal-body">
                  <Input label="Product title*" name="productTitle" />
                  <span className="form-label mt-20 mb-8">Gender*</span>
                  <GenderSegment
                    isDisabled={false}
                    handleGender={setSelectedGender}
                    selectedGender={selectedGender}
                    customGenders={genderOptions}
                  />
                  <div className="modal-tips-wrapper modal-product-form">
                    {draggableMeasurementListProps.map((listProps, index) => (
                      <DraggableMeasurementList key={index} {...listProps} />
                    ))}
                  </div>
                </div>
                <div className="modal-footer">
                  <Button
                    text="Cancel"
                    className="btn btn-primary"
                    onClick={handleModalOpen}
                  />
                  <Button
                    text="Save"
                    className="btn btn-main"
                    type="submit"
                    disable={isSaveBtnDisabled}
                  />
                </div>
              </FormProvider>
            </form>
          </PortalModal>
        )}
      </>
    </DndProvider>
  );
};
