import { FC } from "react";
import { IPlug } from "@models/components/secondary/plug";
import {
  NO_EVENTS_PLUG_TYPE,
  NO_FOUND_PLUG_TYPE,
  NO_NETWORK_PLUG_TYPE,
} from "@constants/commons";

export const Plug: FC<IPlug> = (props) => {
  const { message, type, load = false } = props;

  return (
    <div className="plug">
      <span className="plug-header" />
      {load ? (
        <div className="plug-container">
          <i className="plug-load loader" />
        </div>
      ) : (
        <div className="plug-container">
          <span className="plug-message">{message}</span>
          <i
            className={`plug-icon icon ${
              type === NO_EVENTS_PLUG_TYPE ||
              type === NO_NETWORK_PLUG_TYPE ||
              type === NO_FOUND_PLUG_TYPE
                ? "icon-plug"
                : "icon-no-result"
            }`}
          />
        </div>
      )}
    </div>
  );
};
