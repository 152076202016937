import { FC, useEffect } from "react";
import {
  DEALER_ROLE,
  DOWNLOAD_REPORT_NOTIFICATION,
  PARENT_ROLE,
  SALES_REP_ROLE,
  SOMETHING_WENT_WRONG_NOTIFICATION,
  TABLE_ROWS_LIMIT,
} from "@constants/commons";
import { usePagination } from "@hooks/common/usePagination";
import { IPagination } from "@models/components/secondary/pagination";
import { UserRole } from "@models/common/user";
import fileDownload from "js-file-download";
import { generateParentsList } from "@api/parent";
import {
  DEALER_GENERATE_LIST_TITLE,
  MEASUREMENTS_GENERATE_LIST_TITLE,
  PARENT_GENERATE_LIST_TITLE,
  SALES_REP_GENERATE_LIST_TITLE,
} from "@constants/users";
import { formateDate } from "@services/common";
import { generateDealersList } from "@api/dealers";
import { generateSalesRepsList } from "@api/salesRep";
import { useNotification } from "@hooks/common/useNotification";
import { Notification } from "@components/Notification";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { EVENT_ROLE } from "@models/common/events";
import { generateEventMeasurementsList } from "@api/measurements";
import { useParams } from "react-router-dom";
import { getFormattedError } from "@services/http";

export const Pagination: FC<IPagination> = ({
  dataLength,
  handleFirstIndex,
  handleLastIndex,
  pageId,
  role,
  filename,
}) => {
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    gaps,
    setPage,
    totalPages,
    resetPagination,
  } = usePagination(TABLE_ROWS_LIMIT, dataLength, pageId + 1);

  const {
    isActive,
    message: notificationMessage,
    type: notificationType,
    showNotification,
    closeNotification,
  } = useNotification();

  useEffect(() => {
    resetPagination(dataLength);
  }, [dataLength]);

  useEffect(() => {
    handleFirstIndex && handleFirstIndex(firstContentIndex);
    handleLastIndex && handleLastIndex(lastContentIndex);
  }, [firstContentIndex, lastContentIndex]);

  const dispatch = useAppDispatch();

  const { eventId } = useParams();

  const generateList = async (userRole: UserRole | typeof EVENT_ROLE) => {
    try {
      if (userRole === PARENT_ROLE) {
        const file = await generateParentsList(dispatch);
        fileDownload(
          file,
          `Parent_List_${formateDate(new Date().toISOString(), true)}.csv`
        );
        showNotification(DOWNLOAD_REPORT_NOTIFICATION, "success");
      }

      if (userRole === DEALER_ROLE) {
        const file = await generateDealersList(dispatch);
        fileDownload(
          file,
          `Dealer_List_${formateDate(new Date().toISOString(), true)}.csv`
        );
        showNotification(DOWNLOAD_REPORT_NOTIFICATION, "success");
      }

      if (userRole === SALES_REP_ROLE) {
        const file = await generateSalesRepsList(dispatch);
        fileDownload(
          file,
          `Sales_Representatives_List_${formateDate(
            new Date().toISOString(),
            true
          )}.csv`
        );
        showNotification(DOWNLOAD_REPORT_NOTIFICATION, "success");
      }

      if (userRole === EVENT_ROLE && eventId) {
        const file = await generateEventMeasurementsList(dispatch, eventId);
        fileDownload(
          file,
          `event_${filename}_${formateDate(new Date().toISOString(), true)}.csv`
        );
        showNotification(DOWNLOAD_REPORT_NOTIFICATION, "success");
      }
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    }
  };

  return (
    <>
      <Notification
        type={notificationType}
        message={notificationMessage}
        isActive={isActive}
        closeNotification={closeNotification}
      />
      <div className="pagination">
        <ul className="pagination-list">
          <li
            className={`pagination-item pagination-btn ${
              page === 1 && "isDisabled"
            }`}
            onClick={prevPage}
          >
            <i className="pagination-icon icon icon-arrow-left" />
          </li>
          <li
            onClick={() => setPage(1)}
            className={`pagination-item pagination-btn ${
              page === 1 && "isActive"
            }`}
          >
            1
          </li>
          {gaps.before ? "..." : null}
          {gaps.paginationGroup.map((el) => (
            <li
              onClick={() => setPage(el)}
              key={el}
              className={`pagination-item pagination-btn ${
                page === el ? "isActive" : ""
              }`}
            >
              {el}
            </li>
          ))}
          {gaps.after ? "..." : null}
          {totalPages > 1 && (
            <li
              onClick={() => setPage(totalPages)}
              className={`pagination-item pagination-btn ${
                page === totalPages && "isActive"
              }`}
            >
              {totalPages}
            </li>
          )}
          <li
            onClick={nextPage}
            className={`pagination-item pagination-btn ${
              page === totalPages && "isDisabled"
            }`}
          >
            <i className="pagination-icon icon icon-arrow-right" />
          </li>
        </ul>
        {role && (
          <button
            className="pagination-generate"
            onClick={() => generateList(role)}
          >
            <i className="pagination-generate-icon icon icon-file-export" />
            {role === PARENT_ROLE
              ? PARENT_GENERATE_LIST_TITLE
              : role === DEALER_ROLE
              ? DEALER_GENERATE_LIST_TITLE
              : role === SALES_REP_ROLE
              ? SALES_REP_GENERATE_LIST_TITLE
              : MEASUREMENTS_GENERATE_LIST_TITLE}
          </button>
        )}
      </div>
    </>
  );
};
