import { IEndUsersList } from "@models/common/endUsers";
import { IBulkResponse } from "@models/components/main/bulkadduser";
import { http } from "@services/http";
import { AppDispatch } from "@store/store";

export const downloadBulkTemplate = async (dispatch: AppDispatch) =>
  await http.get<Blob>(
    "/api/v1/user/end-user/bulk/download-template",
    dispatch,
    {
      headers: {
        Accept: "application/vnd.ms-excel",
      },
      responseType: "blob",
    }
  );

export const sendBulkTemplateToEmail = async (dispatch: AppDispatch) =>
  await http.post<Blob, null>(
    "/api/v1/user/end-user/bulk/send-template",
    null,
    dispatch
  );

export const uploadBulkFile = async (dispatch: AppDispatch, file: Blob) =>
  await http.post<IBulkResponse, { bulkTemplate: Blob }>(
    "/api/v1/user/end-user/bulk",
    { bulkTemplate: file },
    dispatch,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const getBulkInfo = async (dispatch: AppDispatch, bulkId: string) =>
  await http.get<IBulkResponse>(
    `/api/v1/user/end-user/bulk/${bulkId}`,
    dispatch,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const getBulkReport = async (dispatch: AppDispatch, bulkId: string) =>
  await http.get<Blob>(
    `/api/v1/user/end-user/bulk/${bulkId}/report`,
    dispatch,
    {
      headers: {
        Accept: "application/vnd.ms-excel",
      },
      responseType: "blob",
    }
  );

export const getBulkEndUsers = async (dispatch: AppDispatch, bulkId: string) =>
  await http.get<IEndUsersList>(
    `/api/v1/user/end-user/bulk/${bulkId}/end-users`,
    dispatch
  );
