import { Header } from "@components/Header";
import { HeaderBarTable } from "@components/HeaderBarTable";
import qrCodeGoogle from "@assets/img/qr-code-google.png";
import qrCodeApple from "@assets/img/qr-code-apple.png";
export const PageMobileApp: React.FC = () => {
  const googlePlay: string = "Google Play";
  const appStore: string = "App Store";

  return (
    <>
      <Header />
      <main className="main-container">
        <HeaderBarTable title="To download the app use the following sources" />
        <div className="event-table-container">
          <table
            className="event-table"
            cellSpacing={0}
            cellPadding={0}
            border={0}
          >
            <thead className="event-thead">
              <tr className="event-tr">
                <th className="event-th">
                  <div className="d-flex a-center"></div>
                </th>
              </tr>
            </thead>
            <tbody className="event-tbody d-flex mobile-app-container">
              <tr className="mobile-app-item">
                <td>
                  <div className="mobile-app-item__title">{googlePlay}</div>
                  <img
                    src={qrCodeGoogle}
                    alt="qr-code"
                    className="mobile-app-item__qr-code"
                  />
                  <a
                    className="badge-button"
                    href="https://play.google.com/store/apps/details?id=com.fit.event"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <i className="icon google-play-icon" />
                    <span className="text">
                      Android app on <br />
                      <span className="text-brand">{googlePlay}</span>
                    </span>
                  </a>
                </td>
              </tr>
              <tr className="mobile-app-item">
                <td>
                  <div className="mobile-app-item__title">{appStore}</div>
                  <img
                    src={qrCodeApple}
                    alt="qr-code"
                    className="mobile-app-item__qr-code"
                  />
                  <a
                    className="badge-button"
                    href="https://apps.apple.com/us/app/fit-event/id1635722916"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <i className="icon app-store-icon" />
                    <span className="text">
                      Available on the <br />
                      <span className="text-brand">{appStore}</span>
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
    </>
  );
};
