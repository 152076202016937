import { MAIN_ROUTE } from "@constants/routes";
import { FC } from "react";
import { Link } from "react-router-dom";

export const PrivacyPolicy: FC = () => {
  return (
    <>
      <header className="header">
        <div className="header-controls">
          <Link className="header-logo" to={MAIN_ROUTE}>
            <i className="header-icon icon icon-logo" />
          </Link>
        </div>
      </header>
      <div className="docs-container">
        <div className="docs-nav">
          <ul>
            <li>
              <a className="docs-nav-link" href="#item1">
                Fit Event Privacy Policy
              </a>
            </li>
            <li>
              <a className="docs-nav-link" href="#item2">
                PERSONAL INFORMATION WE COLLECT
              </a>
            </li>
            <li>
              <a className="docs-nav-link" href="#item3">
                HOW DO WE USE YOUR PERSONAL INFORMATION?
              </a>
            </li>
            <li>
              <a className="docs-nav-link" href="#item4">
                SHARING YOUR PERSONAL INFORMATION
              </a>
            </li>
            <li>
              <a className="docs-nav-link" href="#item5">
                DO NOT TRACK
              </a>
            </li>
            <li>
              <a className="docs-nav-link" href="#item6">
                YOUR RIGHTS
              </a>
            </li>
            <li>
              <a className="docs-nav-link" href="#item7">
                DATA RETENTION
              </a>
            </li>
            <li>
              <a className="docs-nav-link" href="#item8">
                MINORS
              </a>
            </li>
            <li>
              <a className="docs-nav-link" href="#item9">
                CHANGES
              </a>
            </li>
            <li>
              <a className="docs-nav-link" href="#item10">
                CONTACT US
              </a>
            </li>
          </ul>
        </div>
        <h3 className="docs-subtitle" id="item1">
          Fit Event Privacy Policy
        </h3>
        <p>
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit or make a purchase from
          www.thefitevent.com (the “Site”).
        </p>
        <h3 className="docs-subtitle" id="item2">
          PERSONAL INFORMATION WE COLLECT
        </h3>
        <p>
          When you visit the Site, we automatically collect certain information
          about your device, including information about your web browser, IP
          address, time zone, and some of the cookies that are installed on your
          device. Additionally, as you browse the Site, we collect information
          about the individual web pages or products that you view, what
          websites or search terms referred you to the Site, and information
          about how you interact with the Site. We refer to this automatically-
          collected information as “Device Information.” <br />
          <br />
          We collect Device Information using the following technologies:
          <br />
          <br /> - “Cookies” are data files that are placed on your device or
          computer and often include an anonymous unique identifier. For more
          information about cookies, and how to disable cookies, visit{" "}
          <a href="http://www.allaboutcookies.org">
            http://www.allaboutcookies.org
          </a>{" "}
          .
          <br />- “Log files” track actions occurring on the Site, and collect
          data including your IP address, browser type, Internet service
          provider, referring/exit pages, and date/time stamps. <br />- “Web
          beacons,” “tags,” and “pixels” are electronic files used to record
          information about how you browse the Site. <br /> <br />
          Additionally, when you make a purchase or attempt to make a purchase
          through the Site, we collect certain information from you, including
          your name, billing address, shipping address, payment information
          (including credit card numbers), email address, and phone number. We
          refer to this information as “Order Information” for the services
          provided. <br /> <br />
          When using the features of the Site we collect information entered
          that includes but not limited to: Company name, user name,
          measurements, address, email, phone number, identification number,
          employer name, event time/date, etc. <br /> <br />
          When we talk about “Personal Information” in this Privacy Policy, we
          are talking both about Device Information and Order Information.
        </p>
        <h3 className="docs-subtitle" id="item3">
          HOW DO WE USE YOUR PERSONAL INFORMATION?
        </h3>
        <p>
          We use the Order Information that we collect generally to fulfill any
          orders or services placed through the Site (including processing your
          payment information, access to the service, and providing you with
          invoices and/or order confirmations). Additionally, we use this Order
          Information to: <br />
          Communicate with you;
          <br />
          Screen our orders for potential risk or fraud.
          <br />
          When in line with the preferences you have shared with us, provide you
          with information or advertising relating to our products or services.
          <br />
          <br />
          We use the Device Information that we collect to help us screen for
          potential risk and fraud (in particular, your IP address), and more
          generally to improve and optimize our Site (for example, by generating
          analytics about how our customers browse and interact with the Site,
          and to assess the success of our marketing and advertising campaigns).
        </p>
        <h3 className="docs-subtitle" id="item4">
          SHARING YOUR PERSONAL INFORMATION
        </h3>
        <p>
          We will not sell your personal data to other parties. However, we may
          share your personal data with the Amazon Web Services, Inc. (AWS)
          because they process personal data on our behalf and provide cloud
          storage. You may read AWS Privacy Policy here:
          <br />
          <a href="https://aws.amazon.com/privacy/">
            https://aws.amazon.com/privacy/
          </a>
          . <br />
          <br />
          Finally, we may also share your Personal Information to comply with
          applicable laws and regulations, to respond to a subpoena, search
          warrant or other lawful request for information we receive, or to
          otherwise protect our rights.
        </p>
        <h3 className="docs-subtitle" id="item5">
          DO NOT TRACK
        </h3>
        <p>
          Please note that we do not alter our Site’s data collection and use
          practices when we see a Do Not Track signal from your browser.
        </p>
        <h3 className="docs-subtitle" id="item6">
          YOUR RIGHTS
        </h3>
        <p>
          If you are a European resident, you have the right to access personal
          information we hold about you and to ask that your personal
          information be corrected, updated, or deleted. If you would like to
          exercise this right, please contact us through the contact information
          below.
          <br />
          If you would like to delete your account, please submit a deletion
          request using the Google Form:{" "}
          <a href="https://forms.gle/VqABvgXzWeKVaDR99">
            https://forms.gle/VqABvgXzWeKVaDR99
          </a>
          .
          <br />
          Once your request has been processed and identified, we will delete
          your account, unless there is an unresolved issue with your account.
          Please note that this action is irreversible.
          <br /> Additionally, if you are a European resident we note that we
          are processing your information in order to fulfill contracts we might
          have with you (for example if you make an order through the Site), or
          otherwise to pursue our legitimate business interests listed above.
          Additionally, please note that your information will be transferred
          outside of Europe, including to Canada and the United States.
        </p>
        <h3 className="docs-subtitle" id="item7">
          DATA RETENTION
        </h3>
        <p>
          When you place an order through the Site, we will maintain your Order
          Information for our records unless and until you ask us to delete this
          information.
        </p>
        <h3 className="docs-subtitle" id="item8">
          MINORS
        </h3>
        <p>The Site is not intended for individuals under the age of 18.</p>
        <h3 className="docs-subtitle" id="item9">
          CHANGES
        </h3>
        <p>
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </p>
        <h3 className="docs-subtitle" id="item10">
          CONTACT US
        </h3>
        <p>
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e-mail at{" "}
          <a href="mailto:info@thefitevent.com" style={{ color: "black" }}>
            info@thefitevent.com
          </a>{" "}
          or by mail using the details provided below:
        </p>
        <br />
        <span>
          &nbsp;&nbsp;7060 W. State Road 84, Davie, FL, 33317, United States
        </span>
      </div>
    </>
  );
};
