import { FC, ReactNode } from "react";
import ReactDOM from "react-dom";

interface Props {
  title: string;
  isOpen: boolean;
  onClose?: () => void;
  children: ReactNode;
  className?: string;
}

export const PortalModal: FC<Props> = (props) => {
  const { title, isOpen, onClose, children, className } = props;

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="modal-back">
      <div className={`modal ${className && className}`}>
        <div className="modal-header">
          <h1 className="modal-title">{title}</h1>
          <button className="modal-close" onClick={onClose}>
            <i className="modal-close-icon icon icon-close" />
          </button>
        </div>
        <div className="modal-body-wrapper">{children}</div>
      </div>
    </div>,
    document.body
  );
};
