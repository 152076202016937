import { DELETED_USER_STATUS } from "@constants/commons";
import { ISalesRep, ISalesRepList } from "@models/common/salesRep";
import { IUserResponse } from "@models/common/user";
import { http } from "@services/http";
import { AppDispatch } from "@store/store";

export const getSalesRepBySearchKey = async (
  dispatch: AppDispatch,
  searchKey: string,
  showHidden: boolean = true,
  size: number = 100
) =>
  await http.get<ISalesRepList>(
    !!searchKey
      ? `/api/v1/user/sales-rep?searchKey=${searchKey}&showHidden=${showHidden}&size=${size}`
      : `/api/v1/user/sales-rep?showHidden=${showHidden}&size=${size}`,
    dispatch
  );

export const getSalesRepById = async (
  dispatch: AppDispatch,
  salesRepId: string
) =>
  await http.get<ISalesRep>(`/api/v1/user/sales-rep/${salesRepId}`, dispatch);

export const listOfFilteredSalesRep = async (
  dispatch: AppDispatch,
  page: number,
  size: number,
  sort: string,
  searchedValue?: string,
  dealerId?: string
) =>
  await http.post<
    ISalesRepList,
    { dealerIdIn: [string] | null; searchKey: string | null }
  >(
    `/api/v1/user/sales-rep/filter?page=${page}&size=${size}&sort=${sort}`,
    {
      dealerIdIn: dealerId ? [dealerId] : null,
      searchKey: searchedValue || null,
    },
    dispatch
  );

export const resendInviteSalesRep = async (
  dispatch: AppDispatch,
  salesRepId: string
) =>
  await http.post(
    `/api/v1/user/sales-rep/${salesRepId}/send-invite`,
    null,
    dispatch
  );

export const deleteSalesRep = async (
  dispatch: AppDispatch,
  salesRepId: string
) =>
  await http.put(
    `/api/v1/user/sales-rep/${salesRepId}`,
    {
      userStatus: DELETED_USER_STATUS,
    },
    dispatch
  );

export const updateSalesRep = async (
  dispatch: AppDispatch,
  salesRepId: string,
  salesRepData: IUserResponse,
  dealerId: string
) =>
  await http.post<ISalesRep, { user: IUserResponse; dealerId: string }>(
    `/api/v1/user/sales-rep/${salesRepId}`,
    {
      user: salesRepData,
      dealerId,
    },
    dispatch
  );

export const createSalesRep = async (
  dispatch: AppDispatch,
  userData: IUserResponse,
  dealerId: string
) =>
  await http.post<ISalesRep, { user: IUserResponse; dealerId: string }>(
    `/api/v1/user/sales-rep`,
    {
      user: userData,
      dealerId,
    },
    dispatch
  );

export const filterSalesRep = async (
  dispatch: AppDispatch,
  email: string | undefined,
  phone_number: string | undefined
) =>
  await http.post<
    ISalesRepList,
    { emailIn: string[] | null; phoneNumberIn: string[] | null }
  >(
    "/api/v1/user/sales-rep/filter",
    {
      emailIn: email ? [email] : null,
      phoneNumberIn: phone_number ? [phone_number] : null,
    },
    dispatch
  );

export const findSalesRepBySearchKey = async (
  dispatch: AppDispatch,
  searchKey?: string,
  dealerId?: string
) =>
  await http.post<
    ISalesRepList,
    { searchKey: string | null; dealerIdIn: [string] | null }
  >(
    "/api/v1/user/sales-rep/filter",
    {
      searchKey: searchKey || null,
      dealerIdIn: dealerId ? [dealerId] : null,
    },
    dispatch
  );

export const generateSalesRepsList = async (dispatch: AppDispatch) =>
  await http.post<Blob, null>("/api/v1/user/sales-rep/export", null, dispatch, {
    responseType: "blob",
  });
