import { useEffect, useState } from "react";
import { Header } from "@components/Header";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { selectTokens } from "@store/selectors/tokens";
import { findSalesRepBySearchKey } from "@api/salesRep";
import { ISalesRepList } from "@models/common/salesRep";
import {
  ADMIN_ROLE,
  DEALER_ROLE,
  DEFAULT_ITEMS_COUNT_WITHOUT_PAGINATION,
  NO_EVENTS_PLUG_TYPE,
} from "@constants/commons";
import { IDealerList } from "@models/common/dealer";
import { findDealersBySearchKey } from "@api/dealers";
import { MiniLoader } from "@components/Loader/MiniLoader";
import { getEventsByFilterTable } from "@api/events";
import { IEventList } from "@models/common/events";
import { Plug } from "@components/Plug";
import { NO_EVENTS_PLUG_ERROR } from "@constants/errors";
import { useNavigate } from "react-router-dom";
import { MEASUREMENTS_LIST_ROUTE } from "@constants/routes";
import { formateAMPM, getUserRoleFromToken, truncStr } from "@services/common";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { findEventParticipantsActiveEvents } from "@api/eventParticipants";
import { IEventParticipantsList } from "@models/common/eventParticipants";
import { findParentsBySearchKey } from "@api/parent";
import { IParentList } from "@models/common/parent";
import _ from "lodash";
import { getFormattedError } from "@services/http";

export const Dashboard = () => {
  const [endUsers, setEndUsers] = useState<
    IEventParticipantsList | undefined
  >();
  const [salesReps, setSalesReps] = useState<ISalesRepList | undefined>();
  const [dealers, setDealers] = useState<IDealerList | undefined>();
  const [parents, setParents] = useState<IParentList | undefined>();
  const [todayEvents, setTodayEvents] = useState<IEventList | undefined>();
  const [inProgressEvents, setInProgressEvents] = useState<number>();
  const [completedEvents, setCompletedEvents] = useState<number>();
  const [canceledEvents, setCanceledEvents] = useState<number>();
  const [scheduledEvents, setScheduledEvents] = useState<number>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tokensInfo = useAppSelector(selectTokens);
  const userRole = getUserRoleFromToken(tokensInfo);
  const currentUserId = tokensInfo?.idToken?.payload?.["cognito:username"];
  const handleSetListParents = (data: IParentList) => {
    setParents(data);
  };

  const handleSetListDealers = (data: IDealerList) => {
    setDealers(data);
  };

  const handleSetListSalesReps = (data: ISalesRepList) => {
    setSalesReps(data);
  };

  const handleSetListEndUsers = (data: IEventParticipantsList) => {
    setEndUsers(data);
  };

  const handleSetListTodayEvents = (data: IEventList) => {
    setTodayEvents(data);
  };

  const handleSetInProgressEvents = (data: number) => {
    setInProgressEvents(data);
  };

  const handleSetCompletedEvents = (data: number) => {
    setCompletedEvents(data);
  };

  const handleSetCanceledEvents = (data: number) => {
    setCanceledEvents(data);
  };

  const handleSetScheduledEvents = (data: number) => {
    setScheduledEvents(data);
  };

  const goToEvent = (eventId: string) => {
    navigate(`${MEASUREMENTS_LIST_ROUTE}/${eventId}/1`);
  };

  useEffect(() => {
    (async function asyncWrapper() {
      try {
        if (userRole === ADMIN_ROLE) {
          const parents = await findParentsBySearchKey(dispatch);
          handleSetListParents(parents);
        }

        if (userRole !== DEALER_ROLE) {
          const dealers = await findDealersBySearchKey(dispatch);
          handleSetListDealers(dealers);
        }

        const salesRep = await findSalesRepBySearchKey(
          dispatch,
          undefined,
          userRole === DEALER_ROLE ? currentUserId : undefined
        );
        handleSetListSalesReps(salesRep);

        const users = await findEventParticipantsActiveEvents(
          dispatch,
          userRole === DEALER_ROLE ? currentUserId : undefined
        );
        handleSetListEndUsers(users);

        const todayEvents = await getEventsByFilterTable({
          dispatch,
          dealerId: userRole === DEALER_ROLE ? currentUserId : undefined,
          upcomingDate: false,
          page: 0,
          size: DEFAULT_ITEMS_COUNT_WITHOUT_PAGINATION,
          sort: "asc",
          startDate: new Date().toISOString(),
          endDate: new Date().toISOString(),
        });
        handleSetListTodayEvents(todayEvents);

        const inProgressEvents = await getEventsByFilterTable({
          dispatch,
          dealerId: userRole === DEALER_ROLE ? currentUserId : undefined,
          upcomingDate: false,
          page: 0,
          size: 20,
          sort: "asc",
          eventStatus: "In Progress",
        });
        handleSetInProgressEvents(inProgressEvents?.totalElements);

        const completedEvents = await getEventsByFilterTable({
          dispatch,
          dealerId: userRole === DEALER_ROLE ? currentUserId : undefined,
          upcomingDate: false,
          page: 0,
          size: 20,
          sort: "asc",
          eventStatus: "Completed",
        });
        handleSetCompletedEvents(completedEvents?.totalElements);

        const canceledEvents = await getEventsByFilterTable({
          dispatch,
          dealerId: userRole === DEALER_ROLE ? currentUserId : undefined,
          upcomingDate: false,
          page: 0,
          size: 20,
          sort: "asc",
          eventStatus: "Canceled",
        });
        handleSetCanceledEvents(canceledEvents?.totalElements);

        const scheduledEvents = await getEventsByFilterTable({
          dispatch,
          dealerId: userRole === DEALER_ROLE ? currentUserId : undefined,
          upcomingDate: false,
          page: 0,
          size: 20,
          sort: "asc",
          eventStatus: "Scheduled",
        });
        handleSetScheduledEvents(scheduledEvents?.totalElements);
      } catch (error) {
        return Promise.reject(getFormattedError(error));
      }
    })();
  }, [userRole]);

  return (
    <>
      <Header />
      <main className="main-container">
        <header className="event-header">
          <h2 className="event-title">Dashboard</h2>
        </header>
        <div className="dashboard-card-wrapper">
          {userRole === ADMIN_ROLE && (
            <div className="dashboard-card">
              <span className="dashboard-card-title">Parents</span>
              <span className="dashboard-card-number">
                {typeof parents?.totalElements === "number" ? (
                  parents.totalElements
                ) : (
                  <MiniLoader />
                )}
              </span>
            </div>
          )}
          {userRole !== DEALER_ROLE && (
            <div className="dashboard-card">
              <span className="dashboard-card-title">Dealers</span>
              <span className="dashboard-card-number">
                {typeof dealers?.totalElements === "number" ? (
                  dealers.totalElements
                ) : (
                  <MiniLoader />
                )}
              </span>
            </div>
          )}
          <div className="dashboard-card">
            <span className="dashboard-card-title">Sales Representatives</span>
            <span className="dashboard-card-number">
              {typeof salesReps?.totalElements === "number" ? (
                salesReps.totalElements
              ) : (
                <MiniLoader />
              )}
            </span>
          </div>
          <div className="dashboard-card">
            <span className="dashboard-card-title">
              Participants in Events in progress
            </span>
            <span className="dashboard-card-number">
              {typeof endUsers?.totalElements === "number" ? (
                endUsers.totalElements
              ) : (
                <MiniLoader />
              )}
            </span>
          </div>
        </div>
        <div className="dashboards-events">
          <div className="dashboard-summary">
            <span className="dashboard-events-title">Events Summary</span>
            <div className="dashboard-status-wrapper">
              <div className="dashboard-event-status">
                <span className="dashboard-status-color inProgress" />
                <span className="dashboard-status-text">In progress</span>
              </div>
              <span className="dashboard-card-number">
                {typeof inProgressEvents === "number" ? (
                  inProgressEvents
                ) : (
                  <MiniLoader />
                )}
              </span>
            </div>
            <div className="dashboard-status-wrapper">
              <div className="dashboard-event-status">
                <span className="dashboard-status-color completed" />
                <span className="dashboard-status-text">Completed</span>
              </div>
              <span className="dashboard-card-number">
                {typeof completedEvents === "number" ? (
                  completedEvents
                ) : (
                  <MiniLoader />
                )}
              </span>
            </div>
            <div className="dashboard-status-wrapper">
              <div className="dashboard-event-status">
                <span className="dashboard-status-color canceled" />
                <span className="dashboard-status-text">Canceled</span>
              </div>
              <span className="dashboard-card-number">
                {typeof canceledEvents === "number" ? (
                  canceledEvents
                ) : (
                  <MiniLoader />
                )}
              </span>
            </div>
            <div className="dashboard-status-wrapper">
              <div className="dashboard-event-status">
                <span className="dashboard-status-color scheduled" />
                <span className="dashboard-status-text">Scheduled</span>
              </div>
              <span className="dashboard-card-number">
                {typeof scheduledEvents === "number" ? (
                  scheduledEvents
                ) : (
                  <MiniLoader />
                )}
              </span>
            </div>
          </div>
          <div className="dashboard-events-list">
            <span className="dashboard-events-title">Events for today</span>
            <div className="dashboard-list">
              {!todayEvents?.content ? (
                <MiniLoader />
              ) : !todayEvents?.content?.length ? (
                <Plug
                  message={NO_EVENTS_PLUG_ERROR}
                  type={NO_EVENTS_PLUG_TYPE}
                />
              ) : (
                _.uniqBy(todayEvents?.content, "eventId").map((event) => (
                  <div className="dashboard-item" key={event.eventId}>
                    <div className="dashboard-item-info">
                      <span className="dashboard-info-title">
                        {truncStr(event.eventName, 20)}
                        {!!event?.productTypes?.length
                          ? ` - ${event.productTypes
                              .map((productType) => productType.name)
                              .join(", ")}`
                          : ""}
                      </span>
                      <span className="dashboard-info-text">
                        {truncStr(event.location, 100)}
                      </span>
                      <span className="dashboard-info-time">
                        {formateAMPM(event.startsAt)} -{" "}
                        {formateAMPM(event.endsAt)}
                      </span>
                    </div>
                    <button
                      className="btn-dashboard"
                      onClick={() => goToEvent(event.eventId)}
                    >
                      <i className="icon icon-arrow-dashboard"></i>
                    </button>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
