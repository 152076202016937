import login from "@assets/img/login.jpg";
import { FC, memo, useEffect, useState } from "react";
import AuthCode from "react-auth-code-input";
import { Button } from "@components/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { validationSchemas } from "@services/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLogin } from "@hooks/common/useLogin";
import { ICredentials } from "@models/auth/data";
import { CODE_LOGIN_ERROR } from "@constants/validation";
import { ResendCodeTimer } from "@components/ResendCodeTimer/ResendCodeTimer";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { selectCognitoUser } from "@store/selectors/cognitoUser";
import {
  FORGOT_PASSWORD_CODE_ROUTE,
  FORGOT_PASSWORD_NEW_ROUTE,
  SIGNIN_CODE_ROUTE,
  SIGNIN_ROUTE,
  SIGNUP_CODE_ROUTE,
  SIGNUP_CREATE_PASSWORD_ROUTE,
  SIGNUP_ROUTE,
} from "@constants/routes";
import {
  CODE_CONF_SUBMIT,
  CODE_RESEND_SUBMIT,
  ERROR_LIMIT_COUNT,
} from "@constants/commons";
import { ICodeConfirmation } from "@models/components/main/codeconfirmation";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { cognitoUserSlice } from "@store/reducers/CognitoUserSlice";
import { selectTokens } from "@store/selectors/tokens";
import {
  getDefaultRouteFromHeaders,
  getHeadersByUserRole,
} from "@services/routes";
import { userInfoSlice } from "@store/reducers/UserSlice";
import { FAILED_TEMP_CODE } from "@constants/errors";
import { selectUserInfo } from "@store/selectors/user";
import { getUserRoleFromToken } from "@services/common";

export const CodeConfirmation: FC = memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const cognitoUser = useAppSelector(selectCognitoUser);
  const userInfo = useAppSelector(selectUserInfo);

  const [submitType, setSubmitType] = useState<string>("");
  const [credentials, setCredentials] = useState<ICredentials>();

  const formMethods = useForm<ICodeConfirmation>({
    resolver: yupResolver(
      submitType === CODE_CONF_SUBMIT
        ? validationSchemas.codeConf
        : validationSchemas.codeResendConf
    ),
    shouldFocusError: false,
    mode: "onSubmit",
  });

  const {
    handleSubmit,
    formState,
    setValue,
    watch,
    reset,
    clearErrors,
    setError,
  } = formMethods;
  const { errors, isSubmitSuccessful } = formState;
  const { code } = watch();

  const goToPreviousPage = () => {
    dispatch(
      userInfoSlice.actions.setUser({
        username: userInfo?.username || "",
        verified: false,
      })
    );
    dispatch(cognitoUserSlice.actions.clearCognitoUser());
    navigate(
      location.pathname === SIGNIN_CODE_ROUTE ||
        location.pathname === FORGOT_PASSWORD_CODE_ROUTE
        ? SIGNIN_ROUTE
        : SIGNUP_ROUTE
    );
    navigate(0);
  };

  const handleCredentials = (credentials: ICredentials | undefined) => {
    setCredentials(credentials);
  };

  const onSetCode = (value: string) => {
    setValue("code", value);
  };

  const codeResendRoute = location.pathname;
  const tokensInfo = useAppSelector(selectTokens);

  const {
    loginError,
    isAuthenticated,
    errorCount,
    isAuthenticating,
    clearAuthentificated,
    clearError,
  } = useLogin(
    credentials,
    false,
    submitType === CODE_RESEND_SUBMIT ? true : false
  );

  const onSubmit = (data: ICredentials) => {
    clearErrors();
    clearError();

    const credentials = { ...data };

    if (submitType === CODE_CONF_SUBMIT && errorCount < ERROR_LIMIT_COUNT) {
      credentials.confirmTerms =
        codeResendRoute === SIGNUP_CODE_ROUTE
          ? true
          : codeResendRoute === FORGOT_PASSWORD_CODE_ROUTE
          ? false
          : undefined;

      setCredentials(credentials);
    }
  };

  useEffect(() => {
    if (submitType === CODE_RESEND_SUBMIT) {
      reset({}, { keepValues: true });
    }
  }, [isSubmitSuccessful, submitType]);

  useEffect(() => {
    if (errorCount === ERROR_LIMIT_COUNT) {
      setError("code", { type: "custom", message: FAILED_TEMP_CODE });
    }

    if (
      !isAuthenticating &&
      isSubmitSuccessful &&
      isAuthenticated &&
      codeResendRoute === SIGNUP_CODE_ROUTE &&
      submitType === CODE_CONF_SUBMIT &&
      !loginError
    ) {
      dispatch(
        userInfoSlice.actions.setUser({
          username: cognitoUser?.username || "",
          verified: true,
        })
      );
      navigate(SIGNUP_CREATE_PASSWORD_ROUTE);
    }

    if (
      !isAuthenticating &&
      isSubmitSuccessful &&
      isAuthenticated &&
      codeResendRoute === FORGOT_PASSWORD_CODE_ROUTE &&
      submitType === CODE_CONF_SUBMIT &&
      !loginError
    ) {
      dispatch(
        userInfoSlice.actions.setUser({
          username: cognitoUser?.username || "",
          verified: true,
        })
      );
      navigate(FORGOT_PASSWORD_NEW_ROUTE);
    }

    if (
      !isAuthenticating &&
      isSubmitSuccessful &&
      isAuthenticated &&
      codeResendRoute === SIGNIN_CODE_ROUTE &&
      errorCount < ERROR_LIMIT_COUNT &&
      submitType !== CODE_RESEND_SUBMIT
    ) {
      navigate(
        getDefaultRouteFromHeaders(
          getHeadersByUserRole(getUserRoleFromToken(tokensInfo))
        )
      );
    }
  }, [
    codeResendRoute,
    errorCount,
    isAuthenticated,
    isSubmitSuccessful,
    navigate,
    loginError,
    isAuthenticating,
  ]);

  const isCodeConfirmationBtnDisabled =
    isAuthenticating ||
    code?.length !== 6 ||
    !cognitoUser ||
    errorCount === ERROR_LIMIT_COUNT;

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit(onSubmit);
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className={`login ${isAuthenticating && "login-loading"}`}>
        <img src={login} alt="login" className="login-img" />
        <div className="login-container login-back">
          <button className="btn-link btn" onClick={goToPreviousPage}>
            <i className="btn-link-icon icon icon-back" /> Go back
          </button>
          <h1 className="login-title">
            Welcome to <b>{process.env.REACT_APP_PROJECT_NAME}</b>
          </h1>
          <div className="login-form">
            <span className="login-subtitle">
              <b>Enter the code we sent to your email/phone number.</b>
              <br />
              If you entered an email address you will receive a code in your
              email inbox, if you entered a phone number you will receive a text
              message on your phone
            </span>
            <FormProvider {...formMethods}>
              <AuthCode
                onChange={onSetCode}
                containerClassName="code-field"
                allowedCharacters="numeric"
                disabled={isAuthenticating}
              />
              {(!!errors.code?.message?.length || !!loginError) && (
                <sub className="form-error">
                  {errors.code?.message || CODE_LOGIN_ERROR}
                </sub>
              )}
              <ResendCodeTimer
                setSubmitType={setSubmitType}
                handleCredentials={handleCredentials}
                clearAuth={clearAuthentificated}
              />
            </FormProvider>
            <Button
              type="submit"
              text="Submit code"
              className="btn-main btn-big"
              disable={isCodeConfirmationBtnDisabled}
              onClick={() => setSubmitType(CODE_CONF_SUBMIT)}
            />
          </div>
        </div>
      </div>
    </form>
  );
});
