import { Header } from "@components/Header";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { getUserRoleFromToken } from "@services/common";
import {
  getDefaultRouteFromHeaders,
  getHeadersByUserRole,
} from "@services/routes";
import { selectTokens } from "@store/selectors/tokens";
import { useNavigate } from "react-router-dom";

export const Page404: React.FC = () => {
  const tokensInfo = useAppSelector(selectTokens);
  const navigate = useNavigate();

  const goToMainPage = () => {
    navigate(
      getDefaultRouteFromHeaders(
        getHeadersByUserRole(getUserRoleFromToken(tokensInfo))
      )
    );
  };

  return (
    <>
      <Header />
      <h1 className="plug-title">This page was not found</h1>
      <i className="plug-icon icon icon-plug"></i>
      <button className="btn btn-main btn-plug" onClick={goToMainPage}>
        Go to main page
      </button>
    </>
  );
};
