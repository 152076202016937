import { useEffect, useRef, useState, FC } from "react";
import { Button } from "@components/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { selectTokens } from "@store/selectors/tokens";
import {
  getDefaultRouteFromHeaders,
  getHeadersByUserRole,
} from "@services/routes";
import { EDIT_EVENT_ROUTE, ADD_EVENT_ROUTE } from "@constants/routes";
import { eventInfoSlice } from "@store/reducers/EventSlice";
import { selectEventData } from "@store/selectors/event";
import { IHeader, IRoleHeader } from "@models/components/main/header";
import { getUserRoleFromToken } from "@services/common";
import { handleLogout } from "@services/auth";
import { END_USER_ROLE } from "@constants/commons";

export const Header: FC<IHeader> = ({
  handleClarifyWindow,
  handleInterruptRoute,
}) => {
  const tokensInfo = useAppSelector(selectTokens);
  const userRole = getUserRoleFromToken(tokensInfo);
  const headersByRole = getHeadersByUserRole(getUserRoleFromToken(tokensInfo));

  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);
  const [isOpened, setOpened] = useState(false);
  const [isBurgerOpened, setBurgerOpened] = useState(false);
  const [selectedHeader, setSelectedHeader] = useState<IRoleHeader>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const eventData = useAppSelector(selectEventData);

  const toggleDropdown = (type: "main" | "burger") => {
    if (type === "main") {
      setOpened(!isOpened);
    } else {
      setBurgerOpened(!isBurgerOpened);
    }
  };

  useEffect(() => {
    if (
      !location.pathname?.includes(EDIT_EVENT_ROUTE) &&
      !location.pathname?.includes(ADD_EVENT_ROUTE) &&
      eventData
    ) {
      dispatch(eventInfoSlice.actions.clearEventData());
    }
  }, [location.pathname]);

  useEffect(() => {
    const currentRoute = (headersByRole || []).find(
      (headerByRole) =>
        location.pathname.includes(headerByRole.mainRoute) ||
        headerByRole.addRoutes.some((addRoute) =>
          location.pathname.includes(addRoute)
        )
    );
    if (currentRoute) {
      setSelectedHeader(currentRoute);
    }
  }, [location.pathname]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        isOpened &&
        ref.current &&
        !ref.current.contains(e.target as HTMLDivElement)
      ) {
        setOpened(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpened]);

  const isAuthenticated = !!useAppSelector(selectTokens)?.idToken?.jwtToken;

  const getHeaderList = () => {
    return (
      <ul className={`header-dropdown-list ${isOpened && "isActive"}`}>
        {isAuthenticated && (
          <>
            <li className="header-dropdown-item">
              <Button
                text="Log out"
                className="header-logout"
                icon="icon-logout"
                onClick={() => handleLogout(dispatch)}
              />
            </li>
            {userRole !== END_USER_ROLE && (
              <li className="header-dropdown-item">
                <Button
                  text="Mobile app"
                  className="header-logout get-mobile-app"
                  icon=""
                  onClick={() => navigate("/mobile_app")}
                />
              </li>
            )}
          </>
        )}
      </ul>
    );
  };

  return (
    <header className={`header ${isBurgerOpened && "menu-open"}`}>
      <div className="header-controls">
        <Link
          to={getDefaultRouteFromHeaders(
            getHeadersByUserRole(getUserRoleFromToken(tokensInfo))
          )}
          className="header-logo"
        >
          <i className="header-icon icon icon-logo" />
        </Link>
        <ul className="header-list">
          {(!!headersByRole?.length && headersByRole?.[0]?.header
            ? headersByRole
            : []
          ).map((roleHeader) => (
            <li
              className={`header-item ${
                roleHeader.id === selectedHeader?.id && "isActive"
              }`}
              key={roleHeader.id}
              onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                e.preventDefault();
                if (
                  !location.pathname.includes(ADD_EVENT_ROUTE) &&
                  !location.pathname.includes(EDIT_EVENT_ROUTE)
                ) {
                  setSelectedHeader(roleHeader);
                  navigate(roleHeader.route);
                } else {
                  handleInterruptRoute &&
                    handleInterruptRoute(roleHeader.route);
                  handleClarifyWindow && handleClarifyWindow();
                }
              }}
            >
              <a className="header-link" href={`${roleHeader.route}`}>
                {roleHeader.header}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="header-dropdown" ref={ref}>
        <button
          className={`header-dropdown-name ${isOpened && "isActive"}`}
          onClick={() => toggleDropdown("main")}
        >
          {tokensInfo?.idToken.payload.given_name || ""}{" "}
          {tokensInfo?.idToken.payload.family_name || ""}
          <i className="header-dropdown-icon icon icon-arrow" />
        </button>
        {getHeaderList()}
      </div>
      <>
        <button className={"menu-bar"} onClick={() => toggleDropdown("burger")}>
          <div className="menu-icon">
            <div className="bar bar1" />
            <div className="bar bar2" />
          </div>
        </button>
      </>
    </header>
  );
};
