import { FC, useEffect, useState } from "react";
import { IFilterButtonset } from "@models/components/secondary/filterbuttonset";
import { FormProvider, useFormContext } from "react-hook-form";
import {
  CREATOR_FILTERS_TITLE,
  filterCreatorButtons,
  filterAllStatusesButtons,
  filterUserStatusesButtons,
  PRODUCT_FILTERS_TITLE,
  STATUS_FILTERS_TITLE,
} from "@constants/commons";
import { getProductTypesBySearchKey } from "@api/types";
import { ProductType } from "@models/common/events";
import { ALL_STATUS } from "@constants/events";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { MiniLoader } from "@components/Loader/MiniLoader";
import _ from "lodash";
import { getFormattedError } from "@services/http";

export const FilterButtonset: FC<IFilterButtonset> = (props) => {
  const {
    title,
    filterStatus,
    productType,
    filterCreator,
    handleFilterStatus,
    handleProductType,
    handleProductTypes,
    handleFilterCreator,
    searchParams,
    type,
  } = props;

  const [productTypes, setProductTypes] = useState<ProductType[]>([
    {
      productTypeId: "all",
      name: ALL_STATUS,
      genderToMeasurementParams: { FEMALE: undefined, MALE: undefined },
    },
  ]);

  const dispatch = useAppDispatch();

  const formData = useFormContext();
  const { register } = formData;

  useEffect(() => {
    (async function asyncWrapper() {
      try {
        if (title === PRODUCT_FILTERS_TITLE && handleProductTypes) {
          const types = await getProductTypesBySearchKey(dispatch, "");
          setProductTypes((prevTypes) =>
            _.uniqBy([...prevTypes, ...types.content].flat(), "productTypeId")
          );
          handleProductTypes(
            _.uniqBy(
              [...productTypes, ...types.content].flat(),
              "productTypeId"
            )
          );
        }
      } catch (error) {
        return Promise.reject(getFormattedError(error));
      }
    })();
  }, [title]);

  return (
    <FormProvider {...formData}>
      <div className="filter-form">
        <h3 className="filter-subtitle">{title}</h3>
        <div className="filter-buttonset">
          {title === STATUS_FILTERS_TITLE && handleFilterStatus && (
            <>
              {(type === "all" || !type
                ? filterAllStatusesButtons
                : filterUserStatusesButtons
              ).map((status) => (
                <button
                  {...register("filterStatus")}
                  className={`filter-btn ${
                    status.id ===
                      filterStatus?.find((s) => s.id === status.id)?.id &&
                    "isActive"
                  }`}
                  key={status.id}
                  onClick={() => handleFilterStatus(status)}
                >
                  {status.value}
                </button>
              ))}
            </>
          )}
          {title === CREATOR_FILTERS_TITLE && handleFilterCreator && (
            <>
              {filterCreatorButtons.map((creator) => (
                <button
                  {...register("filterCreator")}
                  className={`filter-btn ${
                    creator.id ===
                      filterCreator?.find((c) => c.id === creator.id)?.id &&
                    "isActive"
                  }`}
                  key={creator.id}
                  onClick={() => handleFilterCreator(creator)}
                >
                  {creator.value}
                </button>
              ))}
            </>
          )}
          {title === PRODUCT_FILTERS_TITLE &&
            productTypes &&
            handleProductType && (
              <>
                {(searchParams?.productTypeId &&
                  typeof searchParams.productTypeId === "string" &&
                  productType &&
                  productType[0].productTypeId !== "all") ||
                !searchParams?.productTypeId ? (
                  <>
                    {productTypes.map((type) => (
                      <button
                        {...register("filterProductType")}
                        className={`filter-btn ${
                          type.productTypeId ===
                            productType?.find(
                              (pt) => pt.productTypeId === type.productTypeId
                            )?.productTypeId && "isActive"
                        }`}
                        key={type.productTypeId}
                        onClick={() => handleProductType(type)}
                      >
                        {type.name}
                      </button>
                    ))}
                  </>
                ) : (
                  <MiniLoader />
                )}
              </>
            )}
        </div>
      </div>
    </FormProvider>
  );
};
