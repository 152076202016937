import { FC, useEffect, useState } from "react";
import { Button } from "@components/Button";
import {
  IManageEndUserInfo,
  IManualAddUser,
} from "@models/components/secondary/manualadduser";
import { eventUsersTableHeaders } from "@constants/headers";
import { findEventParticipants } from "@api/eventParticipants";
import { useParams } from "react-router-dom";
import {
  getUserGender,
  hideWindowScroll,
  showWindowScroll,
} from "@services/common";
import { ManageEndUserModal } from "@pages/Modals/Users/ManageEndUserModal";
import {
  ADD_END_USER_TITLE,
  DELETE_USER_EVENT_BODY,
  DELETE_USER_TITLE,
  EDIT_END_USER_TITLE,
} from "@constants/users";
import { AlertModal } from "@components/AlertModal";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { Notification } from "@components/Notification";
import { useNotification } from "@hooks/common/useNotification";
import { SOMETHING_WENT_WRONG_NOTIFICATION } from "@constants/commons";
import { IEndUser } from "@models/common/endUsers";
import { MiniLoader } from "@components/Loader/MiniLoader";
import { handleUpdateEventParticipant } from "@api/common";
import { getFormattedError } from "@services/http";

export const ManualAddUser: FC<IManualAddUser> = ({
  handleUserData,
  participants,
  handleUserEventDelete,
}) => {
  const [openAddUserModal, setAddUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [eventParticipants, setEventParticipants] = useState<IEndUser[]>();
  const [userInfo, setUserInfo] = useState<IManageEndUserInfo | undefined>();
  const [participantManaged, setParticipantManaged] = useState(false);
  const [currentParticipantId, setCurrentParticipantId] = useState("");
  const [deleteWindowOpen, setDeleteWindowOpen] = useState(false);

  const { eventID } = useParams();
  const dispatch = useAppDispatch();

  const {
    isActive,
    message: notificationMessage,
    type: notificationType,
    showNotification,
    closeNotification,
  } = useNotification();

  const handleManaged = (state: boolean) => {
    setParticipantManaged(state);
  };

  const handleDeleteWindowOpen = (userId?: string) => {
    setCurrentParticipantId(userId || "");
    setDeleteWindowOpen(!deleteWindowOpen);
  };

  const handleSetCurrentParticipantsEvent = (data: IEndUser[]) => {
    setEventParticipants(data);
  };

  const handleAddUserModalOpen = () => {
    participantManaged && setParticipantManaged(false);
    setAddUserModal(!openAddUserModal);
    if (openAddUserModal) {
      setUserInfo(undefined);
    }
  };

  const handleEditUserModalOpen = () => {
    participantManaged && setParticipantManaged(false);
    setOpenEditUserModal(!openEditUserModal);
    if (openEditUserModal) {
      setUserInfo(undefined);
    }
  };

  const handleUserEventEdit = (eventParticipant: IEndUser) => {
    setUserInfo({
      userId: eventParticipant.user.userId,
      firstName: eventParticipant.user.firstName,
      lastName: eventParticipant.user.lastName,
      email: eventParticipant.user.email,
      phone_number: eventParticipant.user.phoneNumber,
      badge: eventParticipant.badgeNumber,
      gender: eventParticipant.gender,
      anonymous: eventParticipant.anonymous,
    });

    handleEditUserModalOpen();
  };

  useEffect(() => {
    setEventParticipants(participants);
  }, [participants]);

  useEffect(() => {
    if (!openAddUserModal && !openEditUserModal && userInfo && eventID) {
      (async function asyncWrapper() {
        try {
          const participants = await findEventParticipants(dispatch, eventID);
          handleSetCurrentParticipantsEvent(
            participants.content.map((participant) => ({
              badgeNumber: participant.endUser.badgeNumber,
              anonymous: participant.endUser.anonymous,
              displayId: participant.endUser.displayId,
              endUserId: participant.endUser.endUserId,
              gender: participant.endUser.gender,
              user: {
                createdAt: participant.endUser.user.createdAt,
                createdBy: participant.endUser.user.createdBy,
                email: participant.endUser.user.email,
                firstName: participant.endUser.user.firstName,
                lastName: participant.endUser.user.lastName,
                phoneNumber: participant.endUser.user.phoneNumber,
                role: participant.endUser.user.role,
                status: participant.endUser.user.status,
                updatedAt: participant.endUser.user.updatedAt,
                userId: participant.endUser.user.userId,
              },
            }))
          );
        } catch (error) {
          showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [eventID, openAddUserModal, openEditUserModal]);

  useEffect(() => {
    if (openAddUserModal || openEditUserModal) {
      showWindowScroll();
    } else {
      hideWindowScroll();
    }
  }, [openAddUserModal, openEditUserModal]);

  return (
    <>
      <Notification
        type={notificationType}
        message={notificationMessage}
        isActive={isActive}
        closeNotification={closeNotification}
      />
      {deleteWindowOpen && (
        <AlertModal
          type="delete"
          title={DELETE_USER_TITLE}
          body={DELETE_USER_EVENT_BODY}
          handleOpen={handleDeleteWindowOpen}
          handleConfirm={handleUserEventDelete}
          id={currentParticipantId}
        />
      )}
      {(openAddUserModal || openEditUserModal) && (
        <ManageEndUserModal
          title={userInfo ? EDIT_END_USER_TITLE : ADD_END_USER_TITLE}
          handleModalOpen={
            userInfo ? handleEditUserModalOpen : handleAddUserModalOpen
          }
          userInfo={userInfo}
          handleUser={handleUpdateEventParticipant}
          eventID={eventID}
          handleManaged={handleManaged}
          handleEditUserModalOpen={handleEditUserModalOpen}
          handleAddUserModalOpen={handleAddUserModalOpen}
          handleUserData={handleUserData}
        />
      )}
      <div className="form">
        <Button
          text="Add user"
          icon="load-icon icon-add-user"
          className="load-btn btn-primary"
          onClick={handleAddUserModalOpen}
        />
        {!!eventParticipants?.length ? (
          <div className="event-table-user">
            <table
              className="event-table"
              cellSpacing={0}
              cellPadding={0}
              border={0}
            >
              <thead className="event-thead">
                <tr className="event-tr">
                  {eventUsersTableHeaders.map((header, index) => (
                    <th className="event-th" key={index}>
                      {header.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="event-tbody">
                {eventParticipants.map((eventParticipant) => (
                  <tr className="event-tr" key={eventParticipant.endUserId}>
                    <td className="event-td" data-label="Name">
                      {eventParticipant.user.firstName}{" "}
                      {eventParticipant.user.lastName}
                    </td>
                    <td className="event-td" data-label="Email">
                      {eventParticipant.user.email
                        ? eventParticipant.user.email
                        : "-"}
                    </td>
                    <td className="event-td" data-label="Phone number">
                      {eventParticipant.user.phoneNumber
                        ? eventParticipant.user.phoneNumber
                        : "-"}
                    </td>
                    <td className="event-td" data-label="Badge number">
                      {eventParticipant.badgeNumber
                        ? eventParticipant.badgeNumber
                        : "-"}
                    </td>
                    <td className="event-td" data-label="Gender">
                      {getUserGender(eventParticipant.gender)}
                    </td>
                    <td className="event-td" data-label="">
                      <button className="event-table-controls">
                        <i
                          className="table-controls-icon icon icon-edit"
                          onClick={() => handleUserEventEdit(eventParticipant)}
                        />
                      </button>
                      <button className="event-table-controls">
                        <i
                          className="table-controls-icon icon icon-delete"
                          onClick={() => {
                            handleDeleteWindowOpen(
                              eventParticipant.user.userId
                            );
                          }}
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <>{userInfo && <MiniLoader />}</>
        )}
      </div>
    </>
  );
};
