import login from "@assets/img/login.jpg";
import { FC, memo, useEffect, useState } from "react";
import { Button } from "@components/Button";
import { Input } from "@components/Input";
import { useLogin } from "@hooks/common/useLogin";
import { ICredentials } from "@models/auth/data";
import { FormProvider, useForm } from "react-hook-form";
import { validationSchemas } from "@services/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { selectCognitoUser } from "@store/selectors/cognitoUser";
import {
  FORGOT_PASSWORD_NEW_ROUTE,
  SIGNUP_CREATE_PASSWORD_ROUTE,
} from "@constants/routes";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CREATE_PASS_PAGE,
  EMAIL_COGNITO_USERNAME_TYPE,
  FORGOT_PASS_PAGE,
  PHONE_NUMBER_COGNITO_USERNAME_TYPE,
  SOMETHING_WENT_WRONG_NOTIFICATION,
} from "@constants/commons";
import { ICreatePass } from "@models/components/main/createpass";
import { Loader } from "@components/Loader";
import { selectUserInfo } from "@store/selectors/user";
import { PASSWORD_TEMPLATE_MATCH } from "@constants/validation";
import { selectTokens } from "@store/selectors/tokens";
import {
  getDefaultRouteFromHeaders,
  getHeadersByUserRole,
} from "@services/routes";
import { withoutSpaces } from "@services/regexps";
import { getUserRoleFromToken } from "@services/common";

export const CreatePass: FC = memo(() => {
  const location = useLocation();
  const navigate = useNavigate();

  const cognitoUser = useAppSelector(selectCognitoUser);
  const userInfo = useAppSelector(selectUserInfo);
  const tokensInfo = useAppSelector(selectTokens);

  const typeUsername =
    cognitoUser?.username?.includes("@") || userInfo?.username?.includes("@")
      ? EMAIL_COGNITO_USERNAME_TYPE
      : cognitoUser?.username?.startsWith("+") ||
        userInfo?.username?.startsWith("+")
      ? PHONE_NUMBER_COGNITO_USERNAME_TYPE
      : undefined;

  const formMethods = useForm<ICreatePass>({
    resolver: yupResolver(validationSchemas.passwordCreate),
    mode: "onSubmit",
    shouldFocusError: false,
    defaultValues: {
      email:
        typeUsername === EMAIL_COGNITO_USERNAME_TYPE
          ? cognitoUser?.username
          : undefined,
      phone_number:
        typeUsername === PHONE_NUMBER_COGNITO_USERNAME_TYPE
          ? cognitoUser?.username
          : undefined,
    },
  });

  const { handleSubmit, formState, clearErrors, setError, watch, setValue } =
    formMethods;
  const { errors, isSubmitting, isSubmitSuccessful } = formState;
  const { phone_number, email } = watch();

  const [credentials, setCredentials] = useState<ICredentials>();

  const pageType =
    location.pathname === SIGNUP_CREATE_PASSWORD_ROUTE
      ? CREATE_PASS_PAGE
      : location.pathname === FORGOT_PASSWORD_NEW_ROUTE
      ? FORGOT_PASS_PAGE
      : undefined;

  const onSubmit = (data: ICredentials) => {
    const credentials = { ...data };
    clearErrors();
    if (pageType === CREATE_PASS_PAGE) {
      credentials.confirmTerms = true;
    } else if (pageType === FORGOT_PASS_PAGE) {
      credentials.confirmTerms = false;
    }

    setCredentials(credentials);
  };

  const { loginError, clearError, isAuthenticating, isAuthenticated } =
    useLogin(credentials);

  useEffect(() => {
    if (typeUsername === EMAIL_COGNITO_USERNAME_TYPE) {
      setValue(
        "email",
        cognitoUser?.username?.trim() || userInfo?.username?.trim()
      );
    } else {
      setValue(
        "phone_number",
        cognitoUser?.username?.trim() || userInfo?.username?.trim()
      );
    }
  }, [phone_number, email]);

  useEffect(() => {
    if (isSubmitting) {
      clearErrors();
      clearError();
    }

    if (loginError) {
      if (typeUsername === EMAIL_COGNITO_USERNAME_TYPE) {
        setError("email", {
          type: "custom",
          message: SOMETHING_WENT_WRONG_NOTIFICATION,
        });
      } else {
        setError("phone_number", {
          type: "custom",
          message: SOMETHING_WENT_WRONG_NOTIFICATION,
        });
      }
    }
  }, [
    clearError,
    clearErrors,
    isSubmitting,
    loginError,
    setError,
    typeUsername,
  ]);

  useEffect(() => {
    if (isSubmitSuccessful && isAuthenticated) {
      navigate(
        getDefaultRouteFromHeaders(
          getHeadersByUserRole(getUserRoleFromToken(tokensInfo))
        )
      );
    } else {
      if (typeUsername === EMAIL_COGNITO_USERNAME_TYPE) {
        setError("email", { type: "custom", message: loginError });
      } else {
        setError("phone_number", { type: "custom", message: loginError });
      }
    }
  }, [isSubmitSuccessful, isAuthenticated, navigate]);

  if (!userInfo?.username) {
    return <Loader />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className={`login ${isAuthenticating && "login-loading"}`}>
        <img src={login} alt="login" className="login-img" />
        <div className="login-container">
          <h1 className="login-title login-title-space">
            Welcome to <b>{process.env.REACT_APP_PROJECT_NAME}</b>
          </h1>
          <div className="login-form">
            <FormProvider {...formMethods}>
              <Input
                value={
                  (cognitoUser?.username || userInfo?.username) &&
                  userInfo.verified
                    ? cognitoUser?.username || userInfo?.username
                    : ""
                }
                label={
                  typeUsername === EMAIL_COGNITO_USERNAME_TYPE
                    ? "Email*"
                    : "Phone Number*"
                }
                subLabel={
                  typeUsername === EMAIL_COGNITO_USERNAME_TYPE
                    ? errors.email?.message
                    : errors.phone_number?.message
                }
                error={
                  typeUsername === EMAIL_COGNITO_USERNAME_TYPE
                    ? !!errors.email?.message
                    : !!errors.phone_number?.message
                }
                disable
                name={
                  typeUsername === EMAIL_COGNITO_USERNAME_TYPE
                    ? "email"
                    : "phone_number"
                }
              />
              <Input
                name="password"
                label={
                  pageType === FORGOT_PASS_PAGE
                    ? "New password*"
                    : "Create password*"
                }
                subLabel={errors.password?.message}
                permanentText={PASSWORD_TEMPLATE_MATCH}
                error={!!errors.password?.message}
                type="password"
                pattern={withoutSpaces}
                disable={isAuthenticating}
              />
              <Input
                name="confirmPassword"
                label={
                  pageType === FORGOT_PASS_PAGE
                    ? "Confirm new password*"
                    : "Confirm password*"
                }
                subLabel={errors.confirmPassword?.message}
                error={!!errors.confirmPassword?.message}
                type="password"
                pattern={withoutSpaces}
                disable={isAuthenticating}
              />
            </FormProvider>
            <Button
              type="submit"
              text="Confirm"
              className="btn-main btn-big"
              disable={isAuthenticating}
            />
          </div>
        </div>
      </div>
    </form>
  );
});
