import { FC } from "react";
import { IManageEndUserMeasurementModal } from "@models/components/secondary/measurementsmodal";
import { ManageMeasurementsModal } from "@pages/Modals/Measurements/ManageMeasurementsModal";

export const ManageEndUserMeasurementsModal: FC<
  IManageEndUserMeasurementModal
> = ({
  title,
  userInfo,
  handleModalOpen,
  handleUser,
  eventMeasurementsInfo,
  userMeasurementsInfo,
}) => {
  return (
    <ManageMeasurementsModal
      title={title}
      showWhoMeasured={false}
      userInfo={userInfo}
      handleModalOpen={handleModalOpen}
      handleUser={handleUser}
      eventMeasurementsInfo={eventMeasurementsInfo}
      userMeasurementsInfo={userMeasurementsInfo}
    />
  );
};
