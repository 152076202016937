import { FC } from "react";
import { INotification } from "@models/components/secondary/notification";

export const Notification: FC<INotification> = ({
  type,
  message,
  isActive,
  closeNotification,
}) => {
  return (
    <>
      {isActive && (
        <div className="notification">
          <div className="notification-wrapper">
            <div className={`alert-status ${type}`}>
              <i
                className={`alert-icon icon ${
                  type === "success" ? "icon-check" : "icon-cross"
                }`}
              ></i>
            </div>
            <i
              className="notification-close-icon icon-close-notification icon"
              onClick={closeNotification}
            ></i>
            <div className="notification-title">
              {type === "success" ? "Success!" : "Error!"}
            </div>
            <div className="notification-subtitle">{message}</div>
          </div>
        </div>
      )}
    </>
  );
};
