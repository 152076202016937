import { IProductTypes, ProductType } from "@models/common/events";
import { RequestGenderMeasurementParameter } from "@models/common/measurements";
import { http } from "@services/http";
import { AppDispatch } from "@store/store";
import { pickBy } from "lodash";

export const getProductTypesBySearchKey = async (
  dispatch: AppDispatch,
  searchKey: string,
  size: number = 200
) =>
  await http.get<IProductTypes>(
    !!searchKey
      ? `/api/v1/product/type?searchKey=${searchKey}${
          size ? `&size=${size}` : ""
        }`
      : `/api/v1/product/type${size ? `?size=${size}` : ""}`,
    dispatch
  );

export const getProductTypesByIds = async (
  dispatch: AppDispatch,
  productTypeIds: string[]
) =>
  await http.post<ProductType[], string[]>(
    "/api/v1/product/type/by-product-type-ids",
    productTypeIds,
    dispatch
  );

export const listOfFilteredProductTypes = async (
  dispatch: AppDispatch,
  page: number,
  size: number,
  sort: string,
  searchKey?: string
) =>
  await http.post<IProductTypes, { searchKey: string | null }>(
    `/api/v1/product/type/filter?page=${page}&size=${size}&sort=${sort}`,
    { searchKey: searchKey || null },
    dispatch
  );

export const updateProductType = async (
  dispatch: AppDispatch,
  productTypeData: {
    productTypeId: string;
    name: string;
    genderToMeasurementParamRefs: {
      FEMALE?: RequestGenderMeasurementParameter[];
      MALE?: RequestGenderMeasurementParameter[];
    };
  }
) => {
  const genderToMeasurementParamRefs = pickBy({
    MALE: !!productTypeData.genderToMeasurementParamRefs?.MALE?.length
      ? productTypeData.genderToMeasurementParamRefs.MALE
      : null,
    FEMALE: !!productTypeData.genderToMeasurementParamRefs?.FEMALE?.length
      ? productTypeData.genderToMeasurementParamRefs.FEMALE
      : null,
  });

  return await http.post<
    ProductType,
    {
      name: string;
      genderToMeasurementParamRefs: {
        MALE?: RequestGenderMeasurementParameter[];
        FEMALE?: RequestGenderMeasurementParameter[];
      };
    }
  >(
    `/api/v1/product/type/${productTypeData.productTypeId}`,
    {
      name: productTypeData.name,
      genderToMeasurementParamRefs,
    },
    dispatch
  );
};

export const createProductType = async (
  dispatch: AppDispatch,
  productTypeData: {
    productTypeId: string;
    name: string;
    genderToMeasurementParamRefs: {
      FEMALE?: RequestGenderMeasurementParameter[];
      MALE?: RequestGenderMeasurementParameter[];
    };
  }
) => {
  const genderToMeasurementParamRefs = pickBy({
    MALE: !!productTypeData.genderToMeasurementParamRefs?.MALE?.length
      ? productTypeData.genderToMeasurementParamRefs.MALE
      : null,
    FEMALE: !!productTypeData.genderToMeasurementParamRefs?.FEMALE?.length
      ? productTypeData.genderToMeasurementParamRefs.FEMALE
      : null,
  });

  return await http.post<
    ProductType,
    {
      name: string;
      genderToMeasurementParamRefs: {
        MALE?: RequestGenderMeasurementParameter[];
        FEMALE?: RequestGenderMeasurementParameter[];
      };
    }
  >(
    "/api/v1/product/type",
    {
      name: productTypeData.name,
      genderToMeasurementParamRefs,
    },
    dispatch
  );
};

export const getProductTypeById = async (
  dispatch: AppDispatch,
  productTypeId: string
) =>
  await http.get<ProductType>(
    `/api/v1/product/type/${productTypeId}`,
    dispatch
  );
