import { FC, useState, useEffect } from "react";
import { StatusBar } from "@components/StatusBar";
import { Loader } from "@components/Loader";
import { ISalesRepList } from "@models/common/salesRep";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { listOfFilteredSalesRep } from "@api/salesRep";
import {
  formateCapitalizeStr,
  getPage,
  getSortTypes,
  hideWindowScroll,
  showWindowScroll,
} from "@services/common";
import {
  ACCEPTED_USER_STATUS,
  DEALERS_BREADCRUMBS,
  DELETED_USER_STATUS,
  NO_FOUND_PLUG_TYPE,
  NO_SALES_REP_PLUG_TYPE,
  PENDING_USER_STATUS,
  SEARCH_QUERY_KEY,
  TABLE_ROWS_LIMIT,
} from "@constants/commons";
import { HeaderBarTable } from "@components/HeaderBarTable";
import { Pagination } from "@components/Pagination";
import { Plug } from "@components/Plug";
import {
  NO_FOUND_PLUG_ERROR,
  NO_SALES_REP_PLUG_ERROR,
} from "@constants/errors";
import { IDealer } from "@models/common/dealer";
import { getDealerById } from "@api/dealers";
import { SalesRepTable } from "@components/SalesRep/SalesRepTable";
import { Breadcrumbs } from "@components/Breadcrumbs";
import { ISortType } from "@models/common/app";
import { ManageSalesRepModal } from "@pages/Modals/Users/ManageSalesRepModal";
import { ADD_SALES_REP_TITLE } from "@constants/users";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import queryString from "query-string";
import { MiniLoader } from "@components/Loader/MiniLoader";
import { MAIN_ROUTE } from "@constants/routes";
import { handleSalesRepUpdate } from "@api/common";
import { getFormattedError } from "@services/http";

export const DealerInfo: FC = () => {
  const location = useLocation();
  const parsedSearchedQuery = queryString.parse(location.search)?.[
    SEARCH_QUERY_KEY
  ];

  const [dealerData, setDealerData] = useState<IDealer>();
  const [dealerSalesReps, setDealerSalesReps] = useState<ISalesRepList>();
  const [searchedValue, setSearchedValue] = useState<string | undefined>(
    typeof parsedSearchedQuery === "string" ? parsedSearchedQuery : undefined
  );
  const [sortType, setSortType] = useState<ISortType>();
  const [openSalesRepEditModal, setOpenSalesRepEditModal] =
    useState<boolean>(false);
  const [openSalesRepCreateModal, setOpenSalesRepCreateModal] =
    useState<boolean>(false);
  const [deleteWindowOpen, setDeleteWindowOpen] = useState(false);
  const [searchParams, setSearchParams] = useState<
    queryString.ParsedQuery<string> | undefined
  >(queryString.parse(location.search));
  const [searchFieldFocused, setSearchFieldFocused] = useState(false);
  const [managed, setManaged] = useState(false);

  const { dealerId, page } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const pageId = getPage(page);

  useEffect(() => {
    if (dealerId) {
      (async function asyncWrapper() {
        try {
          const dealer = await getDealerById(dispatch, dealerId);
          setDealerData(dealer);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [dealerId]);

  const handleSearchFieldFocus = () => {
    setSearchFieldFocused(true);
  };

  const addBtnHandler = () => {
    setOpenSalesRepCreateModal(true);
  };

  const handleModalSalesRepCreate = () => {
    setOpenSalesRepCreateModal(!openSalesRepCreateModal);
  };

  const handleFilteredSalesReps = (salesReps: ISalesRepList) => {
    setDealerSalesReps(salesReps);
  };

  const handleSearchedSalesRep = (searchedValue: string | undefined) => {
    setSearchedValue(searchedValue);
  };

  const handleModalSalesRepEdit = () => {
    setOpenSalesRepEditModal(!openSalesRepEditModal);
  };

  const handleDeleteWindowOpen = () => {
    setDeleteWindowOpen(!deleteWindowOpen);
  };

  const handleManaged = (state: boolean) => {
    setManaged(state);
  };

  const handleSorting = (parameter: string) => {
    let filteredParameter;

    if (parameter === "Name") {
      filteredParameter = "user.firstName,user.lastName";
    }
    if (parameter === "System status") {
      filteredParameter = "user.status";
    }

    setSortType(getSortTypes(sortType, filteredParameter, parameter));
  };

  useEffect(() => {
    if (searchFieldFocused) {
      if (!!searchedValue) {
        if (pageId + 1 === 1) {
          navigate(`?${SEARCH_QUERY_KEY}=${searchedValue}`);
        } else {
          navigate(
            `/${
              location.pathname.split(MAIN_ROUTE)[1]
            }/1?${SEARCH_QUERY_KEY}=${searchedValue}`
          );
        }
      } else {
        navigate("");
      }
    }
  }, [searchedValue]);

  useEffect(() => {
    const searchQuery = queryString.parse(location.search);
    if (!!Object.keys(searchQuery).length) {
      setSearchParams(searchQuery);
    } else {
      setSearchParams({});
    }
  }, [location.search]);

  useEffect(() => {
    if (!!searchParams?.[SEARCH_QUERY_KEY]) {
      handleSearchedSalesRep(searchParams[SEARCH_QUERY_KEY].toString());
    }
  }, [searchParams?.[SEARCH_QUERY_KEY]]);

  useEffect(() => {
    if (
      !deleteWindowOpen &&
      !openSalesRepCreateModal &&
      !openSalesRepEditModal &&
      (searchedValue
        ? searchParams?.[SEARCH_QUERY_KEY]
        : !searchParams?.[SEARCH_QUERY_KEY])
    ) {
      (async function asyncWrapper() {
        try {
          const salesReps = await listOfFilteredSalesRep(
            dispatch,
            pageId,
            TABLE_ROWS_LIMIT,
            sortType
              ? sortType.filteredParameter
              : "user.firstName,user.lastName,asc",
            searchedValue,
            dealerId
          );
          handleFilteredSalesReps(salesReps);
          if (managed) handleManaged(false);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [
    pageId,
    sortType,
    deleteWindowOpen,
    managed,
    openSalesRepCreateModal,
    openSalesRepEditModal,
    searchParams?.[SEARCH_QUERY_KEY],
  ]);

  useEffect(() => {
    if (openSalesRepCreateModal) {
      showWindowScroll();
    } else {
      hideWindowScroll();
    }
  }, [openSalesRepCreateModal]);

  return (
    <>
      {dealerData ? (
        <main className="main-container info-container">
          <Breadcrumbs
            title={dealerData.dealerName}
            type={DEALERS_BREADCRUMBS}
          />
          <h2 className="event-header event-title">{dealerData.dealerName}</h2>
          <ul className="sales">
            <li className="sales-item">
              Email:{" "}
              <span className="sales-val">
                {dealerData.user.email ? dealerData.user.email : "-"}
              </span>
            </li>
            <li className="sales-item">
              Event's completeness:{" "}
              <div className="event-progress sales-val sales-bar">
                <StatusBar
                  value={
                    dealerData.assignedEventsTotal > 0
                      ? Math.ceil(
                          (dealerData.assignedEventsFinished /
                            dealerData.assignedEventsTotal) *
                            100
                        )
                      : 0
                  }
                />
                {dealerData.assignedEventsFinished}/
                {dealerData.assignedEventsTotal}
              </div>
            </li>
            <li className="sales-item">
              Phone number:{" "}
              <span className="sales-val">
                {dealerData.user.phoneNumber
                  ? dealerData.user.phoneNumber
                  : "-"}
              </span>
            </li>
            <li className="sales-item">
              System status:{" "}
              <span
                className={`sales-status status ${
                  dealerData.user.status === ACCEPTED_USER_STATUS
                    ? "status-success"
                    : dealerData.user.status === DELETED_USER_STATUS
                    ? "status-danger"
                    : dealerData.user.status === PENDING_USER_STATUS
                    ? "status-warning"
                    : ""
                }`}
              >
                {formateCapitalizeStr(dealerData.user.status)}
              </span>
            </li>
          </ul>
        </main>
      ) : (
        <Loader />
      )}
      <>
        {openSalesRepCreateModal && (
          <ManageSalesRepModal
            title={ADD_SALES_REP_TITLE}
            handleModalOpen={handleModalSalesRepCreate}
            userInfo={undefined}
            handleUser={handleSalesRepUpdate}
            assignedDealers={dealerData?.dealerId || ""}
          />
        )}
        {dealerSalesReps ? (
          <main className="main-container">
            <HeaderBarTable
              searchedValue={searchedValue}
              handleSearchedValues={handleSearchedSalesRep}
              title="Sales representatives"
              btnTitles="Add new sales representatives"
              btnHandlers={[addBtnHandler]}
              handleSearchFieldFocus={handleSearchFieldFocus}
            />
            {!!dealerSalesReps.content.length ? (
              <div className="event-table-container">
                <SalesRepTable
                  sort={sortType}
                  salesRep={dealerSalesReps.content}
                  handleSorting={handleSorting}
                  handleModalOpen={handleModalSalesRepEdit}
                  deleteWindowOpen={deleteWindowOpen}
                  handleDeleteModal={handleDeleteWindowOpen}
                  handleManaged={handleManaged}
                />
                <Pagination
                  dataLength={dealerSalesReps.totalElements}
                  pageId={pageId}
                />
              </div>
            ) : (
              <MiniLoader
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5vh",
                }}
                time={3}
                afterload={
                  <Plug
                    message={
                      searchParams && !!Object.keys(searchParams)?.length
                        ? NO_FOUND_PLUG_ERROR
                        : NO_SALES_REP_PLUG_ERROR
                    }
                    type={
                      searchParams && !!Object.keys(searchParams)?.length
                        ? NO_FOUND_PLUG_TYPE
                        : NO_SALES_REP_PLUG_TYPE
                    }
                  />
                }
              />
            )}
          </main>
        ) : (
          <Loader />
        )}
      </>
    </>
  );
};
