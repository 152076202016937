import { ICheckRoute } from "@models/components/secondary/checkroute";
import { Navigate } from "react-router-dom";
import { DEFAULT_REDIRECT_ROUTE } from "@constants/routes";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { selectTokens } from "@store/selectors/tokens";
import {
  getDefaultRouteFromHeaders,
  getHeadersByUserRole,
  isRouteAuthCheck,
} from "@services/routes";
import { Page404 } from "@pages/Page404";
import { checkIfRoleHasAccess, getUserRoleFromToken } from "@services/common";

export const CheckRoute: React.FC<ICheckRoute> = ({
  Component,
  redirectLink = DEFAULT_REDIRECT_ROUTE,
  isProtected = false,
  path,
  access,
  ...props
}) => {
  const isAuthenticated = !!Object.keys(
    JSON.parse(
      localStorage.getItem(`${process.env.REACT_APP_TOKENS_STATE}`) || "{}"
    )
  )?.length;
  const tokensInfo = useAppSelector(selectTokens);
  const userRole = getUserRoleFromToken(tokensInfo);

  if (
    !isAuthenticated &&
    isProtected &&
    (access && userRole ? !checkIfRoleHasAccess(userRole, access) : true)
  ) {
    return <Navigate to={redirectLink} />;
  }

  if (access && userRole && !checkIfRoleHasAccess(userRole, access)) {
    return <Page404 />;
  }

  if (isAuthenticated && isRouteAuthCheck(path)) {
    return (
      <Navigate
        to={getDefaultRouteFromHeaders(
          getHeadersByUserRole(getUserRoleFromToken(tokensInfo))
        )}
        replace
      />
    );
  }

  return <Component {...props} />;
};
