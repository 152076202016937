import { FC } from "react";
import { IAlertModal } from "@models/components/secondary/alertmodal";

export const AlertModal: FC<IAlertModal> = ({
  type,
  title,
  body,
  id,
  handleOpen,
  handleConfirm,
  cancelBtn = "Cancel",
  confirmBtn = "Confirm",
}) => {
  const confirmModal = (id: string) => {
    handleConfirm(id);
    handleOpen(id);
  };

  return (
    <>
      <div className="modal-back">
        <div className="alert-modal">
          <div
            className={`alert-status ${
              type === "create" ? "success" : "danger"
            }`}
          >
            <i
              className={`alert-icon icon ${
                type === "create" ? "icon-check" : "icon-cross"
              }`}
            ></i>
          </div>
          <div className="alert-title">{title}</div>
          <div className="alert-subtitle">{body}</div>
          <div className="alert-footer">
            <button className="btn btn-primary" onClick={() => handleOpen(id)}>
              {cancelBtn}
            </button>
            <button
              className={`btn ${type === "create" ? "btn-add" : "btn-danger"}`}
              onClick={() => confirmModal(id)}
            >
              {confirmBtn}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
