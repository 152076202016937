import { DEFAULT_ITEMS_COUNT_WITHOUT_PAGINATION } from "@constants/commons";
import {
  IMeasurementList,
  IMeasurementParameter,
  IMeasurementParamsList,
} from "@models/common/measurements";
import { UserGender } from "@models/common/user";
import { http } from "@services/http";
import { AppDispatch } from "@store/store";

export const getEventParticipantMeasurement = async (
  dispatch: AppDispatch,
  eventId: string,
  endUserId: string
) =>
  await http.get<IMeasurementList>(
    `/api/v1/measurement/event/${eventId}/participant/${endUserId}/measurement`,
    dispatch
  );

export const updateEventParticipantMeasurement = async (
  dispatch: AppDispatch,
  eventId: string,
  endUserId: string,
  gender: UserGender,
  measurementData: {
    measurementParamId: string;
    value: string;
  }[],
  whoMeasured?: string
) =>
  await http.post<
    IMeasurementList,
    {
      whoMeasured: string;
      gender: UserGender;
      measurementData: {
        measurementParamId: string;
        value: string;
      }[];
    }
  >(
    `/api/v1/measurement/event/${eventId}/participant/${endUserId}/measurement`,
    {
      whoMeasured: whoMeasured || "",
      gender,
      measurementData,
    },
    dispatch
  );

export const deleteEventParticipantMeasurement = async (
  dispatch: AppDispatch,
  eventId: string,
  endUserId: string
) =>
  await http.delete(
    `/api/v1/measurement/event/${eventId}/participant/${endUserId}/measurement`,
    null,
    dispatch
  );

export const getMeasurementsBySearchKey = async (
  dispatch: AppDispatch,
  searchKey = "",
  sort = "name,asc",
  size = DEFAULT_ITEMS_COUNT_WITHOUT_PAGINATION
) =>
  await http.get<IMeasurementParamsList>(
    `/api/v1/product/measurement-param?sort=${sort}&size=${size}${
      !!searchKey ? `&searchKey=${searchKey}` : ""
    }`,
    dispatch
  );

export const createMeasurementParam = async (
  dispatch: AppDispatch,
  measurementName: string,
  image?: Blob
) =>
  await http.post<IMeasurementParameter, { name: string; image?: Blob }>(
    "/api/v1/product/measurement-param",
    image
      ? {
          name: measurementName,
          image,
        }
      : {
          name: measurementName,
        },
    dispatch,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const updateMeasurementParam = async (
  dispatch: AppDispatch,
  measurementParamId: string,
  measurementName: string,
  isImageChanged: boolean,
  image?: Blob
) =>
  await http.post<
    IMeasurementParameter,
    { name: string; image?: Blob; isImageChanged: boolean }
  >(
    `/api/v1/product/measurement-param/${measurementParamId}`,
    isImageChanged
      ? {
          name: measurementName,
          image,
          isImageChanged,
        }
      : {
          name: measurementName,
          isImageChanged,
        },
    dispatch,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const generateEventMeasurementsList = async (
  dispatch: AppDispatch,
  eventId: string
) =>
  await http.post<Blob, null>(
    `/api/v1/measurement/event/${eventId}/participant/export`,
    null,
    dispatch,
    {
      responseType: "blob",
    }
  );
