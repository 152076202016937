import { FC } from "react";
import { MouseEventHandler, useEffect, useState } from "react";
import { bulkFilesExtensions } from "@constants/commons";
import { useFilePicker } from "use-file-picker";
import { getBulkEndUsers, getBulkInfo, uploadBulkFile } from "@api/bulk";
import { useAsync } from "@hooks/common/useAsync";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import {
  BulkErrorMessage,
  IBulkLoadField,
  IBulkResponse,
} from "@models/components/main/bulkadduser";
import { WARNING_MESSAGE_BULK } from "@constants/events";
import { formateBulkError, formateBulkStatus } from "@services/common";
import { BULK_ERROR_REPORT } from "@constants/errors";
import { getFormattedError } from "@services/http";

export const LoadField: FC<IBulkLoadField> = ({
  bulkStatus,
  handleBulkUploadStatus,
  handleBulkId,
  isContinue,
  handleEventParticipants,
  handleLoadedFile,
}) => {
  const [loadedFile, setLoadedFile] = useState<Blob | undefined>();
  const [bulkId, setBulkId] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<
    BulkErrorMessage | undefined
  >();

  const dispatch = useAppDispatch();

  const [openFileSelector, { filesContent, clear: clearLoadedFile }] =
    useFilePicker({
      accept: bulkFilesExtensions,
      limitFilesConfig: { max: 1 },
      readAs: "DataURL",
    });

  const loadBulkFile = async () => {
    try {
      if (loadedFile instanceof Blob) {
        const bulk = await uploadBulkFile(dispatch, loadedFile);
        return bulk;
      }
    } catch (error) {
      return Promise.reject(getFormattedError(error));
    }
  };

  const { reset, status, error, value } = useAsync<IBulkResponse | undefined>(
    loadBulkFile,
    {},
    loadedFile instanceof Blob
  );

  const handleFileUpload: MouseEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();

    handleBulkUploadStatus("idle");
    setLoadedFile(undefined);
    handleLoadedFile(undefined);
    clearLoadedFile();
    setErrorMessage(undefined);
    reset();
    openFileSelector();
  };

  const handleFileClear: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    handleBulkUploadStatus("idle");
    setLoadedFile(undefined);
    handleLoadedFile(undefined);
    clearLoadedFile();
    setErrorMessage(undefined);
    reset();
  };

  const bulkInfoHandler = async () => {
    try {
      if (bulkId) {
        const bulkInfo = await getBulkInfo(dispatch, bulkId);
        handleBulkUploadStatus(formateBulkStatus(bulkInfo.status));
        if (bulkInfo.status === "IN_PROGRESS") {
          setTimeout(() => {
            bulkInfoHandler();
          }, 1000);
        } else {
          return bulkInfo;
        }
      }
    } catch (error) {
      return Promise.reject(getFormattedError(error));
    }
  };

  useEffect(() => {
    if (!!filesContent.length) {
      (async function asyncWrapper() {
        try {
          const response = await fetch(filesContent[0].content);
          const blob = await response.blob();
          setLoadedFile(blob);
          handleLoadedFile(blob);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [filesContent]);

  useEffect(() => {
    if (!!error) {
      setLoadedFile(undefined);
      handleLoadedFile(undefined);
      handleBulkUploadStatus("error");
      setErrorMessage(formateBulkError(error));
    }
  }, [error]);

  useEffect(() => {
    if (status === "success" && value?.bulkUploadId) {
      setBulkId(value.bulkUploadId);
      handleBulkId(value.bulkUploadId);
    }
  }, [status, value]);

  useEffect(() => {
    if (bulkId && !isContinue) {
      (async function asyncWrapper() {
        const response = await bulkInfoHandler();
        if (
          response?.status === "COMPLETED_WITH_ERRORS" ||
          response?.status === "FAILED"
        ) {
          setLoadedFile(undefined);
          handleLoadedFile(undefined);
          setErrorMessage(BULK_ERROR_REPORT);
        }
      })();
    }
  }, [bulkId]);

  useEffect(() => {
    if (isContinue && bulkId) {
      (async function asyncWrapper() {
        try {
          const users = await getBulkEndUsers(dispatch, bulkId);
          handleEventParticipants(users.content);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [isContinue]);

  return (
    <>
      <form className="load">
        {!filesContent?.[0]?.content && bulkStatus === "idle" ? (
          <label htmlFor="file" className="load-label">
            <i className="load-icon-add icon icon-doc" />
            Click here to add a document
          </label>
        ) : filesContent?.[0]?.name &&
          (bulkStatus === "success" || bulkStatus === "warning") ? (
          <label htmlFor="file" className="load-label load-file">
            <i className="load-icon-filename icon icon icon-xlsx" />
            {filesContent?.[0]?.name}
            <button className="load-delete" onClick={handleFileClear}>
              <i className="load-delete-icon icon icon-delete" />
            </button>
          </label>
        ) : bulkStatus === "error" ? (
          <label htmlFor="file" className="load-label">
            <i className="load-icon-add icon icon-failed" />
            Upload failed... Click to try again
          </label>
        ) : (
          <label htmlFor="file" className="load-label">
            <i className="loader load-loader" />
            Uploading...
          </label>
        )}
        {(bulkStatus === "idle" || bulkStatus === "error") && (
          <input
            type="file"
            id="file"
            name="filename"
            className="load-input"
            onClick={handleFileUpload}
          />
        )}
      </form>
      {bulkStatus === "warning" ? (
        <div className="bulk-report bulk-report-warning">
          {WARNING_MESSAGE_BULK}
        </div>
      ) : (
        <>
          {bulkStatus === "error" && (
            <div className="bulk-report bulk-report-error">
              {errorMessage || BULK_ERROR_REPORT}
            </div>
          )}
        </>
      )}
    </>
  );
};
