import { FC } from "react";
import { Header } from "@components/Header";
import { EventDetails } from "@components/Events/EventDetails";

export const SelectedEvent: FC = () => {
  return (
    <>
      <Header />
      <EventDetails />
    </>
  );
};
