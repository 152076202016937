import { FC, useState, useEffect } from "react";
import { TableHeader } from "@components/TableHeader/TableHeader";
import { productTypesTableHeaders } from "@constants/headers";
import { IProductTypesTable } from "@models/components/main/producttypestable";
import {
  getProductTypeGender,
  getProductTypeMeasurements,
  hideWindowScroll,
  showWindowScroll,
} from "@services/common";
import { EDIT_PRODUCT_TYPE_TITLE } from "@constants/users";
import { ManageProductTypeModal } from "@pages/Modals/ProductTypes";
import { getProductTypeById } from "@api/types";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { Loader } from "@components/Loader";
import { handleProductTypesUpdate } from "@api/common";
import { ProductType } from "@models/common/events";
import { getFormattedError } from "@services/http";

export const ProductTypesTable: FC<IProductTypesTable> = ({
  productTypes,
  sort,
  handleSorting,
  handleManaged,
}) => {
  const [isProductTypesLoading, setProductTypesLoading] = useState(false);
  const [isOpened, setOpened] = useState<boolean>(false);
  const [productTypesWindowOpen, setProductTypesWindowOpen] = useState(false);
  const [productTypeInfo, setProductTypeInfo] = useState<ProductType>();

  const dispatch = useAppDispatch();

  const handleProductTypeWindow = () => {
    isProductTypesLoading && setProductTypesLoading(false);
    setProductTypesWindowOpen(!productTypesWindowOpen);
  };

  const toggleDropdown = async (productTypeId: string) => {
    setOpened(!isOpened);
    await productTypeEdit(productTypeId);
  };

  const productTypeEdit = async (productTypeId: string) => {
    try {
      setProductTypesLoading(true);
      const productType = await getProductTypeById(dispatch, productTypeId);
      setProductTypeInfo({
        productTypeId,
        name: productType.name,
        genderToMeasurementParams: productType.genderToMeasurementParams,
      });
      handleProductTypeWindow();
    } catch (error) {
      return Promise.reject(getFormattedError(error));
    } finally {
      setProductTypesLoading(false);
    }
  };

  useEffect(() => {
    if (productTypesWindowOpen) {
      showWindowScroll();
    } else {
      hideWindowScroll();
    }
  }, [productTypesWindowOpen]);

  if (isProductTypesLoading) {
    return <Loader />;
  }

  return (
    <>
      {productTypesWindowOpen && productTypeInfo && (
        <ManageProductTypeModal
          title={EDIT_PRODUCT_TYPE_TITLE}
          handleModalOpen={handleProductTypeWindow}
          handleProductTypes={handleProductTypesUpdate}
          productTypeInfo={productTypeInfo}
          handleManaged={handleManaged}
        />
      )}
      <table className="event-table" cellSpacing={0} cellPadding={0} border={0}>
        <TableHeader
          sort={sort}
          headers={productTypesTableHeaders}
          handleSorting={handleSorting}
        />
        <tbody className="event-tbody">
          {productTypes.map((productType) => (
            <tr className="event-tr" key={productType.productTypeId}>
              <td className="event-td" data-label="Product type">
                {productType.name}
              </td>
              <td className="event-td" data-label="Gender">
                {productType?.genderToMeasurementParams &&
                  getProductTypeGender({
                    MALE: productType.genderToMeasurementParams.MALE,
                    FEMALE: productType.genderToMeasurementParams.FEMALE,
                  })}
              </td>
              <td className="event-td" data-label="Measurement types">
                <span style={{ display: "block" }}>
                  {!!productType?.genderToMeasurementParams?.MALE?.length
                    ? `For male: ${
                        productType?.genderToMeasurementParams &&
                        getProductTypeMeasurements(
                          productType.genderToMeasurementParams
                        )
                          .maleMeasurements.map((type) => type.measurement)
                          .join(", ")
                      }`
                    : ""}
                </span>
                {!!productType?.genderToMeasurementParams?.FEMALE?.length
                  ? `For female: ${
                      productType?.genderToMeasurementParams &&
                      getProductTypeMeasurements(
                        productType.genderToMeasurementParams
                      )
                        .femaleMeasurements.map((type) => type.measurement)
                        .join(", ")
                    }`
                  : ""}
              </td>
              <td className="event-td" data-label="Actions">
                <div className="header-dropdown">
                  <button className="event-table-controls">
                    <i
                      className="table-controls-icon icon icon-edit"
                      onClick={() => toggleDropdown(productType.productTypeId)}
                    />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
