import { FC } from "react";
import { IFilterLocationFieldset } from "@models/components/secondary/filterfieldset";
import "react-datepicker/dist/react-datepicker.css";
import { Radio } from "@components/Radio";

export const FilterLocationFieldset: FC<IFilterLocationFieldset> = (props) => {
  const { title, handleLocationRadios, locationRadios } = props;

  return (
    <div className="filter-form">
      <h3 className="filter-subtitle">{title}</h3>
      <fieldset className="filter-fields">
        <Radio
          label="All types"
          id="locationsAll"
          name="allLocations"
          value={locationRadios.allLocations}
          checked={locationRadios.allLocations}
          onClick={() =>
            handleLocationRadios({
              allLocations: true,
              locationOnly: false,
              locationRemote: false,
            })
          }
        />
        <Radio
          label="With locations only"
          id="onlyLocation"
          name="locationOnly"
          value={locationRadios.locationOnly}
          checked={locationRadios.locationOnly}
          onClick={() =>
            handleLocationRadios({
              allLocations: false,
              locationOnly: true,
              locationRemote: false,
            })
          }
        />
        <Radio
          label="Remote"
          id="remoteLocation"
          name="locationRemote"
          value={locationRadios.locationRemote}
          checked={locationRadios.locationRemote}
          onClick={() =>
            handleLocationRadios({
              allLocations: false,
              locationOnly: false,
              locationRemote: true,
            })
          }
        />
      </fieldset>
    </div>
  );
};
