import { FC, useEffect, useState } from "react";
import { Button } from "@components/Button";
import { Input } from "@components/Input";
import { PortalModal } from "@components/PortalModal";
import {
  IManageEndUserModal,
  IPersonalUserInfo,
  ICheckEndUser,
} from "@models/components/secondary/manualadduser";
import { validationSchemas } from "@services/validation";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  validateOnlyNumber,
  validateOnlyAllowedEmailSymbols,
} from "@services/regexps";
import {
  focuseField,
  getPhoneNumberValue,
  hasPhoneNumberValue,
  serializePhoneNumber,
  unFocuseField,
} from "@services/common";
import { GenderSegment } from "@components/GenderSegment";
import { IEndUser } from "@models/common/endUsers";
import { filterEndUser } from "@api/endUsers";
import {
  FEMALE_USER_GENDER,
  genders,
  MALE_USER_GENDER,
  MIN_PHONE_NUMBER_LENGTH,
  PHONE_NUMBER_START_CODE,
} from "@constants/commons";
import { GenderValue } from "@models/components/secondary/gendersegment";
import { ADD_END_USER_TITLE, EDIT_END_USER_TITLE } from "@constants/users";
import {
  EMAIL_ALREADY_USED_ERROR,
  EMAIL_PHONE_NUMBER_ALREADY_USED_ERROR,
  PHONE_NUMBER_ALREADY_USED_ERROR,
} from "@constants/errors";
import {
  checkEndUserByData,
  handleSubmitErrors,
  SubmitError,
} from "@api/common";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { getFormattedError } from "@services/http";

export const ManageEndUserModal: FC<IManageEndUserModal> = ({
  handleModalOpen,
  userInfo,
  handleUser,
  title,
  eventID,
  handleManaged,
  handleUserData,
  createParticipant,
}) => {
  const checkFormMethods = useForm<ICheckEndUser>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldFocusError: false,
    resolver: yupResolver(validationSchemas.checkEndUser),
    defaultValues: {
      phone_number: PHONE_NUMBER_START_CODE,
    },
  });

  const personalFormMethods = useForm<IPersonalUserInfo>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    shouldFocusError: false,
    resolver: yupResolver(validationSchemas.personalEndUserInfo),
  });

  const {
    handleSubmit: checkHandleSubmit,
    formState: checkFormState,
    watch: checkWatch,
    clearErrors: clearCheckErrors,
    setValue: setCheckValue,
    setError: setCheckError,
    trigger: triggerCheckFormValidation,
  } = checkFormMethods;
  const { email = "", phone_number = "" } = checkWatch();

  const hasEmail = Boolean(email?.trim());
  const hasPhoneNumber = hasPhoneNumberValue(phone_number?.trim());

  const {
    errors: checkErrors,
    isSubmitting: isCheckSubmitting,
    isSubmitted: isCheckSubmitted,
  } = checkFormState;

  const {
    handleSubmit: personalHandleSubmit,
    formState: personalFormState,
    watch: personalWatch,
    setValue: setPersonalValue,
    reset: personalReset,
  } = personalFormMethods;
  const { firstName = "", lastName = "" } = personalWatch();

  const {
    errors: personalErrors,
    isSubmitting: isPersonalSubmitting,
    isSubmitted: isPersonalSubmitted,
  } = personalFormState;

  const currentGender = genders.find(
    (gender) => gender.value === userInfo?.gender
  );

  const [personalSubmitted, setPersonalSubmitted] = useState(false);
  const [focusedFields, setFocused] = useState<{
    [fieldName: string]: boolean;
  }>({
    email: false,
    phone_number: false,
    firstName: false,
    lastName: false,
    badge: false,
  });
  const [checkedUser, setCheckedUser] = useState<IEndUser[]>();
  const [selectedGender, setSelectedGender] = useState<GenderValue>(
    currentGender || genders[0]
  );
  const [isAnonymous, setAnonymous] = useState(!!userInfo?.anonymous);

  const dispatch = useAppDispatch();
  const handleCheckedUser = (data: IEndUser[] | undefined) => {
    setCheckedUser(data);
  };
  const toggleAnonymous = () => {
    setAnonymous((prev) => !prev);
  };

  const handleGender = (gender: GenderValue) => {
    setSelectedGender(gender);
  };

  useEffect(() => {
    if (email?.indexOf(" ") !== -1) {
      setCheckValue("email", email?.replace(/\s/g, ""));
    }
  }, [email]);

  useEffect(() => {
    if (phone_number?.indexOf(" ") !== -1) {
      setCheckValue("phone_number", phone_number?.replace(/\s/g, ""));
    }
  }, [phone_number]);

  useEffect(() => {
    const isPhoneNumberChanged =
      getPhoneNumberValue(checkedUser?.[0]?.user?.phoneNumber) !==
      getPhoneNumberValue(phone_number);

    const isEmailChanged =
      (checkedUser?.[0]?.user?.email || "").trim() !== (email || "").trim();

    if (
      (isEmailChanged || isPhoneNumberChanged) &&
      title === ADD_END_USER_TITLE
    ) {
      handleCheckedUser(undefined);
      personalReset();
    }
  }, [email, phone_number]);

  useEffect(() => {
    if (isAnonymous && title === ADD_END_USER_TITLE) clearCheckErrors();
  }, [isAnonymous]);

  useEffect(() => {
    if (userInfo) {
      setCheckValue("email", userInfo.email);
      setCheckValue("phone_number", userInfo.phone_number);
      setPersonalValue("firstName", userInfo.firstName);
      setPersonalValue("lastName", userInfo.lastName);
      setPersonalValue("badge", userInfo.badge || "");
      handleGender(
        userInfo.gender === MALE_USER_GENDER
          ? genders[0]
          : userInfo.gender === FEMALE_USER_GENDER
          ? genders[1]
          : genders[2]
      );
    }
  }, [userInfo]);

  useEffect(() => {
    if (!!checkedUser?.length) {
      if (!hasEmail) {
        setCheckValue("email", checkedUser[0]?.user?.email);
      }
      if (!hasPhoneNumber) {
        setCheckValue("phone_number", checkedUser[0]?.user?.phoneNumber);
      }

      setPersonalValue("firstName", checkedUser[0]?.user?.firstName);
      setPersonalValue("lastName", checkedUser[0]?.user?.lastName);
      setPersonalValue("badge", checkedUser[0]?.badgeNumber || "");
      handleGender(
        checkedUser[0]?.gender === MALE_USER_GENDER
          ? genders[0]
          : checkedUser[0]?.gender === FEMALE_USER_GENDER
          ? genders[1]
          : genders[2]
      );
    }
  }, [checkedUser]);

  useEffect(() => {
    if (!phone_number?.startsWith(PHONE_NUMBER_START_CODE)) {
      setCheckValue("phone_number", PHONE_NUMBER_START_CODE);
    }
  }, [setCheckValue, phone_number]);

  const handleFocuse = (focuseState: { [fieldName: string]: boolean }) => {
    setFocused(focuseState);
  };

  const checkOnSubmit = async (data: ICheckEndUser) => {
    try {
      const { email, phone_number } = data;

      const emailData = email?.trim();
      const phoneNumberData =
        phone_number === undefined && email
          ? undefined
          : phone_number &&
            phone_number.length > MIN_PHONE_NUMBER_LENGTH &&
            !email
          ? phone_number.trim()
          : email &&
            phone_number &&
            phone_number.length > MIN_PHONE_NUMBER_LENGTH
          ? phone_number.trim()
          : undefined;

      const checkEndUserCreate = await checkEndUserByData(
        dispatch,
        emailData,
        phoneNumberData
      );

      if (
        checkEndUserCreate.emailExists &&
        !checkEndUserCreate.phoneNumberExists &&
        hasPhoneNumber
      ) {
        setCheckError("email", {
          type: "custom",
          message: EMAIL_ALREADY_USED_ERROR,
        });
      } else if (
        !checkEndUserCreate.emailExists &&
        checkEndUserCreate.phoneNumberExists &&
        hasEmail
      ) {
        setCheckError("phone_number", {
          type: "custom",
          message: PHONE_NUMBER_ALREADY_USED_ERROR,
        });
      } else if (
        checkEndUserCreate.emailExists ||
        checkEndUserCreate.phoneNumberExists
      ) {
        const user = await filterEndUser(
          dispatch,
          email?.trim(),
          phone_number?.trim()
        );

        if (!!user.content.length) {
          handleCheckedUser(user.content);
        } else {
          setCheckError("email", {
            type: "custom",
            message: EMAIL_PHONE_NUMBER_ALREADY_USED_ERROR,
          });
          setCheckError("phone_number", {
            type: "custom",
            message: EMAIL_PHONE_NUMBER_ALREADY_USED_ERROR,
          });
        }
      } else {
        clearCheckErrors();
        handleCheckedUser([]);
      }
    } catch (error) {
      return Promise.reject(getFormattedError(error));
    }
  };

  const personalOnSubmit = async (data: IPersonalUserInfo) => {
    const isCheckFormValid = await triggerCheckFormValidation();

    checkHandleSubmit(() => {})();

    if (!isCheckFormValid) {
      return;
    }

    const userData = {
      email: isAnonymous || (!isAnonymous && !email) ? undefined : email.trim(),
      phone_number: isAnonymous
        ? undefined
        : serializePhoneNumber(phone_number),
      gender: selectedGender,
      userId: userInfo?.userId || checkedUser?.[0]?.endUserId || "",
      ...data,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
    };

    if ((!checkErrors.email && !checkErrors.phone_number) || isAnonymous) {
      try {
        await handleUser(
          dispatch,
          userData,
          !!createParticipant,
          eventID,
          handleManaged,
          handleUserData
        );
        handleModalOpen();
      } catch (error) {
        handleSubmitErrors({
          error: error as SubmitError,
          setError: setCheckError,
        });
      }
    }
  };

  const isCheckBtnDisabled =
    (!email && title === EDIT_END_USER_TITLE) ||
    (!email?.length && phone_number.length === MIN_PHONE_NUMBER_LENGTH) ||
    isCheckSubmitting;

  const isDisabledForm = checkedUser === undefined && !userInfo && !isAnonymous;
  const hasCheckFormErrors = Boolean(
    Object.values(checkFormState.errors).length
  );

  const isSaveBtnDisabled =
    hasCheckFormErrors ||
    isDisabledForm ||
    (firstName.trim().length && lastName.trim().length
      ? !firstName.length || !lastName.length || isPersonalSubmitting
      : !email.length ||
        phone_number.length === MIN_PHONE_NUMBER_LENGTH ||
        !firstName.trim().length ||
        !lastName.trim().length ||
        isPersonalSubmitting);

  return (
    <PortalModal
      title={title}
      isOpen={true}
      onClose={handleModalOpen}
      className="modal-user"
    >
      <div className="modal-body">
        <h2 className={`modal-subtitle ${isAnonymous ? "isDisabled" : ""}`}>
          <span className="modal-step">1</span> User verification
        </h2>
      </div>
      <form
        className="modal-form form-start-label"
        onSubmit={checkHandleSubmit(checkOnSubmit)}
      >
        <div className="modal-body form modal-form">
          <FormProvider {...checkFormMethods}>
            <Input
              label="Email"
              subLabel={
                !!checkErrors.email?.message &&
                (title === ADD_END_USER_TITLE
                  ? isCheckSubmitted
                  : isPersonalSubmitted)
                  ? checkErrors.email?.message
                  : ""
              }
              error={
                Boolean(email?.trim()) &&
                !!checkErrors.email?.message &&
                (title === ADD_END_USER_TITLE
                  ? isCheckSubmitted
                  : isPersonalSubmitted)
              }
              name="email"
              disable={
                isCheckSubmitting ||
                (title === ADD_END_USER_TITLE && isAnonymous) ||
                isAnonymous
              }
              focuseField={() =>
                focuseField("email", focusedFields, handleFocuse)
              }
              unFocuseField={() =>
                unFocuseField("email", focusedFields, handleFocuse)
              }
              pattern={validateOnlyAllowedEmailSymbols}
              trimValue
            />
            <Input
              label="Mobile phone number"
              subLabel={
                !!checkErrors.phone_number?.message &&
                (title === ADD_END_USER_TITLE
                  ? isCheckSubmitted
                  : isPersonalSubmitted)
                  ? checkErrors.phone_number?.message
                  : ""
              }
              error={
                !!checkErrors.phone_number?.message &&
                (title === ADD_END_USER_TITLE
                  ? isCheckSubmitted
                  : isPersonalSubmitted)
              }
              disable={isCheckSubmitting || isAnonymous}
              name="phone_number"
              focuseField={() =>
                focuseField("phone_number", focusedFields, handleFocuse)
              }
              unFocuseField={() =>
                unFocuseField("phone_number", focusedFields, handleFocuse)
              }
              pattern={validateOnlyNumber}
              trimValue
            />
            <div
              className={`checkbox-container ${
                email ||
                email?.length ||
                phone_number?.length > MIN_PHONE_NUMBER_LENGTH ||
                title === EDIT_END_USER_TITLE
                  ? "checkbox-container__hidden"
                  : ""
              }`}
            >
              <input
                type="checkbox"
                id="isAnonymousCheckbox"
                name="isAnonymousCheckbox"
                onClick={toggleAnonymous}
                className="checkbox__handler"
              />
              <label className="checkbox__label" htmlFor="isAnonymousCheckbox">
                Anonymous user
              </label>
            </div>
          </FormProvider>
          {isDisabledForm && (
            <Button
              type="submit"
              text="Check"
              className="btn btn-main btn-content"
              disable={isCheckBtnDisabled}
            />
          )}
        </div>
      </form>
      <form
        className="form modal-form form-start-label"
        onSubmit={personalHandleSubmit(personalOnSubmit)}
      >
        <div
          className={`modal-body form modal-form ${
            isDisabledForm && "isDisabled"
          }`}
        >
          <FormProvider {...personalFormMethods}>
            <h2 className={`modal-subtitle ${isDisabledForm && "isDisabled"}`}>
              <span className="modal-step">2</span> Personal information
            </h2>
            <div className="form">
              <span className={`form-label ${isDisabledForm && "isDisabled"}`}>
                Gender
              </span>
              <GenderSegment
                isDisabled={isDisabledForm}
                handleGender={handleGender}
                selectedGender={selectedGender}
              />
            </div>
            <Input
              label="First name*"
              name="firstName"
              disable={isDisabledForm}
              subLabel={
                !!personalSubmitted && !!personalWatch().firstName.length
                  ? personalErrors.firstName?.message
                  : ""
              }
              error={!!personalErrors.firstName?.message && !!personalSubmitted}
              focuseField={() =>
                focuseField("firstName", focusedFields, handleFocuse)
              }
              unFocuseField={() =>
                unFocuseField("firstName", focusedFields, handleFocuse)
              }
            />
            <Input
              label="Last name*"
              name="lastName"
              disable={isDisabledForm}
              subLabel={
                !!personalSubmitted && !!personalWatch().lastName.length
                  ? personalErrors.lastName?.message
                  : ""
              }
              error={!!personalErrors.lastName?.message && !!personalSubmitted}
              focuseField={() =>
                focuseField("lastName", focusedFields, handleFocuse)
              }
              unFocuseField={() =>
                unFocuseField("lastName", focusedFields, handleFocuse)
              }
            />
            <Input
              label="Badge number"
              name="badge"
              disable={isDisabledForm}
              subLabel={
                !!personalSubmitted && !!personalWatch().badge?.length
                  ? personalErrors.badge?.message
                  : ""
              }
              error={!!personalErrors.badge?.message && !!personalSubmitted}
              focuseField={() =>
                focuseField("badge", focusedFields, handleFocuse)
              }
              unFocuseField={() =>
                unFocuseField("badge", focusedFields, handleFocuse)
              }
            />
          </FormProvider>
        </div>
        <div className="modal-footer">
          <Button
            text="Cancel"
            className="btn btn-primary"
            onClick={handleModalOpen}
          />
          <Button
            text="Save"
            className="btn btn-main"
            type="submit"
            disable={isSaveBtnDisabled}
            onClick={() => setPersonalSubmitted(true)}
          />
        </div>
      </form>
    </PortalModal>
  );
};
