import { FC, useState } from "react";
import { Button } from "@components/Button";
import { Search } from "@components/Search";
import { IHeaderBarTable } from "@models/components/secondary/headerbartable";
import { Filters } from "@components/Filters";
import { ToggleSwitcher } from "@components/ToggleSwitcher";

export const HeaderBarTable: FC<IHeaderBarTable> = ({
  title,
  handleFilter,
  btnTitles,
  btnHandlers,
  handleFilteredValues,
  handleSearchedValues,
  filterType,
  sort,
  searchedValue,
  filtered,
  handleSearchFieldFocus,
  placeholder,
  isAnonymousUser,
  handleAnonymousUsers,
}) => {
  const [filtersOpen, setFiltersOpen] = useState(false);

  const handleFiltersModal = () => {
    setFiltersOpen(!filtersOpen);
  };

  return (
    <>
      <header className="event-header">
        <h2 className="event-title">{title}</h2>
        {handleAnonymousUsers && (
          <ToggleSwitcher
            id="switcher-anonymous"
            label="Display Anonymous Users"
            checked={isAnonymousUser}
            onChange={handleAnonymousUsers}
          />
        )}

        <div className="event-controls">
          {handleSearchedValues && (
            <Search
              id="search"
              name="searchField"
              disable={false}
              handleSearchedValues={handleSearchedValues}
              searchedValue={searchedValue}
              handleFilter={handleFilter}
              filtered={filtered}
              handleSearchFieldFocus={handleSearchFieldFocus}
              placeholder={placeholder}
            />
          )}
          {handleFilter && (
            <div className="p-rel">
              <button className="btn-control" onClick={handleFiltersModal}>
                <i className="btn-control-icon icon icon-filter" />
              </button>
              {filtersOpen && handleFilteredValues && (
                <Filters
                  modalOpen={filtersOpen}
                  handleModalOpen={handleFiltersModal}
                  handleFilteredValues={handleFilteredValues}
                  handleFilter={handleFilter}
                  type={filterType || "all"}
                  sort={sort}
                />
              )}
            </div>
          )}
          {Array.isArray(btnTitles) ? (
            <>
              {btnTitles.map((title, index) => (
                <Button
                  key={index}
                  text={title}
                  className="events-btn btn-add"
                  icon="icon-plus"
                  onClick={btnHandlers?.[index]}
                />
              ))}
            </>
          ) : (
            <>
              {btnTitles && (
                <Button
                  text={btnTitles}
                  className="events-btn btn-add"
                  icon="icon-plus"
                  onClick={btnHandlers?.[0]}
                />
              )}
            </>
          )}
        </div>
      </header>
    </>
  );
};
