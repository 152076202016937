import { FC, Fragment, useEffect, useRef } from "react";
import { IDropdownMenu } from "@models/components/secondary/dropdownmenu";

export const DropdownMenu: FC<IDropdownMenu> = ({
  dropdownItems,
  dropdownOpened,
  setDropdownOpened,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        dropdownOpened &&
        ref.current &&
        !(e.target as HTMLDivElement).classList.contains("icon-kebab") &&
        !ref.current.contains(e.target as HTMLDivElement)
      ) {
        setDropdownOpened(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropdownOpened]);

  return (
    <div className={"table-dropdown-list isActive"} ref={ref}>
      {dropdownItems.map((item, index) => (
        <Fragment key={index}>
          {item && (
            <button className="table-dropdown-item" onClick={item.onClick}>
              {item.text}
            </button>
          )}
        </Fragment>
      ))}
    </div>
  );
};
