import { PHONE_NUMBER_START_CODE } from "@constants/commons";

export const validatePassword =
  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

export const validateEmail = /^[^\s@<>()?]+@[^\s@<>()?]+\.[^\s@<>()]{2,}$/i;

const PHONE_VALUE_DIGITS_AMOUNT = 10;
export const validatePhoneNumber = new RegExp(
  `^((\\${PHONE_NUMBER_START_CODE})([0-9]){${PHONE_VALUE_DIGITS_AMOUNT}})$`
);
export const validatePhoneNumberWithouCode = new RegExp(
  `^(([0-9]){${PHONE_VALUE_DIGITS_AMOUNT}})$`
);

export const validateOnlyNumber = /^\d+$/;
export const validateOnlyAllowedEmailSymbols = /^([a-zA-Z0-9+_.@-])/;
export const withoutSpaces = /[^\s]+/;
