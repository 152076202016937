import { useEffect, useState } from "react";
import { Header } from "@components/Header";
import { Pagination } from "@components/Pagination";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/Loader";
import {
  DEALER_ROLE,
  NO_DEALERS_PLUG_TYPE,
  NO_FOUND_PLUG_TYPE,
  SEARCH_QUERY_KEY,
  TABLE_ROWS_LIMIT,
} from "@constants/commons";
import { HeaderBarTable } from "@components/HeaderBarTable";
import { Plug } from "@components/Plug";
import { NO_DEALERS_PLUG_ERROR, NO_FOUND_PLUG_ERROR } from "@constants/errors";
import { IDealerList } from "@models/common/dealer";
import { listOfFilteredDealer } from "@api/dealers";
import { DealerTable } from "@components/Dealer/DealerTable";
import { ManageDealerModal } from "@pages/Modals/Users/ManageDealerModal";
import {
  getPage,
  getSortTypes,
  hideWindowScroll,
  showWindowScroll,
} from "@services/common";
import { ISortType } from "@models/common/app";
import { ADD_DEALER_TITLE } from "@constants/users";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import queryString from "query-string";
import { MiniLoader } from "@components/Loader/MiniLoader";
import { MAIN_ROUTE } from "@constants/routes";
import { handleDealerUpdate } from "@api/common";
import { getFormattedError } from "@services/http";

export const ListDealers = () => {
  const location = useLocation();
  const parsedSearchedQuery = queryString.parse(location.search)?.[
    SEARCH_QUERY_KEY
  ];

  const [dealer, setDealer] = useState<IDealerList | undefined>();
  const [openDealerCreateModal, setOpenDealerCreateModal] =
    useState<boolean>(false);
  const [openDealerEditModal, setOpenDealerEditModal] =
    useState<boolean>(false);
  const [searchedValue, setSearchedValue] = useState<string | undefined>(
    typeof parsedSearchedQuery === "string" ? parsedSearchedQuery : undefined
  );
  const [sortType, setSortType] = useState<ISortType>();
  const [deleteWindowOpen, setDeleteWindowOpen] = useState(false);
  const [searchParams, setSearchParams] = useState<
    queryString.ParsedQuery<string> | undefined
  >(queryString.parse(location.search));
  const [searchFieldFocused, setSearchFieldFocused] = useState(false);
  const [managed, setManaged] = useState(false);

  const handleSetListDealer = (data: IDealerList) => {
    setDealer(data);
  };

  const handleSearchFieldFocus = () => {
    setSearchFieldFocused(true);
  };

  const { page } = useParams();
  const pageId = getPage(page);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchFieldFocused) {
      if (!!searchedValue) {
        if (pageId + 1 === 1) {
          navigate(`?${SEARCH_QUERY_KEY}=${searchedValue}`);
        } else {
          navigate(
            `/${
              location.pathname.split(MAIN_ROUTE)[1]
            }/1?${SEARCH_QUERY_KEY}=${searchedValue}`
          );
        }
      } else {
        navigate("");
      }
    }
  }, [searchedValue]);

  useEffect(() => {
    const searchQuery = queryString.parse(location.search);
    if (!!Object.keys(searchQuery).length) {
      setSearchParams(searchQuery);
    } else {
      setSearchParams({});
    }
  }, [location.search]);

  useEffect(() => {
    if (!!searchParams?.[SEARCH_QUERY_KEY]) {
      handleSearchedDealers(searchParams[SEARCH_QUERY_KEY].toString());
    }
  }, [searchParams?.[SEARCH_QUERY_KEY]]);

  useEffect(() => {
    if (
      !openDealerCreateModal &&
      !openDealerEditModal &&
      !deleteWindowOpen &&
      (searchedValue
        ? searchParams?.[SEARCH_QUERY_KEY]
        : !searchParams?.[SEARCH_QUERY_KEY])
    ) {
      (async function asyncWrapper() {
        try {
          const dealers = await listOfFilteredDealer(
            dispatch,
            pageId,
            TABLE_ROWS_LIMIT,
            sortType ? sortType.filteredParameter : "name,asc",
            searchedValue
          );
          handleSetListDealer(dealers);
          if (managed) handleManaged(false);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [
    pageId,
    sortType,
    deleteWindowOpen,
    managed,
    openDealerCreateModal,
    openDealerEditModal,
    searchParams?.[SEARCH_QUERY_KEY],
  ]);

  const handleModalDealerCreate = () => {
    setOpenDealerCreateModal(!openDealerCreateModal);
  };

  const handleDeleteWindowOpen = () => {
    setDeleteWindowOpen(!deleteWindowOpen);
  };

  const addBtnHandler = () => {
    setOpenDealerCreateModal(true);
  };

  const handleSearchedDealers = (searchedValue: string | undefined) => {
    setSearchedValue(searchedValue);
  };

  const handleModalDealerEdit = () => {
    setOpenDealerEditModal(!openDealerEditModal);
  };

  const handleManaged = (state: boolean) => {
    setManaged(state);
  };

  const handleSorting = (parameter: string) => {
    let filteredParameter;

    if (parameter === "Dealer name") {
      filteredParameter = "name";
    }
    if (parameter === "Person of contact") {
      filteredParameter = "user.firstName,user.lastName";
    }
    if (parameter === "System status") {
      filteredParameter = "user.status";
    }

    setSortType(getSortTypes(sortType, filteredParameter, parameter));
  };

  useEffect(() => {
    if (openDealerCreateModal) {
      showWindowScroll();
    } else {
      hideWindowScroll();
    }
  }, [openDealerCreateModal]);

  return (
    <>
      <Header />
      {openDealerCreateModal && (
        <ManageDealerModal
          title={ADD_DEALER_TITLE}
          handleModalOpen={handleModalDealerCreate}
          userInfo={undefined}
          handleUser={handleDealerUpdate}
        />
      )}
      {dealer ? (
        <main className="main-container">
          <HeaderBarTable
            searchedValue={searchedValue}
            handleSearchedValues={handleSearchedDealers}
            title="Dealers"
            btnTitles="Add new Dealer"
            btnHandlers={[addBtnHandler]}
            sort={sortType}
            handleSearchFieldFocus={handleSearchFieldFocus}
          />
          {!!dealer.content.length ? (
            <div className="event-table-container">
              <DealerTable
                sort={sortType}
                dealer={dealer.content}
                handleSorting={handleSorting}
                handleModalOpen={handleModalDealerEdit}
                handleDeleteModal={handleDeleteWindowOpen}
                deleteWindowOpen={deleteWindowOpen}
                handleManaged={handleManaged}
              />
              <Pagination
                dataLength={dealer.totalElements}
                pageId={pageId}
                role={DEALER_ROLE}
              />
            </div>
          ) : (
            <MiniLoader
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "5vh",
              }}
              time={3}
              afterload={
                <Plug
                  message={
                    searchParams && !!Object.keys(searchParams)?.length
                      ? NO_FOUND_PLUG_ERROR
                      : NO_DEALERS_PLUG_ERROR
                  }
                  type={
                    searchParams && !!Object.keys(searchParams)?.length
                      ? NO_FOUND_PLUG_TYPE
                      : NO_DEALERS_PLUG_TYPE
                  }
                />
              }
            />
          )}
        </main>
      ) : (
        <Loader />
      )}
    </>
  );
};
