import { memo, useEffect, useRef, useState } from "react";
import { formateDuring } from "@services/common";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { CODE_RESEND_SUBMIT } from "@constants/commons";
import { IResendCodeTimer } from "@models/components/secondary/resendcodetimer";
import { selectCognitoUser } from "@store/selectors/cognitoUser";
import { Button } from "@components/Button";
import { cognitoUserCodeExpiresAtMs } from "@services/auth";
export const ResendCodeTimer = memo(
  ({ setSubmitType, handleCredentials, clearAuth }: IResendCodeTimer) => {
    const cognitoUser = useAppSelector(selectCognitoUser);
    const expiresAt = cognitoUserCodeExpiresAtMs(useAppSelector);

    const [count, setCount] = useState<number | null>(null);
    const ticker = useRef<string | number | NodeJS.Timeout | undefined>();

    useEffect(() => {
      function tick() {
        setCount(Math.max(expiresAt - Date.now() / 1000, 0));
      }
      ticker.current = setInterval(() => tick(), 1000);

      return () => {
        clearInterval(ticker.current);
      };
    }, [expiresAt]);

    useEffect(() => {
      if (count === 0) {
        clearInterval(ticker.current);
      }
    }, [count]);

    const handleOnSubmit = () => {
      clearAuth();
      handleCredentials({
        email: cognitoUser?.username?.includes("@")
          ? cognitoUser?.username
          : undefined,
        phone_number: cognitoUser?.username?.startsWith("+")
          ? cognitoUser?.username
          : undefined,
      });

      setSubmitType(CODE_RESEND_SUBMIT);
    };

    return (
      <>
        {!!Object.keys(cognitoUser || {}).length &&
          typeof expiresAt === "number" && (
            <Button
              disable={!!count}
              className="btn-link code-link"
              onClick={handleOnSubmit}
              text={
                count
                  ? `Resend code in ${formateDuring(count)} m`
                  : "Resend code"
              }
            />
          )}
      </>
    );
  }
);
