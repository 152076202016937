import { FC, useState } from "react";
import { StatusBar } from "@components/StatusBar";
import { TableHeader } from "@components/TableHeader/TableHeader";
import { eventTableHeaders } from "@constants/headers";
import { IEventTable } from "@models/components/main/eventtable";
import { DropdownMenu } from "@components/Dropdown/DropdownMenu";
import { useLocation, useNavigate } from "react-router-dom";
import {
  EDIT_EVENT_ROUTE,
  MAIN_ROUTE,
  MEASUREMENTS_LIST_ROUTE,
} from "@constants/routes";
import {
  formateDate,
  getEventStatus,
  getFormattedUserRole,
} from "@services/common";
import {
  CANCELED_STATUS_REQUEST,
  CANCEL_EVENT_BODY,
  CANCEL_EVENT_TITLE,
  COMPLETED_STATUS_REQUEST,
  DELETE_EVENT_BODY,
  DELETE_EVENT_TITLE,
  IN_PERSON_EVENT_TYPE,
  IN_PROGRESS_STATUS_REQUEST,
} from "@constants/events";
import { AlertModal } from "@components/AlertModal";
import { cancelEvent, deleteEvent } from "@api/events";
import { EventStatusRequest } from "@models/common/events";
import { useNotification } from "@hooks/common/useNotification";
import {
  ADMIN_ROLE,
  CANCEL_EVENT_NOTIFICATION,
  DELETE_EVENT_NOTIFICATION,
  SOMETHING_WENT_WRONG_NOTIFICATION,
} from "@constants/commons";
import { Notification } from "@components/Notification";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { selectTokens } from "@store/selectors/tokens";
import { getFormattedError } from "@services/http";

export const EventTable: FC<IEventTable> = ({
  events,
  sort,
  handleSorting,
  handleDeleteModal,
  deleteWindowOpen,
  handleCancelModal,
  cancelWindowOpen,
  handleEventManaged,
  eventManaged,
}) => {
  const [isOpened, setOpened] = useState<boolean>(false);
  const [activeEvent, setActiveEvent] = useState<string>("");

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const tokensInfo = useAppSelector(selectTokens);
  const userRole = tokensInfo?.idToken?.payload?.["custom:user_role"];

  const {
    isActive,
    message: notificationMessage,
    type: notificationType,
    showNotification,
    closeNotification,
  } = useNotification();

  const toggleDropdown = (eventID: string) => {
    setOpened(!isOpened);
    setActiveEvent(eventID);
  };

  const handleDropdownMenu = (dropdownState: boolean) => {
    setOpened(dropdownState);
  };

  const handleEventCancel = async (eventId: string) => {
    if (eventManaged) {
      handleEventManaged(false);
    }
    try {
      await cancelEvent(dispatch, eventId, true);
      handleEventManaged(true);
      showNotification(CANCEL_EVENT_NOTIFICATION, "success");
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    } finally {
      setOpened(false);
    }
  };

  const handleEventDelete = async (eventId: string) => {
    if (eventManaged) {
      handleEventManaged(false);
    }
    try {
      if (events?.length === 1) {
        await deleteEvent(dispatch, eventId);
        handleEventManaged(true);
        showNotification(DELETE_EVENT_NOTIFICATION, "success");
        navigate(
          `/${location.pathname.split(MAIN_ROUTE)[1]}/${
            typeof +location.pathname.split(MAIN_ROUTE)[2] === "number" &&
            +location.pathname.split(MAIN_ROUTE)[2] - 1 > 0
              ? +location.pathname.split(MAIN_ROUTE)[2] - 1
              : 1
          }${location.search}`
        );
      } else {
        await deleteEvent(dispatch, eventId);
        handleEventManaged(true);
        showNotification(DELETE_EVENT_NOTIFICATION, "success");
      }
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    } finally {
      setOpened(false);
    }
  };

  const handleCancelWindowOpen = () => {
    handleCancelModal();
    setOpened(false);
  };

  const handleDeleteWindowOpen = () => {
    handleDeleteModal();
    setOpened(false);
  };

  const eventTableDropdownItems = (
    eventID: string,
    eventStatus: EventStatusRequest
  ) =>
    [
      eventStatus !== COMPLETED_STATUS_REQUEST &&
      eventStatus !== CANCELED_STATUS_REQUEST
        ? {
            text: "Edit",
            onClick: () => {
              navigate(`${EDIT_EVENT_ROUTE}/${eventID}`);
              handleDropdownMenu(false);
            },
          }
        : null,
      eventStatus !== COMPLETED_STATUS_REQUEST &&
      eventStatus !== CANCELED_STATUS_REQUEST
        ? {
            text: "Cancel",
            onClick: () => {
              handleCancelWindowOpen();
            },
          }
        : null,
      userRole === ADMIN_ROLE
        ? {
            text: "Delete",
            onClick: () => {
              handleDeleteWindowOpen();
            },
          }
        : null,
    ].filter((item) => !!item);

  return (
    <>
      <Notification
        type={notificationType}
        message={notificationMessage}
        isActive={isActive}
        closeNotification={closeNotification}
      />
      {cancelWindowOpen && (
        <AlertModal
          type="delete"
          title={CANCEL_EVENT_TITLE}
          body={CANCEL_EVENT_BODY}
          handleOpen={handleCancelModal}
          handleConfirm={handleEventCancel}
          id={activeEvent}
        />
      )}
      {deleteWindowOpen && (
        <AlertModal
          type="delete"
          title={DELETE_EVENT_TITLE}
          body={DELETE_EVENT_BODY}
          handleOpen={handleDeleteModal}
          handleConfirm={handleEventDelete}
          id={activeEvent}
        />
      )}
      <table className="event-table" cellSpacing={0} cellPadding={0} border={0}>
        <TableHeader
          sort={sort}
          headers={eventTableHeaders}
          handleSorting={handleSorting}
        />
        <tbody className="event-tbody">
          {events.map((event) => (
            <tr className="event-tr" key={event.eventId}>
              <td className="event-td" data-label="Event ID">
                {event.eventNumber}
              </td>
              <td
                className="event-td cursor-pointer event-link"
                data-label="Event name"
              >
                <a
                  className="header-link"
                  href={`${MEASUREMENTS_LIST_ROUTE}/${event.eventId}/1`}
                >
                  {event.eventName}
                </a>
              </td>
              <td
                className={`event-td ${
                  event.eventType !== IN_PERSON_EVENT_TYPE && "is-color"
                }`}
                data-label="Department location"
              >
                {event.eventType === IN_PERSON_EVENT_TYPE
                  ? event.location || "-"
                  : "Remote"}
              </td>
              <td className="event-td" data-label="Start/End of the Event">
                <span className="event-period event-period-start">
                  {formateDate(event.startsAt)}
                </span>
                <span className="event-period event-period-end">
                  {formateDate(event.endsAt)}
                </span>
              </td>
              <td className="event-td" data-label="Product type">
                {(event?.productTypes || [])
                  .map((type) => type.name)
                  .join(", ")}
              </td>
              <td className="event-td" data-label="Creator">
                {getFormattedUserRole(event.createdBy.role)}
                <br />
                {event.createdBy.firstName} {event.createdBy.lastName}
              </td>
              <td className="event-td" data-label="Status">
                <span
                  className={`status ${
                    event.status === COMPLETED_STATUS_REQUEST
                      ? "status-success"
                      : event.status === CANCELED_STATUS_REQUEST
                      ? "status-danger"
                      : event.status === IN_PROGRESS_STATUS_REQUEST
                      ? "status-warning"
                      : ""
                  }`}
                >
                  {getEventStatus(event.status)}
                </span>
              </td>
              <td className="event-td" data-label="Progress">
                <div className="event-progress">
                  <StatusBar
                    value={
                      event.participantsTotal > 0
                        ? Math.ceil(
                            (event.participantsMeasured /
                              event.participantsTotal) *
                              100
                          )
                        : 0
                    }
                  />
                  {event.participantsMeasured}/{event.participantsTotal}
                </div>
              </td>
              <td className="event-td" data-label="Actions">
                {!!eventTableDropdownItems(event.eventId, event.status)
                  ?.length && (
                  <div className="header-dropdown">
                    <button
                      className={`header-dropdown-name ${
                        isOpened && "isActive"
                      } event-table-btn`}
                      onClick={() => toggleDropdown(event.eventId)}
                    >
                      <i className="event-table-icon icon icon-kebab" />
                    </button>
                    {isOpened && activeEvent === event.eventId && (
                      <DropdownMenu
                        dropdownItems={eventTableDropdownItems(
                          event.eventId,
                          event.status
                        )}
                        dropdownOpened={isOpened}
                        setDropdownOpened={handleDropdownMenu}
                      />
                    )}
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
