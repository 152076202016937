import { FC, useEffect, useRef, useState } from "react";
import { ISalesRepDropdown } from "@models/components/secondary/dropdownmenu";
import { useFormContext } from "react-hook-form";
import { REQUIRED_FIELD } from "@constants/validation";
import { DebounceInput } from "react-debounce-input";
import { setDropdownItems } from "@services/common";
import { ASSIGN_TO_TITLE } from "@constants/users";
import { ISalesRep } from "@models/common/salesRep";
import { IEventSalesRep } from "@models/common/events";

export const SalesRepDropdown: FC<ISalesRepDropdown> = (props) => {
  const {
    name,
    pattern,
    id,
    error,
    subLabel,
    limit,
    defaultValue,
    dropdownList,
    searchedValue,
    handleSearchedValue,
    disable,
    required,
    clear,
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [salesRepsDropdownItems, setSalesRepsDropdownItems] =
    useState(defaultValue);

  const [isOpen, setOpen] = useState(false);
  const [focusedFields, setFocused] = useState({
    [name]: false,
  });

  const focuseField = (fieldName: string) => {
    setFocused({ [fieldName]: true });
  };

  const handleDropdownOpen = (state: boolean) => {
    setOpen(state);
  };

  const toggleDropdown = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleDropdownOpen(!isOpen);
  };

  const formData = useFormContext();

  const { watch, clearErrors, setError, formState, setValue } = formData;
  const { errors } = formState;

  const handleSalesRepDropdownItems = (items: IEventSalesRep | undefined) => {
    setSalesRepsDropdownItems(items);
  };

  useEffect(() => {
    if (!salesRepsDropdownItems && defaultValue) {
      setSalesRepsDropdownItems(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (clear) {
      handleSalesRepDropdownItems(undefined);
      setOpen(false);
    }
  }, [clear]);

  useEffect(() => {
    handleSalesRepDropdownItems(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (focusedFields?.[name]) {
      handleDropdownOpen(true);
    } else {
      handleDropdownOpen(false);
    }
  }, [focusedFields]);

  useEffect(() => {
    if (!!watch()?.[name]?.length) {
      clearErrors(name);
    }
  }, [watch()?.[name]]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        isOpen &&
        ref.current &&
        !ref.current.contains(e.target as HTMLDivElement)
      ) {
        handleDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    setValue(name, salesRepsDropdownItems?.salesRepId);

    if (!salesRepsDropdownItems && focusedFields[name]) {
      setError(name, { type: "custom", message: REQUIRED_FIELD });
    } else {
      clearErrors(name);
    }
  }, [salesRepsDropdownItems]);

  useEffect(() => {
    if (focusedFields[name]) {
      clearErrors(name);
    }
  }, [focusedFields]);

  const handleDropdownItems = (value: ISalesRep) => {
    setDropdownItems({
      title: ASSIGN_TO_TITLE,
      limit,
      salesReps: salesRepsDropdownItems,
      handleSalesReps: handleSalesRepDropdownItems,
      value,
      dropdownOpen: handleDropdownOpen,
      handleSearchedValue,
    });
  };

  return (
    <>
      <div className={`dropdown form ${isOpen && "isActive"}`}>
        <label htmlFor="dropdown" className="form-label">
          {ASSIGN_TO_TITLE} {required && <sup>*</sup>}
        </label>
        <div ref={ref}>
          <div className="dropdown-form">
            <div
              className={`dropdown-input-container ${
                !!errors?.[name]?.message &&
                !focusedFields[name] &&
                "form-input-error"
              }`}
            >
              {salesRepsDropdownItems && (
                <button
                  disabled={disable}
                  className="dropdown-selected"
                  key={salesRepsDropdownItems.userId}
                  onClick={(e) => e.preventDefault()}
                >
                  {salesRepsDropdownItems.firstName}{" "}
                  {salesRepsDropdownItems.lastName}
                  <i
                    className="dropdown-selected-icon icon icon-round-close "
                    onClick={() => {
                      handleSalesRepDropdownItems(undefined);
                    }}
                  />
                </button>
              )}
              <DebounceInput
                id={id}
                {...(formData?.register
                  ? formData.register(name, { pattern })
                  : {})}
                name={name}
                type="text"
                placeholder="Select sales representative"
                className={`dropdown-input ${
                  !!errors?.[name]?.message &&
                  !focusedFields[name] &&
                  "form-input-error"
                }`}
                onFocus={() => focuseField(name)}
                debounceTimeout={500}
                onChange={(event) => {
                  handleSearchedValue(event.target.value);
                }}
                value={searchedValue}
                disabled={disable}
              />
            </div>
            <button
              className="dropdown-btn"
              onClick={toggleDropdown}
              disabled={disable}
            >
              <i className={`dropdown-icon icon icon-arrow`} />
            </button>
          </div>
          <ul className="dropdown-list">
            {!!dropdownList?.length ? (
              dropdownList.map((value) => (
                <li
                  className={`dropdown-item ${
                    value?.salesRepId === salesRepsDropdownItems?.userId &&
                    "isActive"
                  }`}
                  onClick={() => handleDropdownItems(value)}
                  key={value?.salesRepId}
                >
                  {value?.user?.firstName || value?.firstName || ""}{" "}
                  {value?.user?.lastName || value?.lastName || ""}
                </li>
              ))
            ) : (
              <li className="dropdown-item">
                <span className="dropdown-no-result">No result found.</span>
              </li>
            )}
          </ul>
        </div>
      </div>
      {subLabel && (error || errors?.[name]?.message) && (
        <label
          htmlFor={id}
          className={`form-sublabel ${
            (error || !!errors?.[name]?.message) && "form-input-error"
          }`}
        >
          <>{subLabel || errors?.[name]?.message}</>
        </label>
      )}
    </>
  );
};
