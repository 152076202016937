import { MAIN_ROUTE } from "@constants/routes";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface Gap {
  before: boolean;
  paginationGroup: number[];
  after: boolean;
}
export const usePagination = (
  contentPerPage: number,
  count: number,
  pageId: number
) => {
  const [page, setPage] = useState<number>(pageId);
  const [pageCount, setPageCount] = useState<number>(
    Math.ceil(count / contentPerPage)
  );

  const location = useLocation();
  const navigate = useNavigate();
  const lastContentIndex = page * contentPerPage;
  const firstContentIndex = lastContentIndex - contentPerPage;

  const [gaps, setGaps] = useState<Gap>({
    before: false,
    paginationGroup: [],
    after: true,
  });
  const [pagesInBetween, setPagesInBetween] = useState<number[]>([]);

  useEffect(() => {
    if (pageCount > 2) {
      const temp = new Array(pageCount - 2).fill(1).map((_, i) => i + 2);
      setPagesInBetween(temp);
    } else {
      setPagesInBetween([]);
    }
  }, [pageCount]);

  useEffect(() => {
    const currentLocation = pagesInBetween.indexOf(page);
    let paginationGroup = [];
    let before = false;
    let after = false;
    if (page === 1) {
      paginationGroup = pagesInBetween.slice(0, 3);
    } else if (
      page === pageCount ||
      page === pageCount - 1 ||
      page === pageCount - 2
    ) {
      paginationGroup = pagesInBetween.slice(-3, pageCount);
    } else if (page === 2) {
      paginationGroup = pagesInBetween.slice(
        currentLocation,
        currentLocation + 3
      );
    } else {
      paginationGroup = [page - 1, page, page + 1];
    }
    if (pageCount <= 5) {
      before = false;
      after = false;
    } else {
      before = false;
      after = false;
      if (paginationGroup[0] > 2) {
        before = true;
      }
      if (paginationGroup[2] < pageCount - 1) {
        after = true;
      }
    }
    setGaps({ paginationGroup, before, after });
  }, [page, pagesInBetween, pageCount]);

  const changePage = (direction: boolean) => {
    setPage((state) => {
      if (direction) {
        if (state === pageCount) {
          return state;
        }
        return state + 1;
      } else {
        if (state === 1) {
          return state;
        }
        return state - 1;
      }
    });
  };

  const setPageSafe = (num: number) => {
    if (num > pageCount) {
      setPage(pageCount);
    } else if (num < 1 || pageCount === 0) {
      setPage(1);
    } else {
      setPage(num);
    }
  };

  useEffect(() => {
    setPageSafe(pageId);
  }, [pageCount, pageId]);

  const currentRoute = location.pathname.split(MAIN_ROUTE);

  const modifiedRoute = currentRoute
    .slice(1, currentRoute.length - 1)
    .join(MAIN_ROUTE);

  useEffect(() => {
    navigate(`/${modifiedRoute}/${page}${location.search}`, { replace: true });
  }, [page]);

  const resetPagination = (contentCount: number) => {
    setPageCount(Math.ceil(contentCount / contentPerPage));
  };

  return {
    totalPages: pageCount,
    nextPage: () => changePage(true),
    prevPage: () => changePage(false),
    setPage: setPageSafe,
    resetPagination,
    firstContentIndex,
    lastContentIndex,
    page,
    gaps,
  };
};
