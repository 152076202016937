export const MAIN_ROUTE = "/";

export const SIGNIN_ROUTE = "/signin";
export const SIGNIN_CODE_ROUTE = "/signin/code";
export const SIGNIN_PASSWORD_ROUTE = "/signin/password";

export const SIGNUP_ROUTE = "/signup";
export const SIGNUP_CODE_ROUTE = "/signup/code";
export const SIGNUP_CREATE_PASSWORD_ROUTE = "/signup/create_password";

export const FORGOT_PASSWORD_CODE_ROUTE = "/forgot_password/code";
export const FORGOT_PASSWORD_NEW_ROUTE = "/forgot_password/new";

export const EVENTS_LIST_ROUTE = "/events_list";
export const ADD_EVENT_ROUTE = "/add_event";
export const EDIT_EVENT_ROUTE = "/edit_event";
export const SALES_LIST_ROUTE = "/sales_list";
export const SALES_REP_INFO_ROUTE = "/sales_info";
export const DASHBOARD_ROUTE = "/dashboard";
export const MOBILE_APP_ROUTE = "/mobile_app";
export const DEALERS_LIST_ROUTE = "/dealers_list";
export const DEALER_INFO_ROUTE = "/dealer_info";
export const PARENTS_LIST_ROUTE = "/parents_list";
export const PARENT_INFO_ROUTE = "/parent_info";
export const MEASUREMENTS_LIST_ROUTE = "/measurements_list";
export const PRODUCT_TYPES_ROUTE = "/product_types";
export const END_USER_MEASUREMENTS_ROUTE = "/my_measurements";
export const MY_END_USER_MEASUREMENTS_LIST_ROUTE = "/my_measurements_list";
export const END_USER_MEASUREMENTS_LIST_ROUTE = "/user_measurements_list";
export const END_USER_LIST_ROUTE = "/users_list";
export const END_USER_INFO_ROUTE = "/user_info";

export const TERMS_CONDITIONS_ROUTE = "/terms_and_conditions";
export const PRIVACY_POLICY_ROUTE = "/privacy_policy";
export const ABOUT_US_ROUTE = "/about_us";

export const DEFAULT_REDIRECT_ROUTE = SIGNIN_ROUTE;
