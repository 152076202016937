import { FC } from "react";
import { IGenderSegment } from "@models/components/secondary/gendersegment";
import { genders } from "@constants/commons";

export const GenderSegment: FC<IGenderSegment> = ({
  isDisabled,
  selectedGender,
  handleGender,
  customGenders,
}) => {
  return (
    <ul className={`segment ${isDisabled && "isDisabled"}`}>
      {(customGenders || genders).map((gender, index) => (
        <li
          key={index}
          className={`segment-item ${
            selectedGender.id === gender.id && "isActive"
          }`}
          onClick={() => !isDisabled && handleGender(gender)}
        >
          {gender.value}
        </li>
      ))}
    </ul>
  );
};
