import { ChangeEventHandler, FC, useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { UseFormRegisterReturn } from "react-hook-form/dist/types/form";
import { MAX_INPUT_LENGTH } from "@constants/commons";
import { IInput } from "@models/components/secondary/input";

export const Input: FC<IInput> = (props) => {
  const {
    name,
    id,
    label,
    subLabel,
    permanentText,
    error,
    placeholder,
    className,
    value,
    type,
    disable,
    shouldRegister = true,
    pattern,
    trimValue = false,
    unFocuseField,
    focuseField,
  } = props;

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const formData = useFormContext();

  const register =
    shouldRegister && formData?.register
      ? formData.register(name, {
          pattern,
        })
      : {};

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      event.target.value = trimValue
        ? event.target.value?.trim()
        : event.target.value;

      (register as UseFormRegisterReturn)?.onChange({
        target: event.target,
        type: "change",
      });
    },
    [register, pattern]
  );

  return (
    <div className={`form ${className} ${disable ? "isDisabled" : ""}`}>
      {label && (
        <label htmlFor={id} className="form-label">
          {label}
        </label>
      )}
      {type === "password" ? (
        <div className="form-wrapper">
          <input
            type={passwordShown ? "text" : "password"}
            id={id}
            value={value}
            className={`form-input ${error && "form-input-error"}`}
            placeholder={placeholder}
            disabled={disable}
            {...register}
            onChange={onChange}
            name={name}
            maxLength={MAX_INPUT_LENGTH}
            onBlur={() => unFocuseField && unFocuseField()}
            onFocus={() => focuseField && focuseField()}
            onKeyPress={(event) => {
              if (pattern && !pattern.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
          <i
            className={`form-icon icon ${
              passwordShown ? "icon-eye-closed" : "icon-eye"
            }`}
            onClick={togglePassword}
          />
        </div>
      ) : (
        <input
          type={type}
          id={id}
          value={value}
          className={`${type !== "checkbox" && "form-input"} ${
            error && "form-input-error"
          }`}
          placeholder={placeholder}
          disabled={disable}
          {...register}
          onChange={onChange}
          name={name}
          maxLength={MAX_INPUT_LENGTH}
          onBlur={() => unFocuseField && unFocuseField()}
          onFocus={() => focuseField && focuseField()}
          onKeyPress={(event) => {
            if (pattern && !pattern.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      )}
      {subLabel && (
        <label
          htmlFor={id}
          className={`form-sublabel ${error && "form-error"}`}
        >
          {subLabel}
        </label>
      )}
      {permanentText && (
        <label className="form-sublabel">{permanentText}</label>
      )}
    </div>
  );
};
