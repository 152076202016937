import {
  ADMIN_ROLE,
  DEALER_ROLE,
  PARENT_ROLE,
  SALES_REP_ROLE,
} from "@constants/commons";
import {
  ALL_LOCATIONS_EVENT_TYPE,
  ALL_STATUS,
  CANCELED_STATUS,
  CANCELED_STATUS_REQUEST,
  COMPLETED_STATUS,
  COMPLETED_STATUS_REQUEST,
  IN_PERSON_EVENT_TYPE,
  IN_PROGRESS_STATUS,
  IN_PROGRESS_STATUS_REQUEST,
  MEASURED,
  MEASURED_STATUS,
  MEASUREMENT_REQUIRED,
  MEASUREMENT_REQUIRED_STATUS,
  REMOTE_EVENT_TYPE,
  SCHEDULED_STATUS,
  SCHEDULED_STATUS_REQUEST,
} from "@constants/events";
import { IManageEventData } from "@models/components/secondary/manageevent";
import { AppDispatch } from "@store/store";
import {
  GenderMeasurementParameter,
  IMeasurementParameter,
  RequestGenderMeasurementParameter,
} from "./measurements";
import { UserGender, UserRole, UserStatus } from "./user";

export interface IPageable {
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  unpaged: boolean;
}

export interface ISort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export type GenderToMeasurementParamRefs = {
  FEMALE?: GenderMeasurementParameter[];
  MALE?: GenderMeasurementParameter[];
};

export interface ProductType {
  productTypeId: string;
  name: string;
  genderToMeasurementParams: GenderToMeasurementParamRefs;
}

export type RequestGenderToMeasurementParamRefs = {
  FEMALE?: RequestGenderMeasurementParameter[];
  MALE?: RequestGenderMeasurementParameter[];
};

export interface RequestProductType
  extends Omit<ProductType, "genderToMeasurementParams"> {
  genderToMeasurementParamRefs: RequestGenderToMeasurementParamRefs;
}

export interface IProductTypes {
  content: ProductType[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: IPageable;
  size: number;
  sort: ISort;
  totalElements: number;
  totalPages: number;
}

export interface IEventSalesRep {
  userId: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  status: UserStatus;
  email?: string;
  phoneNumber?: string;
  salesRepId: string;
}

export interface IEvent {
  eventId: string;
  salesRep: IEventSalesRep;
  dealer: {
    user: {
      userId: string;
      firstName: string;
      lastName: string;
      role: UserRole;
      status: UserStatus;
      email: string;
      phoneNumber: string;
    };
    dealerId: string;
    dealerName: string;
  };
  productTypes: ProductType[];
  eventName: string;
  eventType: EventLocation;
  location: string;
  startsAt: string;
  endsAt: string;
  orderNumber: string;
  createdAt: string;
  updatedAt: string;
  createdBy: {
    userId: string;
    firstName: string;
    lastName: string;
    role: UserRole;
    status: UserStatus;
    email: string;
    phoneNumber: string;
  };
  status: EventStatusRequest;
  participantsMeasured: number;
  participantsTotal: number;
  eventNumber: number;
  eventParticipation: {
    eventId: string;
    endUser: {
      user: {
        userId: string;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        status: UserStatus;
        role: UserRole;
        createdAt: string;
        updatedAt: string;
        createdBy: {
          userId: string;
          firstName: string;
          lastName: string;
          role: UserRole;
          status: UserStatus;
          email: string;
          phoneNumber: string;
        };
      };
      endUserId: string;
      badgeNumber: string;
      gender: UserGender;
    };
    measurementStatus: MeasurementStatus;
    measurement: {
      measurementId: string;
      createdAt: string;
      updatedAt: string;
      measuredBy: {
        userId: string;
        firstName: string;
        lastName: string;
        role: UserRole;
        status: UserStatus;
        email: string;
        phoneNumber: string;
      };
      gender: UserGender;
      whoMeasured: string;
      measurementData: [
        {
          measurementParam: IMeasurementParameter;
          value: string;
        }
      ];
    };
  };
}

export interface IEventList {
  content: IEvent[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: IPageable;
  size: boolean;
  sort: ISort;
  totalElements: number;
  totalPages: number;
}

export interface EventDataState {
  type: string;
  payload: {
    createEvent: IManageEventData | undefined;
    editEvent: IManageEventData | undefined;
  };
}

export interface IFilterEventValues {
  dispatch: AppDispatch;
  upcomingDate: boolean;
  startDate?: string;
  endDate?: string;
  eventLocation?: EventLocation;
  eventStatus?: EventStatus;
  endUserEventStatus?: EndUserEventStatus;
  productTypeId?: string;
  eventCreator?: string;
  page: number;
  size: number;
  sort: string;
  salesRepId?: string;
  endUserId?: string;
  dealerId?: string;
}

export type EventLocation =
  | typeof ALL_LOCATIONS_EVENT_TYPE
  | typeof IN_PERSON_EVENT_TYPE
  | typeof REMOTE_EVENT_TYPE;

export type EventStatus =
  | typeof ALL_STATUS
  | typeof COMPLETED_STATUS
  | typeof CANCELED_STATUS
  | typeof SCHEDULED_STATUS
  | typeof IN_PROGRESS_STATUS;

export type EndUserEventStatus =
  | typeof ALL_STATUS
  | typeof MEASUREMENT_REQUIRED_STATUS
  | typeof MEASURED_STATUS;

export type EventStatusRequest =
  | typeof COMPLETED_STATUS_REQUEST
  | typeof CANCELED_STATUS_REQUEST
  | typeof SCHEDULED_STATUS_REQUEST
  | typeof IN_PROGRESS_STATUS_REQUEST;

export type EndUserEventStatusRequest =
  | typeof MEASUREMENT_REQUIRED
  | typeof MEASURED;

export type MeasurementStatus = typeof MEASUREMENT_REQUIRED | typeof MEASURED;

export type EventCreator =
  | typeof ALL_STATUS
  | "Admin"
  | "Parent"
  | "Dealer"
  | "Sales representative";

export type EventCreatorRequest =
  | typeof ALL_STATUS
  | typeof ADMIN_ROLE
  | typeof PARENT_ROLE
  | typeof DEALER_ROLE
  | typeof SALES_REP_ROLE;

export interface EventState {
  type: string;
  payload: IEvent | undefined;
}

export const EVENT_ROLE = "EVENT";
