import {
  ACCEPTED_USER_STATUS,
  ADMIN_ROLE,
  DEALER_ROLE,
  DELETED_USER_STATUS,
  END_USER_ROLE,
  FEMALE_USER_GENDER,
  MALE_USER_GENDER,
  OTHER_USER_GENDER,
  PARENT_ROLE,
  PENDING_USER_STATUS,
  SALES_REP_ROLE,
  DEALER_SALES_REP_ROLE,
} from "@constants/commons";

export interface ICognitoUser {
  Session?: string | null;
  authenticationFlowType?: string;
  client?: {
    endpoint?: string;
    userAgent?: string;
  };
  keyPrefix?: string;
  pool?: {
    advancedSecurityDataCollectionFlag?: boolean;
    clientId?: string;
    userPoolId?: string;
  };
  username?: string;
  userConfirmed?: boolean;
  userSub?: string;
  challengeName?: string;
  challengeParam?: {
    [key: string]: unknown;
    expiresAt: number;
  };
  unverified?: {
    email?: string;
    phone_number?: string;
  };
  signInUserSession?: IUserTokens;
  [attributes: string]: unknown;
}

export interface IUserTokens {
  accessToken: {
    jwtToken: string;
    payload: {
      [attributes: string]: string | number;
    };
  };
  clockDrift?: 0;
  idToken: {
    jwtToken: string;
    payload: {
      [attributes: string]: string | number;
      ["custom:user_role"]: UserRole;
      ["cognito:username"]: string;
    };
  };
  refreshToken: {
    token: string;
  };
}

export interface IUser {
  username: string;
  verified: boolean;
}

export interface CognitoUserState {
  type: string;
  payload: ICognitoUser | undefined;
}

export interface UserState {
  user: {
    type: string;
    payload: IUser | undefined;
  };
}

export interface TokenState {
  type: string;
  payload: IUserTokens | undefined;
}

export type UserRole =
  | typeof ADMIN_ROLE
  | typeof PARENT_ROLE
  | typeof DEALER_ROLE
  | typeof SALES_REP_ROLE
  | typeof END_USER_ROLE
  | typeof DEALER_SALES_REP_ROLE;

export const onlyEndUserRole: UserRole[] = [END_USER_ROLE];
export const onlySalesRepRole: UserRole[] = [SALES_REP_ROLE];
export const onlyDealerRole: UserRole[] = [DEALER_ROLE];
export const onlyParentRole: UserRole[] = [PARENT_ROLE];
export const onlyAdminRole: UserRole[] = [ADMIN_ROLE];
export const eachRole: UserRole[] = [
  END_USER_ROLE,
  SALES_REP_ROLE,
  DEALER_ROLE,
  PARENT_ROLE,
  ADMIN_ROLE,
];

export type UserGender =
  | typeof MALE_USER_GENDER
  | typeof FEMALE_USER_GENDER
  | typeof OTHER_USER_GENDER;

export type UserStatus =
  | typeof PENDING_USER_STATUS
  | typeof ACCEPTED_USER_STATUS
  | typeof DELETED_USER_STATUS;

export interface IUserResponse {
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber?: string;
  salesRep?: boolean;
}

export interface IEndUserResponse
  extends Omit<IUserResponse, "email" | "phoneNumber"> {
  email?: string;
  phoneNumber?: string;
  dealer?: boolean;
  dealerName?: string;
  salesRep?: boolean;
}
