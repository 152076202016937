import { FC } from "react";
import { IFilterDateFieldset } from "@models/components/secondary/filterfieldset";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { Radio } from "@components/Radio";

export const FilterDateFieldset: FC<IFilterDateFieldset> = (props) => {
  const { title, handleDateRadios, dateRadios } = props;

  const formData = useFormContext();
  const { watch, control } = formData;
  const { startDate, endDate } = watch();

  return (
    <FormProvider {...formData}>
      <div className="filter-form">
        <h3 className="filter-subtitle">{title}</h3>
        <fieldset className="filter-fields">
          <Radio
            label="All"
            id="dateAll"
            name="allDate"
            value={dateRadios.allDate}
            checked={dateRadios.allDate}
            onClick={() =>
              handleDateRadios({
                allDate: true,
                upcomingDate: false,
                rangeDate: false,
              })
            }
          />
          <Radio
            label="All upcoming"
            id="dateUpcoming"
            name="upcomingDate"
            value={dateRadios.upcomingDate}
            checked={dateRadios.upcomingDate}
            onClick={() =>
              handleDateRadios({
                allDate: false,
                upcomingDate: true,
                rangeDate: false,
              })
            }
          />
          <Radio
            label="Date range"
            id="dateRange"
            name="rangeDate"
            value={dateRadios.rangeDate}
            checked={dateRadios.rangeDate}
            onClick={() =>
              handleDateRadios({
                allDate: false,
                upcomingDate: false,
                rangeDate: true,
              })
            }
          />
          {dateRadios.rangeDate && (
            <div className="datepicker datepicker-filter">
              <Controller
                control={control}
                name="startDate"
                render={({ field }) => (
                  <DatePicker
                    startDate={startDate}
                    endDate={endDate}
                    selectsStart
                    placeholderText="Start date"
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    onFocus={(e) => e.target.blur()}
                    selected={startDate}
                    className="datepicker-filter-input"
                  />
                )}
              />
              <Controller
                control={control}
                name="endDate"
                render={({ field }) => (
                  <DatePicker
                    startDate={startDate}
                    endDate={endDate}
                    selectsEnd
                    placeholderText="End date"
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    onFocus={(e) => e.target.blur()}
                    selected={endDate}
                    className="datepicker-filter-input"
                  />
                )}
              />
            </div>
          )}
        </fieldset>
      </div>
    </FormProvider>
  );
};
