import React, { FC, useEffect, useState } from "react";
import { Header } from "@components/Header";
import { Input } from "@components/Input";
import { EventStepsHeader } from "@components/Events/EventStepsHeader/index";
import { Radio } from "@components/Radio";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "@components/Button";
import {
  IManageEvent,
  IManageEventForm,
} from "@models/components/secondary/manageevent";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchemas } from "@services/validation";
import { createEvent, findEventById, updateEvent } from "@api/events";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { selectTokens } from "@store/selectors/tokens";
import {
  EventLocation,
  IEvent,
  IEventSalesRep,
  ProductType,
} from "@models/common/events";
import { Loader } from "@components/Loader";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import {
  CANCELED_STATUS_REQUEST,
  CANCEL_ADDING_EVENT_BODY,
  CANCEL_ADDING_EVENT_TITLE,
  CANCEL_EDITING_EVENT_BODY,
  CANCEL_EDITING_EVENT_TITLE,
  IN_PERSON_EVENT_TYPE,
  PAST_DATE_EVENT_BODY,
  PAST_END_DATE_EVENT_TITLE,
  PAST_START_DATE_EVENT_TITLE,
  REMOTE_EVENT_TYPE,
  SEND_INVITATION_EVENT_BODY,
  SEND_INVITATION_EVENT_TITLE,
} from "@constants/events";
import { ADD_EVENT, EDIT_EVENT } from "@constants/events";
import { HeaderBarTable } from "@components/HeaderBarTable";
import { getProductTypesBySearchKey } from "@api/types";
import { Breadcrumbs } from "@components/Breadcrumbs";
import {
  DEFAULT_ITEMS_COUNT_WITHOUT_PAGINATION,
  EVENTS_BREADCRUMBS,
  SALES_REP_ROLE,
  SOMETHING_WENT_WRONG_NOTIFICATION,
} from "@constants/commons";
import { ISalesRep } from "@models/common/salesRep";
import { getSalesRepBySearchKey } from "@api/salesRep";
import { SalesRepDropdown } from "@components/Dropdown/SalesRepDropdown";
import { ProductTypeDropdown } from "@components/Dropdown/ProductTypeDropdown";
import {
  ADD_EVENT_ROUTE,
  EVENTS_LIST_ROUTE,
  MEASUREMENTS_LIST_ROUTE,
} from "@constants/routes";
import { AlertModal } from "@components/AlertModal";
import { selectEventData } from "@store/selectors/event";
import { eventInfoSlice } from "@store/reducers/EventSlice";
import { Notification } from "@components/Notification";
import { useNotification } from "@hooks/common/useNotification";
import { INTERRUPT_EVENT_BODY, INTERRUPT_EVENT_TITLE } from "@constants/events";
import { sendInvitationToEventParticipants } from "@api/eventParticipants";
import { getFormattedError } from "@services/http";
import { getUserRoleFromToken } from "@services/common";

export const ManageEvent: FC<IManageEvent> = ({ title }) => {
  const formMethods = useForm<IManageEventForm>({
    resolver: yupResolver(validationSchemas.manageEvent),
    mode: "onTouched",
    shouldFocusError: false,
  });

  const [submitted, setSubmitted] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<IEvent | undefined>();
  const [productTypes, setProductTypes] = useState<ProductType[]>();
  const [listProductTypes, setListProductTypes] = useState<ProductType[]>();
  const [searchedType, setSearchedType] = useState("");
  const [radios, setRadios] = useState<{
    person: boolean;
    remote: boolean;
  }>({
    person: true,
    remote: false,
  });
  const [assignedSalesRep, setAssignedSalesRep] = useState<IEventSalesRep>();
  const [listAssignedSalesReps, setListAssignedSalesReps] =
    useState<ISalesRep[]>();
  const [searchedSalesRep, setSearchedSalesRep] = useState("");
  const [createdEventId, setCreatedEventId] = useState("");
  const [cancelWindowOpen, setCancelWindowOpen] = useState(false);
  const [redirectOpen, setRedirectOpen] = useState(false);
  const [interruptRoute, setInterruptRoute] = useState<string>();
  const [pastDate, setPastDate] = useState<string | undefined>();
  const [sendInvite, setSendInvite] = useState(false);

  const { handleSubmit, watch, control, formState, setValue } = formMethods;
  const { errors, isSubmitSuccessful } = formState;
  const {
    eventName,
    eventTypes,
    department,
    assignedSalesRep: watchAssignedSalesRep,
    startdate,
    enddate,
    starttime,
    endtime,
    remote,
    person,
  } = watch();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { eventID } = useParams();
  const eventData = useAppSelector(selectEventData);
  const tokensInfo = useAppSelector(selectTokens);
  const userRole = getUserRoleFromToken(tokensInfo);

  const {
    isActive,
    message: notificationMessage,
    type: notificationType,
    showNotification,
    closeNotification,
  } = useNotification();

  const handleSetCurrentEvent = (data: IEvent) => {
    setCurrentEvent(data);
    setProductTypes(data.productTypes);
    setAssignedSalesRep(data.salesRep);
    if (data?.eventType === REMOTE_EVENT_TYPE) {
      setRadios({ person: false, remote: true });
    } else {
      setRadios({ person: true, remote: false });
    }
  };

  const handleSearchedType = (type: string) => {
    setSearchedType(type);
  };

  const handleProductTypeItems = (items: ProductType[]) => {
    setProductTypes(items);
  };

  const handleSearchedSalesRep = (salesRep: string) => {
    setSearchedSalesRep(salesRep);
  };

  const handleSalesRepItems = (items: IEventSalesRep | undefined) => {
    setAssignedSalesRep(items);
  };

  const handleCancelWindowOpen = () => {
    setCancelWindowOpen(!cancelWindowOpen);
  };

  const handleEventCancel = async () => {
    dispatch(eventInfoSlice.actions.clearEventData());
    navigate(`${EVENTS_LIST_ROUTE}/1`);
  };

  const cancelManage = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    handleCancelWindowOpen();
  };

  const handleClarifyWindow = () => {
    setRedirectOpen(!redirectOpen);
  };

  const handleRedirect = () => {
    interruptRoute && navigate(interruptRoute);
  };

  const handleInterruptRoute = (route: string) => {
    setInterruptRoute(route);
  };

  const handlePastDateWindowClose = () => {
    setPastDate(undefined);
  };

  const handleSetPastDate = () => {
    let selectedDate;
    if (pastDate?.includes("start")) {
      selectedDate = new Date(+pastDate.split("_")[1]);
      if (selectedDate instanceof Date) {
        setValue("startdate", selectedDate);
        setValue("starttime", selectedDate);
      }
    }
    if (pastDate?.includes("end")) {
      selectedDate = new Date(+pastDate.split("_")[1]);
      if (selectedDate instanceof Date) {
        setValue("enddate", selectedDate);
        setValue("endtime", selectedDate);
      }
    }
  };

  const handleInviteWindowClose = () => {
    setSendInvite(false);
    setSubmitted(true);
  };

  const handleSetInvite = async () => {
    if (eventID) {
      try {
        await sendInvitationToEventParticipants(dispatch, eventID);
        setSubmitted(true);
      } catch (error) {
        showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
        return Promise.reject(getFormattedError(error));
      }
    }
  };

  useEffect(() => {
    if (title === EDIT_EVENT && eventID) {
      if (eventData) {
        handleSetCurrentEvent(eventData);
      } else {
        (async function asyncWrapper() {
          try {
            const event = await findEventById(dispatch, eventID);
            handleSetCurrentEvent(event);
          } catch (error) {
            return Promise.reject(getFormattedError(error));
          }
        })();
      }
    }
  }, [eventID]);

  useEffect(() => {
    if (userRole !== SALES_REP_ROLE) {
      (async function asyncWrapper() {
        try {
          const salesReps = await getSalesRepBySearchKey(
            dispatch,
            searchedSalesRep
          );
          setListAssignedSalesReps(salesReps.content);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [searchedSalesRep]);

  useEffect(() => {
    (async function asyncWrapper() {
      try {
        const types = await getProductTypesBySearchKey(
          dispatch,
          searchedType,
          DEFAULT_ITEMS_COUNT_WITHOUT_PAGINATION
        );
        setListProductTypes(types.content);
      } catch (error) {
        return Promise.reject(getFormattedError(error));
      }
    })();
  }, [searchedType]);

  useEffect(() => {
    if (title === EDIT_EVENT) {
      setValue("eventName", currentEvent?.eventName || "");

      if (currentEvent?.startsAt) {
        setValue("startdate", new Date(`${currentEvent.startsAt}.000Z`));
        setValue("starttime", new Date(`${currentEvent.startsAt}.000Z`));
      }
      if (currentEvent?.endsAt) {
        setValue("enddate", new Date(`${currentEvent.endsAt}.000Z`));
        setValue("endtime", new Date(`${currentEvent.endsAt}.000Z`));
      }
      if (currentEvent?.location) {
        setValue("department", currentEvent.location);
      }
      setValue("orderNumber", currentEvent?.orderNumber || "");
    }
  }, [currentEvent]);

  useEffect(() => {
    setValue("person", radios.person.toString());
    setValue("remote", radios.remote.toString());
  }, [radios]);

  useEffect(() => {
    if (Date.parse(enddate?.toString()) < Date.parse(startdate?.toString())) {
      setValue("enddate", startdate);
    }

    if (Date.parse(endtime?.toString()) <= Date.parse(starttime?.toString())) {
      setValue("endtime", new Date(Date.parse(starttime?.toString()) + 900000));
    }
  }, [startdate, enddate, starttime, endtime]);

  const onSubmit = async (data: IManageEventForm) => {
    try {
      const startsDate = new Date(data.startdate.toUTCString()).toISOString();

      const endsDate = new Date(data.enddate.toUTCString()).toISOString();

      const filteredEventData = {
        salesRepId: currentEvent?.salesRep?.salesRepId
          ? currentEvent.salesRep.salesRepId
          : userRole === SALES_REP_ROLE
          ? tokensInfo?.idToken.payload["cognito:username"]
          : data.assignedSalesRep || "",
        productTypesIds: data.eventTypes?.split(", "),
        eventName: data.eventName,
        location: !!JSON.parse(data?.person) ? data.department : "",
        eventType: (!!JSON.parse(data?.person)
          ? IN_PERSON_EVENT_TYPE
          : REMOTE_EVENT_TYPE) as EventLocation,
        startsAt: startsDate,
        endsAt: endsDate,
        orderNumber: data?.orderNumber || "",
        isCanceled: currentEvent?.status
          ? currentEvent.status === CANCELED_STATUS_REQUEST
            ? true
            : false
          : false,
      };

      if (title === EDIT_EVENT && eventID) {
        await updateEvent(dispatch, eventID, filteredEventData);
      }
      if (title === ADD_EVENT) {
        const event = await createEvent(dispatch, filteredEventData);
        setCreatedEventId(event.eventId);
        dispatch(eventInfoSlice.actions.setEventData(event));
      }
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      if (
        currentEvent?.startsAt &&
        currentEvent?.endsAt &&
        (currentEvent?.participantsTotal || 0) > 0 &&
        title === EDIT_EVENT
      ) {
        const startsAt = new Date(Date.parse(`${currentEvent.startsAt}.000Z`));
        const startsAtMill = new Date(startsAt.setMilliseconds(0));
        const startsAtSec = new Date(startsAtMill.setSeconds(0));

        const modifiedStartsAt = new Date(Date.parse(starttime.toString()));
        const modifiedStartsAtMill = new Date(
          modifiedStartsAt.setMilliseconds(0)
        );
        const modifiedStartsAtSec = new Date(
          modifiedStartsAtMill.setSeconds(0)
        );

        const endsAt = new Date(Date.parse(`${currentEvent.endsAt}.000Z`));
        const endsAtMill = new Date(endsAt.setMilliseconds(0));
        const endsAtSec = new Date(endsAtMill.setSeconds(0));

        const modifiedEndsAt = new Date(Date.parse(endtime.toString()));
        const modifiedEndsAtMill = new Date(modifiedEndsAt.setMilliseconds(0));
        const modifiedEndsAtSec = new Date(modifiedEndsAtMill.setSeconds(0));

        if (
          Date.parse(startsAtSec.toString()) !==
            Date.parse(modifiedStartsAtSec.toString()) ||
          Date.parse(endsAtSec.toString()) !==
            Date.parse(modifiedEndsAtSec.toString()) ||
          (currentEvent.eventType === IN_PERSON_EVENT_TYPE
            ? remote === "true"
            : person === "true" && currentEvent.location !== (department || ""))
        ) {
          setSendInvite(true);
        } else {
          setSubmitted(true);
        }
      } else {
        setSubmitted(true);
      }
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (submitted) {
      if (title === ADD_EVENT && createdEventId) {
        navigate(`${ADD_EVENT_ROUTE}/${createdEventId}`);
      }
      if (title === EDIT_EVENT) {
        if (eventData) {
          navigate(`${ADD_EVENT_ROUTE}/${eventID}`);
        } else {
          dispatch(eventInfoSlice.actions.clearEventData());
          navigate(`${MEASUREMENTS_LIST_ROUTE}/${eventID}/1`);
        }
      }
    }
  }, [submitted, createdEventId]);

  const isManageBtnDisabled =
    !eventName?.trim() ||
    !eventTypes?.length ||
    (radios.person && !department?.trim()) ||
    (userRole !== SALES_REP_ROLE && !watchAssignedSalesRep?.length) ||
    !startdate ||
    !enddate ||
    !starttime ||
    !endtime;

  return (
    <>
      <Header
        handleClarifyWindow={handleClarifyWindow}
        handleInterruptRoute={handleInterruptRoute}
      />
      <Notification
        type={notificationType}
        message={notificationMessage}
        isActive={isActive}
        closeNotification={closeNotification}
      />
      {sendInvite && (
        <AlertModal
          type="create"
          title={SEND_INVITATION_EVENT_TITLE}
          body={SEND_INVITATION_EVENT_BODY}
          handleOpen={handleInviteWindowClose}
          handleConfirm={handleSetInvite}
          id={eventID || ""}
          cancelBtn="Don't send"
          confirmBtn="Send"
        />
      )}
      {pastDate && (
        <AlertModal
          type="create"
          title={
            pastDate?.includes("start")
              ? PAST_START_DATE_EVENT_TITLE
              : PAST_END_DATE_EVENT_TITLE
          }
          body={PAST_DATE_EVENT_BODY}
          handleOpen={handlePastDateWindowClose}
          handleConfirm={handleSetPastDate}
          id={eventID || ""}
        />
      )}
      {redirectOpen && (
        <AlertModal
          type="delete"
          title={INTERRUPT_EVENT_TITLE}
          body={INTERRUPT_EVENT_BODY}
          handleOpen={handleClarifyWindow}
          handleConfirm={handleRedirect}
          id={eventID || ""}
          confirmBtn="Yes"
        />
      )}
      {cancelWindowOpen && (
        <AlertModal
          type="delete"
          title={
            title === ADD_EVENT
              ? CANCEL_ADDING_EVENT_TITLE
              : CANCEL_EDITING_EVENT_TITLE
          }
          body={
            title === ADD_EVENT
              ? CANCEL_ADDING_EVENT_BODY
              : CANCEL_EDITING_EVENT_BODY
          }
          handleOpen={handleCancelWindowOpen}
          handleConfirm={handleEventCancel}
          id={eventID || ""}
        />
      )}
      {(currentEvent && title === EDIT_EVENT) || title === ADD_EVENT ? (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <main className="main-container">
            <Breadcrumbs
              title={title}
              type={EVENTS_BREADCRUMBS}
              handleClarifyWindow={handleClarifyWindow}
              handleInterruptRoute={handleInterruptRoute}
            />
            <HeaderBarTable title={title} />
            <div className="event-form">
              <EventStepsHeader eventStepType="general" title={title} />
              <div className="step">
                <FormProvider {...formMethods}>
                  <div className="step-col">
                    {userRole !== SALES_REP_ROLE && (
                      <SalesRepDropdown
                        name="assignedSalesRep"
                        id="dropdown"
                        defaultValue={assignedSalesRep}
                        dropdownList={listAssignedSalesReps}
                        handleSalesRepItems={handleSalesRepItems}
                        handleSearchedValue={handleSearchedSalesRep}
                        searchedValue={searchedSalesRep}
                        required
                        limit={1}
                      />
                    )}
                    <Input
                      name="eventName"
                      label="Event Name*"
                      placeholder="Event name"
                    />
                    <ProductTypeDropdown
                      name="eventTypes"
                      id="dropdown"
                      defaultValue={productTypes}
                      dropdownList={listProductTypes}
                      searchedValue={searchedType}
                      handleProductTypeItems={handleProductTypeItems}
                      handleSearchedValue={handleSearchedType}
                      required
                    />
                    <div className="form">
                      <label htmlFor="input" className="form-label">
                        Department location <sup>*</sup>
                      </label>
                      <div className="event-department">
                        <Radio
                          label="In person"
                          id="person"
                          name="person"
                          value={radios.person}
                          checked={radios.person}
                          onClick={() =>
                            setRadios({ person: true, remote: false })
                          }
                        />
                        <Radio
                          label="Remote"
                          id="remote"
                          name="remote"
                          value={radios.remote}
                          checked={radios.remote}
                          onClick={() =>
                            setRadios({ person: false, remote: true })
                          }
                        />
                      </div>
                      {radios.person && (
                        <Input
                          name="department"
                          placeholder="Department location"
                        />
                      )}
                    </div>
                  </div>
                  <div className="step-col">
                    <div className="datepicker form">
                      <span className="form-label">
                        Start date / End date <sup>*</sup>
                      </span>
                      <div className="d-flex a-center">
                        <Controller
                          control={control}
                          name="startdate"
                          render={({ field }) => (
                            <DatePicker
                              startDate={startdate}
                              endDate={enddate}
                              selectsStart
                              dateFormat="MM.dd.yyyy"
                              placeholderText="Start date"
                              onChange={(date) => {
                                if (date) {
                                  if (
                                    date.setHours(0, 0, 0, 0) <
                                    new Date().setHours(0, 0, 0, 0)
                                  ) {
                                    setPastDate(
                                      `start_${Date.parse(date.toString())}`
                                    );
                                  } else {
                                    field.onChange(date);
                                    date && setValue("starttime", date);
                                  }
                                }
                              }}
                              onFocus={(e) => e.target.blur()}
                              selected={startdate}
                              className={`datepicker-input ${
                                !!errors.startdate?.message &&
                                "form-input-error"
                              }`}
                            />
                          )}
                        />
                        <i className="datepicker-icon icon icon-arrow-right-grey" />
                        <Controller
                          control={control}
                          name="enddate"
                          render={({ field }) => (
                            <DatePicker
                              startDate={startdate}
                              endDate={enddate}
                              selectsEnd
                              dateFormat="MM.dd.yyyy"
                              placeholderText="End date"
                              onChange={(date) => {
                                if (date) {
                                  if (
                                    date.setHours(0, 0, 0, 0) <
                                    new Date().setHours(0, 0, 0, 0)
                                  ) {
                                    setPastDate(
                                      `end_${Date.parse(date.toString())}`
                                    );
                                  } else {
                                    field.onChange(date);
                                    date && setValue("endtime", date);
                                  }
                                }
                              }}
                              onFocus={(e) => e.target.blur()}
                              selected={enddate}
                              className={`datepicker-input ${
                                !!errors.enddate?.message && "form-input-error"
                              }`}
                            />
                          )}
                        />
                      </div>
                    </div>
                    {(errors.startdate?.message || errors.enddate?.message) && (
                      <sub className="form-error">
                        {errors.startdate?.message || errors.enddate?.message}
                      </sub>
                    )}
                    <div className="timepicker form form">
                      <span className="form-label">
                        Start time / End time <sup>*</sup>
                      </span>
                      <div className="d-flex a-center">
                        <Controller
                          control={control}
                          name="starttime"
                          render={({ field }) => (
                            <DatePicker
                              selected={starttime}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              placeholderText="Start time"
                              onChange={(date) => {
                                if (date instanceof Date) {
                                  field.onChange(date);
                                  date && setValue("startdate", date);
                                }
                              }}
                              onFocus={(e) => e.target.blur()}
                              className={`datepicker-input ${
                                !!errors.starttime?.message &&
                                "form-input-error"
                              }`}
                            />
                          )}
                        />
                        <i className="datepicker-icon icon icon-arrow-right-grey" />
                        <Controller
                          control={control}
                          name="endtime"
                          render={({ field }) => (
                            <DatePicker
                              selected={endtime}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              placeholderText="End time"
                              onChange={(date) => {
                                if (date !== null) {
                                  field.onChange(date);
                                  date && setValue("enddate", date);
                                }
                              }}
                              onFocus={(e) => e.target.blur()}
                              className={`datepicker-input ${
                                !!errors.endtime?.message && "form-input-error"
                              }`}
                            />
                          )}
                        />
                      </div>
                    </div>
                    {(errors.starttime?.message || errors.endtime?.message) && (
                      <sub className="form-error">
                        {errors.starttime?.message || errors.endtime?.message}
                      </sub>
                    )}
                    <Input
                      name="orderNumber"
                      label="Order number"
                      placeholder="Order number"
                    />
                  </div>
                </FormProvider>
              </div>
              <div className="event-footer">
                <Button
                  text="Cancel"
                  className="event-footer-btn btn-primary"
                  onClick={cancelManage}
                />
                <Button
                  text={title === EDIT_EVENT && !eventData ? "Save" : "Next"}
                  className="event-footer-btn btn-main"
                  type="submit"
                  disable={isManageBtnDisabled}
                />
              </div>
            </div>
          </main>
        </form>
      ) : (
        <Loader />
      )}
    </>
  );
};
