import { DELETED_USER_STATUS } from "@constants/commons";
import { IParent, IParentList } from "@models/common/parent";
import { IEndUserResponse, IUserResponse } from "@models/common/user";
import { http } from "@services/http";
import { AppDispatch } from "@store/store";

export const getParentsBySearchKey = async (
  dispatch: AppDispatch,
  searchKey: string
) =>
  await http.get<IParentList>(
    !!searchKey
      ? `/api/v1/user/parent?searchKey=${searchKey}`
      : "/api/v1/user/parent",
    dispatch
  );

export const findParentsBySearchKey = async (
  dispatch: AppDispatch,
  searchKey?: string
) =>
  await http.post<IParentList, { searchKey: string | null }>(
    `/api/v1/user/parent/filter`,
    { searchKey: searchKey || null },
    dispatch
  );

export const listOfFilteredParent = async (
  dispatch: AppDispatch,
  page: number,
  size: number,
  sort: string,
  searchedValue?: string
) =>
  await http.post<IParentList, { searchKey: string | null }>(
    `/api/v1/user/parent/filter?page=${page}&size=${size}&sort=${sort}`,
    { searchKey: searchedValue || null },
    dispatch
  );

export const updateParent = async (
  dispatch: AppDispatch,
  parentId: string,
  parentData: {
    firstName: string;
    lastName: string;
    salesRep: boolean | undefined;
    phoneNumber: string | undefined;
    dealerName: string | undefined;
    dealer: boolean | undefined;
    email: string | undefined;
  },
  assignedDealers?: string
) =>
  await http.post<
    IParent,
    {
      user: IUserResponse;
      dealer: boolean;
      dealerName: string;
      salesRep: boolean;
      assignedDealers: [string] | null;
    }
  >(
    `/api/v1/user/parent/${parentId}`,
    {
      user: parentData,
      dealer: parentData.dealer || false,
      dealerName: parentData.dealerName || "",
      salesRep: parentData.salesRep || false,
      assignedDealers: assignedDealers ? [assignedDealers] : null,
    },
    dispatch
  );

export const createParent = async (
  dispatch: AppDispatch,
  parentData: IEndUserResponse,
  assignedDealers?: string
) => {
  return await http.post<
    IParent,
    {
      user: IEndUserResponse;
      dealer?: boolean;
      dealerName?: string;
      salesRep?: boolean;
      assignedDealers: [string] | null;
    }
  >(
    `/api/v1/user/parent`,
    {
      user: parentData,
      dealer: parentData.dealer || false,
      dealerName: parentData.dealerName || "",
      salesRep: parentData.salesRep || false,
      assignedDealers: assignedDealers ? [assignedDealers] : null,
    },
    dispatch
  );
};

export const getParentById = async (dispatch: AppDispatch, parentId: string) =>
  await http.get<IParent>(`/api/v1/user/parent/${parentId}`, dispatch);

export const resendInviteParent = async (
  dispatch: AppDispatch,
  parentId: string
) =>
  await http.post(
    `/api/v1/user/parent/${parentId}/send-invite`,
    null,
    dispatch
  );

export const deleteParent = async (dispatch: AppDispatch, parentId: string) =>
  await http.put(
    `/api/v1/user/parent/${parentId}`,
    {
      userStatus: DELETED_USER_STATUS,
    },
    dispatch
  );

export const filterParent = async (
  dispatch: AppDispatch,
  email: string | undefined,
  phone_number: string | undefined
) =>
  await http.post<
    IParentList,
    { emailIn: string[] | null; phoneNumberIn: string[] | null }
  >(
    "/api/v1/user/parent/filter",
    {
      emailIn: email ? [email] : null,
      phoneNumberIn: phone_number ? [phone_number] : null,
    },
    dispatch
  );

export const generateParentsList = async (dispatch: AppDispatch) =>
  await http.post<Blob, null>("/api/v1/user/parent/export", null, dispatch, {
    responseType: "blob",
  });
