import { SOMETHING_WENT_WRONG_NOTIFICATION } from "@constants/commons";
import { INotificationMessage } from "@models/components/secondary/notification";
import { useState, useEffect } from "react";

export const useNotification = () => {
  const [isActive, setIsActive] = useState(false);
  const [message, setMessage] = useState<INotificationMessage>(
    SOMETHING_WENT_WRONG_NOTIFICATION
  );
  const [type, setType] = useState<"success" | "danger">("danger");
  const [duration, setDuration] = useState<number>(3000);

  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        setIsActive(false);
      }, duration);
    }
  }, [isActive, duration]);

  const showNotification = (
    msg: INotificationMessage,
    type: "success" | "danger",
    duration = 3000
  ) => {
    setMessage(msg);
    setType(type);
    setDuration(duration);
    setIsActive(true);
  };

  const closeNotification = () => {
    setIsActive(false);
  };

  return { isActive, message, type, showNotification, closeNotification };
};
