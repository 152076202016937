import { FC, useState, ChangeEvent } from "react";
import { IToggleSwitcher } from "@models/components/secondary/toggleswitcher";

export const ToggleSwitcher: FC<IToggleSwitcher> = ({
  id,
  label,
  checked,
  onChange,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(!!checked);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked: state } = event.target;
    setIsChecked(state);
    onChange(state);
  };

  return (
    <div className="toggle-switcher-container">
      <label className="toggle-switcher" htmlFor={id}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleChange}
          id={id}
        />
        <span className="slider" />
        <span className="toggle-switcher__label toggle-switcher__position-toggle">
          {label}
        </span>
      </label>
    </div>
  );
};
