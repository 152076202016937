import axios, { AxiosRequestConfig } from "axios";
import { Auth } from "aws-amplify";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { AppDispatch } from "@store/store";
import { configureAmplify, handleLogout, isAuthError } from "./auth";
import { IError } from "@models/common/app";
import { SOMETHING_WENT_WRONG_NOTIFICATION } from "@constants/commons";

export const getFormattedError = (error: string | IError | unknown) =>
  typeof error === "string"
    ? error
    : !!(error as IError)?.message
    ? (error as IError).message
    : SOMETHING_WENT_WRONG_NOTIFICATION;

export const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  baseURL: process.env.REACT_APP_API_LINK,
});

const request = async <T>(
  config: AxiosRequestConfig,
  dispatch: AppDispatch
): Promise<T> => {
  try {
    await configureAmplify();

    const getAuthorizedResponse = async (
      currentSession: CognitoUserSession
    ) => {
      if (process.env.REACT_APP_TOKENS_STATE) {
        localStorage.setItem(
          process.env.REACT_APP_TOKENS_STATE,
          JSON.stringify(currentSession)
        );
        const token = currentSession.getIdToken().getJwtToken();
        const response = await axiosInstance.request<T>({
          ...config,
          headers: { ...config.headers, Authorization: `Bearer ${token}` },
        });
        return response.data;
      } else {
        throw new Error(SOMETHING_WENT_WRONG_NOTIFICATION);
      }
    };

    const currentSession = await Auth.currentSession();
    return await getAuthorizedResponse(currentSession);
  } catch (error) {
    const e = getFormattedError(error);
    if (isAuthError(e)) await handleLogout(dispatch);
    return Promise.reject(error);
  }
};

export const http = {
  get: <T>(
    url: string,
    dispatch: AppDispatch,
    config: AxiosRequestConfig = {}
  ): Promise<T> => {
    return request<T>(
      {
        url,
        method: "GET",
        ...config,
      },
      dispatch
    );
  },
  post: <T, D>(
    url: string,
    data: D,
    dispatch: AppDispatch,
    config: AxiosRequestConfig<D> = {}
  ): Promise<T> => {
    return request<T>(
      {
        url,
        method: "POST",
        ...config,
        data,
      },
      dispatch
    );
  },
  put: <T, D>(
    url: string,
    data: D,
    dispatch: AppDispatch,
    config: AxiosRequestConfig<D> = {}
  ): Promise<T> => {
    return request<T>(
      {
        url,
        method: "PUT",
        ...config,
        data,
      },
      dispatch
    );
  },
  patch: <T, D>(
    url: string,
    data: D,
    dispatch: AppDispatch,
    config: AxiosRequestConfig<D> = {}
  ): Promise<T> => {
    return request<T>(
      {
        url,
        method: "PATCH",
        ...config,
        data,
      },
      dispatch
    );
  },
  delete: <T, D>(
    url: string,
    data: D,
    dispatch: AppDispatch,
    config: AxiosRequestConfig<D> = {}
  ): Promise<T> => {
    return request<T>(
      {
        url,
        method: "DELETE",
        ...config,
        data,
      },
      dispatch
    );
  },
};
