import { FC } from "react";
import { Header } from "@components/Header";
import { DealerInfo } from "@components/Dealer/DealerInfo";

export const SelectedDealer: FC = () => {
  return (
    <>
      <Header />
      <DealerInfo />
    </>
  );
};
