import { FC, useEffect, useRef, useState } from "react";
import { IDealerDropdown } from "@models/components/secondary/dropdownmenu";
import { useFormContext } from "react-hook-form";
import { REQUIRED_FIELD } from "@constants/validation";
import { DebounceInput } from "react-debounce-input";
import { setDropdownItems } from "@services/common";
import { IDealer } from "@models/common/dealer";
import { ASSIGN_DEALER_TITLE } from "@constants/users";

export const DealerDropdown: FC<IDealerDropdown> = (props) => {
  const {
    name,
    pattern,
    id,
    error,
    subLabel,
    limit,
    defaultValue,
    dropdownList,
    searchedValue,
    handleSearchedValue,
    disable,
    required,
    clear,
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [dealersDropdownItems, setDealersDropdownItems] = useState(
    defaultValue || []
  );

  const [isOpen, setOpen] = useState(false);
  const [focusedFields, setFocused] = useState({
    [name]: false,
  });

  const focuseField = (fieldName: string) => {
    setFocused({ [fieldName]: true });
  };

  const handleDropdownOpen = (state: boolean) => {
    setOpen(state);
  };

  const toggleDropdown = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleDropdownOpen(!isOpen);
  };

  const formData = useFormContext();

  const { watch, clearErrors, setError, formState, setValue } = formData;
  const { errors } = formState;

  useEffect(() => {
    if (!dealersDropdownItems.length && defaultValue) {
      setDealersDropdownItems(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (clear) {
      setDealersDropdownItems([]);
      setOpen(false);
    }
  }, [clear]);

  useEffect(() => {
    if (focusedFields?.[name]) {
      handleDropdownOpen(true);
    } else {
      handleDropdownOpen(false);
    }
  }, [focusedFields]);

  useEffect(() => {
    if (!!watch()?.[name]?.length) {
      clearErrors(name);
    }
  }, [watch()?.[name]]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        isOpen &&
        ref.current &&
        !ref.current.contains(e.target as HTMLDivElement)
      ) {
        handleDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    setValue(
      name,
      dealersDropdownItems.map((item) => item.dealerId).join(", ")
    );

    if (!dealersDropdownItems?.length && focusedFields[name]) {
      setError(name, { type: "custom", message: REQUIRED_FIELD });
    } else {
      clearErrors(name);
    }
  }, [dealersDropdownItems]);

  useEffect(() => {
    if (focusedFields[name]) {
      clearErrors(name);
    }
  }, [focusedFields]);

  const handleDealerDropdownItems = (items: IDealer[]) => {
    setDealersDropdownItems(items);
  };

  const handleDropdownItems = (value: IDealer) => {
    setDropdownItems({
      title: ASSIGN_DEALER_TITLE,
      limit,
      dealers: dealersDropdownItems,
      handleDealers: handleDealerDropdownItems,
      value,
      dropdownOpen: handleDropdownOpen,
      handleSearchedValue,
    });
  };

  return (
    <>
      <div className={`dropdown form ${isOpen && "isActive"}`}>
        <label htmlFor="dropdown" className="form-label">
          {ASSIGN_DEALER_TITLE} {required && <sup>*</sup>}
        </label>
        <div ref={ref}>
          <div className="dropdown-form">
            <div
              className={`dropdown-input-container ${
                !!errors?.[name]?.message &&
                !focusedFields[name] &&
                "form-input-error"
              }`}
            >
              {dealersDropdownItems.map((item) => (
                <button
                  disabled={disable}
                  className="dropdown-selected"
                  key={item.dealerId}
                  onClick={(e) => e.preventDefault()}
                >
                  {item.dealerName}({item.user.firstName} {item.user.lastName})
                  <i
                    className="dropdown-selected-icon icon icon-round-close "
                    onClick={() => {
                      setDealersDropdownItems((prevItems) => [
                        ...prevItems.filter(
                          (prevItem) => prevItem.dealerId !== item.dealerId
                        ),
                      ]);
                    }}
                  />
                </button>
              ))}
              <DebounceInput
                id={id}
                {...(formData?.register
                  ? formData.register(name, { pattern })
                  : {})}
                name={name}
                type="text"
                placeholder="Select dealer"
                className={`dropdown-input ${
                  !!errors?.[name]?.message &&
                  !focusedFields[name] &&
                  "form-input-error"
                }`}
                onFocus={() => focuseField(name)}
                debounceTimeout={500}
                onChange={(event) => {
                  handleSearchedValue(event.target.value);
                }}
                value={searchedValue}
                disabled={disable}
              />
            </div>
            <button
              className="dropdown-btn"
              onClick={toggleDropdown}
              disabled={disable}
            >
              <i className={`dropdown-icon icon icon-arrow`} />
            </button>
          </div>
          <ul className="dropdown-list">
            {!!dropdownList?.length ? (
              dropdownList.map((value) => (
                <li
                  className={`dropdown-item ${
                    value.dealerId ===
                      dealersDropdownItems.find(
                        (item) => item.dealerId === value.dealerId
                      )?.dealerId && "isActive"
                  }`}
                  onClick={() => handleDropdownItems(value)}
                  key={value.dealerId}
                >
                  {value.dealerName}({value.user.firstName}{" "}
                  {value.user.lastName})
                </li>
              ))
            ) : (
              <li className="dropdown-item">
                <span className="dropdown-no-result">No result found.</span>
              </li>
            )}
          </ul>
        </div>
      </div>
      {subLabel && (error || errors?.[name]?.message) && (
        <label
          htmlFor={id}
          className={`form-sublabel ${
            (error || !!errors?.[name]?.message) && "form-input-error"
          }`}
        >
          <>{subLabel || errors?.[name]?.message}</>
        </label>
      )}
    </>
  );
};
