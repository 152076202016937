import { FC, useEffect, useState } from "react";
import { Button } from "@components/Button";
import { LoadField } from "@components/LoadField/index";
import { IBulkAddUser } from "@models/components/main/bulkadduser";
import {
  downloadBulkTemplate,
  getBulkReport,
  sendBulkTemplateToEmail,
} from "@api/bulk";
import fileDownload from "js-file-download";
import {
  DOWNLOAD_REPORT_NOTIFICATION,
  DOWNLOAD_TEMPLATE_NOTIFICATION,
  SENT_TEMPLATE_NOTIFICATION,
  SOMETHING_WENT_WRONG_NOTIFICATION,
} from "@constants/commons";
import { useNotification } from "@hooks/common/useNotification";
import { Notification } from "@components/Notification";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { eventUsersTableHeaders } from "@constants/headers";
import {
  getUserGender,
  hideWindowScroll,
  showWindowScroll,
} from "@services/common";
import { IEndUser } from "@models/common/endUsers";
import { IManageEndUserInfo } from "@models/components/secondary/manualadduser";
import { UserGender } from "@models/common/user";
import { ManageEndUserModal } from "@pages/Modals/Users/ManageEndUserModal";
import { AlertModal } from "@components/AlertModal";
import {
  DELETE_USER_TITLE,
  DELETE_USER_EVENT_BODY,
  EDIT_END_USER_TITLE,
  ADD_END_USER_TITLE,
} from "@constants/users";
import { useParams } from "react-router-dom";
import { handleUpdateEventParticipant } from "@api/common";
import { getFormattedError } from "@services/http";

export const BulkAddUser: FC<IBulkAddUser> = ({
  bulkStatus,
  handleBulkUploadStatus,
  isContinue,
  handleEventParticipants,
  participants,
  handleUserEventDelete,
  handleLoadedFile,
  handleUserData,
}) => {
  const [bulkId, setBulkId] = useState<string>();
  const [eventParticipants, setEventParticipants] = useState<IEndUser[]>();
  const [openAddUserModal, setAddUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [userInfo, setUserInfo] = useState<IManageEndUserInfo | undefined>();
  const [participantManaged, setParticipantManaged] = useState(false);
  const [currentParticipantId, setCurrentParticipantId] = useState("");
  const [deleteWindowOpen, setDeleteWindowOpen] = useState(false);

  const dispatch = useAppDispatch();
  const { eventID } = useParams();

  const {
    isActive,
    message: notificationMessage,
    type: notificationType,
    showNotification,
    closeNotification,
  } = useNotification();

  const handleBulkId = (bulkId: string) => {
    setBulkId(bulkId);
  };

  const handleDownloadTemplate = async () => {
    try {
      const file = await downloadBulkTemplate(dispatch);
      fileDownload(file, "bulk_template.xlsx");
      showNotification(DOWNLOAD_TEMPLATE_NOTIFICATION, "success");
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    }
  };

  const handleDownloadReport = async (
    status: "error" | "warning",
    bulkId: string
  ) => {
    try {
      const file = await getBulkReport(dispatch, bulkId);
      fileDownload(file, `bulk_${status}_report.xlsx`);
      showNotification(DOWNLOAD_REPORT_NOTIFICATION, "success");
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    }
  };

  const handleSendTemplate = async () => {
    try {
      await sendBulkTemplateToEmail(dispatch);
      showNotification(SENT_TEMPLATE_NOTIFICATION, "success");
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    }
  };

  const handleManaged = (state: boolean) => {
    setParticipantManaged(state);
  };

  const handleDeleteWindowOpen = (userId?: string) => {
    setCurrentParticipantId(userId || "");
    setDeleteWindowOpen(!deleteWindowOpen);
  };

  const handleAddUserModalOpen = () => {
    participantManaged && setParticipantManaged(false);
    setAddUserModal(!openAddUserModal);
    if (openAddUserModal) {
      setUserInfo(undefined);
    }
  };

  const handleEditUserModalOpen = () => {
    participantManaged && setParticipantManaged(false);
    setOpenEditUserModal(!openEditUserModal);
    if (openEditUserModal) {
      setUserInfo(undefined);
    }
  };

  const handleUserEventEdit = (eventParticipant: IEndUser) => {
    setUserInfo({
      userId: eventParticipant.endUserId,
      firstName: eventParticipant.user.firstName,
      lastName: eventParticipant.user.lastName,
      email: eventParticipant.user.email,
      phone_number: eventParticipant.user.phoneNumber,
      badge: eventParticipant.badgeNumber,
      gender: eventParticipant.gender as UserGender,
      anonymous: eventParticipant.anonymous,
    });

    handleEditUserModalOpen();
  };

  const handleParticipantsDelete = (userId?: string) => {
    handleUserEventDelete(userId);
  };

  useEffect(() => {
    setEventParticipants(participants);
    if (bulkStatus !== "idle" && !participants?.length) {
      handleBulkUploadStatus("idle");
    }
  }, [participants]);

  useEffect(() => {
    if (openAddUserModal || openEditUserModal) {
      showWindowScroll();
    } else {
      hideWindowScroll();
    }
  }, [openAddUserModal, openEditUserModal]);

  return (
    <>
      <Notification
        type={notificationType}
        message={notificationMessage}
        isActive={isActive}
        closeNotification={closeNotification}
      />
      {deleteWindowOpen && (
        <AlertModal
          type="delete"
          title={DELETE_USER_TITLE}
          body={DELETE_USER_EVENT_BODY}
          handleOpen={handleDeleteWindowOpen}
          handleConfirm={handleParticipantsDelete}
          id={currentParticipantId}
        />
      )}
      {(openAddUserModal || openEditUserModal) && (
        <ManageEndUserModal
          title={userInfo ? EDIT_END_USER_TITLE : ADD_END_USER_TITLE}
          handleModalOpen={
            userInfo ? handleEditUserModalOpen : handleAddUserModalOpen
          }
          userInfo={userInfo}
          handleUser={handleUpdateEventParticipant}
          eventID={eventID}
          handleManaged={handleManaged}
          handleEditUserModalOpen={handleEditUserModalOpen}
          handleAddUserModalOpen={handleAddUserModalOpen}
          handleUserData={handleUserData}
        />
      )}
      <div className="form">
        {!eventParticipants && (
          <span className="load-form-label">
            Upload a list with end-users in xlsx format
            {bulkStatus === "idle" && (
              <>
                <br />
                Bulk template max size: 20 Mb
              </>
            )}
          </span>
        )}
        {eventParticipants ? (
          <>
            <Button
              text="Add user"
              icon="load-icon icon-add-user"
              className="load-btn btn-primary"
              onClick={handleAddUserModalOpen}
            />
            {!!eventParticipants.length && (
              <div className="event-table-user">
                <table
                  className="event-table"
                  cellSpacing={0}
                  cellPadding={0}
                  border={0}
                >
                  <thead className="event-thead">
                    <tr className="event-tr">
                      {eventUsersTableHeaders.map((header, index) => (
                        <th className="event-th" key={index}>
                          {header.name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="event-tbody">
                    {eventParticipants.map((eventParticipant) => (
                      <tr className="event-tr" key={eventParticipant.endUserId}>
                        <td className="event-td" data-label="Name">
                          {eventParticipant.user.firstName}{" "}
                          {eventParticipant.user.lastName}
                        </td>
                        <td className="event-td" data-label="Email">
                          {eventParticipant.user.email}
                        </td>
                        <td className="event-td" data-label="Phone number">
                          {eventParticipant.user.phoneNumber}
                        </td>
                        <td className="event-td" data-label="Badge number">
                          {eventParticipant.badgeNumber}
                        </td>
                        <td className="event-td" data-label="Gender">
                          {getUserGender(eventParticipant.gender)}
                        </td>
                        <td className="event-td" data-label="">
                          <button className="event-table-controls">
                            <i
                              className="table-controls-icon icon icon-edit"
                              onClick={() =>
                                handleUserEventEdit(eventParticipant)
                              }
                            />
                          </button>
                          <button className="event-table-controls">
                            <i
                              className="table-controls-icon icon icon-delete"
                              onClick={() => {
                                handleDeleteWindowOpen(
                                  eventParticipant.endUserId
                                );
                              }}
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </>
        ) : (
          <LoadField
            bulkStatus={bulkStatus}
            handleBulkUploadStatus={handleBulkUploadStatus}
            handleEventParticipants={handleEventParticipants}
            handleBulkId={handleBulkId}
            isContinue={isContinue}
            handleLoadedFile={handleLoadedFile}
          />
        )}
        {!eventParticipants && (
          <div className="load-controls">
            {bulkStatus === "error" && bulkId ? (
              <Button
                text="Download error report"
                icon="load-icon icon-download"
                className="load-btn btn-primary"
                onClick={() => handleDownloadReport("error", bulkId)}
              />
            ) : bulkStatus === "warning" && bulkId ? (
              <Button
                text="Download warning report"
                icon="load-icon icon-download"
                className="load-btn btn-primary"
                onClick={() => handleDownloadReport("warning", bulkId)}
              />
            ) : (
              bulkStatus === "idle" && (
                <>
                  <Button
                    text="Download template"
                    icon="load-icon icon-download"
                    className="load-btn btn-primary"
                    onClick={handleDownloadTemplate}
                  />
                  <Button
                    text="Send template to email"
                    icon="load-icon icon-send"
                    className="load-btn btn-primary"
                    onClick={handleSendTemplate}
                  />
                </>
              )
            )}
          </div>
        )}
      </div>
    </>
  );
};
