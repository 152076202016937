import { useState, useEffect, useCallback } from "react";

export type AsyncStatus = "idle" | "pending" | "success" | "error" | "warning";

export const useAsync = <T, E = string>(
  asyncFunction: () => Promise<T>,
  warningDeps: { [dep: string]: string },
  immediate = true
) => {
  const [status, setStatus] = useState<AsyncStatus>("idle");
  const [value, setValue] = useState<T | null>(null);
  const [error, setError] = useState<E | null>(null);

  const execute = useCallback(async () => {
    setStatus("pending");
    setValue(null);
    setError(null);
    try {
      const response = await asyncFunction();
      setValue(response);
      const isWarning =
        !!Object.keys(warningDeps)?.length &&
        Object.keys(warningDeps).every(
          (dep) =>
            (response as unknown as { [dep: string]: string })?.[dep] ===
            warningDeps[dep]
        );

      if (isWarning) {
        setStatus("warning");
      } else {
        setStatus("success");
      }

      return response;
    } catch (error) {
      setError(error as E);
      setStatus("error");
    }
  }, [asyncFunction]);

  useEffect(() => {
    if (immediate && status === "idle") {
      execute();
    }
  }, [execute, immediate, status]);

  const reset = () => {
    setStatus("idle");
    setValue(null);
    setError(null);
  };

  return { execute, status, value, error, reset };
};
