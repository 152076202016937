import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { IMeasurementInput } from "@models/components/secondary/measurementinput";
import { Input } from "@components/Input";

export const MeasurementInput: FC<IMeasurementInput> = ({
  isDuplicate,
  ...props
}) => {
  const { watch } = useFormContext();
  const fieldValue = watch(props.name) || "";

  return (
    <Input
      disable={isDuplicate}
      shouldRegister={!isDuplicate}
      value={isDuplicate ? fieldValue : undefined}
      {...props}
    />
  );
};
