export const ALL_STATUS = "All";
export const COMPLETED_STATUS = "Completed";
export const CANCELED_STATUS = "Canceled";
export const SCHEDULED_STATUS = "Scheduled";
export const IN_PROGRESS_STATUS = "In Progress";

export const MEASUREMENT_REQUIRED_STATUS = "Measurement required";
export const MEASURED_STATUS = "Measured";

export const COMPLETED_STATUS_REQUEST = "COMPLETED";
export const CANCELED_STATUS_REQUEST = "CANCELED";
export const SCHEDULED_STATUS_REQUEST = "SCHEDULED";
export const IN_PROGRESS_STATUS_REQUEST = "IN_PROGRESS";

export const ALL_LOCATIONS_EVENT_TYPE = "ALL";
export const IN_PERSON_EVENT_TYPE = "IN_PERSON";
export const REMOTE_EVENT_TYPE = "REMOTE";

export const ADD_EVENT = "Add new event";
export const ADD_EVENT_PARTICIPANTS = "Add new event participants";
export const EDIT_EVENT = "Edit event";

export const PRODUCT_TYPE_TITLE = "Product type";

export const MEASUREMENT_REQUIRED = "MEASUREMENT_REQUIRED";
export const MEASURED = "MEASURED";

export const CANCEL_EVENT_TITLE = "Cancel Event";
export const DELETE_EVENT_TITLE = "Delete Event";
export const CANCEL_ADDING_EVENT_TITLE = "Cancel Adding Event";
export const CANCEL_EDITING_EVENT_TITLE = "Cancel Editing Event";

export const CANCEL_EVENT_BODY = "Are you sure you want to cancel this event?";
export const CANCEL_ADDING_EVENT_BODY =
  "Are you sure you want to cancel adding event?";
export const CANCEL_EDITING_EVENT_BODY =
  "Are you sure you want to cancel editing event?";
export const DELETE_EVENT_BODY =
  "Are you sure you want to cancel and delete this event?";
export const INTERRUPT_EVENT_TITLE = "Interrupt Event";
export const INTERRUPT_EVENT_BODY =
  "Are you sure you want to interrupt the process?";
export const PAST_START_DATE_EVENT_TITLE = "Set start date";
export const PAST_END_DATE_EVENT_TITLE = "Set end date";
export const PAST_DATE_EVENT_BODY =
  "Do you want to set past date for the Event?";
export const SEND_INVITATION_EVENT_TITLE = "Send Invitation";
export const SEND_INVITATION_EVENT_BODY =
  "Do you want to send invitation for this event to end-user(s)?";

export const WARNING_MESSAGE_BULK =
  "Warning! The data for some users differs from data in system and system data will be used instead of data from Bulk file. You can download the report with warnings to view the difference and edit the users manually";
