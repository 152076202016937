import { FC, useEffect, useState } from "react";
import { TableHeader } from "@components/TableHeader/TableHeader";
import { dealerTableHeaders } from "@constants/headers";
import {
  formateCapitalizeStr,
  hideWindowScroll,
  showWindowScroll,
} from "@services/common";
import { StatusBar } from "@components/StatusBar";
import { DropdownMenu } from "@components/Dropdown/DropdownMenu";
import {
  ACCEPTED_USER_STATUS,
  DELETED_USER_STATUS,
  DELETE_USER_NOTIFICATION,
  PENDING_USER_STATUS,
  SOMETHING_WENT_WRONG_NOTIFICATION,
} from "@constants/commons";
import { UserStatus } from "@models/common/user";
import { IManageUserInfo } from "@models/components/secondary/manualadduser";
import { DEALER_INFO_ROUTE, MAIN_ROUTE } from "@constants/routes";
import { IDealerTable } from "@models/components/main/dealertable";
import { deleteDealer, getDealerById, resendInviteDealer } from "@api/dealers";
import { ManageDealerModal } from "@pages/Modals/Users/ManageDealerModal";
import {
  DELETE_USER_BODY,
  DELETE_USER_TITLE,
  EDIT_DEALER_TITLE,
} from "@constants/users";
import { AlertModal } from "@components/AlertModal";
import { useNotification } from "@hooks/common/useNotification";
import { Notification } from "@components/Notification";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { useLocation, useNavigate } from "react-router-dom";
import { handleDealerUpdate } from "@api/common";
import { getFormattedError } from "@services/http";

export const DealerTable: FC<IDealerTable> = ({
  dealer,
  handleSorting,
  sort,
  handleModalOpen: handleEditModalOpen,
  handleDeleteModal,
  deleteWindowOpen,
  handleManaged,
}) => {
  const [userInfo, setUserInfo] = useState<IManageUserInfo | undefined>();
  const [openDealerModal, setOpenDealerModal] = useState<boolean>(false);
  const [isOpened, setOpened] = useState<boolean>(false);
  const [dealerId, setDealerId] = useState("");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    isActive,
    message: notificationMessage,
    type: notificationType,
    showNotification,
    closeNotification,
  } = useNotification();

  const toggleDropdown = (dealerId: string) => {
    setOpened(!isOpened);
    setDealerId(dealerId);
  };

  useEffect(() => {
    if (!openDealerModal) {
      setDealerId("");
    }
  }, [openDealerModal]);

  const handleModalOpen = () => {
    setOpenDealerModal(!openDealerModal);
    if (openDealerModal) {
      setUserInfo(undefined);
    }
    handleEditModalOpen();
  };

  const handleDropdownMenu = (dropdownState: boolean) => {
    setOpened(dropdownState);
  };

  const handleDealerEdit = async (dealerId: string) => {
    try {
      const dealer = await getDealerById(dispatch, dealerId);
      setUserInfo({
        dealerId: dealer.dealerId,
        email: dealer?.user.email || "",
        phone_number: dealer?.user.phoneNumber || "",
        firstName: dealer?.user.firstName || "",
        lastName: dealer?.user.lastName || "",
        parentsInfo: dealer?.parents,
        salesRep: dealer?.salesRep,
        dealerName: dealer?.dealerName,
      });
      handleModalOpen();
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    }
  };

  const handleDealerResendInvite = async (dealerId: string) => {
    try {
      await resendInviteDealer(dispatch, dealerId);
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    } finally {
      setOpened(false);
    }
  };

  const handleDeleteWindowOpen = () => {
    handleDeleteModal();
    setOpened(false);
  };

  const handleDealerDelete = async (dealerId: string) => {
    if (dealer?.length === 1) {
      try {
        await deleteDealer(dispatch, dealerId);
        showNotification(DELETE_USER_NOTIFICATION, "success");
        handleManaged(true);
        navigate(
          `/${location.pathname.split(MAIN_ROUTE)[1]}/${
            typeof +location.pathname.split(MAIN_ROUTE)[2] === "number" &&
            +location.pathname.split(MAIN_ROUTE)[2] - 1 > 0
              ? +location.pathname.split(MAIN_ROUTE)[2] - 1
              : 1
          }${location.search}`
        );
      } catch (error) {
        showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
        return Promise.reject(getFormattedError(error));
      } finally {
        setOpened(false);
      }
    } else {
      try {
        await deleteDealer(dispatch, dealerId);
        showNotification(DELETE_USER_NOTIFICATION, "success");
        handleManaged(true);
      } catch (error) {
        showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
        return Promise.reject(getFormattedError(error));
      } finally {
        setOpened(false);
      }
    }
  };

  const dealerTableDropdownItems = (
    dealerId: string,
    dealerStatus: UserStatus
  ) =>
    [
      {
        text: "Edit personal information",
        onClick: async () => {
          await handleDealerEdit(dealerId).finally(() =>
            handleDropdownMenu(false)
          );
        },
      },
      dealerStatus === PENDING_USER_STATUS
        ? {
            text: "Resend invite",
            onClick: async () => {
              await handleDealerResendInvite(dealerId).finally(() =>
                handleDropdownMenu(false)
              );
            },
          }
        : null,
      dealerStatus === PENDING_USER_STATUS ||
      dealerStatus === ACCEPTED_USER_STATUS
        ? {
            text: "Delete",
            onClick: () => {
              handleDeleteWindowOpen();
            },
          }
        : null,
    ].filter((item) => !!item);

  useEffect(() => {
    if (openDealerModal && dealerId) {
      showWindowScroll();
    } else {
      hideWindowScroll();
    }
  }, [openDealerModal, dealerId]);

  return (
    <>
      <Notification
        type={notificationType}
        message={notificationMessage}
        isActive={isActive}
        closeNotification={closeNotification}
      />
      {deleteWindowOpen && (
        <AlertModal
          type="delete"
          title={DELETE_USER_TITLE}
          body={DELETE_USER_BODY}
          handleOpen={handleDeleteWindowOpen}
          handleConfirm={handleDealerDelete}
          id={dealerId}
        />
      )}
      {openDealerModal && dealerId && (
        <ManageDealerModal
          title={EDIT_DEALER_TITLE}
          handleModalOpen={handleModalOpen}
          userInfo={userInfo}
          handleUser={handleDealerUpdate}
        />
      )}
      <table className="event-table" cellSpacing={0} cellPadding={0} border={0}>
        <TableHeader
          sort={sort}
          headers={dealerTableHeaders}
          handleSorting={handleSorting}
        />
        <tbody className="event-tbody">
          {dealer.map((d) => (
            <tr className="event-tr" key={d.dealerId}>
              <td
                className="event-td cursor-pointer event-link"
                data-label="Dealer name"
              >
                <a
                  className="header-link"
                  href={`${DEALER_INFO_ROUTE}/${d.dealerId}/1`}
                >
                  {d.dealerName}
                </a>
              </td>
              <td className="event-td" data-label="Person of contact">
                {d.user.firstName + " " + d.user.lastName}
              </td>
              <td className="event-td" data-label="Email">
                {d.user.email}
              </td>
              <td className="event-td" data-label="Phone Number">
                {d.user.phoneNumber}
              </td>
              <td className="event-td" data-label="System Status">
                <span
                  className={`status ${
                    d.user.status === ACCEPTED_USER_STATUS
                      ? "status-success"
                      : d.user.status === DELETED_USER_STATUS
                      ? "status-danger"
                      : d.user.status === PENDING_USER_STATUS
                      ? "status-warning"
                      : ""
                  }`}
                >
                  {formateCapitalizeStr(d.user.status)}
                </span>
              </td>
              <td className="event-td" data-label="Event's completeness">
                <div className="event-progress">
                  <StatusBar
                    value={
                      d.assignedEventsTotal > 0
                        ? Math.ceil(
                            (d.assignedEventsFinished / d.assignedEventsTotal) *
                              100
                          )
                        : 0
                    }
                  />
                  {d.assignedEventsFinished}/{d.assignedEventsTotal}
                </div>
              </td>
              <td className="event-td" data-label="Parent">
                {d.parents
                  .map((parent) => `${parent.firstName} ${parent.lastName}`)
                  .join(", ")}
              </td>
              <td className="event-td" data-label="Actions">
                {!!dealerTableDropdownItems(d.dealerId, d.user.status)
                  ?.length && (
                  <div className="header-dropdown">
                    <button
                      className={`header-dropdown-name ${
                        isOpened && "isActive"
                      } event-table-btn`}
                      onClick={() => toggleDropdown(d.dealerId)}
                    >
                      <i className="event-table-icon icon icon-kebab" />
                    </button>
                    {isOpened && dealerId === d.dealerId && (
                      <DropdownMenu
                        dropdownItems={dealerTableDropdownItems(
                          d.dealerId,
                          d.user.status
                        )}
                        dropdownOpened={isOpened}
                        setDropdownOpened={handleDropdownMenu}
                      />
                    )}
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
