import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser, UserState } from "@models/common/user";

const initialState: UserState = {
  user: {
    type: "",
    payload: {
      username: "",
      verified: false,
    },
  },
};

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<IUser>) => {
      state.user.payload = payload;
    },
    clearUser: (state) => {
      state.user.payload = {
        username: "",
        verified: false,
      };
    },
  },
});

export default userInfoSlice.reducer;
