import {
  SIGN_IN_PHONE_CODE,
  SIGN_IN_EMAIL_CODE,
  SIGN_IN_PASSWORD,
  SIGN_IN_SUBMIT_CODE,
  SIGN_IN_NEW_PASSWORD,
  SIGN_IN_FORGOT_PASSWORD,
  SIGN_UP_AUTH_FLOW,
  FORGOT_PASSWORD_AUTH_FLOW,
  SIGN_IN_AUTH_FLOW,
  MIN_PHONE_NUMBER_LENGTH,
} from "@constants/commons";
import {
  FORGOT_PASSWORD_ERROR,
  SIGN_IN_ERROR,
  SIGN_UP_ERROR,
} from "@constants/errors";
import { ICredentials } from "@models/auth/data";
import { AuthFlowType } from "@models/common/app";
import { IUser } from "@models/common/user";
import { selectCognitoUser } from "@store/selectors/cognitoUser";
import { AppDispatch, RootState } from "@store/store";
import { TypedUseSelectorHook } from "react-redux";
import { Amplify, Auth } from "aws-amplify";
import { userInfoSlice } from "@store/reducers/UserSlice";
import { tokensInfoSlice } from "@store/reducers/TokensSlice";

const getTokensInfoFromLocalStorage = () => {
  try {
    return JSON.parse(
      localStorage.getItem(`${process.env.REACT_APP_TOKENS_STATE}`) || "{}"
    );
  } catch (err) {
    return null;
  }
};
const getUserPoolWebClientId = () => {
  const tokensInfo = getTokensInfoFromLocalStorage();

  const isUserLoggedInWithPhoneCode =
    tokensInfo?.accessToken?.payload?.client_id ===
    process.env.REACT_APP_PHONE_USER_POOL_WEB_CLIENT_ID;
  return isUserLoggedInWithPhoneCode
    ? process.env.REACT_APP_PHONE_USER_POOL_WEB_CLIENT_ID
    : process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID;
};

export const configureAmplify = async () => {
  Amplify.configure({
    Auth: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: getUserPoolWebClientId(),
      phoneUserPoolWebClientId:
        process.env.REACT_APP_PHONE_USER_POOL_WEB_CLIENT_ID,
    },
    API: {
      graphql_headers: async () => {
        const session = await Auth.currentSession();
        return {
          Authorization: session.getIdToken().getJwtToken(),
        };
      },
    },
  });
};

export const getLoginCredentials = (
  credentials: ICredentials,
  userInfo: IUser | undefined
) => {
  const username: string =
    credentials?.email ||
    (credentials?.phone_number &&
    credentials?.phone_number?.length > MIN_PHONE_NUMBER_LENGTH
      ? credentials.phone_number
      : userInfo?.username
      ? userInfo?.username
      : "");

  const code = credentials?.code || "";
  const password = credentials?.password || "";

  return { username, code, password };
};

export const getLoginType = (credentials: ICredentials) => {
  if (credentials?.code) {
    return SIGN_IN_SUBMIT_CODE;
  }
  if (
    (credentials?.email || credentials?.phone_number) &&
    credentials?.password &&
    credentials?.confirmTerms === undefined
  ) {
    return SIGN_IN_PASSWORD;
  }
  if (
    credentials?.phone_number &&
    !credentials?.email &&
    !credentials?.password &&
    !credentials?.code
  ) {
    return SIGN_IN_PHONE_CODE;
  }
  if (
    credentials?.email &&
    !credentials?.phone_number &&
    !credentials?.password &&
    !credentials?.code
  ) {
    return SIGN_IN_EMAIL_CODE;
  }
  if (
    (credentials?.email || credentials?.phone_number) &&
    credentials?.password &&
    credentials?.confirmTerms === true
  ) {
    return SIGN_IN_NEW_PASSWORD;
  }
  if (
    (credentials?.email || credentials?.phone_number) &&
    credentials?.password &&
    credentials?.confirmTerms === false
  ) {
    return SIGN_IN_FORGOT_PASSWORD;
  }
  return "";
};

export const getAuthFlowType = (confirmTerms: boolean | undefined) => {
  if (confirmTerms === true) {
    return SIGN_UP_AUTH_FLOW;
  } else if (confirmTerms === false) {
    return FORGOT_PASSWORD_AUTH_FLOW;
  } else {
    return SIGN_IN_AUTH_FLOW;
  }
};

export const getAuthErrorType = (authFlow: AuthFlowType) => {
  return authFlow === FORGOT_PASSWORD_AUTH_FLOW
    ? FORGOT_PASSWORD_ERROR
    : authFlow === SIGN_UP_AUTH_FLOW
    ? SIGN_UP_ERROR
    : SIGN_IN_ERROR;
};

export const cognitoUserCodeExpiresAt = (
  selector: TypedUseSelectorHook<RootState>
) =>
  Math.floor(
    (selector(selectCognitoUser)?.challengeParam?.expiresAt || 0) -
      Date.now() / 1000
  );

export const cognitoUserCodeExpiresAtMs = (
  selector: TypedUseSelectorHook<RootState>
) => Number(selector(selectCognitoUser)?.challengeParam?.expiresAt) || 0;

export const handleLogout = async (dispatch: AppDispatch) => {
  dispatch(userInfoSlice.actions.clearUser());
  dispatch(tokensInfoSlice.actions.clearTokens());
  localStorage.removeItem(`${process.env.REACT_APP_TOKENS_STATE}`);
  await Auth.signOut();
};

export const isAuthError = (error: string) => error?.includes("401");
