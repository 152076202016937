import { FC } from "react";
import { IBreadcrumbs } from "@models/components/secondary/breadcrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ADD_EVENT_ROUTE,
  DEALERS_LIST_ROUTE,
  EDIT_EVENT_ROUTE,
  END_USER_LIST_ROUTE,
  END_USER_MEASUREMENTS_ROUTE,
  EVENTS_LIST_ROUTE,
  PARENTS_LIST_ROUTE,
  SALES_LIST_ROUTE,
} from "@constants/routes";
import {
  DEALERS_BREADCRUMBS,
  END_USERS_BREADCRUMBS,
  END_USER_MEASUREMENTS_BREADCRUMBS,
  EVENTS_BREADCRUMBS,
  MEASUREMENTS_BREADCRUMBS,
  MY_END_USER_MEASUREMENTS_BREADCRUMBS,
  PARENTS_BREADCRUMBS,
  SALES_REPS_BREADCRUMBS,
} from "@constants/commons";

export const Breadcrumbs: FC<IBreadcrumbs> = ({
  title,
  type,
  handleInterruptRoute,
  handleClarifyWindow,
  additionalData,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const goToEventsList = () => navigate(`${EVENTS_LIST_ROUTE}/1`);
  const goToSalesRepList = () => navigate(`${SALES_LIST_ROUTE}/1`);
  const goToDealersList = () => navigate(`${DEALERS_LIST_ROUTE}/1`);
  const goToParentsList = () => navigate(`${PARENTS_LIST_ROUTE}/1`);
  const goToMyInformation = () => navigate(`${END_USER_MEASUREMENTS_ROUTE}/1`);
  const goToEndUsersList = () => navigate(`${END_USER_LIST_ROUTE}/1`);

  const redirectRoute =
    type === EVENTS_BREADCRUMBS
      ? goToEventsList
      : type === SALES_REPS_BREADCRUMBS
      ? goToSalesRepList
      : type === DEALERS_BREADCRUMBS
      ? goToDealersList
      : type === PARENTS_BREADCRUMBS
      ? goToParentsList
      : type === MEASUREMENTS_BREADCRUMBS
      ? goToEventsList
      : type === MY_END_USER_MEASUREMENTS_BREADCRUMBS
      ? goToMyInformation
      : type === END_USERS_BREADCRUMBS
      ? goToEndUsersList
      : undefined;

  const breadcrumbOnClick = (count?: "first" | "second") => {
    if (
      !location.pathname.includes(ADD_EVENT_ROUTE) &&
      !location.pathname.includes(EDIT_EVENT_ROUTE)
    ) {
      if (count && type === END_USER_MEASUREMENTS_BREADCRUMBS) {
        if (count === "first") {
          additionalData && additionalData.firstBreadcrumb.onClick();
        }
        if (count === "second") {
          additionalData && additionalData.secondBreadcrumb.onClick();
        }
      } else {
        redirectRoute && redirectRoute();
      }
    } else {
      handleInterruptRoute && handleInterruptRoute(`${EVENTS_LIST_ROUTE}/1`);
      handleClarifyWindow && handleClarifyWindow();
    }
  };

  return (
    <ul className="breadcrumbs">
      {additionalData && (
        <li className="breadcrumb" onClick={() => breadcrumbOnClick("first")}>
          {additionalData.firstBreadcrumb.title}
        </li>
      )}
      <li
        className="breadcrumb"
        onClick={() =>
          additionalData ? breadcrumbOnClick("second") : breadcrumbOnClick()
        }
      >
        {type === EVENTS_BREADCRUMBS || type === MEASUREMENTS_BREADCRUMBS
          ? "Events"
          : type === SALES_REPS_BREADCRUMBS
          ? "Sales representatives"
          : type === DEALERS_BREADCRUMBS
          ? "Dealers"
          : type === PARENTS_BREADCRUMBS
          ? "Parents"
          : type === END_USER_MEASUREMENTS_BREADCRUMBS
          ? additionalData?.secondBreadcrumb.title || ""
          : type === MY_END_USER_MEASUREMENTS_BREADCRUMBS
          ? "My information"
          : type === END_USERS_BREADCRUMBS
          ? "End users"
          : ""}
      </li>
      <li className="breadcrumb isActive">{title}</li>
    </ul>
  );
};
