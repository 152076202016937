import { useEffect, useState } from "react";
import { Header } from "@components/Header";
import { Pagination } from "@components/Pagination";
import { IProductTypes } from "@models/common/events";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/Loader";
import {
  NO_FOUND_PLUG_TYPE,
  PRODUCT_TYPES_SEARCH_PLACEHOLDER,
  SEARCH_QUERY_KEY,
  TABLE_ROWS_LIMIT,
} from "@constants/commons";
import { HeaderBarTable } from "@components/HeaderBarTable";
import { Plug } from "@components/Plug";
import {
  NO_FOUND_PLUG_ERROR,
  NO_PRODUCT_TYPES_PLUG_ERROR,
} from "@constants/errors";
import { ISortType } from "@models/common/app";
import {
  getPage,
  getSortTypes,
  hideWindowScroll,
  showWindowScroll,
} from "@services/common";
import queryString from "query-string";
import { listOfFilteredProductTypes } from "@api/types";
import { ProductTypesTable } from "@components/ProductTypes/ProductTypesTable";
import { ManageProductTypeModal } from "@pages/Modals/ProductTypes";
import {
  ADD_PRODUCT_TYPE_TITLE,
  MEASUREMENTS_TYPES_TITLE,
} from "@constants/users";
import { ViewMeasurementsModal } from "@pages/Modals/Measurements/ViewMeasurementsModal";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { MiniLoader } from "@components/Loader/MiniLoader";
import { MAIN_ROUTE } from "@constants/routes";
import { handleProductTypesUpdate } from "@api/common";
import { getFormattedError } from "@services/http";

export const ProductTypes = () => {
  const location = useLocation();
  const parsedSearchedQuery = queryString.parse(location.search)?.[
    SEARCH_QUERY_KEY
  ];

  const [productTypes, setProductTypes] = useState<IProductTypes | undefined>();
  const [searchedValue, setSearchedValue] = useState<string | undefined>(
    typeof parsedSearchedQuery === "string" ? parsedSearchedQuery : undefined
  );
  const [sortType, setSortType] = useState<ISortType>();
  const [searchParams, setSearchParams] = useState<
    queryString.ParsedQuery<string> | undefined
  >(queryString.parse(location.search));
  const [productTypesWindowOpen, setProductTypesWindowOpen] = useState(false);
  const [measurementsWindowOpen, setMeasurementsWindowOpen] = useState(false);
  const [searchFieldFocused, setSearchFieldFocused] = useState(false);
  const [managed, setManaged] = useState(false);

  const { page } = useParams();
  const pageId = getPage(page);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSearchFieldFocus = () => {
    setSearchFieldFocused(true);
  };

  const addBtnHandler = () => {
    setProductTypesWindowOpen(!productTypesWindowOpen);
  };

  const measurementTypesHandler = () => {
    setMeasurementsWindowOpen(!measurementsWindowOpen);
  };

  const handleSetListProductTypes = (data: IProductTypes) => {
    setProductTypes(data);
  };

  const handleSearchedProductTypes = (searchedValue: string | undefined) => {
    setSearchedValue(searchedValue);
  };

  const handleSorting = (parameter: string) => {
    let filteredParameter;

    if (parameter === "Product type") {
      filteredParameter = "name";
    }

    setSortType(getSortTypes(sortType, filteredParameter, parameter));
  };

  const handleModalProductTypeCreate = () => {
    setProductTypesWindowOpen(!productTypesWindowOpen);
  };

  const handleManaged = (state: boolean) => {
    setManaged(state);
  };

  useEffect(() => {
    if (searchFieldFocused) {
      if (!!searchedValue) {
        if (pageId + 1 === 1) {
          navigate(`?${SEARCH_QUERY_KEY}=${searchedValue}`);
        } else {
          navigate(
            `/${
              location.pathname.split(MAIN_ROUTE)[1]
            }/1?${SEARCH_QUERY_KEY}=${searchedValue}`
          );
        }
      } else {
        navigate("");
      }
    }
  }, [searchedValue]);

  useEffect(() => {
    const searchQuery = queryString.parse(location.search);
    if (!!Object.keys(searchQuery).length) {
      setSearchParams(searchQuery);
    } else {
      setSearchParams({});
    }
  }, [location.search]);

  useEffect(() => {
    if (!!searchParams?.[SEARCH_QUERY_KEY]) {
      handleSearchedProductTypes(searchParams[SEARCH_QUERY_KEY].toString());
    }
  }, [searchParams?.[SEARCH_QUERY_KEY]]);

  useEffect(() => {
    if (
      !productTypesWindowOpen &&
      (searchedValue
        ? searchParams?.[SEARCH_QUERY_KEY]
        : !searchParams?.[SEARCH_QUERY_KEY])
    ) {
      (async function asyncWrapper() {
        try {
          const types = await listOfFilteredProductTypes(
            dispatch,
            pageId,
            TABLE_ROWS_LIMIT,
            sortType ? sortType.filteredParameter : "name,asc",
            searchedValue
          );
          handleSetListProductTypes(types);
          if (managed) handleManaged(false);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [
    pageId,
    sortType,
    productTypesWindowOpen,
    searchParams?.[SEARCH_QUERY_KEY],
    managed,
  ]);

  useEffect(() => {
    if (productTypesWindowOpen || measurementsWindowOpen) {
      showWindowScroll();
    } else {
      hideWindowScroll();
    }
  }, [productTypesWindowOpen, measurementsWindowOpen]);

  return (
    <>
      <Header />
      {measurementsWindowOpen && (
        <ViewMeasurementsModal
          title={MEASUREMENTS_TYPES_TITLE}
          handleModalOpen={measurementTypesHandler}
        />
      )}
      {productTypesWindowOpen && (
        <ManageProductTypeModal
          title={ADD_PRODUCT_TYPE_TITLE}
          handleModalOpen={handleModalProductTypeCreate}
          handleProductTypes={handleProductTypesUpdate}
          productTypeInfo={undefined}
          handleManaged={handleManaged}
        />
      )}
      {productTypes ? (
        <main className="main-container">
          <HeaderBarTable
            searchedValue={searchedValue}
            handleSearchedValues={handleSearchedProductTypes}
            title="Product types"
            btnTitles={["Measurement types", "Add new product type"]}
            btnHandlers={[measurementTypesHandler, addBtnHandler]}
            sort={sortType}
            handleSearchFieldFocus={handleSearchFieldFocus}
            placeholder={PRODUCT_TYPES_SEARCH_PLACEHOLDER}
          />
          {!!productTypes.content.length ? (
            <div className="event-table-container">
              <ProductTypesTable
                sort={sortType}
                productTypes={productTypes.content}
                handleSorting={handleSorting}
                handleManaged={handleManaged}
              />
              <Pagination
                dataLength={productTypes.totalElements}
                pageId={pageId}
              />
            </div>
          ) : (
            <MiniLoader
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "5vh",
              }}
              time={3}
              afterload={
                <Plug
                  message={
                    searchParams && !!Object.keys(searchParams)?.length
                      ? NO_FOUND_PLUG_ERROR
                      : NO_PRODUCT_TYPES_PLUG_ERROR
                  }
                  type={
                    searchParams && !!Object.keys(searchParams)?.length
                      ? NO_FOUND_PLUG_TYPE
                      : NO_PRODUCT_TYPES_PLUG_ERROR
                  }
                />
              }
            />
          )}
        </main>
      ) : (
        <Loader />
      )}
    </>
  );
};
