import { FC, useEffect, useState } from "react";
import { Button } from "@components/Button";
import { Input } from "@components/Input";
import { PortalModal } from "@components/PortalModal";
import {
  IManageEndUserModal,
  ICheckEndUser,
  IPersonalUserInfo,
} from "@models/components/secondary/manualadduser";
import { validationSchemas } from "@services/validation";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  validateOnlyNumber,
  validateOnlyAllowedEmailSymbols,
} from "@services/regexps";
import {
  focuseField,
  getPhoneNumberValue,
  getUserRoleFromToken,
  unFocuseField,
} from "@services/common";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { selectTokens } from "@store/selectors/tokens";
import {
  MIN_PHONE_NUMBER_LENGTH,
  PARENT_ROLE,
  PHONE_NUMBER_START_CODE,
} from "@constants/commons";
import { filterDealer } from "@api/dealers";
import { IDealer } from "@models/common/dealer";
import { IParent } from "@models/common/parent";
import { getParentsBySearchKey } from "@api/parent";
import { ParentDropdown } from "@components/Dropdown/ParentDropdown";
import { ADD_DEALER_TITLE } from "@constants/users";
import {
  EMAIL_ALREADY_USED_ERROR,
  EMAIL_PHONE_NUMBER_ALREADY_USED_ERROR,
  PHONE_NUMBER_ALREADY_USED_ERROR,
} from "@constants/errors";
import {
  checkEndUserByData,
  handleSubmitErrors,
  SubmitError,
} from "@api/common";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { getFormattedError } from "@services/http";
import { Checkbox } from "@components/Checkbox";

export const ManageDealerModal: FC<IManageEndUserModal> = ({
  handleModalOpen,
  userInfo,
  handleUser,
  title,
  assignedParents,
}) => {
  const checkFormMethods = useForm<ICheckEndUser>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldFocusError: false,
    resolver: yupResolver(validationSchemas.checkEndUser),
    defaultValues: {
      phone_number: PHONE_NUMBER_START_CODE,
    },
  });

  const personalFormMethods = useForm<IPersonalUserInfo>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    shouldFocusError: false,
    resolver: yupResolver(validationSchemas.personalDealerInfo),
  });

  const {
    handleSubmit: checkHandleSubmit,
    formState: checkFormState,
    watch: checkWatch,
    clearErrors: clearCheckErrors,
    setValue: setCheckValue,
    setError: setCheckError,
    trigger: triggerCheckFormValidation,
  } = checkFormMethods;
  const { email = "", phone_number = "" } = checkWatch();

  const {
    errors: checkErrors,
    isSubmitting: isCheckSubmitting,
    isSubmitted: isCheckSubmitted,
  } = checkFormState;

  const {
    handleSubmit: personalHandleSubmit,
    formState: personalFormState,
    watch: personalWatch,
    setValue: setPersonalValue,
    reset: personalReset,
  } = personalFormMethods;
  const {
    firstName = "",
    lastName = "",
    dealerName = "",
    assignedParents: personalAssignedParents,
  } = personalWatch();

  const {
    errors: personalErrors,
    isSubmitting: isPersonalSubmitting,
    isSubmitted: isPersonalSubmitted,
  } = personalFormState;

  const [personalSubmitted, setPersonalSubmitted] = useState(false);
  const [focusedFields, setFocused] = useState<{
    [fieldName: string]: boolean;
  }>({
    email: false,
    phone_number: false,
    firstName: false,
    lastName: false,
    assignedParents: false,
  });
  const [checkedUser, setCheckedUser] = useState<IDealer[]>();
  const [defaultParents, setDefaultParents] = useState<IParent[] | undefined>(
    userInfo?.parentsInfo
  );
  const [listParents, setListParents] = useState<IParent[]>();
  const [searchedParent, setSearchedParent] = useState("");
  const checkedUserSalesRep = checkedUser?.some((user) => user.salesRep);
  const [isSalesRep, setSalesRep] = useState(userInfo?.salesRep);
  const tokensInfo = useAppSelector(selectTokens);
  const userRole = getUserRoleFromToken(tokensInfo);
  const dispatch = useAppDispatch();

  const handleCheckedUser = (data: IDealer[] | undefined) => {
    setCheckedUser(data);
    setDefaultParents(data?.[0]?.parents);
  };
  const toggleSalesRep = () => {
    setSalesRep((prev) => !prev);
  };
  const handleParentsDropdownItems = (items: IParent[] | undefined) => {
    setListParents(items);
  };

  const handleSearchedParent = (parent: string) => {
    setSearchedParent(parent);
  };

  useEffect(() => {
    if (email?.indexOf(" ") !== -1) {
      setCheckValue("email", email?.replace(/\s/g, ""));
    }
  }, [email]);

  useEffect(() => {
    if (phone_number?.indexOf(" ") !== -1) {
      setCheckValue("phone_number", phone_number?.replace(/\s/g, ""));
    }
  }, [phone_number]);

  useEffect(() => {
    if ((checkedUser || userInfo) && userRole !== PARENT_ROLE) {
      (async function asyncWrapper() {
        try {
          const parents = await getParentsBySearchKey(dispatch, searchedParent);
          handleParentsDropdownItems(parents.content);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [searchedParent, checkedUser, userInfo]);

  useEffect(() => {
    if (
      ((checkedUser?.[0]?.user?.email || "")?.replace(/\s/g, "") !==
        (email || "")?.replace(/\s/g, "") ||
        getPhoneNumberValue(checkedUser?.[0]?.user?.phoneNumber) !==
          getPhoneNumberValue(phone_number)) &&
      title === ADD_DEALER_TITLE
    ) {
      handleCheckedUser(undefined);
      personalReset();
    }
  }, [email, phone_number]);

  useEffect(() => {
    if (userInfo) {
      setCheckValue("email", userInfo.email);
      setCheckValue("phone_number", userInfo.phone_number);
      setPersonalValue("firstName", userInfo.firstName);
      setPersonalValue("lastName", userInfo.lastName);
      setPersonalValue("salesRep", userInfo.salesRep);
      setPersonalValue("dealerName", userInfo.dealerName || "");
      if (assignedParents) {
        setPersonalValue("assignedParents", assignedParents);
      } else {
        setPersonalValue(
          "assignedParents",
          (userInfo.parentsInfo || []).map((parent) => parent.userId).join(", ")
        );
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (!!checkedUser?.length) {
      setCheckValue("email", checkedUser[0]?.user?.email);
      setCheckValue("phone_number", checkedUser[0]?.user?.phoneNumber);
      setPersonalValue("firstName", checkedUser[0]?.user?.firstName);
      setPersonalValue("lastName", checkedUser[0]?.user?.lastName);
      setPersonalValue("salesRep", checkedUser[0]?.user?.salesRep);
      setPersonalValue("dealerName", checkedUser[0]?.dealerName);
      if (assignedParents) {
        setPersonalValue("assignedParents", assignedParents);
      } else {
        setPersonalValue(
          "assignedParents",
          (checkedUser[0]?.parents || [])
            .map((parent) => parent.userId)
            .join(", ")
        );
      }
    } else {
      if (assignedParents) {
        setPersonalValue("assignedParents", assignedParents);
      }
    }
  }, [checkedUser]);

  useEffect(() => {
    if (!phone_number?.startsWith(PHONE_NUMBER_START_CODE)) {
      setCheckValue("phone_number", PHONE_NUMBER_START_CODE);
    }
  }, [setCheckValue, phone_number]);

  const handleFocuse = (focuseState: { [fieldName: string]: boolean }) => {
    setFocused(focuseState);
  };

  const checkOnSubmit = async (data: ICheckEndUser) => {
    try {
      const { email = "", phone_number = "" } = data;
      const emailData = email?.trim();
      const phoneNumberData: string | undefined =
        phone_number === undefined && email
          ? undefined
          : phone_number &&
            phone_number.length > MIN_PHONE_NUMBER_LENGTH &&
            !email
          ? phone_number.trim()
          : email &&
            phone_number &&
            phone_number.length > MIN_PHONE_NUMBER_LENGTH
          ? phone_number.trim()
          : undefined;

      const checkDealerCreate = await checkEndUserByData(
        dispatch,
        emailData,
        phoneNumberData
      );

      if (
        checkDealerCreate.emailExists ||
        checkDealerCreate.phoneNumberExists
      ) {
        if (userRole === PARENT_ROLE) {
          const user = await filterDealer(
            dispatch,
            email?.trim(),
            phoneNumberData
          );
          if (!!user.content.length) {
            handleCheckedUser(user.content);
          } else {
            setCheckError("email", {
              type: "custom",
              message: EMAIL_PHONE_NUMBER_ALREADY_USED_ERROR,
            });
            setCheckError("phone_number", {
              type: "custom",
              message: EMAIL_PHONE_NUMBER_ALREADY_USED_ERROR,
            });
          }
        } else {
          if (checkDealerCreate.emailExists) {
            setCheckError("email", {
              type: "custom",
              message: EMAIL_ALREADY_USED_ERROR,
            });
          }

          if (checkDealerCreate.phoneNumberExists) {
            setCheckError("phone_number", {
              type: "custom",
              message: PHONE_NUMBER_ALREADY_USED_ERROR,
            });
          }
        }
      } else {
        clearCheckErrors();
        handleCheckedUser([]);
      }
    } catch (error) {
      return Promise.reject(getFormattedError(error));
    }
  };

  const personalOnSubmit = async (data: IPersonalUserInfo) => {
    const isCheckFormValid = await triggerCheckFormValidation();

    checkHandleSubmit(() => {})();

    if (!isCheckFormValid) {
      return;
    }

    let filteredAssignedParents = data.assignedParents;

    if (userRole === PARENT_ROLE) {
      if (!filteredAssignedParents) {
        filteredAssignedParents = `${tokensInfo?.idToken?.payload?.["cognito:username"]}`;
      } else if (title === ADD_DEALER_TITLE) {
        filteredAssignedParents += `, ${tokensInfo?.idToken?.payload?.["cognito:username"]}`;
      }
    }

    const userData = {
      ...data,
      email: !email ? undefined : email.trim(),
      phone_number:
        phone_number?.length <= MIN_PHONE_NUMBER_LENGTH
          ? undefined
          : phone_number.trim(),
      dealerId: userInfo?.dealerId || checkedUser?.[0]?.dealerId,
      assignedParents: filteredAssignedParents,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      dealerName: dealerName.trim(),
      salesRep: isSalesRep || checkedUserSalesRep,
    };

    if (!checkErrors.email && !checkErrors.phone_number) {
      try {
        await handleUser(dispatch, userData, false);
        handleModalOpen();
      } catch (error) {
        handleSubmitErrors({
          error: error as SubmitError,
          setError: setCheckError,
        });
      }
    }
  };

  const isCheckBtnDisabled =
    (!email?.length && phone_number?.length === MIN_PHONE_NUMBER_LENGTH) ||
    isCheckSubmitting;

  const isDisabledForm = checkedUser === undefined && !userInfo;
  const hasCheckFormErrors = Boolean(
    Object.values(checkFormState.errors).length
  );

  const isSaveBtnDisabled =
    hasCheckFormErrors ||
    isDisabledForm ||
    (firstName.trim().length &&
    lastName.trim().length &&
    dealerName.trim().length
      ? !firstName.length ||
        !lastName.length ||
        (userRole !== PARENT_ROLE &&
          !assignedParents &&
          !personalAssignedParents?.length) ||
        isPersonalSubmitting
      : !email.length ||
        phone_number.length === MIN_PHONE_NUMBER_LENGTH ||
        !firstName.trim().length ||
        !lastName.trim().length ||
        !dealerName?.length ||
        (userRole !== PARENT_ROLE &&
          !assignedParents &&
          !personalAssignedParents?.length) ||
        isPersonalSubmitting);

  return (
    <PortalModal
      title={title}
      isOpen={true}
      onClose={handleModalOpen}
      className="modal-user"
    >
      <div className="modal-body">
        <h2 className="modal-subtitle">
          <span className="modal-step">1</span> User verification
        </h2>
      </div>
      <form
        className="modal-form form-start-label"
        onSubmit={checkHandleSubmit(checkOnSubmit)}
      >
        <div className="modal-body form modal-form">
          <FormProvider {...checkFormMethods}>
            <Input
              label="Email"
              subLabel={
                !!checkErrors.email?.message &&
                (title === ADD_DEALER_TITLE
                  ? isCheckSubmitted
                  : isPersonalSubmitted)
                  ? checkErrors.email.message
                  : ""
              }
              error={
                (!!checkErrors.email?.message ||
                  checkErrors.phone_number?.message ===
                    EMAIL_PHONE_NUMBER_ALREADY_USED_ERROR) &&
                (title === ADD_DEALER_TITLE
                  ? isCheckSubmitted
                  : isPersonalSubmitted)
              }
              name="email"
              disable={isCheckSubmitting}
              focuseField={() =>
                focuseField("email", focusedFields, handleFocuse)
              }
              unFocuseField={() =>
                unFocuseField("email", focusedFields, handleFocuse)
              }
              pattern={validateOnlyAllowedEmailSymbols}
              trimValue
            />
            <Input
              label="Mobile phone number"
              subLabel={
                !!checkErrors.phone_number?.message &&
                (title === ADD_DEALER_TITLE
                  ? isCheckSubmitted
                  : isPersonalSubmitted)
                  ? checkErrors.phone_number.message
                  : ""
              }
              error={
                !!checkErrors.phone_number?.message &&
                (title === ADD_DEALER_TITLE
                  ? isCheckSubmitted
                  : isPersonalSubmitted)
              }
              disable={isCheckSubmitting}
              name="phone_number"
              focuseField={() =>
                focuseField("phone_number", focusedFields, handleFocuse)
              }
              unFocuseField={() =>
                unFocuseField("phone_number", focusedFields, handleFocuse)
              }
              pattern={validateOnlyNumber}
              trimValue
            />
          </FormProvider>
          {isDisabledForm && (
            <Button
              type="submit"
              text="Check"
              className="btn btn-main btn-content"
              disable={isCheckBtnDisabled}
            />
          )}
        </div>
      </form>
      <form
        className="form modal-form form-start-label"
        onSubmit={personalHandleSubmit(personalOnSubmit)}
      >
        <div
          className={`modal-body form modal-form ${
            isDisabledForm && "isDisabled"
          }`}
        >
          <FormProvider {...personalFormMethods}>
            <h2 className={`modal-subtitle ${isDisabledForm && "isDisabled"}`}>
              <span className="modal-step">2</span> Dealer information
            </h2>
            <Input
              label="Dealer name*"
              name="dealerName"
              disable={isDisabledForm ? true : false}
              subLabel={
                !!personalSubmitted && !!personalWatch().dealerName?.length
                  ? personalErrors.dealerName?.message
                  : ""
              }
              error={
                !!personalErrors.dealerName?.message && !!personalSubmitted
              }
              focuseField={() =>
                focuseField("dealerName", focusedFields, handleFocuse)
              }
              unFocuseField={() =>
                unFocuseField("dealerName", focusedFields, handleFocuse)
              }
            />
            <h2 className={`modal-subtitle ${isDisabledForm && "isDisabled"}`}>
              <span className="modal-step">3</span> Person of contact
            </h2>
            <Input
              label="First name*"
              name="firstName"
              disable={isDisabledForm ? true : false}
              subLabel={
                !!personalSubmitted && !!personalWatch().firstName?.length
                  ? personalErrors.firstName?.message
                  : ""
              }
              error={!!personalErrors.firstName?.message && !!personalSubmitted}
              focuseField={() =>
                focuseField("firstName", focusedFields, handleFocuse)
              }
              unFocuseField={() =>
                unFocuseField("firstName", focusedFields, handleFocuse)
              }
            />
            <Input
              label="Last name*"
              name="lastName"
              disable={isDisabledForm ? true : false}
              subLabel={
                !!personalSubmitted && !!personalWatch().lastName?.length
                  ? personalErrors.lastName?.message
                  : ""
              }
              error={!!personalErrors.lastName?.message && !!personalSubmitted}
              focuseField={() =>
                focuseField("lastName", focusedFields, handleFocuse)
              }
              unFocuseField={() =>
                unFocuseField("lastName", focusedFields, handleFocuse)
              }
            />
            <Checkbox
              label="Create a sales representative"
              name="salesRep"
              value="salesRep"
              id="salesRepCheckbox"
              checked={isSalesRep || checkedUserSalesRep}
              disabled={userInfo?.salesRep || checkedUserSalesRep}
              onChange={toggleSalesRep}
            />
            {userRole !== PARENT_ROLE && !assignedParents && (
              <>
                <h2
                  className={`modal-subtitle ${isDisabledForm && "isDisabled"}`}
                >
                  <span className="modal-step">4</span> Assign to Parent
                </h2>
                <ParentDropdown
                  name="assignedParents"
                  id="dropdown"
                  defaultValue={defaultParents}
                  dropdownList={listParents}
                  searchedValue={searchedParent}
                  handleParentItems={handleParentsDropdownItems}
                  handleSearchedValue={handleSearchedParent}
                  disable={isDisabledForm ? true : false}
                  clear={isDisabledForm ? true : false}
                  required
                />
              </>
            )}
          </FormProvider>
        </div>
        <div className="modal-footer">
          <Button
            text="Cancel"
            className="btn btn-primary"
            onClick={handleModalOpen}
          />
          <Button
            text="Save"
            className="btn btn-main"
            type="submit"
            disable={isSaveBtnDisabled}
            onClick={() => setPersonalSubmitted(true)}
          />
        </div>
      </form>
    </PortalModal>
  );
};
