import React, { ChangeEvent, useEffect, useState } from "react";
import { CheckboxProps } from "@models/components/secondary/input";
export const Checkbox: React.FC<CheckboxProps> = ({
  name,
  id,
  label,
  disabled,
  checked,
  onChange,
  className,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(!!checked);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked: state } = event.target;
    setIsChecked(state);
    if (onChange) {
      onChange(state);
    }
  };

  useEffect(() => {
    setIsChecked(!!checked);
  }, [checked]);

  return (
    <div
      className={`checkbox-container ${
        className === "sales-rep" && "checkbox-container__sales-rep"
      }`}
    >
      <input
        type="checkbox"
        id={id}
        className={`checkbox__handler ${disabled && "isDisabled disabled"}`}
        name={name}
        checked={isChecked}
        onChange={handleChange}
      />
      {label && (
        <label
          htmlFor={id}
          className={`checkbox__label ${disabled && "isDisabled disabled"}`}
        >
          {label}
        </label>
      )}
    </div>
  );
};
