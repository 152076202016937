import { FC, Fragment } from "react";
import { ITableHeader } from "@models/components/secondary/tableheader";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { selectTokens } from "@store/selectors/tokens";
import { useLocation } from "react-router-dom";
import { getUserRoleFromToken } from "@services/common";

export const TableHeader: FC<ITableHeader> = ({
  headers,
  sort,
  handleSorting,
}) => {
  const tokensInfo = useAppSelector(selectTokens);
  const location = useLocation();
  const userRole = getUserRoleFromToken(tokensInfo);
  return (
    <thead className="event-thead">
      <tr className="event-tr">
        {headers.map((header, index) =>
          !header?.notShowRole?.includes(userRole || "") &&
          !header?.notShowRoute?.some((route) =>
            location.pathname.includes(route)
          ) ? (
            <Fragment key={index}>
              {header.sort ? (
                <th className="event-th">
                  <div className="d-flex a-center">
                    {header.name}
                    <div onClick={() => handleSorting(header.name)}>
                      <i
                        className={`event-sort-icon icon-sort-up icon ${
                          sort?.parameter?.includes("asc") &&
                          sort?.parameter?.includes(`${header.name}`) &&
                          "isActive"
                        }`}
                      ></i>
                      <i
                        className={`event-sort-icon icon-sort-down icon ${
                          sort?.parameter?.includes("desc") &&
                          sort?.parameter?.includes(`${header.name}`) &&
                          "isActive"
                        }`}
                      ></i>
                    </div>
                  </div>
                </th>
              ) : (
                <th className="event-th">{header.name}</th>
              )}
            </Fragment>
          ) : (
            <Fragment key={index}></Fragment>
          )
        )}
      </tr>
    </thead>
  );
};
