import { FC } from "react";
import { IEventStepsHeader } from "@models/components/secondary/eventstepsheader";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { selectEventData } from "@store/selectors/event";
import { ADD_EVENT } from "@constants/events";

export const EventStepsHeader: FC<IEventStepsHeader> = ({
  eventStepType,
  title,
}) => {
  const eventData = useAppSelector(selectEventData);

  return (
    <ul className="event-form-list">
      <li
        className={`event-form-item ${
          eventStepType === "general" && "isActive"
        }`}
      >
        <span className="event-step">1</span> General information
      </li>
      {(eventData || title === ADD_EVENT) && (
        <li
          className={`event-form-item ${
            eventStepType === "add_users" && "isActive"
          }`}
        >
          <span className="event-step">2</span>
          Add users
        </li>
      )}
    </ul>
  );
};
