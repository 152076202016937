import { useState, useEffect, FC } from "react";
import { MINI_LOADER_MAX_TIMEOUT } from "@constants/commons";
import { IMiniLoader } from "@models/components/secondary/loader";
import { InfinitySpin } from "react-loader-spinner";

export const MiniLoader: FC<IMiniLoader> = ({ style, time, afterload }) => {
  const [seconds, setSeconds] = useState(time || MINI_LOADER_MAX_TIMEOUT);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(0);
    }
  }, [seconds]);

  useEffect(() => {
    if (time) {
      setSeconds(time);
    }
  }, [afterload]);

  return (
    <>
      {style ? (
        <div style={style}>
          {seconds > 0 ? (
            <InfinitySpin width="100" color="#5E5ADB" />
          ) : (
            <>{afterload}</>
          )}
        </div>
      ) : (
        <>{seconds > 0 && <InfinitySpin width="100" color="#5E5ADB" />}</>
      )}
    </>
  );
};
