import { DEALER_ROLE } from "./commons";
import {
  ADD_EVENT_ROUTE,
  DASHBOARD_ROUTE,
  DEALERS_LIST_ROUTE,
  DEALER_INFO_ROUTE,
  EDIT_EVENT_ROUTE,
  END_USER_INFO_ROUTE,
  END_USER_LIST_ROUTE,
  END_USER_MEASUREMENTS_LIST_ROUTE,
  END_USER_MEASUREMENTS_ROUTE,
  EVENTS_LIST_ROUTE,
  MEASUREMENTS_LIST_ROUTE,
  PARENTS_LIST_ROUTE,
  PARENT_INFO_ROUTE,
  PRODUCT_TYPES_ROUTE,
  SALES_LIST_ROUTE,
  SALES_REP_INFO_ROUTE,
} from "./routes";

export const eventUsersTableHeaders = [
  { name: "Name", sort: false },
  { name: "Email", sort: false },
  { name: "Phone number", sort: false },
  { name: "Badge number", sort: false },
  { name: "Gender", sort: false },
  { name: "", sort: false },
];

export const eventTableHeaders = [
  { name: "Event ID", sort: false },
  { name: "Event name", sort: true },
  { name: "Department location", sort: false },
  { name: "Start/End of the Event", sort: true },
  { name: "Product type", sort: false },
  { name: "Creator", sort: false },
  { name: "Status", sort: true },
  { name: "Progress", sort: false },
  { name: "Actions", sort: false },
];

export const salesRepTableHeaders = [
  { name: "Name", sort: true },
  { name: "Email", sort: false },
  { name: "Phone number", sort: false },
  { name: "System status", sort: true },
  { name: "Event's completeness", sort: false },
  {
    name: "Dealer",
    sort: true,
    notShowRole: [DEALER_ROLE],
    notShowRoute: [DEALER_INFO_ROUTE],
  },
  { name: "Actions", sort: false },
];

export const dealerTableHeaders = [
  { name: "Dealer name", sort: true },
  { name: "Person of contact", sort: true },
  { name: "Email", sort: false },
  { name: "Phone number", sort: false },
  { name: "System status", sort: true },
  { name: "Event's completeness", sort: false },
  { name: "Parent", sort: false },
  { name: "Actions", sort: false },
];

export const parentTableHeaders = [
  { name: "Parent name", sort: true },
  { name: "Email", sort: false },
  { name: "Phone number", sort: false },
  { name: "System status", sort: true },
  { name: "Actions", sort: false },
];

export const endUsersMeasurementsHeaders = [
  { name: "Name", sort: true },
  { name: "Email", sort: false },
  { name: "Phone number", sort: false },
  { name: "Badge number", sort: false },
  { name: "Status", sort: true },
  { name: "Measurements", sort: false },
  { name: "Who measured", sort: false },
  { name: "Actions", sort: false },
];

export const productTypesTableHeaders = [
  { name: "Product type", sort: true },
  { name: "Gender", sort: false },
  { name: "Measurement types", sort: false },
  { name: "Actions", sort: false },
];

export const endUserMeasurementsTableHeaders = [
  { name: "Event name", sort: true },
  { name: "Department location", sort: false },
  { name: "Start/End of the Event", sort: true },
  { name: "Product type", sort: false },
  { name: "Status", sort: true },
  { name: "Measurements", sort: false },
  { name: "Actions", sort: false },
];

export const endUsersTableHeaders = [
  { name: "ID", sort: false },
  { name: "Name", sort: true },
  { name: "Email", sort: false },
  { name: "Phone number", sort: false },
  { name: "System status", sort: true },
  { name: "Actions", sort: false },
];

export const endUserHeaders = [
  {
    id: 1,
    header: "",
    route: `${END_USER_MEASUREMENTS_ROUTE}/1`,
    mainRoute: `${END_USER_MEASUREMENTS_ROUTE}`,
    addRoutes: [],
  },
];

export const salesRepHeaders = [
  {
    id: 1,
    header: "End users",
    route: `${END_USER_LIST_ROUTE}/1`,
    mainRoute: `${END_USER_LIST_ROUTE}`,
    addRoutes: [END_USER_INFO_ROUTE, END_USER_MEASUREMENTS_LIST_ROUTE],
  },
  {
    id: 2,
    header: "Events",
    route: `${EVENTS_LIST_ROUTE}/1`,
    mainRoute: `${EVENTS_LIST_ROUTE}`,
    addRoutes: [ADD_EVENT_ROUTE, EDIT_EVENT_ROUTE, MEASUREMENTS_LIST_ROUTE],
  },
];

export const dealersHeaders = [
  {
    id: 1,
    header: "Sales representatives",
    route: `${SALES_LIST_ROUTE}/1`,
    mainRoute: `${SALES_LIST_ROUTE}`,
    addRoutes: [SALES_REP_INFO_ROUTE],
  },
  {
    id: 2,
    header: "End users",
    route: `${END_USER_LIST_ROUTE}/1`,
    mainRoute: `${END_USER_LIST_ROUTE}`,
    addRoutes: [END_USER_INFO_ROUTE, END_USER_MEASUREMENTS_LIST_ROUTE],
  },
  {
    id: 3,
    header: "Events",
    route: `${EVENTS_LIST_ROUTE}/1`,
    mainRoute: `${EVENTS_LIST_ROUTE}`,
    addRoutes: [ADD_EVENT_ROUTE, EDIT_EVENT_ROUTE, MEASUREMENTS_LIST_ROUTE],
  },
  {
    id: 4,
    header: "Dashboard",
    route: `${DASHBOARD_ROUTE}`,
    mainRoute: `${DASHBOARD_ROUTE}`,
    addRoutes: [],
  },
];

export const parentHeaders = [
  {
    id: 1,
    header: "Dealers",
    route: `${DEALERS_LIST_ROUTE}/1`,
    mainRoute: `${DEALERS_LIST_ROUTE}`,
    addRoutes: [DEALER_INFO_ROUTE],
  },
  {
    id: 2,
    header: "Sales representatives",
    route: `${SALES_LIST_ROUTE}/1`,
    mainRoute: `${SALES_LIST_ROUTE}`,
    addRoutes: [SALES_REP_INFO_ROUTE],
  },
  {
    id: 3,
    header: "End users",
    route: `${END_USER_LIST_ROUTE}/1`,
    mainRoute: `${END_USER_LIST_ROUTE}`,
    addRoutes: [END_USER_INFO_ROUTE, END_USER_MEASUREMENTS_LIST_ROUTE],
  },
  {
    id: 4,
    header: "Events",
    route: `${EVENTS_LIST_ROUTE}/1`,
    mainRoute: `${EVENTS_LIST_ROUTE}`,
    addRoutes: [ADD_EVENT_ROUTE, EDIT_EVENT_ROUTE, MEASUREMENTS_LIST_ROUTE],
  },
  {
    id: 5,
    header: "Product types",
    route: `${PRODUCT_TYPES_ROUTE}/1`,
    mainRoute: `${PRODUCT_TYPES_ROUTE}`,
    addRoutes: [],
  },
  {
    id: 6,
    header: "Dashboard",
    route: `${DASHBOARD_ROUTE}`,
    mainRoute: `${DASHBOARD_ROUTE}`,
    addRoutes: [],
  },
];

export const adminHeaders = [
  {
    id: 1,
    header: "Parents",
    route: `${PARENTS_LIST_ROUTE}/1`,
    mainRoute: `${PARENTS_LIST_ROUTE}`,
    addRoutes: [PARENT_INFO_ROUTE],
  },
  {
    id: 2,
    header: "Dealers",
    route: `${DEALERS_LIST_ROUTE}/1`,
    mainRoute: `${DEALERS_LIST_ROUTE}`,
    addRoutes: [DEALER_INFO_ROUTE],
  },
  {
    id: 3,
    header: "Sales representatives",
    route: `${SALES_LIST_ROUTE}/1`,
    mainRoute: `${SALES_LIST_ROUTE}`,
    addRoutes: [SALES_REP_INFO_ROUTE],
  },
  {
    id: 4,
    header: "End users",
    route: `${END_USER_LIST_ROUTE}/1`,
    mainRoute: `${END_USER_LIST_ROUTE}`,
    addRoutes: [END_USER_INFO_ROUTE, END_USER_MEASUREMENTS_LIST_ROUTE],
  },
  {
    id: 5,
    header: "Events",
    route: `${EVENTS_LIST_ROUTE}/1`,
    mainRoute: `${EVENTS_LIST_ROUTE}`,
    addRoutes: [ADD_EVENT_ROUTE, EDIT_EVENT_ROUTE, MEASUREMENTS_LIST_ROUTE],
  },
  {
    id: 6,
    header: "Product types",
    route: `${PRODUCT_TYPES_ROUTE}/1`,
    mainRoute: `${PRODUCT_TYPES_ROUTE}`,
    addRoutes: [],
  },
  {
    id: 7,
    header: "Dashboard",
    route: `${DASHBOARD_ROUTE}`,
    mainRoute: `${DASHBOARD_ROUTE}`,
    addRoutes: [],
  },
];
