import { FC } from "react";
import { IStatusBar } from "@models/components/secondary/statusbar";

export const StatusBar: FC<IStatusBar> = (props) => {
  const { value } = props;

  return (
    <span className="status-bar-container">
      <span className="status-bar" />
      <span className="status-line" style={{ width: `${value}%` }} />
    </span>
  );
};
