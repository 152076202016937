import { Fragment, FC, useState, useEffect } from "react";
import { IEndUserEventsTable } from "@models/components/main/endusertable";
import { TableHeader } from "@components/TableHeader";
import { endUserMeasurementsTableHeaders } from "@constants/headers";
import {
  IN_PERSON_EVENT_TYPE,
  MEASURED,
  MEASUREMENT_REQUIRED,
} from "@constants/events";
import {
  formateDate,
  hideWindowScroll,
  showWindowScroll,
  sortAlphabet,
} from "@services/common";
import { Tooltip } from "@components/Tooltip";
import { ManageEndUserMeasurementsModal } from "@pages/Modals/Measurements/ManageEndUserMeasurementsModal";
import {
  ADD_MEASUREMENT_TITLE,
  EDIT_MEASUREMENT_TITLE,
} from "@constants/measurements";
import { IManageUserInfo } from "@models/components/secondary/manualadduser";
import {
  IEventMeasurementInfo,
  IUserMeasurementInfo,
} from "@models/common/measurements";
import {
  getEventParticipantMeasurement,
  updateEventParticipantMeasurement,
} from "@api/measurements";
import { IEventParticipant } from "@models/common/eventParticipants";
import { UserGender } from "@models/common/user";
import { useNotification } from "@hooks/common/useNotification";
import {
  UPDATE_MEASUREMENT_NOTIFICATION,
  SOMETHING_WENT_WRONG_NOTIFICATION,
  SELF_MEASURED,
} from "@constants/commons";
import { Notification } from "@components/Notification";
import { IEvent } from "@models/common/events";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import {
  MEASUREMENTS_LIST_ROUTE,
  MY_END_USER_MEASUREMENTS_LIST_ROUTE,
} from "@constants/routes";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { selectTokens } from "@store/selectors/tokens";
import { getProductTypesByIds } from "@api/types";
import { getFormattedError } from "@services/http";

export const EndUserEventsTable: FC<IEndUserEventsTable> = ({
  endUserEvents,
  endUser,
  handleSorting,
  sort,
  handleMeasurementModal,
  handleMeasurementsManaged,
}) => {
  const [eventData, setEventData] = useState<IEvent>();
  const [isMeasurementOpened, setMeasurementOpened] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<IManageUserInfo | undefined>();
  const [eventMeasurementsInfo, setEventMeasurementsInfo] = useState<
    IEventMeasurementInfo | undefined
  >();
  const [userMeasurementsInfo, setUserMeasurementsInfo] = useState<
    IUserMeasurementInfo | undefined
  >();
  const [userMeasurementManaged, setUserMeasurementManaged] = useState(false);
  const [activeEndUserMeasurements, setActiveEndUserMeasurements] = useState<
    string[]
  >([]);

  const dispatch = useAppDispatch();
  const tokensInfo = useAppSelector(selectTokens);
  const userRole = tokensInfo?.idToken?.payload?.["custom:user_role"];

  const {
    isActive,
    message: notificationMessage,
    type: notificationType,
    showNotification,
    closeNotification,
  } = useNotification();

  const updateMeasurements = () => {
    if (userMeasurementManaged) {
      setUserMeasurementManaged(false);
      handleMeasurementsManaged(false);
    }
    setMeasurementOpened(!isMeasurementOpened);
    handleMeasurementModal(!isMeasurementOpened);
  };

  const getEndUserMeasurements = async (endUserId: string) => {
    try {
      return await getEventParticipantMeasurement(
        dispatch,
        eventData?.eventId || "",
        endUserId
      );
    } catch (error) {
      return Promise.reject(getFormattedError(error));
    }
  };

  const getEventMeasurements = async () => {
    if (eventData?.productTypes) {
      try {
        return await getProductTypesByIds(
          dispatch,
          eventData?.productTypes.map(
            (productType) => productType.productTypeId
          )
        );
      } catch (error) {
        return Promise.reject(getFormattedError(error));
      }
    }
  };

  const handleUpdateMeasurements = async (data: {
    gender: UserGender;
    measurementData: {
      measurementParamId: string;
      value: string;
    }[];
    whoMeasured?: string;
  }) => {
    try {
      await updateEventParticipantMeasurement(
        dispatch,
        eventData?.eventId || "",
        endUser.endUserId,
        data.gender,
        data.measurementData,
        data.whoMeasured
      );
      setUserMeasurementManaged(true);
      handleMeasurementsManaged(true);
      showNotification(UPDATE_MEASUREMENT_NOTIFICATION, "success");
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    }
  };

  const handleUserMeasurements = (
    eventParticipant: IEventParticipant | undefined,
    event: IEvent
  ) => {
    setUserMeasurementsInfo(
      eventParticipant
        ? {
            measurementData: eventParticipant?.measurement.measurementData,
            whoMeasured: eventParticipant?.measurement.whoMeasured,
            gender: eventParticipant?.measurement.gender,
          }
        : undefined
    );
    setEventMeasurementsInfo(undefined);

    setEventData(event);

    setUserInfo({
      userId: eventParticipant?.endUser.user.userId,
      firstName: eventParticipant?.endUser.user.firstName || "",
      lastName: eventParticipant?.endUser.user.lastName || "",
      email: eventParticipant?.endUser.user.email || "",
      phone_number: eventParticipant?.endUser.user.phoneNumber || "",
      badge: eventParticipant?.endUser.badgeNumber,
      gender: eventParticipant?.endUser.gender || endUser.gender,
    });
  };

  const handleSeeMeasurements = (eventId: string) => {
    if (activeEndUserMeasurements.includes(eventId)) {
      setActiveEndUserMeasurements((measurements) =>
        measurements.filter((measurementId) => measurementId !== eventId)
      );
    } else {
      setActiveEndUserMeasurements((measurementIds) => [
        ...measurementIds,
        eventId,
      ]);
    }
  };

  useEffect(() => {
    if (isMeasurementOpened) {
      (async function asyncWrapper() {
        if (userInfo?.userId) {
          try {
            const endUserMeasurements = await getEndUserMeasurements(
              endUser.endUserId
            );
            if (endUserMeasurements) {
              setUserMeasurementsInfo({
                measurementData: endUserMeasurements.measurementData,
                whoMeasured: endUserMeasurements.whoMeasured,
                gender: endUserMeasurements.gender,
              });
            } else {
              setUserMeasurementsInfo(undefined);
            }
          } catch (error) {
            setUserMeasurementsInfo(undefined);
            showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
            return Promise.reject(getFormattedError(error));
          }
        }

        try {
          const eventMeasurements = await getEventMeasurements();
          if (eventMeasurements) {
            setEventMeasurementsInfo({
              measurementData: eventMeasurements,
              whoMeasured: SELF_MEASURED,
            });
          }
        } catch (error) {
          setEventMeasurementsInfo(undefined);
          showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [isMeasurementOpened]);

  useEffect(() => {
    if (isMeasurementOpened && eventMeasurementsInfo) {
      showWindowScroll();
    } else {
      hideWindowScroll();
    }
  }, [isMeasurementOpened, eventMeasurementsInfo]);

  return (
    <>
      <Notification
        type={notificationType}
        message={notificationMessage}
        isActive={isActive}
        closeNotification={closeNotification}
      />
      {isMeasurementOpened && eventMeasurementsInfo && (
        <ManageEndUserMeasurementsModal
          title={
            userMeasurementsInfo
              ? EDIT_MEASUREMENT_TITLE
              : ADD_MEASUREMENT_TITLE
          }
          handleModalOpen={updateMeasurements}
          eventMeasurementsInfo={eventMeasurementsInfo}
          userMeasurementsInfo={userMeasurementsInfo}
          handleUser={handleUpdateMeasurements}
          userInfo={userInfo}
          eventInfo={eventData}
        />
      )}
      <table className="event-table" cellSpacing={0} cellPadding={0} border={0}>
        <TableHeader
          sort={sort}
          headers={endUserMeasurementsTableHeaders}
          handleSorting={handleSorting}
        />
        <tbody className="event-tbody">
          {endUserEvents.map((e) => (
            <tr className="event-tr" key={e.eventId}>
              <td
                className="event-td cursor-pointer event-link"
                data-label="Event name"
              >
                <a
                  className="header-link"
                  href={`${
                    userRole === "END_USER"
                      ? MY_END_USER_MEASUREMENTS_LIST_ROUTE
                      : MEASUREMENTS_LIST_ROUTE
                  }/${e.eventId}/1`}
                >
                  {e.eventName}
                </a>
              </td>
              <td
                className={`event-td ${
                  e.eventType !== IN_PERSON_EVENT_TYPE && "is-color"
                }`}
                data-label="Department location"
              >
                {e.eventType === IN_PERSON_EVENT_TYPE ? e.location : "Remote"}
              </td>
              <td className="event-td" data-label="Start/End of the Event">
                <span className="event-period event-period-start">
                  {formateDate(e.startsAt)}
                </span>
                <span className="event-period event-period-end">
                  {formateDate(e.endsAt)}
                </span>
              </td>
              <td className="event-td" data-label="Product type">
                {e.productTypes.map((type) => type.name).join(", ")}
              </td>
              <td className="event-td" data-label="Status">
                <span
                  className={`status ${
                    e.eventParticipation?.measurementStatus === MEASURED
                      ? "status-success"
                      : e.eventParticipation?.measurementStatus ===
                        MEASUREMENT_REQUIRED
                      ? "status-warning"
                      : ""
                  }`}
                >
                  {e.eventParticipation?.measurementStatus === MEASURED
                    ? "Measured"
                    : e.eventParticipation?.measurementStatus ===
                      MEASUREMENT_REQUIRED
                    ? "Measurements required"
                    : ""}
                </span>
              </td>
              <td className="event-td" data-label="Measurements">
                {!!e.eventParticipation?.measurement?.measurementData
                  ?.length ? (
                  <>
                    <span
                      className={`measurement-table-list ${
                        activeEndUserMeasurements.find(
                          (measurement) => measurement === e.eventId
                        ) === e.eventId && "showMore"
                      }`}
                      onClick={() => handleSeeMeasurements(e.eventId)}
                    >
                      See measurements
                      <i className="measurement-list-icon icon icon-arrow"></i>
                    </span>
                    {activeEndUserMeasurements.find(
                      (measurement) => measurement === e.eventId
                    ) === e.eventId &&
                      e.eventParticipation?.measurement.measurementData
                        .sort((a, b) =>
                          sortAlphabet(
                            a.measurementParam.name,
                            b.measurementParam.name
                          )
                        )
                        .map((data) => (
                          <Fragment
                            key={data.measurementParam.measurementParamId}
                          >
                            <span>
                              {data.measurementParam.name} - {data.value};
                            </span>
                            <br />
                          </Fragment>
                        ))}
                  </>
                ) : (
                  <span className="is-color">No data yet</span>
                )}
              </td>
              <td className="event-td" data-label="Actions">
                <div className="header-dropdown">
                  {!!e.eventParticipation?.measurement?.measurementData
                    .length &&
                  e.eventParticipation?.measurementStatus === MEASURED ? (
                    <button
                      className="event-table-action event-table-edit"
                      onClick={() => {
                        handleUserMeasurements(e.eventParticipation, e);
                        updateMeasurements();
                      }}
                    >
                      <i className="event-table-action-icon icon icon-edit-action" />
                      <Tooltip type="edit" />
                    </button>
                  ) : !!e.eventParticipation?.measurement?.measurementData
                      .length &&
                    e.eventParticipation?.measurementStatus ===
                      MEASUREMENT_REQUIRED ? (
                    <button
                      className="event-table-action event-table-update"
                      onClick={() => {
                        handleUserMeasurements(e.eventParticipation, e);
                        updateMeasurements();
                      }}
                    >
                      <i className="event-table-action-icon icon icon-update-action" />
                      <Tooltip type="update" />
                    </button>
                  ) : (
                    <button
                      className="event-table-action event-table-add"
                      onClick={() => {
                        handleUserMeasurements(undefined, e);
                        updateMeasurements();
                      }}
                    >
                      <i className="event-table-action-icon icon icon-add-action" />
                      <Tooltip type="add" />
                    </button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
