import { useEffect, useMemo, useState } from "react";
import { IMeasurementParameter } from "@models/common/measurements";
import {
  getGenderToMeasurementParamRefs,
  getInitiallySelectedMeasurementParamIds,
  getInitialMeasurementParameters,
  moveField,
} from "@pages/Modals/ProductTypes/helper";
import { ProductType } from "@models/common/events";
import { getMeasurementsBySearchKey } from "@api/measurements";
import { getFormattedError } from "@services/http";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";

interface ISelectedMeasurement {
  male: IMeasurementParameter[] | undefined;
  female: IMeasurementParameter[] | undefined;
}

const defaultListTitle = (
  <>
    Choose measurements
    <span className="form-label-star">*</span>
  </>
);

export const useDraggableMeasurementList = ({
  selectedGender,
  productTypeInfo,
}: {
  selectedGender: string;
  productTypeInfo?: ProductType;
}) => {
  const [loading, setLoading] = useState(false);
  const [measurementParametersMale, setMeasurementParametersMale] =
    useState<IMeasurementParameter[]>();
  const [measurementParametersFemale, setMeasurementParametersFemale] =
    useState<IMeasurementParameter[]>();
  const [selectedMeasurement, setSelectedMeasurement] =
    useState<ISelectedMeasurement>();

  const isMaleSelected = selectedGender === "Male";
  const dispatch = useAppDispatch();

  const moveMaleField = (dragIndex: number, hoverIndex: number) => {
    setMeasurementParametersMale((prevState) =>
      moveField({ prevState, hoverIndex, dragIndex })
    );
  };
  const moveFemaleField = (dragIndex: number, hoverIndex: number) => {
    setMeasurementParametersFemale((prevState) =>
      moveField({ prevState, hoverIndex, dragIndex })
    );
  };
  const handleSelectMeasurementParam = (
    param: IMeasurementParameter[],
    type: "male" | "female"
  ) => {
    setSelectedMeasurement((prevState) => ({
      ...(prevState as ISelectedMeasurement),
      [type]: param,
    }));
  };

  useEffect(() => {
    if (
      !selectedMeasurement?.male?.length &&
      measurementParametersMale?.length
    ) {
      setSelectedMeasurement({
        ...(selectedMeasurement as ISelectedMeasurement),
        male: [measurementParametersMale[0]],
      });
    }

    if (
      !selectedMeasurement?.female?.length &&
      measurementParametersFemale?.length
    ) {
      setSelectedMeasurement({
        ...(selectedMeasurement as ISelectedMeasurement),
        female: [measurementParametersFemale[0]],
      });
    }
  }, [
    selectedMeasurement,
    measurementParametersMale,
    measurementParametersFemale,
  ]);

  const initiallySelectedMeasurementParamIdsMale = useMemo(
    () =>
      getInitiallySelectedMeasurementParamIds(
        productTypeInfo?.genderToMeasurementParams.MALE
      ),
    [productTypeInfo]
  );
  const initiallySelectedMeasurementParamIdsFemale = useMemo(
    () =>
      getInitiallySelectedMeasurementParamIds(
        productTypeInfo?.genderToMeasurementParams.FEMALE
      ),
    [productTypeInfo]
  );

  useEffect(() => {
    (async function asyncWrapper() {
      try {
        setLoading(true);
        const { content } = await getMeasurementsBySearchKey(dispatch);

        setMeasurementParametersMale(
          getInitialMeasurementParameters({
            rawItems: content,
            selectedIds: initiallySelectedMeasurementParamIdsMale,
          })
        );
        setMeasurementParametersFemale(
          getInitialMeasurementParameters({
            rawItems: content,
            selectedIds: initiallySelectedMeasurementParamIdsFemale,
          })
        );
      } catch (error) {
        return Promise.reject(getFormattedError(error));
      } finally {
        setLoading(false);
      }
    })();
  }, [
    initiallySelectedMeasurementParamIdsMale,
    initiallySelectedMeasurementParamIdsFemale,
  ]);

  useEffect(() => {
    if (
      initiallySelectedMeasurementParamIdsMale?.length ||
      initiallySelectedMeasurementParamIdsFemale?.length
    ) {
      if (isMaleSelected && !selectedMeasurement?.male?.length) {
        setSelectedMeasurement({
          ...(selectedMeasurement as ISelectedMeasurement),
          male: measurementParametersMale?.filter(({ measurementParamId }) =>
            initiallySelectedMeasurementParamIdsMale?.includes(
              measurementParamId
            )
          ),
        });
        return;
      }

      if (!isMaleSelected && !selectedMeasurement?.female?.length) {
        setSelectedMeasurement({
          ...(selectedMeasurement as ISelectedMeasurement),
          male: measurementParametersMale?.filter(({ measurementParamId }) =>
            initiallySelectedMeasurementParamIdsMale?.includes(
              measurementParamId
            )
          ),
          female: measurementParametersFemale?.filter(
            ({ measurementParamId }) =>
              initiallySelectedMeasurementParamIdsFemale?.includes(
                measurementParamId
              )
          ),
        });
        return;
      }
    }
  }, [
    initiallySelectedMeasurementParamIdsMale,
    initiallySelectedMeasurementParamIdsFemale,
    measurementParametersMale,
    measurementParametersFemale,
  ]);

  const maleListProps = useMemo(
    () => ({
      items: measurementParametersMale || [],
      title: defaultListTitle,
      type: "male" as const,
      handleSelectMeasurementParam,
      selectedMeasurement: selectedMeasurement?.male,
      moveField: moveMaleField,
    }),
    [
      measurementParametersMale,
      handleSelectMeasurementParam,
      selectedMeasurement?.male,
      moveMaleField,
    ]
  );
  const femaleListProps = useMemo(
    () => ({
      items: measurementParametersFemale || [],
      title: defaultListTitle,
      type: "female" as const,
      handleSelectMeasurementParam,
      selectedMeasurement: selectedMeasurement?.female,
      moveField: moveFemaleField,
    }),
    [
      measurementParametersFemale,
      handleSelectMeasurementParam,
      selectedMeasurement?.female,
      moveFemaleField,
    ]
  );

  const unisexListProps = useMemo(
    () => [
      {
        ...maleListProps,
        title: (
          <>
            Choose measurements for male
            <span className="form-label-star">*</span>
          </>
        ),
      },
      {
        ...femaleListProps,
        title: (
          <>
            Choose measurements for female
            <span className="form-label-star">*</span>
          </>
        ),
      },
    ],
    [maleListProps, femaleListProps]
  );

  const draggableMeasurementListProps = useMemo(() => {
    switch (selectedGender) {
      case "Male":
        return [maleListProps];
      case "Female":
        return [femaleListProps];
      default:
        return unisexListProps;
    }
  }, [maleListProps, femaleListProps, unisexListProps, selectedGender]);

  const genderToMeasurementParamRefs = useMemo(
    () =>
      getGenderToMeasurementParamRefs({
        selectedGender,
        selectedMeasurement,
        measurementParametersMale,
        measurementParametersFemale,
      }),
    [
      selectedGender,
      selectedMeasurement,
      measurementParametersMale,
      measurementParametersFemale,
    ]
  );

  return {
    draggableMeasurementListProps,
    draggableMeasurementListLoading: loading,
    genderToMeasurementParamRefs,
  };
};
