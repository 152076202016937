import { useState, useEffect, FC, useMemo } from "react";
import { Tooltip } from "@components/Tooltip";
import { Breadcrumbs } from "@components/Breadcrumbs";
import { IEvent, ProductType } from "@models/common/events";
import {
  END_USER_MEASUREMENTS_BREADCRUMBS,
  MALE_USER_GENDER,
  MY_END_USER_MEASUREMENTS_BREADCRUMBS,
  SELF_MEASURED,
  SOMETHING_WENT_WRONG_NOTIFICATION,
  UPDATE_MEASUREMENT_NOTIFICATION,
} from "@constants/commons";
import {
  getEventType,
  formateDate,
  getEventStatus,
  hideWindowScroll,
  showWindowScroll,
  sortAlphabet,
} from "@services/common";
import { useNavigate, useParams } from "react-router-dom";
import { findEventById } from "@api/events";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { Loader } from "@components/Loader";
import { MEASURED, MEASUREMENT_REQUIRED } from "@constants/events";
import { getEventParticipantMeasurement } from "@api/measurements";
import {
  GenderMeasurementParameter,
  IEventMeasurementInfo,
  IUserMeasurementInfo,
} from "@models/common/measurements";
import { ManageEndUserMeasurementsModal } from "@pages/Modals/Measurements/ManageEndUserMeasurementsModal";
import {
  EDIT_MEASUREMENT_TITLE,
  ADD_MEASUREMENT_TITLE,
} from "@constants/measurements";
import { IManageUserInfo } from "@models/components/secondary/manualadduser";
import { UserGender } from "@models/common/user";
import { useNotification } from "@hooks/common/useNotification";
import { Notification } from "@components/Notification";
import { updateEventParticipantMeasurement } from "@api/measurements";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { selectTokens } from "@store/selectors/tokens";
import { IEndUserEventDetails } from "@models/components/main/enduserevent";
import { END_USER_INFO_ROUTE, END_USER_LIST_ROUTE } from "@constants/routes";
import { IEndUser } from "@models/common/endUsers";
import { getProductTypesByIds } from "@api/types";
import { getFormattedError } from "@services/http";

interface EnhancedProductType extends ProductType {
  items: (GenderMeasurementParameter & { value?: string })[];
}

export const EndUserEventDetails: FC<IEndUserEventDetails> = ({ type }) => {
  const [eventData, setEventData] = useState<IEvent>();
  const [eventMeasurementsInfo, setEventMeasurementsInfo] = useState<
    IEventMeasurementInfo | undefined
  >();
  const [userMeasurementsInfo, setUserMeasurementsInfo] = useState<
    IUserMeasurementInfo | undefined
  >();
  const [userMeasurementManaged, setUserMeasurementManaged] = useState(false);
  const [isMeasurementOpened, setMeasurementOpened] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<IManageUserInfo | undefined>();

  const { eventId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tokensInfo = useAppSelector(selectTokens);

  const currentUserId = tokensInfo?.idToken?.payload?.["cognito:username"];
  const endUserInfo: IEndUser | null = !!Object.keys(
    JSON.parse(
      localStorage.getItem(`${process.env.REACT_APP_END_USER_INFO}`) || "{}"
    )
  ).length
    ? JSON.parse(
        localStorage.getItem(`${process.env.REACT_APP_END_USER_INFO}`) as string
      )
    : null;

  const {
    isActive,
    message: notificationMessage,
    type: notificationType,
    showNotification,
    closeNotification,
  } = useNotification();

  const updateMeasurements = () => {
    if (userMeasurementManaged) {
      setUserMeasurementManaged(false);
    }
    setMeasurementOpened(!isMeasurementOpened);
  };

  const handleUserMeasurements = (event: IEvent, measurements = true) => {
    const { eventParticipation: eventParticipant } = event;
    const gender = eventParticipant.endUser.gender;

    setUserMeasurementsInfo(
      eventParticipant && measurements
        ? {
            measurementData: eventParticipant?.measurement.measurementData,
            whoMeasured: eventParticipant?.measurement.whoMeasured,
            gender: eventParticipant?.measurement.gender,
          }
        : undefined
    );
    setEventMeasurementsInfo(undefined);

    setEventData(event);

    setUserInfo({
      userId: eventParticipant?.endUser.user.userId,
      firstName: eventParticipant?.endUser.user.firstName || "",
      lastName: eventParticipant?.endUser.user.lastName || "",
      email: eventParticipant?.endUser.user.email || "",
      phone_number: eventParticipant?.endUser.user.phoneNumber || "",
      badge: eventParticipant?.endUser.badgeNumber,
      gender: eventParticipant?.endUser.gender || gender,
    });
  };

  const handleUpdateMeasurements = async (data: {
    gender: UserGender;
    measurementData: {
      measurementParamId: string;
      value: string;
    }[];
    whoMeasured?: string;
  }) => {
    try {
      await updateEventParticipantMeasurement(
        dispatch,
        eventData?.eventId || "",
        currentUserId || "",
        data.gender,
        data.measurementData,
        data.whoMeasured
      );
      setUserMeasurementManaged(true);
      showNotification(UPDATE_MEASUREMENT_NOTIFICATION, "success");
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    }
  };

  const getEndUserMeasurements = async (endUserId: string) => {
    try {
      return await getEventParticipantMeasurement(
        dispatch,
        eventData?.eventId || "",
        endUserId
      );
    } catch (error) {
      return Promise.reject(getFormattedError(error));
    }
  };

  const getEventMeasurements = async () => {
    if (eventData?.productTypes) {
      try {
        return await getProductTypesByIds(
          dispatch,
          eventData?.productTypes.map(
            (productType) => productType.productTypeId
          )
        );
      } catch (error) {
        return Promise.reject(getFormattedError(error));
      }
    }
  };

  const additionBreadcrumbsData = {
    firstBreadcrumb: {
      title: "End users",
      onClick: () => navigate(`${END_USER_LIST_ROUTE}/1`),
    },
    secondBreadcrumb: {
      title: `End user - ${endUserInfo?.user.firstName} ${endUserInfo?.user.lastName}`,
      onClick: () =>
        navigate(`${END_USER_INFO_ROUTE}/${endUserInfo?.endUserId}/1`),
    },
  };

  useEffect(() => {
    if (eventId && !isMeasurementOpened) {
      (async function asyncWrapper() {
        try {
          const event = await findEventById(dispatch, eventId);
          setEventData(event);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [userMeasurementManaged, isMeasurementOpened]);

  useEffect(() => {
    (async function asyncWrapper() {
      if (isMeasurementOpened) {
        if (currentUserId && userMeasurementsInfo) {
          try {
            const endUserMeasurements = await getEndUserMeasurements(
              currentUserId
            );
            if (endUserMeasurements) {
              setUserMeasurementsInfo({
                measurementData: endUserMeasurements.measurementData,
                whoMeasured: endUserMeasurements.whoMeasured,
                gender: endUserMeasurements.gender,
              });
            } else {
              setUserMeasurementsInfo(undefined);
            }
          } catch (error) {
            setUserMeasurementsInfo(undefined);
            showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
            return Promise.reject(getFormattedError(error));
          }
        }
      }

      try {
        const eventMeasurements = await getEventMeasurements();
        if (eventMeasurements) {
          setEventMeasurementsInfo({
            measurementData: eventMeasurements,
            whoMeasured: SELF_MEASURED,
          });
        }
      } catch (error) {
        setEventMeasurementsInfo(undefined);
        showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
        return Promise.reject(getFormattedError(error));
      }
    })();
  }, [isMeasurementOpened, eventData]);

  useEffect(() => {
    if (isMeasurementOpened && eventMeasurementsInfo) {
      showWindowScroll();
    } else {
      hideWindowScroll();
    }
  }, [isMeasurementOpened, eventMeasurementsInfo]);

  const isMaleSelected =
    eventData?.eventParticipation?.endUser.gender === MALE_USER_GENDER;

  const measurementValues = useMemo(() => {
    return eventData?.eventParticipation?.measurement?.measurementData.reduce(
      (result, { measurementParam, value }) => ({
        ...result,
        [measurementParam.measurementParamId]: value,
      }),
      {} as Record<string, string>
    );
  }, [eventData?.eventParticipation?.measurement?.measurementData]);

  const measurementParams = useMemo(() => {
    return eventMeasurementsInfo?.measurementData
      ?.reduce((result, item) => {
        const genderToMeasurementParams = isMaleSelected
          ? item.genderToMeasurementParams?.MALE
          : item.genderToMeasurementParams?.FEMALE;

        const itemsWithValues = genderToMeasurementParams?.map(
          (genderToMeasurementParam) => ({
            ...genderToMeasurementParam,
            value:
              measurementValues?.[
                genderToMeasurementParam.measurementParam.measurementParamId
              ] ?? "",
          })
        );

        const processedItem = {
          ...item,
          items: [...(itemsWithValues || [])].sort((a, b) =>
            sortAlphabet(a.measurementParam.name, b.measurementParam.name)
          ),
        };

        return [...result, processedItem];
      }, [] as EnhancedProductType[])
      .filter(({ items }) => items.length);
  }, [
    isMaleSelected,
    measurementValues,
    eventMeasurementsInfo?.measurementData,
  ]);

  return (
    <>
      <Notification
        type={notificationType}
        message={notificationMessage}
        isActive={isActive}
        closeNotification={closeNotification}
      />
      {isMeasurementOpened && eventMeasurementsInfo && (
        <ManageEndUserMeasurementsModal
          title={
            userMeasurementsInfo
              ? EDIT_MEASUREMENT_TITLE
              : ADD_MEASUREMENT_TITLE
          }
          handleModalOpen={updateMeasurements}
          eventMeasurementsInfo={eventMeasurementsInfo}
          userMeasurementsInfo={userMeasurementsInfo}
          handleUser={handleUpdateMeasurements}
          userInfo={userInfo}
          eventInfo={eventData}
        />
      )}
      {measurementParams?.length && eventData ? (
        <>
          <main className="main-container info-container">
            {(type === "my_user" || endUserInfo) && (
              <Breadcrumbs
                title={`${eventData.eventName}`}
                type={
                  type === "my_user"
                    ? MY_END_USER_MEASUREMENTS_BREADCRUMBS
                    : END_USER_MEASUREMENTS_BREADCRUMBS
                }
                additionalData={
                  type === "another_user" ? additionBreadcrumbsData : undefined
                }
              />
            )}
            <h2 className="event-header event-title">{eventData.eventName}</h2>
            <ul className="sales">
              <li className="sales-item">
                Sales representative:{" "}
                <span className="sales-val">
                  {eventData.salesRep.firstName} {eventData.salesRep.lastName}
                </span>
              </li>
              <li className="sales-item">
                Dealer name:{" "}
                <span className="sales-val">
                  {eventData.dealer.dealerName}(
                  {eventData.dealer.user.firstName}{" "}
                  {eventData.dealer.user.lastName})
                </span>
              </li>
              <li className="sales-item">
                Event name:{" "}
                <span className="sales-val">{eventData.eventName}</span>
              </li>
              <li className="sales-item">
                Event type:{" "}
                <span className="sales-val">
                  {getEventType(eventData.eventType)}
                </span>
              </li>
              <li className="sales-item">
                Event location:{" "}
                <span className="sales-val">
                  {!!eventData.location ? eventData.location : "-"}
                </span>
              </li>
              <li className="sales-item">
                Event timeline:{" "}
                <span className="sales-val">
                  {formateDate(eventData.startsAt)} -{" "}
                  {formateDate(eventData.endsAt)}
                </span>
              </li>
              <li className="sales-item">
                Event status:{" "}
                <span className="sales-val">
                  {getEventStatus(eventData.status)}
                </span>
              </li>
              <li className="sales-item">
                Product types:{" "}
                <span className="sales-val">
                  {!!eventData.productTypes.length
                    ? eventData.productTypes
                        .map((productType) => productType.name)
                        .join(", ")
                    : "-"}
                </span>
              </li>
              <li className="sales-item">
                Order number:{" "}
                <span className="sales-val">
                  {!!eventData.orderNumber ? eventData.orderNumber : "-"}
                </span>
              </li>
            </ul>
          </main>
          <main className="main-container">
            <h2 className="event-header event-title">Measurements</h2>
            <div className="measurings">
              <div className="measurings-status">
                {!!eventData.eventParticipation?.measurement?.measurementData
                  .length &&
                eventData.eventParticipation?.measurementStatus === MEASURED ? (
                  <>
                    <span className="status status-success">Measured</span>
                    <button
                      className="event-table-action event-table-edit"
                      onClick={() => {
                        if (eventData?.eventParticipation?.endUser) {
                          handleUserMeasurements(eventData);
                          updateMeasurements();
                        } else {
                          showNotification(
                            SOMETHING_WENT_WRONG_NOTIFICATION,
                            "danger"
                          );
                        }
                      }}
                    >
                      <i className="event-table-action-icon icon icon-edit-action" />
                      <Tooltip type="edit" />
                    </button>
                  </>
                ) : !!eventData.eventParticipation?.measurement?.measurementData
                    .length &&
                  eventData.eventParticipation?.measurementStatus ===
                    MEASUREMENT_REQUIRED ? (
                  <>
                    <span className="status status-warning">
                      Measurements required
                    </span>
                    <button
                      className="event-table-action event-table-update"
                      onClick={() => {
                        if (eventData?.eventParticipation?.endUser) {
                          handleUserMeasurements(eventData);
                          updateMeasurements();
                        } else {
                          showNotification(
                            SOMETHING_WENT_WRONG_NOTIFICATION,
                            "danger"
                          );
                        }
                      }}
                    >
                      <i className="event-table-action-icon icon icon-update-action" />
                      <Tooltip type="update" />
                    </button>
                  </>
                ) : (
                  <>
                    <span className="status status-warning">
                      Measurements required
                    </span>
                    <button
                      className="event-table-action event-table-add"
                      onClick={() => {
                        if (eventData?.eventParticipation?.endUser) {
                          handleUserMeasurements(eventData, false);
                          updateMeasurements();
                        } else {
                          showNotification(
                            SOMETHING_WENT_WRONG_NOTIFICATION,
                            "danger"
                          );
                        }
                      }}
                    >
                      <i className="event-table-action-icon icon icon-add-action" />
                      <Tooltip type="add" />
                    </button>
                  </>
                )}
              </div>
              <div className="groups">
                {measurementParams?.map(({ items, name }) => (
                  <div key={name}>
                    <div className="group-label">{name}</div>
                    <ul className="measurings-list">
                      {items?.map(({ measurementParam, value }) => (
                        <li
                          className="measurings-item"
                          key={measurementParam.measurementParamId}
                        >
                          <span className="measurings-title">
                            {measurementParam.name}
                          </span>
                          <span
                            className={`measurings-data ${!value && "no-data"}`}
                          >
                            {value || "No data yet"}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </main>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
