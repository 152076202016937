import { DELETED_USER_STATUS } from "@constants/commons";
import { IEndUser, IEndUsersList } from "@models/common/endUsers";
import { IEndUserResponse, UserGender } from "@models/common/user";
import { http } from "@services/http";
import { AppDispatch } from "@store/store";
import { hasPhoneNumberValue } from "@services/common";

export const filterEndUser = async (
  dispatch: AppDispatch,
  email?: string,
  phone_number?: string
) =>
  await http.post<
    IEndUsersList,
    { emailIn: string[] | null; phoneNumberIn: string[] | null }
  >(
    "/api/v1/user/end-user/filter",
    {
      emailIn: email ? [email] : null,
      phoneNumberIn:
        hasPhoneNumberValue(phone_number as string) && phone_number
          ? [phone_number]
          : null,
    },
    dispatch
  );

export const createEndUser = async (
  dispatch: AppDispatch,
  user: IEndUserResponse,
  gender: UserGender,
  badgeNumber?: string
) =>
  await http.post<
    IEndUser,
    { user: IEndUserResponse; gender: UserGender; badgeNumber: string | null }
  >(
    "/api/v1/user/end-user",
    { user, gender, badgeNumber: badgeNumber || null },
    dispatch
  );

export const updateEndUser = async (
  dispatch: AppDispatch,
  endUserId: string,
  user: IEndUserResponse,
  gender: UserGender,
  badgeNumber?: string
) =>
  await http.post<
    IEndUser,
    {
      user: IEndUserResponse;
      gender: UserGender;
      badgeNumber: string | null;
      anonymous?: boolean;
      displayId?: number;
    }
  >(
    `/api/v1/user/end-user/${endUserId}`,
    { user, gender, badgeNumber: badgeNumber || null },
    dispatch
  );

export const deleteEndUser = async (dispatch: AppDispatch, endUserId: string) =>
  await http.delete(
    `/api/v1/user/end-user/${endUserId}`,
    {
      userStatus: DELETED_USER_STATUS,
    },
    dispatch
  );

export const resendInviteEndUser = async (
  dispatch: AppDispatch,
  userId: string
) =>
  await http.post(
    `/api/v1/user/end-user/${userId}/send-invite`,
    null,
    dispatch
  );

export const listOfFilteredEndUser = async (
  dispatch: AppDispatch,
  page: number,
  size: number,
  sort: string,
  isAnonymous: boolean,
  searchKey?: string,
  dealerId?: string,
  salesRepId?: string
) =>
  await http.post<
    IEndUsersList,
    {
      salesRepId: string | null;
      dealerId: string | null;
      searchKey: string | null;
      isAnonymous: boolean | null;
    }
  >(
    `/api/v1/user/end-user/filter?page=${page}&size=${size}&sort=${sort}`,
    {
      salesRepId: salesRepId || null,
      dealerId: dealerId || null,
      searchKey: searchKey || null,
      isAnonymous: isAnonymous ? null : false,
    },
    dispatch
  );

export const findEndUsersBySearchKey = async (
  dispatch: AppDispatch,
  searchKey?: string
) =>
  await http.post<IEndUsersList, { searchKey: string | null }>(
    `/api/v1/user/end-user/filter`,
    { searchKey: searchKey || null },
    dispatch
  );

export const getEndUserById = async (
  dispatch: AppDispatch,
  endUserId: string
) => await http.get<IEndUser>(`/api/v1/user/end-user/${endUserId}`, dispatch);
