import { FC, memo, useState } from "react";
import { DraggableMeasurementListProps } from "@models/common/measurements";
import { DraggableMeasurement } from "@components/DraggableMeasurement";

export const DraggableMeasurementList: FC<DraggableMeasurementListProps> = memo(
  ({
    items,
    title,
    type,
    moveField,
    selectedMeasurement,
    handleSelectMeasurementParam,
  }) => {
    const [hoverIndex, setHoverIndex] = useState<number | null>(null);

    return (
      <>
        <span className="modal-tips-title">{title}</span>
        <ul className="modal-tips-list">
          {items.map((param, index) => (
            <DraggableMeasurement
              key={param.measurementParamId}
              param={param}
              index={index}
              type={type}
              handleSelectMeasurementParam={handleSelectMeasurementParam}
              selectedMeasurement={selectedMeasurement}
              moveField={moveField}
              hoverIndex={hoverIndex}
              onHoverIndexChange={setHoverIndex}
            />
          ))}
        </ul>
      </>
    );
  }
);
