import { Fragment, useEffect, useState } from "react";
import { Tooltip } from "@components/Tooltip";
import { Loader } from "@components/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EVENT_ROLE, IEvent } from "@models/common/events";
import { Breadcrumbs } from "@components/Breadcrumbs";
import {
  ACCEPTED_USER_STATUS,
  ADMIN_ROLE,
  DEALER_ROLE,
  DELETE_USER_EVENT_NOTIFICATION,
  MEASUREMENTS_BREADCRUMBS,
  NO_END_USERS_PLUG_TYPE,
  NO_FOUND_PLUG_TYPE,
  PARENT_ROLE,
  PENDING_USER_STATUS,
  SEARCH_QUERY_KEY,
  SOMETHING_WENT_WRONG_NOTIFICATION,
  TABLE_ROWS_LIMIT,
  UPDATE_MEASUREMENT_NOTIFICATION,
} from "@constants/commons";
import { findEventById } from "@api/events";
import {
  formateDate,
  getEventStatus,
  getEventType,
  getPage,
  getSortTypes,
  getFormattedUserRole,
  hideWindowScroll,
  showWindowScroll,
  sortAlphabet,
  getUserRoleFromToken,
} from "@services/common";
import { TableHeader } from "@components/TableHeader";
import {
  deleteEventParticipants,
  findEventParticipants,
  sendInvitationToEventParticipant,
} from "@api/eventParticipants";
import {
  IEventParticipant,
  IEventParticipantsList,
} from "@models/common/eventParticipants";
import { endUsersMeasurementsHeaders } from "@constants/headers";
import { MEASURED, MEASUREMENT_REQUIRED } from "@constants/events";
import { DropdownMenu } from "@components/Dropdown/DropdownMenu";
import { Pagination } from "@components/Pagination";
import { Plug } from "@components/Plug";
import { HeaderBarTable } from "@components/HeaderBarTable";
import {
  NO_END_USERS_PLUG_ERROR,
  NO_FOUND_PLUG_ERROR,
} from "@constants/errors";
import { ISortType } from "@models/common/app";
import { ManageMeasurementsModal } from "@pages/Modals/Measurements/ManageMeasurementsModal";
import {
  ADD_MEASUREMENT_TITLE,
  EDIT_MEASUREMENT_TITLE,
} from "@constants/measurements";
import {
  updateEventParticipantMeasurement,
  getEventParticipantMeasurement,
} from "@api/measurements";
import {
  IEventMeasurementInfo,
  IUserMeasurementInfo,
} from "@models/common/measurements";
import { AlertModal } from "@components/AlertModal";
import {
  DELETE_USER_TITLE,
  EDIT_END_USER_TITLE,
  ADD_END_USER_TITLE,
  DELETE_USER_EVENT_BODY,
} from "@constants/users";
import { IManageUserInfo } from "@models/components/secondary/manualadduser";
import { ManageEndUserModal } from "@pages/Modals/Users/ManageEndUserModal";
import { UserGender } from "@models/common/user";
import { useNotification } from "@hooks/common/useNotification";
import { Notification } from "@components/Notification";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { selectTokens } from "@store/selectors/tokens";
import queryString from "query-string";
import { MiniLoader } from "@components/Loader/MiniLoader";
import { MAIN_ROUTE } from "@constants/routes";
import { handleUpdateEventParticipant } from "@api/common";
import { getProductTypesByIds } from "@api/types";
import { getFormattedError } from "@services/http";

export const EventDetails = () => {
  const location = useLocation();
  const parsedSearchedQuery = queryString.parse(location.search)?.[
    SEARCH_QUERY_KEY
  ];

  const [eventData, setEventData] = useState<IEvent>();
  const [sortType, setSortType] = useState<ISortType>();
  const [eventParticipants, setEventParticipants] =
    useState<IEventParticipantsList>();
  const [activeEndUserMeasurements, setActiveEndUserMeasurements] = useState<
    string[]
  >([]);
  const [activeEndUserMeasurement, setActiveEndUserMeasurement] =
    useState<string>("");
  const [isDropdownOpened, setDropdownOpened] = useState<boolean>(false);
  const [searchedValue, setSearchedValue] = useState<string | undefined>(
    typeof parsedSearchedQuery === "string" ? parsedSearchedQuery : undefined
  );
  const [isMeasurementOpened, setMeasurementOpened] = useState<boolean>(false);
  const [userMeasurementManaged, setUserMeasurementManaged] = useState(false);
  const [eventMeasurementsInfo, setEventMeasurementsInfo] = useState<
    IEventMeasurementInfo | undefined
  >();
  const [userMeasurementsInfo, setUserMeasurementsInfo] = useState<
    IUserMeasurementInfo | undefined
  >();
  const [deleteWindowOpen, setDeleteWindowOpen] = useState(false);
  const [userInfo, setUserInfo] = useState<IManageUserInfo | undefined>();
  const [openAddUserModal, setAddUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [participantManaged, setParticipantManaged] = useState(false);
  const [searchParams, setSearchParams] = useState<
    queryString.ParsedQuery<string> | undefined
  >(queryString.parse(location.search));
  const [searchFieldFocused, setSearchFieldFocused] = useState(false);

  const { eventId, page } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const tokensInfo = useAppSelector(selectTokens);
  const userRole = getUserRoleFromToken(tokensInfo);
  const pageId = getPage(page);

  const {
    isActive,
    message: notificationMessage,
    type: notificationType,
    showNotification,
    closeNotification,
  } = useNotification();

  const handleSearchFieldFocus = () => {
    setSearchFieldFocused(true);
  };

  const getEventMeasurements = async () => {
    if (eventData?.productTypes) {
      try {
        return await getProductTypesByIds(
          dispatch,
          eventData?.productTypes.map(
            (productType) => productType.productTypeId
          )
        );
      } catch (error) {
        return Promise.reject(getFormattedError(error));
      }
    }
  };

  const getEndUserMeasurements = async (endUserId: string) => {
    try {
      return await getEventParticipantMeasurement(
        dispatch,
        eventId || "",
        endUserId
      );
    } catch (error) {
      return Promise.reject(getFormattedError(error));
    }
  };

  const handleAddUserModalOpen = () => {
    participantManaged && setParticipantManaged(false);
    setAddUserModal(!openAddUserModal);
    setUserInfo(undefined);
  };

  const handleEditUserModalOpen = () => {
    participantManaged && setParticipantManaged(false);
    setOpenEditUserModal(!openEditUserModal);
  };

  const handleDropdownMenu = (dropdownState: boolean) => {
    setDropdownOpened(dropdownState);
  };

  const toggleDropdown = (endUserId: string) => {
    handleDropdownMenu(!isDropdownOpened);
    setActiveEndUserMeasurement(endUserId);
  };

  const updateMeasurements = (endUserId: string) => {
    userMeasurementManaged && setUserMeasurementManaged(false);
    setMeasurementOpened(!isMeasurementOpened);
    setActiveEndUserMeasurement(endUserId);
  };

  const handleSearchedEvents = (searchedValue: string | undefined) => {
    setSearchedValue(searchedValue);
  };

  const handleManaged = (state: boolean) => {
    setParticipantManaged(state);
  };

  const handleUpdateMeasurements = async (data: {
    gender: UserGender;
    measurementData: {
      measurementParamId: string;
      value: string;
    }[];
    whoMeasured?: string;
  }) => {
    try {
      await updateEventParticipantMeasurement(
        dispatch,
        eventId || "",
        activeEndUserMeasurement,
        data.gender,
        data.measurementData,
        data.whoMeasured
      );
      setUserMeasurementManaged(true);
      showNotification(UPDATE_MEASUREMENT_NOTIFICATION, "success");
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    }
  };

  useEffect(() => {
    if (isMeasurementOpened) {
      (async function asyncWrapper() {
        if (userInfo?.userId) {
          try {
            const endUserMeasurements = await getEndUserMeasurements(
              activeEndUserMeasurement
            );
            if (endUserMeasurements) {
              setUserMeasurementsInfo({
                measurementData: endUserMeasurements.measurementData,
                whoMeasured: endUserMeasurements.whoMeasured,
                gender: endUserMeasurements.gender,
              });
            } else {
              setUserMeasurementsInfo(undefined);
            }
          } catch (error) {
            setUserMeasurementsInfo(undefined);
            showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
            return Promise.reject(getFormattedError(error));
          }
        }

        try {
          const eventMeasurements = await getEventMeasurements();
          if (eventMeasurements) {
            setEventMeasurementsInfo({
              measurementData: eventMeasurements,
              whoMeasured: `${eventData?.salesRep.firstName} ${eventData?.salesRep.lastName}`,
            });
          } else {
            setEventMeasurementsInfo(undefined);
          }
        } catch (error) {
          setEventMeasurementsInfo(undefined);
          showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [isMeasurementOpened]);

  useEffect(() => {
    if (eventId) {
      (async function asyncWrapper() {
        try {
          const event = await findEventById(dispatch, eventId);
          setEventData(event);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [eventId]);

  useEffect(() => {
    if (searchFieldFocused) {
      if (!!searchedValue) {
        if (pageId + 1 === 1) {
          navigate(`?${SEARCH_QUERY_KEY}=${searchedValue}`);
        } else {
          navigate(
            `/${
              location.pathname.split(MAIN_ROUTE)[1]
            }/${eventId}/1?${SEARCH_QUERY_KEY}=${searchedValue}`
          );
        }
      } else {
        navigate("");
      }
    }
  }, [searchedValue]);

  useEffect(() => {
    const searchQuery = queryString.parse(location.search);
    if (!!Object.keys(searchQuery).length) {
      setSearchParams(searchQuery);
    } else {
      setSearchParams({});
    }
  }, [location.search]);

  useEffect(() => {
    if (!!searchParams?.[SEARCH_QUERY_KEY]) {
      handleSearchedEvents(searchParams[SEARCH_QUERY_KEY].toString());
    }
  }, [searchParams?.[SEARCH_QUERY_KEY]]);

  useEffect(() => {
    if (
      !openEditUserModal &&
      !openAddUserModal &&
      !deleteWindowOpen &&
      !isMeasurementOpened &&
      (searchedValue
        ? searchParams?.[SEARCH_QUERY_KEY]
        : !searchParams?.[SEARCH_QUERY_KEY])
    ) {
      (async function asyncWrapper() {
        try {
          const participants = await findEventParticipants(
            dispatch,
            eventId || "",
            searchedValue,
            pageId,
            TABLE_ROWS_LIMIT,
            sortType
              ? sortType.filteredParameter
              : "endUser.user.firstName,endUser.user.lastName,asc"
          );
          setEventParticipants(participants);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [
    pageId,
    sortType,
    isMeasurementOpened,
    deleteWindowOpen,
    openAddUserModal,
    openEditUserModal,
    participantManaged,
    searchParams?.[SEARCH_QUERY_KEY],
  ]);

  const handleSorting = (parameter: string) => {
    let filteredParameter;

    if (parameter === "Name") {
      filteredParameter = "endUser.user.firstName,endUser.user.lastName";
    }
    if (parameter === "Status") {
      filteredParameter = "measurementStatus";
    }

    setSortType(getSortTypes(sortType, filteredParameter, parameter));
  };

  const handleDeleteWindowOpen = () => {
    setDeleteWindowOpen(!deleteWindowOpen);
  };

  const handleUserDelete = async (userId: string) => {
    if (participantManaged) {
      setParticipantManaged(false);
    }
    if (eventId) {
      try {
        await deleteEventParticipants(dispatch, eventId, userId);
        setParticipantManaged(true);
        showNotification(DELETE_USER_EVENT_NOTIFICATION, "success");
      } catch (error) {
        showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
        return Promise.reject(getFormattedError(error));
      } finally {
        setDropdownOpened(false);
      }
    }
  };

  const handleEndUsersResendInvite = async (userId: string) => {
    if (eventId) {
      try {
        await sendInvitationToEventParticipant(dispatch, eventId, userId);
      } catch (error) {
        return Promise.reject(getFormattedError(error));
      } finally {
        setDropdownOpened(false);
      }
    }
  };

  const handleUserMeasurements = (
    eventParticipant: IEventParticipant | undefined
  ) => {
    setUserMeasurementsInfo(undefined);
    setEventMeasurementsInfo(undefined);

    setUserInfo(
      eventParticipant
        ? {
            userId: eventParticipant.endUser.user.userId,
            firstName: eventParticipant.endUser.user.firstName || "",
            lastName: eventParticipant.endUser.user.lastName || "",
            email: eventParticipant.endUser.user.email || "",
            phone_number: eventParticipant.endUser.user.phoneNumber || "",
            badge: eventParticipant.endUser.badgeNumber,
            gender: eventParticipant.endUser.gender as UserGender,
            anonymous: eventParticipant.endUser.anonymous || false,
          }
        : undefined
    );
  };

  const handleUserEventEdit = (eventParticipant: IEventParticipant) => {
    handleUserMeasurements(eventParticipant);

    handleEditUserModalOpen();
  };

  const handleSeeMeasurements = (endUserId: string) => {
    if (activeEndUserMeasurements.includes(endUserId)) {
      setActiveEndUserMeasurements((measurements) =>
        measurements.filter((measurementId) => measurementId !== endUserId)
      );
    } else {
      setActiveEndUserMeasurements((measurementIds) => [
        ...measurementIds,
        endUserId,
      ]);
    }
  };

  const measurementsTableDropdownItems = (
    eventParticipant: IEventParticipant
  ) =>
    [
      {
        text: "Edit personal information",
        onClick: () => {
          handleUserEventEdit(eventParticipant);
        },
      },
      eventParticipant.endUser.user.status === PENDING_USER_STATUS ||
      (eventParticipant.endUser.user.status === ACCEPTED_USER_STATUS &&
        userInfo?.anonymous === false)
        ? {
            text: "Resend invite",
            onClick: async () => {
              try {
                await handleEndUsersResendInvite(
                  eventParticipant.endUser.user.userId
                );
              } catch (error) {
                showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
                return Promise.reject(getFormattedError(error));
              } finally {
                handleDropdownMenu(false);
              }
            },
          }
        : null,
      eventParticipant.endUser.user.status === PENDING_USER_STATUS ||
      eventParticipant.endUser.user.status === ACCEPTED_USER_STATUS
        ? {
            text: "Delete user",
            onClick: () => {
              handleDeleteWindowOpen();
            },
          }
        : null,
    ].filter((item) => !!item);

  useEffect(() => {
    if (
      openAddUserModal ||
      openEditUserModal ||
      (isMeasurementOpened && eventMeasurementsInfo)
    ) {
      showWindowScroll();
    } else {
      hideWindowScroll();
    }
  }, [
    openAddUserModal,
    openEditUserModal,
    isMeasurementOpened,
    eventMeasurementsInfo,
  ]);

  return (
    <>
      <Notification
        type={notificationType}
        message={notificationMessage}
        isActive={isActive}
        closeNotification={closeNotification}
      />
      {deleteWindowOpen && (
        <AlertModal
          type="delete"
          title={DELETE_USER_TITLE}
          body={DELETE_USER_EVENT_BODY}
          handleOpen={handleDeleteWindowOpen}
          handleConfirm={handleUserDelete}
          id={activeEndUserMeasurement}
        />
      )}
      {(openAddUserModal || openEditUserModal) && (
        <ManageEndUserModal
          title={userInfo ? EDIT_END_USER_TITLE : ADD_END_USER_TITLE}
          handleModalOpen={
            userInfo ? handleEditUserModalOpen : handleAddUserModalOpen
          }
          userInfo={userInfo}
          handleUser={handleUpdateEventParticipant}
          eventID={eventId}
          handleManaged={handleManaged}
          handleEditUserModalOpen={handleEditUserModalOpen}
          handleAddUserModalOpen={handleAddUserModalOpen}
          createParticipant={!userInfo}
        />
      )}
      {isMeasurementOpened && eventMeasurementsInfo && (
        <ManageMeasurementsModal
          title={
            userMeasurementsInfo
              ? EDIT_MEASUREMENT_TITLE
              : ADD_MEASUREMENT_TITLE
          }
          handleModalOpen={updateMeasurements}
          eventMeasurementsInfo={eventMeasurementsInfo}
          userMeasurementsInfo={userMeasurementsInfo}
          handleUser={handleUpdateMeasurements}
          userInfo={userInfo}
        />
      )}
      {eventData && eventParticipants ? (
        <>
          <main className="main-container info-container">
            <Breadcrumbs
              title={`${eventData.eventName}`}
              type={MEASUREMENTS_BREADCRUMBS}
            />
            <h2 className="event-header event-title">{eventData.eventName}</h2>
            <ul className="sales">
              <li className="sales-item">
                Event creator:{" "}
                <span className="sales-val">
                  {getFormattedUserRole(eventData.createdBy.role)} -{" "}
                  {eventData.createdBy.firstName} {eventData.createdBy.lastName}
                </span>
              </li>
              {(userRole === ADMIN_ROLE ||
                userRole === PARENT_ROLE ||
                userRole === DEALER_ROLE) && (
                <li className="sales-item">
                  Assignee:{" "}
                  <span className="sales-val">
                    {getFormattedUserRole(eventData.salesRep.role)} -{" "}
                    {eventData.salesRep.firstName} {eventData.salesRep.lastName}
                  </span>
                </li>
              )}
              <li className="sales-item">
                Event name:{" "}
                <span className="sales-val">{eventData.eventName}</span>
              </li>
              <li className="sales-item">
                Event type:{" "}
                <span className="sales-val">
                  {getEventType(eventData.eventType)}
                </span>
              </li>
              <li className="sales-item">
                Product types:{" "}
                <span className="sales-val">
                  {!!eventData.productTypes.length
                    ? eventData.productTypes
                        .map((productType) => productType.name)
                        .join(", ")
                    : "-"}
                </span>
              </li>
              <li className="sales-item">
                Department location:{" "}
                <span className="sales-val">
                  {!!eventData.location ? eventData.location : "-"}
                </span>
              </li>
              <li className="sales-item">
                Event timeline:{" "}
                <span className="sales-val">
                  {formateDate(eventData.startsAt)} -{" "}
                  {formateDate(eventData.endsAt)}
                </span>
              </li>
              <li className="sales-item">
                Event status:{" "}
                <span className="sales-val">
                  {getEventStatus(eventData.status)}
                </span>
              </li>
              <li className="sales-item">
                Event ID:{" "}
                <span className="sales-val">{eventData.eventNumber}</span>
              </li>
              <li className="sales-item">
                Order number:{" "}
                <span className="sales-val">
                  {!!eventData.orderNumber ? eventData.orderNumber : "-"}
                </span>
              </li>
            </ul>
          </main>
          <main className="main-container">
            <HeaderBarTable
              searchedValue={searchedValue}
              handleSearchedValues={handleSearchedEvents}
              title="List with end-users"
              btnTitles="Add end-users"
              btnHandlers={[handleAddUserModalOpen]}
              sort={sortType}
              handleSearchFieldFocus={handleSearchFieldFocus}
            />
            {!!eventParticipants?.content?.length ? (
              <div className="event-table-container">
                <table
                  className="event-table"
                  cellSpacing={0}
                  cellPadding={0}
                  border={0}
                >
                  <TableHeader
                    sort={sortType}
                    headers={endUsersMeasurementsHeaders}
                    handleSorting={handleSorting}
                  />
                  <tbody className="event-tbody">
                    {eventParticipants.content.map((eventParticipant) => (
                      <tr
                        className="event-tr"
                        key={eventParticipant.endUser.endUserId}
                      >
                        <td className="event-td" data-label="Name">
                          {eventParticipant.endUser.user.firstName}{" "}
                          {eventParticipant.endUser.user.lastName}
                        </td>
                        <td className="event-td" data-label="Email">
                          {eventParticipant.endUser.user.email
                            ? eventParticipant.endUser.user.email
                            : "-"}
                        </td>
                        <td className="event-td" data-label="Phone number">
                          {eventParticipant.endUser.user.phoneNumber
                            ? eventParticipant.endUser.user.phoneNumber
                            : "-"}
                        </td>
                        <td className="event-td" data-label="Badge number">
                          {eventParticipant.endUser.badgeNumber
                            ? eventParticipant.endUser.badgeNumber
                            : "-"}
                        </td>
                        <td className="event-td" data-label="Status">
                          <span
                            className={`status ${
                              eventParticipant?.measurementStatus === MEASURED
                                ? "status-success"
                                : eventParticipant?.measurementStatus ===
                                  MEASUREMENT_REQUIRED
                                ? "status-warning"
                                : "-"
                            }`}
                          >
                            {eventParticipant?.measurementStatus === MEASURED
                              ? "Measured"
                              : eventParticipant?.measurementStatus ===
                                MEASUREMENT_REQUIRED
                              ? "Measurements required"
                              : "-"}
                          </span>
                        </td>
                        <td className="event-td" data-label="Measurements">
                          {!!eventParticipant?.measurement?.measurementData
                            .length ? (
                            <>
                              <span
                                className={`measurement-table-list ${
                                  activeEndUserMeasurements.find(
                                    (measurement) =>
                                      measurement ===
                                      eventParticipant.endUser.endUserId
                                  ) === eventParticipant.endUser.endUserId &&
                                  "showMore"
                                }`}
                                onClick={() =>
                                  handleSeeMeasurements(
                                    eventParticipant.endUser.endUserId
                                  )
                                }
                              >
                                See measurements
                                <i className="measurement-list-icon icon icon-arrow"></i>
                              </span>
                              {activeEndUserMeasurements.find(
                                (measurement) =>
                                  measurement ===
                                  eventParticipant.endUser.endUserId
                              ) === eventParticipant.endUser.endUserId &&
                                eventParticipant?.measurement.measurementData
                                  .sort((a, b) =>
                                    sortAlphabet(
                                      a.measurementParam.name,
                                      b.measurementParam.name
                                    )
                                  )
                                  .map((data, index) => (
                                    <Fragment key={index}>
                                      <span>
                                        {data.measurementParam.name} -{" "}
                                        {data.value};
                                      </span>
                                      <br />
                                    </Fragment>
                                  ))}
                            </>
                          ) : (
                            <span className="is-color">No data yet</span>
                          )}
                        </td>
                        <td className="event-td" data-label="Who measured">
                          {eventParticipant?.measurement?.whoMeasured || "-"}
                        </td>
                        <td className="event-td" data-label="Actions">
                          <div className="header-dropdown">
                            {!!eventParticipant?.measurement?.measurementData
                              .length &&
                            eventParticipant?.measurementStatus === MEASURED ? (
                              <button
                                className="event-table-action event-table-edit"
                                onClick={() => {
                                  handleUserMeasurements(eventParticipant);
                                  updateMeasurements(
                                    eventParticipant.endUser.endUserId
                                  );
                                }}
                              >
                                <i className="event-table-action-icon icon icon-edit-action" />
                                <Tooltip type="edit" />
                              </button>
                            ) : !!eventParticipant?.measurement?.measurementData
                                .length &&
                              eventParticipant?.measurementStatus ===
                                MEASUREMENT_REQUIRED ? (
                              <button
                                className="event-table-action event-table-update"
                                onClick={() => {
                                  handleUserMeasurements(eventParticipant);
                                  updateMeasurements(
                                    eventParticipant.endUser.endUserId
                                  );
                                }}
                              >
                                <i className="event-table-action-icon icon icon-update-action" />
                                <Tooltip type="update" />
                              </button>
                            ) : (
                              <button
                                className="event-table-action event-table-add"
                                onClick={() => {
                                  handleUserMeasurements(undefined);
                                  updateMeasurements(
                                    eventParticipant.endUser.endUserId
                                  );
                                }}
                              >
                                <i className="event-table-action-icon icon icon-add-action" />
                                <Tooltip type="add" />
                              </button>
                            )}
                            {!!measurementsTableDropdownItems(eventParticipant)
                              ?.length && (
                              <>
                                <button
                                  className={`header-dropdown-name ${
                                    isDropdownOpened && "isActive"
                                  } event-table-btn`}
                                  onClick={() =>
                                    toggleDropdown(
                                      eventParticipant.endUser.endUserId
                                    )
                                  }
                                >
                                  <i className="event-table-icon icon icon-kebab" />
                                </button>
                                {isDropdownOpened &&
                                  activeEndUserMeasurement ===
                                    eventParticipant.endUser.endUserId && (
                                    <DropdownMenu
                                      dropdownItems={measurementsTableDropdownItems(
                                        eventParticipant
                                      )}
                                      dropdownOpened={isDropdownOpened}
                                      setDropdownOpened={handleDropdownMenu}
                                    />
                                  )}
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  dataLength={eventParticipants.totalElements}
                  pageId={pageId}
                  role={EVENT_ROLE}
                  filename={eventData.eventName}
                />
              </div>
            ) : (
              <MiniLoader
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5vh",
                }}
                time={3}
                afterload={
                  <Plug
                    message={
                      searchParams && !!Object.keys(searchParams)?.length
                        ? NO_FOUND_PLUG_ERROR
                        : NO_END_USERS_PLUG_ERROR
                    }
                    type={
                      searchParams && !!Object.keys(searchParams)?.length
                        ? NO_FOUND_PLUG_TYPE
                        : NO_END_USERS_PLUG_TYPE
                    }
                  />
                }
              />
            )}
          </main>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
