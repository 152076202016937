import { DELETED_USER_STATUS } from "@constants/commons";
import { IDealer, IDealerList } from "@models/common/dealer";
import { IUserResponse } from "@models/common/user";
import { http } from "@services/http";
import { AppDispatch } from "@store/store";

export const getDealerBySearchKey = async (
  dispatch: AppDispatch,
  searchKey: string,
  showHidden: boolean = true,
  size: number = 100
) =>
  await http.get<IDealerList>(
    !!searchKey
      ? `/api/v1/user/dealer?searchKey=${searchKey}&showHidden=${showHidden}&size=${size}`
      : `/api/v1/user/dealer?showHidden=${showHidden}&size=${size}`,
    dispatch
  );

export const findDealersBySearchKey = async (
  dispatch: AppDispatch,
  searchKey?: string,
  parentId?: string
) =>
  await http.post<
    IDealerList,
    { searchKey: string | null; parentUserId: string | null }
  >(
    `/api/v1/user/dealer/filter`,
    { searchKey: searchKey || null, parentUserId: parentId || null },
    dispatch
  );

export const listOfFilteredDealer = async (
  dispatch: AppDispatch,
  page: number,
  size: number,
  sort: string,
  searchedValue?: string,
  parentId?: string
) =>
  await http.post<
    IDealerList,
    { parentUserId: string | null; searchKey: string | null }
  >(
    `/api/v1/user/dealer/filter?page=${page}&size=${size}&sort=${sort}`,
    { parentUserId: parentId || null, searchKey: searchedValue || null },
    dispatch
  );

export const resendInviteDealer = async (
  dispatch: AppDispatch,
  dealerId: string
) =>
  await http.post(
    `/api/v1/user/dealer/${dealerId}/send-invite`,
    null,
    dispatch
  );

export const deleteDealer = async (dispatch: AppDispatch, dealerId: string) =>
  await http.put(
    `/api/v1/user/dealer/${dealerId}`,
    {
      userStatus: DELETED_USER_STATUS,
    },
    dispatch
  );

export const updateDealer = async (
  dispatch: AppDispatch,
  dealerId: string,
  dealerData: {
    firstName: string;
    lastName: string;
    phoneNumber: string | undefined;
    email: string | undefined;
    salesRep?: boolean;
  },
  dealerName: string,
  assignedParents: string[] | undefined
) =>
  await http.post<
    IDealer,
    {
      user: IUserResponse;
      dealerName: string;
      assignedParents: string[] | null;
      salesRep: boolean | false;
    }
  >(
    `/api/v1/user/dealer/${dealerId}`,
    {
      user: dealerData,
      dealerName,
      assignedParents: assignedParents || null,
      salesRep: dealerData.salesRep || false,
    },
    dispatch
  );

export const createDealer = async (
  dispatch: AppDispatch,
  dealerData: {
    firstName: string;
    lastName: string;
    phoneNumber: string | undefined;
    email: string | undefined;
    salesRep?: boolean;
  },
  dealerName: string,
  assignedParents: string[] | undefined
) =>
  await http.post<
    IDealer,
    {
      user: IUserResponse;
      dealerName: string;
      assignedParents: string[] | null;
      salesRep?: boolean;
    }
  >(
    `/api/v1/user/dealer`,
    {
      user: dealerData,
      dealerName,
      assignedParents: assignedParents || null,
      salesRep: dealerData.salesRep || false,
    },
    dispatch
  );

export const getDealerById = async (dispatch: AppDispatch, dealerId: string) =>
  await http.get<IDealer>(`/api/v1/user/dealer/${dealerId}`, dispatch);

export const filterDealer = async (
  dispatch: AppDispatch,
  email: string | undefined,
  phone_number: string | undefined
) =>
  await http.post<
    IDealerList,
    { emailIn: string[] | null; phoneNumberIn: string[] | null }
  >(
    "/api/v1/user/dealer/filter",
    {
      emailIn: email ? [email] : null,
      phoneNumberIn: phone_number ? [phone_number] : null,
    },
    dispatch
  );

export const generateDealersList = async (dispatch: AppDispatch) =>
  await http.post<Blob, null>("/api/v1/user/dealer/export", null, dispatch, {
    responseType: "blob",
  });
