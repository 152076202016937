import { FC } from "react";
import { ITooltip } from "@models/components/secondary/tooltip";
import {
  ADD_MEASUREMENT_TITLE,
  EDIT_MEASUREMENT_TITLE,
  UPDATE_MEASUREMENT_TITLE,
} from "@constants/measurements";

export const Tooltip: FC<ITooltip> = ({ type }) => {
  return (
    <span className={`event-table-tooltip tooltip-${type}`}>
      {type === "add"
        ? ADD_MEASUREMENT_TITLE
        : type === "edit"
        ? EDIT_MEASUREMENT_TITLE
        : type === "update"
        ? UPDATE_MEASUREMENT_TITLE
        : "You can use either bulk load or manual load"}
    </span>
  );
};
