import { FC, useEffect, useState } from "react";
import { Button } from "@components/Button";
import { Input } from "@components/Input";
import { PortalModal } from "@components/PortalModal";
import {
  ICheckEndUser,
  IManageEndUserModal,
  IPersonalUserInfo,
} from "@models/components/secondary/manualadduser";
import { validationSchemas } from "@services/validation";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  validateOnlyAllowedEmailSymbols,
  validateOnlyNumber,
} from "@services/regexps";
import {
  focuseField,
  getUserRoleFromToken,
  serializePhoneNumber,
  unFocuseField,
} from "@services/common";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { selectTokens } from "@store/selectors/tokens";
import {
  DEALER_ROLE,
  MIN_PHONE_NUMBER_LENGTH,
  PHONE_NUMBER_START_CODE,
} from "@constants/commons";
import { ISalesRep } from "@models/common/salesRep";
import { DealerDropdown } from "@components/Dropdown/DealerDropdown";
import { getDealerBySearchKey } from "@api/dealers";
import { IDealer } from "@models/common/dealer";
import {
  EMAIL_ALREADY_USED_ERROR,
  EMAIL_PHONE_NUMBER_ALREADY_USED_ERROR,
  PHONE_NUMBER_ALREADY_USED_ERROR,
} from "@constants/errors";
import { ADD_SALES_REP_TITLE } from "@constants/users";
import {
  checkEndUserByData,
  handleSubmitErrors,
  SubmitError,
} from "@api/common";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { getFormattedError } from "@services/http";

export const ManageSalesRepModal: FC<IManageEndUserModal> = ({
  handleModalOpen,
  userInfo,
  handleUser,
  title,
  assignedDealers,
}) => {
  const checkFormMethods = useForm<ICheckEndUser>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldFocusError: false,
    resolver: yupResolver(validationSchemas.checkEndUser),
    defaultValues: {
      phone_number: PHONE_NUMBER_START_CODE,
    },
  });

  const personalFormMethods = useForm<IPersonalUserInfo>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    shouldFocusError: false,
    resolver: yupResolver(validationSchemas.personalSalesRepInfo),
  });

  const {
    handleSubmit: checkHandleSubmit,
    formState: checkFormState,
    watch: checkWatch,
    clearErrors: clearCheckErrors,
    setValue: setCheckValue,
    setError: setCheckError,
    trigger: triggerCheckFormValidation,
  } = checkFormMethods;
  const { email = "", phone_number = "" } = checkWatch();

  const {
    errors: checkErrors,
    isSubmitting: isCheckSubmitting,
    isSubmitted: isCheckSubmitted,
  } = checkFormState;

  const {
    handleSubmit: personalHandleSubmit,
    formState: personalFormState,
    watch: personalWatch,
    setValue: setPersonalValue,
    reset: personalReset,
  } = personalFormMethods;
  const {
    firstName = "",
    lastName = "",
    assignedDealers: watchAssignedDealers,
  } = personalWatch();

  const {
    errors: personalErrors,
    isSubmitting: isPersonalSubmitting,
    isSubmitted: isPersonalSubmitted,
  } = personalFormState;

  const [personalSubmitted, setPersonalSubmitted] = useState(false);
  const [focusedFields, setFocused] = useState<{
    [fieldName: string]: boolean;
  }>({
    email: false,
    phone_number: false,
    firstName: false,
    lastName: false,
    assignedDealers: false,
  });
  const [checkedUser, setCheckedUser] = useState<ISalesRep[]>();
  const [defaultDealer, setDefaultDealer] = useState<IDealer[] | undefined>(
    userInfo?.dealersInfo
  );
  const [listDealers, setListDealers] = useState<IDealer[]>();
  const [searchedDealer, setSearchedDealer] = useState("");

  const dispatch = useAppDispatch();
  const tokensInfo = useAppSelector(selectTokens);
  const userRole = getUserRoleFromToken(tokensInfo);
  const handleCheckedUser = (data: ISalesRep[] | undefined) => {
    setCheckedUser(data);
    setDefaultDealer(data?.[0]?.dealer ? [data?.[0].dealer] : undefined);
  };

  const handleDealersDropdownItems = (items: IDealer[] | undefined) => {
    setListDealers(items);
  };

  const handleSearchedDealer = (dealer: string) => {
    setSearchedDealer(dealer);
  };

  useEffect(() => {
    if (checkedUser || userInfo) {
      (async function asyncWrapper() {
        try {
          const dealers = await getDealerBySearchKey(dispatch, searchedDealer);
          handleDealersDropdownItems(dealers.content);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [searchedDealer, checkedUser, userInfo]);

  useEffect(() => {
    if (email?.indexOf(" ") !== -1) {
      setCheckValue("email", email?.replace(/\s/g, ""));
    }
  }, [email]);

  useEffect(() => {
    if (phone_number?.indexOf(" ") !== -1) {
      setCheckValue("phone_number", phone_number?.replace(/\s/g, ""));
    }
  }, [phone_number]);

  useEffect(() => {
    if (
      (checkedUser?.[0]?.user?.email?.replace(/\s/g, "") !==
        email?.replace(/\s/g, "") ||
        checkedUser?.[0]?.user?.phoneNumber !== phone_number) &&
      title === ADD_SALES_REP_TITLE
    ) {
      handleCheckedUser(undefined);
      personalReset();
    }
  }, [email, phone_number]);

  useEffect(() => {
    if (userInfo) {
      setCheckValue("email", userInfo.email);
      setCheckValue("phone_number", userInfo.phone_number);
      setPersonalValue("firstName", userInfo.firstName);
      setPersonalValue("lastName", userInfo.lastName);
      if (assignedDealers) {
        setPersonalValue("assignedDealers", assignedDealers);
      } else {
        setPersonalValue(
          "assignedDealers",
          (userInfo.dealersInfo || [])
            .map((dealer) => dealer.dealerId)
            .join(", ")
        );
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (!!checkedUser?.length) {
      setCheckValue("email", checkedUser[0]?.user?.email);
      setCheckValue("phone_number", checkedUser[0]?.user?.phoneNumber);
      setPersonalValue("firstName", checkedUser[0]?.user?.firstName);
      setPersonalValue("lastName", checkedUser[0]?.user?.lastName);
      if (assignedDealers) {
        setPersonalValue("assignedDealers", assignedDealers);
      } else {
        setPersonalValue("assignedDealers", checkedUser[0]?.dealer.dealerId);
      }
    } else {
      if (assignedDealers) {
        setPersonalValue("assignedDealers", assignedDealers);
      }
    }
  }, [checkedUser]);

  useEffect(() => {
    if (!phone_number?.startsWith(PHONE_NUMBER_START_CODE)) {
      setCheckValue("phone_number", PHONE_NUMBER_START_CODE);
    }
  }, [setCheckValue, phone_number]);

  const handleFocuse = (focuseState: { [fieldName: string]: boolean }) => {
    setFocused(focuseState);
  };

  const checkOnSubmit = async (data: ICheckEndUser) => {
    try {
      const { email = "", phone_number = "" } = data;
      const emailData = email?.trim();
      const phoneNumberData: string | undefined =
        phone_number === undefined && email
          ? undefined
          : phone_number &&
            phone_number.length > MIN_PHONE_NUMBER_LENGTH &&
            !email
          ? phone_number.trim()
          : email &&
            phone_number &&
            phone_number.length > MIN_PHONE_NUMBER_LENGTH
          ? phone_number.trim()
          : undefined;

      const checkSalesRepCreate = await checkEndUserByData(
        dispatch,
        emailData,
        phoneNumberData
      );
      if (checkSalesRepCreate.emailExists) {
        setCheckError("email", {
          type: "custom",
          message: EMAIL_ALREADY_USED_ERROR,
        });
      } else if (checkSalesRepCreate.phoneNumberExists) {
        setCheckError("phone_number", {
          type: "custom",
          message: PHONE_NUMBER_ALREADY_USED_ERROR,
        });
      } else if (
        checkSalesRepCreate.emailExists &&
        checkSalesRepCreate.phoneNumberExists
      ) {
        setCheckError("email", {
          type: "custom",
          message: EMAIL_PHONE_NUMBER_ALREADY_USED_ERROR,
        });
        setCheckError("phone_number", {
          type: "custom",
          message: EMAIL_PHONE_NUMBER_ALREADY_USED_ERROR,
        });
      } else {
        clearCheckErrors();
        handleCheckedUser([]);
      }
    } catch (error) {
      return Promise.reject(getFormattedError(error));
    }
  };

  const personalOnSubmit = async (data: IPersonalUserInfo) => {
    const isCheckFormValid = await triggerCheckFormValidation();

    checkHandleSubmit(() => {})();

    if (!isCheckFormValid) {
      return;
    }

    const userData = {
      email: !email ? undefined : email.trim(),
      phone_number: serializePhoneNumber(phone_number),
      salesRepId: userInfo?.salesRepId,
      dealerId:
        checkedUser && checkedUser[0]?.dealer.dealerId
          ? checkedUser[0]?.dealer.dealerId
          : userRole === DEALER_ROLE
          ? tokensInfo?.idToken?.payload?.["cognito:username"]
          : data?.assignedDealers || "",
      ...data,
    };

    if (!checkErrors.email && !checkErrors.phone_number) {
      try {
        await handleUser(dispatch, userData, false);
        handleModalOpen();
      } catch (error) {
        handleSubmitErrors({
          error: error as SubmitError,
          setError: setCheckError,
        });
      }
    }
  };

  const isCheckBtnDisabled =
    (!email.length && phone_number.length === MIN_PHONE_NUMBER_LENGTH) ||
    isCheckSubmitting;

  const isDisabledForm = checkedUser === undefined && !userInfo;
  const hasCheckFormErrors = Boolean(
    Object.values(checkFormState.errors).length
  );

  const isSaveBtnDisabled =
    hasCheckFormErrors ||
    isDisabledForm ||
    (userRole !== DEALER_ROLE &&
      !assignedDealers &&
      !watchAssignedDealers?.length) ||
    (firstName.trim().length && lastName.trim().length
      ? !firstName.length || !lastName.length || isPersonalSubmitting
      : !email.length ||
        phone_number.length === MIN_PHONE_NUMBER_LENGTH ||
        !firstName.trim().length ||
        !lastName.trim().length ||
        (userRole !== DEALER_ROLE &&
          !assignedDealers &&
          !watchAssignedDealers?.length) ||
        isPersonalSubmitting);

  return (
    <PortalModal
      title={title}
      isOpen={true}
      onClose={handleModalOpen}
      className="modal-user"
    >
      <div className="modal-body">
        <h2 className="modal-subtitle">
          <span className="modal-step">1</span> User verification
        </h2>
      </div>
      <form
        className="modal-form form-start-label"
        onSubmit={checkHandleSubmit(checkOnSubmit)}
      >
        <div className="modal-body form modal-form">
          <FormProvider {...checkFormMethods}>
            <Input
              label="Email"
              subLabel={
                !!checkErrors.email?.message &&
                (title === ADD_SALES_REP_TITLE
                  ? isCheckSubmitted
                  : isPersonalSubmitted)
                  ? checkErrors.email.message
                  : ""
              }
              error={
                (!!checkErrors.email?.message ||
                  checkErrors.phone_number?.message ===
                    EMAIL_PHONE_NUMBER_ALREADY_USED_ERROR) &&
                (title === ADD_SALES_REP_TITLE
                  ? isCheckSubmitted
                  : isPersonalSubmitted)
              }
              name="email"
              disable={isCheckSubmitting}
              focuseField={() =>
                focuseField("email", focusedFields, handleFocuse)
              }
              unFocuseField={() =>
                unFocuseField("email", focusedFields, handleFocuse)
              }
              pattern={validateOnlyAllowedEmailSymbols}
              trimValue
            />
            <Input
              label="Mobile phone number"
              subLabel={
                !!checkErrors.phone_number?.message &&
                (title === ADD_SALES_REP_TITLE
                  ? isCheckSubmitted
                  : isPersonalSubmitted)
                  ? checkErrors.phone_number.message
                  : ""
              }
              error={
                !!checkErrors.phone_number?.message &&
                (title === ADD_SALES_REP_TITLE
                  ? isCheckSubmitted
                  : isPersonalSubmitted)
              }
              disable={isCheckSubmitting}
              name="phone_number"
              focuseField={() =>
                focuseField("phone_number", focusedFields, handleFocuse)
              }
              unFocuseField={() =>
                unFocuseField("phone_number", focusedFields, handleFocuse)
              }
              pattern={validateOnlyNumber}
              trimValue
            />
          </FormProvider>
          {isDisabledForm && (
            <Button
              type="submit"
              text="Check"
              className="btn btn-main btn-content"
              disable={isCheckBtnDisabled}
            />
          )}
        </div>
      </form>
      <form
        className="form modal-form form-start-label"
        onSubmit={personalHandleSubmit(personalOnSubmit)}
      >
        <div
          className={`modal-body form modal-form ${
            isDisabledForm && "isDisabled"
          }`}
        >
          <FormProvider {...personalFormMethods}>
            <h2 className={`modal-subtitle ${isDisabledForm && "isDisabled"}`}>
              <span className="modal-step">2</span> Personal information
            </h2>
            <Input
              label="First name*"
              name="firstName"
              disable={isDisabledForm ? true : false}
              subLabel={
                !!personalSubmitted && !!firstName?.length
                  ? personalErrors.firstName?.message
                  : ""
              }
              error={!!personalErrors.firstName?.message && !!personalSubmitted}
              focuseField={() =>
                focuseField("firstName", focusedFields, handleFocuse)
              }
              unFocuseField={() =>
                unFocuseField("firstName", focusedFields, handleFocuse)
              }
            />
            <Input
              label="Last name*"
              name="lastName"
              disable={isDisabledForm ? true : false}
              subLabel={
                !!personalSubmitted && !!lastName?.length
                  ? personalErrors.lastName?.message
                  : ""
              }
              error={!!personalErrors.lastName?.message && !!personalSubmitted}
              focuseField={() =>
                focuseField("lastName", focusedFields, handleFocuse)
              }
              unFocuseField={() =>
                unFocuseField("lastName", focusedFields, handleFocuse)
              }
            />
            {userRole !== DEALER_ROLE && !assignedDealers && (
              <DealerDropdown
                name="assignedDealers"
                id="dropdown"
                defaultValue={defaultDealer}
                dropdownList={listDealers}
                handleDealerItems={handleDealersDropdownItems}
                searchedValue={searchedDealer}
                handleSearchedValue={handleSearchedDealer}
                disable={isDisabledForm ? true : false}
                clear={isDisabledForm ? true : false}
                required
                limit={1}
              />
            )}
          </FormProvider>
        </div>
        <div className="modal-footer">
          <Button
            text="Cancel"
            className="btn btn-primary"
            onClick={handleModalOpen}
          />
          <Button
            text="Save"
            className="btn btn-main"
            type="submit"
            disable={isSaveBtnDisabled}
            onClick={() => setPersonalSubmitted(true)}
          />
        </div>
      </form>
    </PortalModal>
  );
};
