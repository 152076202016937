import login from "@assets/img/login.jpg";
import { FC, memo, useEffect, useState } from "react";
import { Button } from "@components/Button";
import { Input } from "@components/Input";
import { ICredentials } from "@models/auth/data";
import { useLogin } from "@hooks/common/useLogin";
import { FormProvider, useForm } from "react-hook-form";
import { validationSchemas } from "@services/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import {
  MIN_PHONE_NUMBER_LENGTH,
  PHONE_NUMBER_START_CODE,
  SIGN_IN_CODE,
  SIGN_IN_PASSWORD,
  SOMETHING_WENT_WRONG_NOTIFICATION,
} from "@constants/commons";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { userInfoSlice } from "@store/reducers/UserSlice";
import {
  ABOUT_US_ROUTE,
  PRIVACY_POLICY_ROUTE,
  SIGNIN_CODE_ROUTE,
  SIGNIN_PASSWORD_ROUTE,
  SIGNUP_ROUTE,
  TERMS_CONDITIONS_ROUTE,
} from "@constants/routes";
import { ISignIn } from "@models/components/main/signin";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { cognitoUserCodeExpiresAt } from "@services/auth";
import { cognitoUserSlice } from "@store/reducers/CognitoUserSlice";
import {
  EMAIL_TEMPLATE_MATCH,
  PHONE_NUMBER_TEMPLATE_MATCH,
} from "@constants/validation";
import {
  validateEmail,
  validateOnlyNumber,
  validateOnlyAllowedEmailSymbols,
  validatePhoneNumber,
} from "@services/regexps";
import { focuseField, unFocuseField } from "@services/common";
import { selectUserInfo } from "@store/selectors/user";

export const SignIn: FC = memo(() => {
  const formMethods = useForm<ISignIn>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    shouldFocusError: false,
    resolver: yupResolver(validationSchemas.signIn),
    defaultValues: {
      phone_number: PHONE_NUMBER_START_CODE,
    },
  });

  const {
    handleSubmit,
    formState,
    watch,
    setValue,
    setError,
    clearErrors,
    reset,
  } = formMethods;
  const { errors, isSubmitSuccessful, isSubmitting } = formState;
  const { phone_number, email } = watch();

  const [submitType, setSubmitType] = useState<string>("");
  const [credentials, setCredentials] = useState<ICredentials>();
  const [focusedFields, setFocused] = useState<{
    [fieldName: string]: boolean;
  }>({
    email: false,
    phone_number: false,
  });

  const handleFocuse = (focuseState: { [fieldName: string]: boolean }) => {
    setFocused(focuseState);
  };

  const expiresAt = cognitoUserCodeExpiresAt(useAppSelector);
  const useInfo = useAppSelector(selectUserInfo);

  const onSubmit = (data: ICredentials) => {
    try {
      if (errors?.email?.message) {
        throw new Error(EMAIL_TEMPLATE_MATCH);
      }

      if (errors?.phone_number?.message) {
        throw new Error(PHONE_NUMBER_TEMPLATE_MATCH);
      }

      dispatch(cognitoUserSlice.actions.clearCognitoUser());

      const credentials = { ...data };

      if (
        data?.phone_number &&
        data?.phone_number?.length === MIN_PHONE_NUMBER_LENGTH
      ) {
        delete credentials.phone_number;
      } else if (
        data?.phone_number &&
        data?.phone_number?.length > MIN_PHONE_NUMBER_LENGTH
      ) {
        credentials.phone_number = credentials?.phone_number?.trim();
      }
      if (!data?.email) {
        delete credentials?.email;
      } else {
        credentials.email = credentials?.email?.trim();
      }

      dispatch(
        userInfoSlice.actions.setUser({
          username: credentials?.email || credentials?.phone_number || "",
          verified: false,
        })
      );

      setCredentials(credentials);
    } catch (error) {}
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loginError, isAuthenticating } = useLogin(
    credentials,
    submitType === SIGN_IN_PASSWORD || !submitType ? true : false
  );

  useEffect(() => {
    if (!!email) {
      dispatch(
        userInfoSlice.actions.setUser({
          username: email?.trim(),
          verified: false,
        })
      );
    }
  }, [email]);

  useEffect(() => {
    if (phone_number.length > MIN_PHONE_NUMBER_LENGTH) {
      dispatch(
        userInfoSlice.actions.setUser({
          username: phone_number?.trim(),
          verified: false,
        })
      );
    }
  }, [phone_number]);

  useEffect(() => {
    if (useInfo?.username) {
      if (useInfo?.username?.includes("@")) {
        setValue("email", useInfo?.username);
      }
      if (useInfo?.username?.startsWith("+")) {
        setValue("phone_number", useInfo?.username);
      }
    }
  }, [useInfo?.username]);

  useEffect(() => {
    if (!phone_number?.startsWith(PHONE_NUMBER_START_CODE)) {
      setValue("phone_number", PHONE_NUMBER_START_CODE);
    }
  }, [setValue, phone_number]);

  useEffect(() => {
    if (focusedFields.phone_number) {
      reset({}, { keepValues: true, keepErrors: true });
    }

    if (focusedFields.email) {
      reset({}, { keepValues: true, keepErrors: true });
    }
  }, [focusedFields]);

  const navigateToSignUp = () => {
    navigate(SIGNUP_ROUTE);
  };

  useEffect(() => {
    if (
      !!email?.length &&
      !email?.trim()?.match(validateEmail) &&
      phone_number?.length === MIN_PHONE_NUMBER_LENGTH &&
      !focusedFields.email
    ) {
      setError("email", { type: "custom", message: EMAIL_TEMPLATE_MATCH });
    } else {
      clearErrors("email");
    }

    if (
      phone_number?.length > MIN_PHONE_NUMBER_LENGTH &&
      !phone_number?.trim()?.match(validatePhoneNumber) &&
      !email?.length &&
      !focusedFields.phone_number
    ) {
      setError("phone_number", {
        type: "custom",
        message: PHONE_NUMBER_TEMPLATE_MATCH,
      });
    } else {
      clearErrors("phone_number");
    }

    if (loginError) {
      if (!!email?.length) {
        setError("email", {
          type: "custom",
          message: SOMETHING_WENT_WRONG_NOTIFICATION,
        });
      }
      if (phone_number?.length > MIN_PHONE_NUMBER_LENGTH) {
        setError("phone_number", {
          type: "custom",
          message: SOMETHING_WENT_WRONG_NOTIFICATION,
        });
      }
    }
  }, [clearErrors, isSubmitting, loginError, setError, email, phone_number]);

  useEffect(() => {
    if (
      submitType === SIGN_IN_CODE &&
      isSubmitSuccessful &&
      expiresAt > 0 &&
      !isSubmitting &&
      !Object.keys(errors).length
    ) {
      navigate(SIGNIN_CODE_ROUTE);
    }
    if (
      submitType === SIGN_IN_PASSWORD &&
      isSubmitSuccessful &&
      !isSubmitting &&
      !Object.keys(errors).length
    ) {
      navigate(SIGNIN_PASSWORD_ROUTE);
    }
  }, [navigate, isSubmitSuccessful, submitType, expiresAt]);

  const isSigninButtonDisabled =
    (!email?.length && phone_number?.length === MIN_PHONE_NUMBER_LENGTH) ||
    isAuthenticating;

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className={`login ${isAuthenticating && "login-loading"}`}>
        <img src={login} alt="login" className="login-img" />
        <div className="login-container">
          <h1 className="login-title">
            Welcome to <b>{process.env.REACT_APP_PROJECT_NAME}</b>
          </h1>
          <span className="login-info">
            Please enter your email or phone number
          </span>
          <div className="login-form">
            <FormProvider {...formMethods}>
              <Input
                label="Email"
                subLabel={
                  !!submitType.length &&
                  phone_number?.length === MIN_PHONE_NUMBER_LENGTH &&
                  !!email?.length
                    ? errors.email?.message
                    : ""
                }
                error={
                  !!errors.email?.message &&
                  !!submitType.length &&
                  !!email?.length &&
                  phone_number?.length === MIN_PHONE_NUMBER_LENGTH
                }
                disable={
                  phone_number?.length > MIN_PHONE_NUMBER_LENGTH ||
                  isAuthenticating
                }
                name="email"
                focuseField={() =>
                  focuseField("email", focusedFields, handleFocuse)
                }
                unFocuseField={() =>
                  unFocuseField("email", focusedFields, handleFocuse)
                }
                pattern={validateOnlyAllowedEmailSymbols}
                trimValue
              />
              <span className="login-line">or</span>
              <Input
                label="Phone Number"
                subLabel={
                  !!submitType.length &&
                  !email?.length &&
                  phone_number?.length > MIN_PHONE_NUMBER_LENGTH
                    ? errors.phone_number?.message
                    : ""
                }
                error={
                  !!errors.phone_number?.message &&
                  !!submitType.length &&
                  !email?.length &&
                  phone_number?.length > MIN_PHONE_NUMBER_LENGTH
                }
                disable={!!email?.length || isAuthenticating}
                name="phone_number"
                focuseField={() =>
                  focuseField("phone_number", focusedFields, handleFocuse)
                }
                unFocuseField={() =>
                  unFocuseField("phone_number", focusedFields, handleFocuse)
                }
                pattern={validateOnlyNumber}
                trimValue
              />
            </FormProvider>
            <Button
              onClick={() => setSubmitType(SIGN_IN_CODE)}
              type="submit"
              text="Sign in with temporary code"
              className="btn-main btn-big"
              disable={isSigninButtonDisabled}
            />
            <Button
              onClick={() => setSubmitType(SIGN_IN_PASSWORD)}
              type="submit"
              text="Sign in with password"
              className="btn-main btn-big"
              disable={isSigninButtonDisabled}
            />
            <Button
              text="Sign up"
              className="btn-secondary btn-big"
              onClick={navigateToSignUp}
              disable={isAuthenticating}
            />
          </div>
          <span className="login-terms">
            By signing in you accept our{" "}
            <Link to={TERMS_CONDITIONS_ROUTE} className="login-link">
              Terms of Use
            </Link>{" "}
            and{" "}
            <Link to={PRIVACY_POLICY_ROUTE} className="login-link">
              Privacy Policy
            </Link>
          </span>
          <div style={{ textAlign: "center", margin: "3vh 0" }}>
            <Link to={ABOUT_US_ROUTE} className="login-link">
              About Us
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
});
