import { FC, useEffect, useState } from "react";
import { TableHeader } from "@components/TableHeader/TableHeader";
import { endUsersTableHeaders } from "@constants/headers";
import {
  formateCapitalizeStr,
  hideWindowScroll,
  showWindowScroll,
} from "@services/common";
import { DropdownMenu } from "@components/Dropdown/DropdownMenu";
import {
  ACCEPTED_USER_STATUS,
  DELETED_USER_STATUS,
  PENDING_USER_STATUS,
  SOMETHING_WENT_WRONG_NOTIFICATION,
} from "@constants/commons";
import { UserStatus } from "@models/common/user";
import { IManageEndUserInfo } from "@models/components/secondary/manualadduser";
import { END_USER_INFO_ROUTE } from "@constants/routes";
import { EDIT_END_USER_TITLE } from "@constants/users";
import { useNotification } from "@hooks/common/useNotification";
import { Notification } from "@components/Notification";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { IEndUserTable } from "@models/components/main/endusertable";
import { getEndUserById, resendInviteEndUser } from "@api/endUsers";
import { ManageEndUserModal } from "@pages/Modals/Users/ManageEndUserModal";
import { handleEndUserUpdate } from "@api/common";
import { getFormattedError } from "@services/http";

export const EndUserTable: FC<IEndUserTable> = ({
  users,
  handleSorting,
  sort,
  handleModalOpen: handleEditModalOpen,
}) => {
  const [userInfo, setUserInfo] = useState<IManageEndUserInfo | undefined>();
  const [openUserModal, setOpenUserModal] = useState<boolean>(false);
  const [isOpened, setOpened] = useState<boolean>(false);
  const [userId, setUserId] = useState("");

  const dispatch = useAppDispatch();

  const {
    isActive,
    message: notificationMessage,
    type: notificationType,
    showNotification,
    closeNotification,
  } = useNotification();

  const toggleDropdown = (userId: string) => {
    setOpened(!isOpened);
    setUserId(userId);
  };

  useEffect(() => {
    if (!openUserModal) {
      setUserId("");
    }
  }, [openUserModal]);

  const handleModalOpen = () => {
    setOpenUserModal(!openUserModal);
    if (openUserModal) {
      setUserInfo(undefined);
    }
    handleEditModalOpen();
  };

  const handleDropdownMenu = (dropdownState: boolean) => {
    setOpened(dropdownState);
  };

  const handleUserEdit = async (userId: string) => {
    try {
      const user = await getEndUserById(dispatch, userId);
      setUserInfo({
        userId: user.endUserId,
        email: user.user.email || "",
        phone_number: user.user.phoneNumber || "",
        firstName: user.user.firstName || "",
        lastName: user.user.lastName || "",
        badge: user.badgeNumber || "",
        gender: user.gender || "",
        anonymous: user.anonymous || undefined,
        displayId: user.user.displayId || undefined,
      });

      handleModalOpen();
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    }
  };

  const handleUserResendInvite = async (userId: string) => {
    try {
      await resendInviteEndUser(dispatch, userId);
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    } finally {
      setOpened(false);
    }
  };

  const userTableDropdownItems = (userId: string, userStatus: UserStatus) =>
    [
      {
        text: "Edit personal information",
        onClick: async () => {
          await handleUserEdit(userId).finally(() => handleDropdownMenu(false));
        },
      },
      userStatus === PENDING_USER_STATUS || ""
        ? {
            text: "Resend invite",
            onClick: async () => {
              await handleUserResendInvite(userId).finally(() =>
                handleDropdownMenu(false)
              );
            },
          }
        : null,
    ].filter((item) => !!item);

  useEffect(() => {
    if (openUserModal) {
      showWindowScroll();
    } else {
      hideWindowScroll();
    }
  }, [openUserModal]);

  return (
    <>
      <Notification
        type={notificationType}
        message={notificationMessage}
        isActive={isActive}
        closeNotification={closeNotification}
      />
      {openUserModal && userId && (
        <ManageEndUserModal
          title={EDIT_END_USER_TITLE}
          handleModalOpen={handleModalOpen}
          userInfo={userInfo}
          handleUser={handleEndUserUpdate}
        />
      )}
      <table className="event-table" cellSpacing={0} cellPadding={0} border={0}>
        <TableHeader
          sort={sort}
          headers={endUsersTableHeaders}
          handleSorting={handleSorting}
        />
        <tbody className="event-tbody">
          {users.map((u) => (
            <tr className="event-tr" key={u.endUserId}>
              <td className="event-td" data-label="ID">
                {u.user.displayId}
              </td>
              <td
                className="event-td cursor-pointer event-link"
                data-label="Name"
              >
                <a
                  className="header-link"
                  href={`${END_USER_INFO_ROUTE}/${u.endUserId}/1`}
                >
                  {u.user.firstName} {u.user.lastName}
                </a>
              </td>
              <td className="event-td" data-label="Email">
                {u.user.email || "-"}
              </td>
              <td className="event-td" data-label="Phone Number">
                {u.user.phoneNumber || "-"}
              </td>
              <td className="event-td" data-label="System Status">
                <span
                  className={`status ${
                    u.user.status === ACCEPTED_USER_STATUS
                      ? "status-success"
                      : u.user.status === DELETED_USER_STATUS
                      ? "status-danger"
                      : u.user.status === PENDING_USER_STATUS
                      ? "status-warning"
                      : ""
                  }`}
                >
                  {formateCapitalizeStr(u.user.status)}
                </span>
              </td>
              <td className="event-td" data-label="Actions">
                {!!userTableDropdownItems(u.endUserId, u.user.status)
                  ?.length && (
                  <div className="header-dropdown">
                    <button
                      className={`header-dropdown-name ${
                        isOpened && "isActive"
                      } event-table-btn`}
                      onClick={() => toggleDropdown(u.endUserId)}
                    >
                      <i className="event-table-icon icon icon-kebab" />
                    </button>
                    {isOpened && userId === u.endUserId && (
                      <DropdownMenu
                        dropdownItems={userTableDropdownItems(
                          u.endUserId,
                          u.user.status
                        )}
                        dropdownOpened={isOpened}
                        setDropdownOpened={handleDropdownMenu}
                      />
                    )}
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
