import { FC, useEffect, useState } from "react";
import { TableHeader } from "@components/TableHeader/TableHeader";
import { parentTableHeaders } from "@constants/headers";
import {
  formateCapitalizeStr,
  hideWindowScroll,
  showWindowScroll,
} from "@services/common";
import { DropdownMenu } from "@components/Dropdown/DropdownMenu";
import {
  ACCEPTED_USER_STATUS,
  DELETED_USER_STATUS,
  DELETE_USER_NOTIFICATION,
  PENDING_USER_STATUS,
  SOMETHING_WENT_WRONG_NOTIFICATION,
} from "@constants/commons";
import { UserStatus } from "@models/common/user";
import { IManageUserInfo } from "@models/components/secondary/manualadduser";
import { MAIN_ROUTE, PARENT_INFO_ROUTE } from "@constants/routes";
import { IParentTable } from "@models/components/main/parenttable";
import { deleteParent, getParentById, resendInviteParent } from "@api/parent";
import { ManageParentModal } from "@pages/Modals/Users/ManageParentModal";
import {
  DELETE_USER_BODY,
  DELETE_USER_TITLE,
  EDIT_PARENT_TITLE,
} from "@constants/users";
import { AlertModal } from "@components/AlertModal";
import { useNotification } from "@hooks/common/useNotification";
import { Notification } from "@components/Notification";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { useLocation, useNavigate } from "react-router-dom";
import { handleParentUpdate } from "@api/common";
import { getFormattedError } from "@services/http";

export const ParentTable: FC<IParentTable> = ({
  parent,
  handleSorting,
  sort,
  handleModalOpen: handleEditModalOpen,
  deleteWindowOpen,
  handleDeleteModal,
  handleManaged,
}) => {
  const [userInfo, setUserInfo] = useState<IManageUserInfo | undefined>();
  const [openParentModal, setOpenParentModal] = useState<boolean>(false);
  const [isOpened, setOpened] = useState<boolean>(false);
  const [parentId, setParentId] = useState("");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    isActive,
    message: notificationMessage,
    type: notificationType,
    showNotification,
    closeNotification,
  } = useNotification();

  const toggleDropdown = (parentId: string) => {
    setOpened(!isOpened);
    setParentId(parentId);
  };

  const handleDropdownMenu = (dropdownState: boolean) => {
    setOpened(dropdownState);
  };

  useEffect(() => {
    if (!openParentModal) {
      setParentId("");
    }
  }, [openParentModal]);

  const handleModalOpen = () => {
    setOpenParentModal(!openParentModal);
    if (openParentModal) {
      setUserInfo(undefined);
    }
    handleEditModalOpen();
  };

  const handleParentEdit = async (parentId: string) => {
    try {
      const parent = await getParentById(dispatch, parentId);
      setUserInfo({
        parentId: parent.userId,
        email: parent?.email || "",
        phone_number: parent?.phoneNumber || "",
        firstName: parent?.firstName || "",
        lastName: parent?.lastName || "",
        dealer: parent?.dealer,
        dealerName: parent?.dealerName,
        salesRep: parent?.salesRep,
      });

      handleModalOpen();
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    }
  };

  const handleParentResendInvite = async (parentId: string) => {
    try {
      await resendInviteParent(dispatch, parentId);
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    } finally {
      setOpened(false);
    }
  };

  const handleParentDelete = async (parentId: string) => {
    try {
      if (parent?.length === 1) {
        await deleteParent(dispatch, parentId);
        showNotification(DELETE_USER_NOTIFICATION, "success");
        handleManaged(true);
        navigate(
          `/${location.pathname.split(MAIN_ROUTE)[1]}/${
            typeof +location.pathname.split(MAIN_ROUTE)[2] === "number" &&
            +location.pathname.split(MAIN_ROUTE)[2] - 1 > 0
              ? +location.pathname.split(MAIN_ROUTE)[2] - 1
              : 1
          }${location.search}`
        );
      } else {
        await deleteParent(dispatch, parentId);
        showNotification(DELETE_USER_NOTIFICATION, "success");
        handleManaged(true);
      }
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    } finally {
      setOpened(false);
    }
  };

  const handleDeleteWindowOpen = () => {
    handleDeleteModal();
    setOpened(false);
  };

  const parentTableDropdownItems = (
    parentId: string,
    parentStatus: UserStatus
  ) =>
    [
      {
        text: "Edit personal information",
        onClick: async () => {
          await handleParentEdit(parentId).finally(() =>
            handleDropdownMenu(false)
          );
        },
      },
      parentStatus === PENDING_USER_STATUS
        ? {
            text: "Resend invite",
            onClick: async () => {
              await handleParentResendInvite(parentId).finally(() =>
                handleDropdownMenu(false)
              );
            },
          }
        : null,
      parentStatus === PENDING_USER_STATUS ||
      parentStatus === ACCEPTED_USER_STATUS
        ? {
            text: "Delete",
            onClick: () => {
              handleDeleteWindowOpen();
            },
          }
        : null,
    ].filter((item) => !!item);

  useEffect(() => {
    if (openParentModal) {
      showWindowScroll();
    } else {
      hideWindowScroll();
    }
  }, [openParentModal]);

  return (
    <>
      <Notification
        type={notificationType}
        message={notificationMessage}
        isActive={isActive}
        closeNotification={closeNotification}
      />
      {deleteWindowOpen && (
        <AlertModal
          type="delete"
          title={DELETE_USER_TITLE}
          body={DELETE_USER_BODY}
          handleOpen={handleDeleteWindowOpen}
          handleConfirm={handleParentDelete}
          id={parentId}
        />
      )}
      {openParentModal && parentId && (
        <ManageParentModal
          title={EDIT_PARENT_TITLE}
          handleModalOpen={handleModalOpen}
          userInfo={userInfo}
          handleUser={handleParentUpdate}
        />
      )}
      <table
        className="event-table event-table-parent"
        cellSpacing={0}
        cellPadding={0}
        border={0}
      >
        <TableHeader
          sort={sort}
          headers={parentTableHeaders}
          handleSorting={handleSorting}
        />
        <tbody className="event-tbody">
          {parent.map((p) => (
            <tr className="event-tr" key={p.userId}>
              <td
                className="event-td cursor-pointer event-link"
                data-label="Dealer name"
              >
                <a
                  className="header-link"
                  href={`${PARENT_INFO_ROUTE}/${p.userId}/1`}
                >
                  {p.firstName + " " + p.lastName}
                </a>
              </td>
              <td className="event-td" data-label="Email">
                {p.email}
              </td>
              <td className="event-td" data-label="Phone Number">
                {p.phoneNumber}
              </td>
              <td className="event-td" data-label="System Status">
                <span
                  className={`status ${
                    p.status === ACCEPTED_USER_STATUS
                      ? "status-success"
                      : p.status === DELETED_USER_STATUS
                      ? "status-danger"
                      : p.status === PENDING_USER_STATUS
                      ? "status-warning"
                      : ""
                  }`}
                >
                  {formateCapitalizeStr(p.status)}
                </span>
              </td>
              <td className="event-td" data-label="Actions">
                {!!parentTableDropdownItems(p.userId, p.status)?.length && (
                  <div className="header-dropdown">
                    <button
                      className={`header-dropdown-name ${
                        isOpened && "isActive"
                      } event-table-btn`}
                      onClick={() => toggleDropdown(p.userId)}
                    >
                      <i className="event-table-icon icon icon-kebab" />
                    </button>
                    {isOpened && parentId === p.userId && (
                      <DropdownMenu
                        dropdownItems={parentTableDropdownItems(
                          p.userId,
                          p.status
                        )}
                        dropdownOpened={isOpened}
                        setDropdownOpened={handleDropdownMenu}
                      />
                    )}
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
