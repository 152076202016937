import { FC, useEffect, useState } from "react";
import { ISearch } from "@models/components/secondary/search";
import { DebounceInput } from "react-debounce-input";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { SEARCH_QUERY_KEY } from "@constants/commons";

export const Search: FC<ISearch> = ({
  id,
  name,
  disable,
  handleSearchedValues,
  searchedValue,
  handleFilter,
  filtered,
  handleSearchFieldFocus,
  placeholder,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = queryString.parse(location.search);

  const [searchOpen, setSearchOpen] = useState(false);

  const openSearch = () => {
    setSearchOpen(true);
  };

  const closeSearch = () => {
    setSearchOpen(false);
    handleSearchedValues(undefined);
    if (!!searchParams?.[SEARCH_QUERY_KEY]) {
      const filteredSearch = location.search.replace(
        `?${SEARCH_QUERY_KEY}=${searchParams[SEARCH_QUERY_KEY]}`,
        ""
      );
      navigate(`${location.pathname}${filteredSearch}`);
    }
  };

  useEffect(() => {
    if (!!searchedValue) {
      !searchOpen && openSearch();
      handleFilter && handleFilter(false);
    } else {
      handleSearchedValues("");
    }
  }, [searchedValue]);

  useEffect(() => {
    if (!searchParams?.[SEARCH_QUERY_KEY] && filtered) {
      closeSearch();
    }
  }, [searchParams?.[SEARCH_QUERY_KEY], filtered]);

  return (
    <div className="search">
      <button className="btn-control" onClick={openSearch}>
        <i className="btn-control-icon icon icon-search" />
      </button>
      {searchOpen && (
        <>
          <DebounceInput
            id={id}
            name={name}
            type="text"
            className="search-field"
            debounceTimeout={1000}
            onChange={(event) => {
              handleSearchedValues(event.target.value);
            }}
            disabled={disable}
            value={searchedValue}
            onFocus={handleSearchFieldFocus}
            placeholder={placeholder}
          />
          <i
            className="search-icon-close icon icon-close"
            onClick={closeSearch}
          />
        </>
      )}
    </div>
  );
};
