import { useEffect, useState } from "react";
import { Header } from "@components/Header";
import { Pagination } from "@components/Pagination";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/Loader";
import {
  NO_EVENTS_PLUG_TYPE,
  NO_FOUND_PLUG_TYPE,
  SEARCH_QUERY_KEY,
  TABLE_ROWS_LIMIT,
} from "@constants/commons";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { selectTokens } from "@store/selectors/tokens";
import { HeaderBarTable } from "@components/HeaderBarTable";
import { Plug } from "@components/Plug";
import { NO_EVENTS_PLUG_ERROR, NO_FOUND_PLUG_ERROR } from "@constants/errors";
import { getPage, getSortTypes, getUserGender } from "@services/common";
import { ISortType } from "@models/common/app";
import { IEndUser } from "@models/common/endUsers";
import { getEndUserById } from "@api/endUsers";
import { EndUserEventsTable } from "@components/EndUsers/EndUserEventsTable";
import { getEventsByFilterTable, listOfEndUserEvents } from "@api/events";
import {
  EndUserEventStatus,
  EventLocation,
  IEventList,
} from "@models/common/events";
import queryString from "query-string";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { MiniLoader } from "@components/Loader/MiniLoader";
import { MAIN_ROUTE } from "@constants/routes";
import { getFormattedError } from "@services/http";

export const ListEndUserEvents = () => {
  const location = useLocation();
  const parsedSearchedQuery = queryString.parse(location.search)?.[
    SEARCH_QUERY_KEY
  ];

  const [isMeasurementOpened, setMeasurementOpened] = useState<boolean>(false);
  const [measurementsManaged, setMeasurementsManaged] =
    useState<boolean>(false);
  const [endUser, setEndUser] = useState<IEndUser | undefined>();
  const [endUserEvents, setEndUserEvents] = useState<IEventList | undefined>();
  const [searchedValue, setSearchedValue] = useState<string | undefined>(
    typeof parsedSearchedQuery === "string" ? parsedSearchedQuery : undefined
  );
  const [sortType, setSortType] = useState<ISortType>();
  const [searchParams, setSearchParams] = useState<
    queryString.ParsedQuery<string>
  >(queryString.parse(location.search));
  const [filtered, setFiltered] = useState(false);
  const [paginationChanged, setPaginationChanged] = useState(false);
  const [searchFieldFocused, setSearchFieldFocused] = useState(false);

  const navigate = useNavigate();

  const handleSearchFieldFocus = () => {
    setSearchFieldFocused(true);
  };

  const handleMeasurementsManaged = (state: boolean) => {
    setMeasurementsManaged(state);
  };

  const handleMeasurementModal = (state: boolean) => {
    setMeasurementOpened(state);
  };

  const handleSetEndUser = (data: IEndUser) => {
    setEndUser(data);
  };

  const handleSetListEndUserEvents = (data: IEventList) => {
    setEndUserEvents(data);
  };

  const handleFilter = (state: boolean) => {
    setFiltered(state);
  };

  const { page } = useParams();
  const pageId = getPage(page);
  const dispatch = useAppDispatch();
  const tokensInfo = useAppSelector(selectTokens);

  useEffect(() => {
    if (tokensInfo?.idToken?.payload?.["cognito:username"]) {
      (async function asyncWrapper() {
        try {
          const user = await getEndUserById(
            dispatch,
            tokensInfo.idToken.payload["cognito:username"]
          );
          handleSetEndUser(user);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [tokensInfo?.idToken.jwtToken]);

  useEffect(() => {
    if (
      tokensInfo?.idToken?.payload?.["cognito:username"] &&
      !isMeasurementOpened &&
      !searchParams?.[SEARCH_QUERY_KEY] &&
      !filtered
    ) {
      (async function asyncWrapper() {
        try {
          const events = await listOfEndUserEvents(
            dispatch,
            pageId,
            TABLE_ROWS_LIMIT,
            sortType ? sortType.filteredParameter : "endsAt,desc",
            searchedValue,
            tokensInfo.idToken.payload["cognito:username"]
          );
          handleSetListEndUserEvents(events);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [
    page,
    sortType,
    isMeasurementOpened,
    measurementsManaged,
    searchParams?.[SEARCH_QUERY_KEY],
    filtered,
  ]);

  useEffect(() => {
    if (!filtered && searchFieldFocused) {
      if (!!searchedValue) {
        if (pageId + 1 === 1) {
          navigate(`?${SEARCH_QUERY_KEY}=${searchedValue}`);
        } else {
          navigate(
            `/${
              location.pathname.split(MAIN_ROUTE)[1]
            }/1?${SEARCH_QUERY_KEY}=${searchedValue}`
          );
        }
      } else {
        navigate("");
      }
    }
  }, [filtered, searchedValue]);

  useEffect(() => {
    if (searchedValue && !filtered) {
      (async function asyncWrapper() {
        try {
          const events = await listOfEndUserEvents(
            dispatch,
            pageId,
            TABLE_ROWS_LIMIT,
            sortType ? sortType.filteredParameter : "endsAt,desc",
            searchedValue,
            endUser?.endUserId
          );
          handleSetListEndUserEvents(events);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [pageId, sortType, searchedValue, filtered]);

  const handleSearchedEvents = (searchedValue: string | undefined) => {
    setSearchedValue(searchedValue);
  };

  const handleSorting = (parameter: string) => {
    let filteredParameter;

    if (parameter === "Event name") {
      filteredParameter = "name";
    }
    if (parameter === "Start/End of the Event") {
      filteredParameter = "endsAt";
    }
    if (parameter === "Status") {
      filteredParameter = "status";
    }

    setSortType(getSortTypes(sortType, filteredParameter, parameter));
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    if (!!Object.keys(searchParams).length) {
      if (filtered) {
        paginationChanged && setPaginationChanged(false);
        const filteredSearchParams = { ...searchParams };
        if (SEARCH_QUERY_KEY in filteredSearchParams) {
          delete filteredSearchParams[SEARCH_QUERY_KEY];
        }
        setSearchParams(filteredSearchParams);
      } else {
        setSearchParams(searchParams);
      }
    } else {
      setSearchParams({});
    }
  }, [location.search, filtered]);

  useEffect(() => {
    if (!!searchParams?.[SEARCH_QUERY_KEY] && !filtered) {
      handleSearchedEvents(searchParams[SEARCH_QUERY_KEY].toString());
    }
  }, [searchParams?.[SEARCH_QUERY_KEY], filtered]);

  useEffect(() => {
    const filteredSearchParams = { ...searchParams };
    if (SEARCH_QUERY_KEY in filteredSearchParams) {
      delete filteredSearchParams[SEARCH_QUERY_KEY];
    }
    if (filtered && !!Object.keys(searchParams).length) {
      if (!paginationChanged) {
        navigate(
          `/${location.pathname.split(MAIN_ROUTE)[1]}/1${location.search}`
        );
        setPaginationChanged(true);
      }

      const checkString = (str: string | (string | null)[] | null) => {
        return typeof str === "string" ? str : undefined;
      };

      const upcomingDate = checkString(searchParams?.upcomingDate);

      (async function asyncWrapper() {
        try {
          const events = await getEventsByFilterTable({
            dispatch,
            upcomingDate: upcomingDate ? JSON.parse(upcomingDate) : undefined,
            startDate: checkString(searchParams?.startDate),
            endDate: checkString(searchParams?.endDate),
            eventLocation: checkString(searchParams?.eventLocation) as
              | EventLocation
              | undefined,
            endUserEventStatus: checkString(searchParams?.measurementStatus) as
              | EndUserEventStatus
              | undefined,
            page: pageId,
            size: TABLE_ROWS_LIMIT,
            sort: sortType ? sortType.filteredParameter : "endsAt,desc",
            endUserId: endUser?.endUserId,
          });
          handleSetListEndUserEvents(events);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [filtered, searchParams, pageId, sortType]);

  return (
    <>
      <Header />
      {endUser && endUserEvents ? (
        <>
          <main className="main-container info-container">
            <h2 className="event-header event-title">My information</h2>
            <ul className="sales">
              <li className="sales-item">
                First name:{" "}
                <span className="sales-val">{endUser.user.firstName}</span>
              </li>
              <li className="sales-item">
                Last name:{" "}
                <span className="sales-val">{endUser.user.lastName}</span>
              </li>
              <li className="sales-item">
                Email: <span className="sales-val">{endUser.user.email}</span>
              </li>
              <li className="sales-item">
                Phone number:{" "}
                <span className="sales-val">{endUser.user.phoneNumber}</span>
              </li>
              <li className="sales-item">
                Badge number:{" "}
                <span className="sales-val">
                  {!!endUser.badgeNumber ? endUser.badgeNumber : "-"}
                </span>
              </li>
              <li className="sales-item">
                Gender:{" "}
                <span className="sales-val">
                  {getUserGender(endUser.gender)}
                </span>
              </li>
            </ul>
          </main>
          <main className="main-container">
            <HeaderBarTable
              searchedValue={searchedValue}
              handleFilteredValues={handleSetListEndUserEvents}
              handleSearchedValues={handleSearchedEvents}
              handleFilter={handleFilter}
              title="My measurements"
              sort={sortType}
              filterType="user"
              filtered={filtered}
              handleSearchFieldFocus={handleSearchFieldFocus}
            />
            {!!endUserEvents.content.length ? (
              <div className="event-table-container">
                <EndUserEventsTable
                  endUserEvents={endUserEvents.content}
                  endUser={endUser}
                  handleSorting={handleSorting}
                  sort={sortType}
                  handleMeasurementModal={handleMeasurementModal}
                  handleMeasurementsManaged={handleMeasurementsManaged}
                />
                <Pagination
                  dataLength={endUserEvents.totalElements}
                  pageId={pageId}
                />
              </div>
            ) : (
              <MiniLoader
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5vh",
                }}
                time={3}
                afterload={
                  <Plug
                    message={
                      searchParams && !!Object.keys(searchParams)?.length
                        ? NO_FOUND_PLUG_ERROR
                        : NO_EVENTS_PLUG_ERROR
                    }
                    type={
                      searchParams && !!Object.keys(searchParams)?.length
                        ? NO_FOUND_PLUG_TYPE
                        : NO_EVENTS_PLUG_TYPE
                    }
                  />
                }
              />
            )}
          </main>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
