import {
  MALE_USER_GENDER,
  FEMALE_USER_GENDER,
  OTHER_USER_GENDER,
} from "@constants/commons";
import { IEndUser } from "@models/common/endUsers";
import {
  IPersonalUserInfo,
  IEndUserManage,
} from "@models/components/secondary/manualadduser";
import { http } from "@services/http";
import { AppDispatch } from "@store/store";
import { updateDealer, createDealer } from "./dealers";
import { updateEndUser, createEndUser } from "./endUsers";
import { createEventParticipants } from "./eventParticipants";
import { updateParent, createParent } from "./parent";
import { updateSalesRep, createSalesRep } from "./salesRep";
import { updateProductType, createProductType } from "./types";
import { RequestProductType } from "@models/common/events";
import { filterObjectFields } from "@services/common";
import { IEndUserResponse } from "@models/common/user";
import { UseFormSetError } from "react-hook-form/dist/types/form";
import { AxiosError } from "axios";

export const checkUserByData = async (
  dispatch: AppDispatch,
  email: string,
  phoneNumber: string
) =>
  await http.post<
    { emailExists: boolean; phoneNumberExists: boolean },
    { email: string; phoneNumber: string }
  >(
    "/api/v1/user/check",
    {
      email,
      phoneNumber,
    },
    dispatch
  );

export const checkEndUserByData = async (
  dispatch: AppDispatch,
  email?: string,
  phoneNumber?: string
) =>
  await http.post<
    { emailExists: boolean; phoneNumberExists: boolean },
    { email?: string; phoneNumber?: string }
  >(
    "/api/v1/user/check",
    {
      email,
      phoneNumber,
    },
    dispatch
  );

export const handleParentUpdate = async (
  dispatch: AppDispatch,
  parentData: IEndUserManage
) => {
  if (parentData?.parentId) {
    await updateParent(
      dispatch,
      parentData?.parentId || "",
      {
        email: parentData?.email,
        phoneNumber: parentData?.phone_number,
        firstName: parentData.firstName,
        lastName: parentData.lastName,
        dealer: parentData.dealer,
        dealerName: parentData?.dealerName,
        salesRep: parentData.salesRep,
      },
      parentData?.assignedDealers || ""
    );
  } else {
    await createParent(
      dispatch,
      {
        email: parentData.email,
        phoneNumber: parentData.phone_number,
        firstName: parentData.firstName,
        lastName: parentData.lastName,
        dealer: parentData.dealer,
        dealerName: parentData.dealerName,
        salesRep: parentData.salesRep,
      },
      parentData?.assignedDealers || ""
    );
  }
};

export const handleDealerUpdate = async (
  dispatch: AppDispatch,
  dealerData: IEndUserManage
) => {
  if (dealerData?.dealerId) {
    await updateDealer(
      dispatch,
      dealerData.dealerId,
      {
        email: dealerData.email,
        phoneNumber: dealerData.phone_number,
        firstName: dealerData.firstName,
        lastName: dealerData.lastName,
        salesRep: dealerData.salesRep,
      },
      dealerData?.dealerName || "",
      dealerData?.assignedParents
        ? dealerData.assignedParents.split(", ")
        : undefined
    );
  } else {
    await createDealer(
      dispatch,
      {
        email: dealerData.email,
        phoneNumber: dealerData.phone_number,
        firstName: dealerData.firstName,
        lastName: dealerData.lastName,
        salesRep: dealerData.salesRep,
      },
      dealerData?.dealerName || "",
      dealerData?.assignedParents
        ? dealerData.assignedParents.split(", ")
        : undefined
    );
  }
};

export const handleSalesRepUpdate = async (
  dispatch: AppDispatch,
  salesRepData: IEndUserManage
) => {
  if (salesRepData?.salesRepId) {
    await updateSalesRep(
      dispatch,
      salesRepData.salesRepId || "",
      {
        email: salesRepData.email,
        phoneNumber: salesRepData.phone_number,
        firstName: salesRepData.firstName,
        lastName: salesRepData.lastName,
        salesRep: salesRepData.salesRep,
      },
      salesRepData.dealerId || ""
    );
  } else {
    await createSalesRep(
      dispatch,
      {
        email: salesRepData.email,
        phoneNumber: salesRepData.phone_number,
        firstName: salesRepData.firstName,
        lastName: salesRepData.lastName,
      },
      salesRepData.dealerId || ""
    );
  }
};

export const handleUpdateEventParticipant = async (
  dispatch: AppDispatch,
  userData: IPersonalUserInfo,
  eventParticipant: boolean,
  eventID?: string,
  handleManaged?: (state: boolean) => void,
  handleUserData?: (user: IEndUser) => void
) => {
  const formattedParticipantData = filterObjectFields(
    {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData?.email,
      phoneNumber: userData?.phone_number,
    },
    [undefined]
  ) as IEndUserResponse;
  const participantGender =
    userData?.gender?.value === "Male"
      ? MALE_USER_GENDER
      : userData?.gender?.value === "Female"
      ? FEMALE_USER_GENDER
      : OTHER_USER_GENDER;

  if (userData?.userId) {
    const user = await updateEndUser(
      dispatch,
      userData.userId,
      formattedParticipantData,
      participantGender,
      userData?.badge || ""
    );
    if (handleUserData) handleUserData(user);
    if (eventParticipant) {
      try {
        await createEventParticipants(dispatch, eventID || "", [
          user.endUserId,
        ]);
      } finally {
        if (handleManaged) handleManaged(true);
      }
    } else {
      if (handleManaged) handleManaged(true);
    }
  } else {
    const user = await createEndUser(
      dispatch,
      formattedParticipantData,
      participantGender,
      userData?.badge || ""
    );
    if (handleUserData) handleUserData(user);
    if (eventParticipant) {
      try {
        await createEventParticipants(dispatch, eventID || "", [
          user.endUserId,
        ]);
      } finally {
        if (handleManaged) handleManaged(true);
      }
    } else {
      if (handleManaged) handleManaged(true);
    }
  }
};

export const handleProductTypesUpdate = async (
  dispatch: AppDispatch,
  productTypeData: RequestProductType
) => {
  if (!!productTypeData?.productTypeId) {
    await updateProductType(dispatch, productTypeData);
  } else {
    await createProductType(dispatch, {
      ...productTypeData,
      productTypeId: "",
    });
  }
};

export const handleEndUserUpdate = async (
  dispatch: AppDispatch,
  endUserData: IEndUserManage
) => {
  const formattedEndUserData = filterObjectFields(
    {
      firstName: endUserData.firstName,
      lastName: endUserData.lastName,
      email: endUserData?.email,
      phoneNumber: endUserData?.phone_number,
    },
    [undefined]
  ) as IEndUserResponse;
  const endUserGender =
    endUserData?.gender?.value === "Male"
      ? MALE_USER_GENDER
      : endUserData?.gender?.value === "Female"
      ? FEMALE_USER_GENDER
      : OTHER_USER_GENDER;

  if (endUserData?.userId) {
    await updateEndUser(
      dispatch,
      endUserData.userId,
      formattedEndUserData,
      endUserGender,
      endUserData?.badge || ""
    );
  } else {
    await createEndUser(
      dispatch,
      formattedEndUserData,
      endUserGender,
      endUserData?.badge || ""
    );
  }
};

const serverValidationErrorFieldNamesToFormFieldNamesMapping = {
  phoneNumber: "phone_number",
  email: "email",
};

export type SubmitError = AxiosError<{
  violations?: { fieldName: string; message: string }[];
}>;
export const handleSubmitErrors = ({
  setError,
  error,
  fieldsNamesMapping = serverValidationErrorFieldNamesToFormFieldNamesMapping,
}: {
  setError: UseFormSetError<Record<string, unknown>>;
  error: SubmitError;
  fieldsNamesMapping?: Record<string, string>;
}) => {
  const violations = error?.response?.data?.violations;
  const serverValidationError = Boolean(violations);

  if (!serverValidationError) {
    return;
  }

  violations?.forEach(({ fieldName, message }) => {
    const formFieldName = fieldsNamesMapping[fieldName];

    if (!formFieldName) {
      return;
    }

    setError(formFieldName, {
      type: "custom",
      message,
    });
  });
};
