import { FC } from "react";
import { IButton } from "@models/components/secondary/button";

export const Button: FC<IButton> = (props) => {
  const { type, text, className, disable, icon, onClick, onKeyUp, tabIndex } =
    props;

  return (
    <button
      type={type}
      className={`${className} btn`}
      disabled={disable}
      onClick={onClick}
      tabIndex={tabIndex}
      onKeyUp={onKeyUp}
    >
      {icon && <i className={`btn-icon icon ${icon || ""}`} />}
      {text}
    </button>
  );
};
