import { FC, useEffect, useState } from "react";
import { Loader } from "@components/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  formateCapitalizeStr,
  getPage,
  getSortTypes,
  hideWindowScroll,
  showWindowScroll,
} from "@services/common";
import {
  ACCEPTED_USER_STATUS,
  DELETED_USER_STATUS,
  NO_DEALERS_PLUG_TYPE,
  NO_FOUND_PLUG_TYPE,
  PARENTS_BREADCRUMBS,
  PENDING_USER_STATUS,
  SEARCH_QUERY_KEY,
  TABLE_ROWS_LIMIT,
} from "@constants/commons";
import { HeaderBarTable } from "@components/HeaderBarTable";
import { Pagination } from "@components/Pagination";
import { Plug } from "@components/Plug";
import { NO_DEALERS_PLUG_ERROR, NO_FOUND_PLUG_ERROR } from "@constants/errors";
import { IDealerList } from "@models/common/dealer";
import { listOfFilteredDealer } from "@api/dealers";
import { IParent } from "@models/common/parent";
import { getParentById } from "@api/parent";
import { Breadcrumbs } from "@components/Breadcrumbs";
import { DealerTable } from "@components/Dealer/DealerTable";
import { ISortType } from "@models/common/app";
import { ManageDealerModal } from "@pages/Modals/Users/ManageDealerModal";
import { ADD_DEALER_TITLE } from "@constants/users";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import queryString from "query-string";
import { MiniLoader } from "@components/Loader/MiniLoader";
import { MAIN_ROUTE } from "@constants/routes";
import { handleDealerUpdate } from "@api/common";
import { getFormattedError } from "@services/http";

export const ParentInfo: FC = () => {
  const location = useLocation();
  const parsedSearchedQuery = queryString.parse(location.search)?.[
    SEARCH_QUERY_KEY
  ];

  const [parentData, setParentData] = useState<IParent>();
  const [parentDealers, setParentDealers] = useState<IDealerList>();
  const [searchedValue, setSearchedValue] = useState<string | undefined>(
    typeof parsedSearchedQuery === "string" ? parsedSearchedQuery : undefined
  );
  const [sortType, setSortType] = useState<ISortType>();
  const [openDealerEditModal, setOpenDealerEditModal] =
    useState<boolean>(false);
  const [openDealerCreateModal, setOpenDealerCreateModal] =
    useState<boolean>(false);
  const [deleteWindowOpen, setDeleteWindowOpen] = useState(false);
  const [searchParams, setSearchParams] = useState<
    queryString.ParsedQuery<string> | undefined
  >(queryString.parse(location.search));
  const [searchFieldFocused, setSearchFieldFocused] = useState(false);
  const [managed, setManaged] = useState(false);

  const { parentId, page } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const pageId = getPage(page);

  const handleSearchFieldFocus = () => {
    setSearchFieldFocused(true);
  };

  const addBtnHandler = () => {
    setOpenDealerCreateModal(true);
  };

  const handleModalDealerEdit = () => {
    setOpenDealerEditModal(!openDealerEditModal);
  };

  const handleFilteredDealers = (dealers: IDealerList) => {
    setParentDealers(dealers);
  };

  const handleSearchedDealers = (searchedValue: string | undefined) => {
    setSearchedValue(searchedValue);
  };

  const handleModalDealerCreate = () => {
    setOpenDealerCreateModal(!openDealerCreateModal);
  };

  const handleDeleteWindowOpen = () => {
    setDeleteWindowOpen(!deleteWindowOpen);
  };

  const handleManaged = (state: boolean) => {
    setManaged(state);
  };

  const handleSorting = (parameter: string) => {
    let filteredParameter;

    if (parameter === "Dealer name") {
      filteredParameter = "name";
    }
    if (parameter === "Person of contact") {
      filteredParameter = "user.firstName,user.lastName";
    }
    if (parameter === "System status") {
      filteredParameter = "user.status";
    }

    setSortType(getSortTypes(sortType, filteredParameter, parameter));
  };

  useEffect(() => {
    (async function asyncWrapper() {
      try {
        if (parentId) {
          const parent = await getParentById(dispatch, parentId);
          setParentData(parent);
        }
      } catch (error) {
        return Promise.reject(getFormattedError(error));
      }
    })();
  }, [parentId]);

  useEffect(() => {
    if (searchFieldFocused) {
      if (!!searchedValue) {
        if (pageId + 1 === 1) {
          navigate(`?${SEARCH_QUERY_KEY}=${searchedValue}`);
        } else {
          navigate(
            `/${
              location.pathname.split(MAIN_ROUTE)[1]
            }/1?${SEARCH_QUERY_KEY}=${searchedValue}`
          );
        }
      } else {
        navigate("");
      }
    }
  }, [searchedValue]);

  useEffect(() => {
    const searchQuery = queryString.parse(location.search);
    if (!!Object.keys(searchQuery).length) {
      setSearchParams(searchQuery);
    } else {
      setSearchParams({});
    }
  }, [location.search]);

  useEffect(() => {
    if (!!searchParams?.[SEARCH_QUERY_KEY]) {
      handleSearchedDealers(searchParams[SEARCH_QUERY_KEY].toString());
    }
  }, [searchParams?.[SEARCH_QUERY_KEY]]);

  useEffect(() => {
    if (
      !deleteWindowOpen &&
      !openDealerCreateModal &&
      !openDealerEditModal &&
      (searchedValue
        ? searchParams?.[SEARCH_QUERY_KEY]
        : !searchParams?.[SEARCH_QUERY_KEY])
    ) {
      (async function asyncWrapper() {
        try {
          const dealers = await listOfFilteredDealer(
            dispatch,
            pageId,
            TABLE_ROWS_LIMIT,
            sortType ? sortType.filteredParameter : "name,asc",
            searchedValue,
            parentId
          );
          handleFilteredDealers(dealers);
          if (managed) handleManaged(false);
        } catch (error) {
          return Promise.reject(getFormattedError(error));
        }
      })();
    }
  }, [
    pageId,
    sortType,
    parentId,
    deleteWindowOpen,
    managed,
    openDealerCreateModal,
    openDealerEditModal,
    searchParams?.[SEARCH_QUERY_KEY],
  ]);

  useEffect(() => {
    if (openDealerCreateModal) {
      showWindowScroll();
    } else {
      hideWindowScroll();
    }
  }, [openDealerCreateModal]);

  return (
    <>
      {parentData ? (
        <main className="main-container info-container">
          <Breadcrumbs
            title={`${parentData.firstName} 
            ${parentData.lastName}`}
            type={PARENTS_BREADCRUMBS}
          />
          <h2 className="event-header event-title">
            {parentData.firstName} {parentData.lastName}
          </h2>
          <ul className="sales sales-parent">
            <li className="sales-item">
              System status:{" "}
              <span
                className={`sales-status status ${
                  parentData.status === ACCEPTED_USER_STATUS
                    ? "status-success"
                    : parentData.status === DELETED_USER_STATUS
                    ? "status-danger"
                    : parentData.status === PENDING_USER_STATUS
                    ? "status-warning"
                    : ""
                }`}
              >
                {formateCapitalizeStr(parentData.status)}
              </span>
            </li>
            <li className="sales-item">
              Email:{" "}
              <span className="sales-val">
                {parentData.email ? parentData.email : "-"}
              </span>
            </li>
            <li className="sales-item">
              Phone number:{" "}
              <span className="sales-val">
                {parentData.phoneNumber ? parentData.phoneNumber : "-"}
              </span>
            </li>
          </ul>
        </main>
      ) : (
        <Loader />
      )}
      <>
        {openDealerCreateModal && (
          <ManageDealerModal
            title={ADD_DEALER_TITLE}
            handleModalOpen={handleModalDealerCreate}
            userInfo={undefined}
            handleUser={handleDealerUpdate}
            assignedParents={parentData?.userId}
          />
        )}
        {parentDealers ? (
          <main className="main-container">
            <HeaderBarTable
              searchedValue={searchedValue}
              handleSearchedValues={handleSearchedDealers}
              title="Dealers"
              btnTitles="Add new Dealer"
              btnHandlers={[addBtnHandler]}
              sort={sortType}
              handleSearchFieldFocus={handleSearchFieldFocus}
            />
            {!!parentDealers.content.length ? (
              <div className="event-table-container">
                <DealerTable
                  sort={sortType}
                  dealer={parentDealers.content}
                  handleSorting={handleSorting}
                  handleModalOpen={handleModalDealerEdit}
                  deleteWindowOpen={deleteWindowOpen}
                  handleDeleteModal={handleDeleteWindowOpen}
                  handleManaged={handleManaged}
                />
                <Pagination
                  dataLength={parentDealers.totalElements}
                  pageId={pageId}
                />
              </div>
            ) : (
              <MiniLoader
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5vh",
                }}
                time={3}
                afterload={
                  <Plug
                    message={
                      searchParams && !!Object.keys(searchParams)?.length
                        ? NO_FOUND_PLUG_ERROR
                        : NO_DEALERS_PLUG_ERROR
                    }
                    type={
                      searchParams && !!Object.keys(searchParams)?.length
                        ? NO_FOUND_PLUG_TYPE
                        : NO_DEALERS_PLUG_TYPE
                    }
                  />
                }
              />
            )}
          </main>
        ) : (
          <Loader />
        )}
      </>
    </>
  );
};
