import { FC } from "react";
import { Header } from "@components/Header";
import { ParentInfo } from "@components/Parent/ParentInfo";

export const SelectedParent: FC = () => {
  return (
    <>
      <Header />
      <ParentInfo />
    </>
  );
};
