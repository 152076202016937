import { IN_PROGRESS_STATUS_REQUEST } from "@constants/events";
import {
  IEventParticipant,
  IEventParticipantsList,
} from "@models/common/eventParticipants";
import { EventStatusRequest } from "@models/common/events";
import { http } from "@services/http";
import { AppDispatch } from "@store/store";

export const findEventParticipants = async (
  dispatch: AppDispatch,
  eventId: string,
  searchKey?: string,
  page?: number,
  size?: number,
  sort?: string
) =>
  await http.post<IEventParticipantsList, { searchKey: string | null }>(
    `/api/v1/measurement/event/${eventId}/participant/filter?page=${
      page || 0
    }&size=${size || 20}&sort=${
      sort || "endUser.user.firstName,endUser.user.lastName,asc"
    }`,
    { searchKey: searchKey || null },
    dispatch
  );

export const createEventParticipants = async (
  dispatch: AppDispatch,
  eventId: string,
  userIds?: string[]
) =>
  await http.post<IEventParticipant, { endUserIds: string[] | null }>(
    `/api/v1/measurement/event/${eventId}/participant`,
    { endUserIds: userIds || null },
    dispatch
  );

export const deleteEventParticipants = async (
  dispatch: AppDispatch,
  eventId: string,
  userId: string
) =>
  await http.delete(
    `/api/v1/measurement/event/${eventId}/participant/${userId}`,
    null,
    dispatch
  );

export const findEventParticipantsActiveEvents = async (
  dispatch: AppDispatch,
  dealerId?: string
) =>
  await http.post<
    IEventParticipantsList,
    {
      event: {
        statusIn: EventStatusRequest[];
        dealerIdIn: string | null;
      };
    }
  >(
    `/api/v1/measurement/event/participant/filter`,
    {
      event: {
        statusIn: [IN_PROGRESS_STATUS_REQUEST],
        dealerIdIn: dealerId || null,
      },
    },
    dispatch
  );

export const sendInvitationToEventParticipants = async (
  dispatch: AppDispatch,
  eventId: string
) =>
  await http.post<IEventParticipantsList, null>(
    `/api/v1/measurement/event/${eventId}/participant/send-invite`,
    null,
    dispatch
  );

export const sendInvitationToEventParticipant = async (
  dispatch: AppDispatch,
  eventId: string,
  participantId: string
) =>
  await http.post<IEventParticipantsList, null>(
    `/api/v1/measurement/event/${eventId}/participant/send-invite/${participantId}`,
    null,
    dispatch
  );
