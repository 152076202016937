import "@assets/scss/main.scss";
import { FC, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Router } from "@pages/Router";
import { store } from "@store/store";
import { SAVE_STORE_INTERVAL } from "@constants/commons";
import { saveLocalStorageState } from "@services/common";
import { debounce } from "debounce";
import { configureAmplify } from "@services/auth";

export const App: FC = () => {
  useEffect(() => {
    (async function asyncWrapper() {
      await configureAmplify();
    })();
  });

  store.subscribe(
    debounce(() => {
      if (process.env.REACT_APP_STORE_STATE)
        localStorage.removeItem(process.env.REACT_APP_STORE_STATE);
      saveLocalStorageState(store.getState());
    }, SAVE_STORE_INTERVAL)
  );

  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
};
