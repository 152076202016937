import { FC, useState } from "react";
import { Button } from "@components/Button";
import { Header } from "@components/Header";
import { EventStepsHeader } from "@components/Events/EventStepsHeader/index";
import { IAddUser } from "@models/components/main/adduser";
import { ManualAddUser } from "./ManualAddUser";
import { BulkAddUser } from "./BulkAddUser";
import { HeaderBarTable } from "@components/HeaderBarTable";
import { Breadcrumbs } from "@components/Breadcrumbs";
import {
  EVENTS_BREADCRUMBS,
  SOMETHING_WENT_WRONG_NOTIFICATION,
} from "@constants/commons";
import { useNavigate, useParams } from "react-router-dom";
import { MEASUREMENTS_LIST_ROUTE } from "@constants/routes";
import { useAppDispatch } from "@hooks/redux/useAppDispatch";
import { useAppSelector } from "@hooks/redux/useAppSelector";
import { selectEventData } from "@store/selectors/event";
import { Loader } from "@components/Loader";
import { AsyncStatus } from "@hooks/common/useAsync";
import { IEndUser } from "@models/common/endUsers";
import { createEventParticipants } from "@api/eventParticipants";
import { Tooltip } from "@components/Tooltip";
import { AlertModal } from "@components/AlertModal";
import { INTERRUPT_EVENT_BODY, INTERRUPT_EVENT_TITLE } from "@constants/events";
import { useNotification } from "@hooks/common/useNotification";
import { Notification } from "@components/Notification";
import { getFormattedError } from "@services/http";

export const AddUser: FC<IAddUser> = ({ title }) => {
  const [tabs, setTabs] = useState({ bulk: false, manual: true });
  const [bulkStatus, setBulkStatus] = useState<AsyncStatus>("idle");
  const [eventParticipants, setEventParticipants] = useState<IEndUser[]>();
  const [isContinue, setContinue] = useState(false);
  const [loadedFile, setLoadedFile] = useState<Blob | undefined>();
  const [redirectOpen, setRedirectOpen] = useState(false);
  const [interruptRoute, setInterruptRoute] = useState<string>();

  const navigate = useNavigate();
  const { eventID } = useParams();
  const dispatch = useAppDispatch();
  const eventData = useAppSelector(selectEventData);

  const {
    isActive,
    message: notificationMessage,
    type: notificationType,
    showNotification,
    closeNotification,
  } = useNotification();

  const handleBulkTab = () => {
    setTabs({ bulk: true, manual: false });
  };

  const handleManualTab = () => {
    setTabs({ bulk: false, manual: true });
  };

  const handleUserData = (user: IEndUser) => {
    if (
      !eventParticipants?.length ||
      eventParticipants.findIndex(
        (participant) => participant.endUserId === user.endUserId
      ) === -1
    ) {
      setEventParticipants((participants) => [...(participants || []), user]);
    } else if (
      eventParticipants?.some(
        (participant) => participant.endUserId === user.endUserId
      )
    ) {
      const indexOfEditedUser = eventParticipants.findIndex(
        (participant) => participant.endUserId === user.endUserId
      );
      const filteredParticipants =
        eventParticipants?.filter(
          (participant) => participant.endUserId !== user.endUserId
        ) || [];

      filteredParticipants.splice(indexOfEditedUser, 0, user);

      setEventParticipants(filteredParticipants);
    }
  };

  const handleUserEventDelete = (userId?: string) => {
    setEventParticipants((participants) => {
      const filteredParticipants = participants?.filter(
        (participant) => participant.endUserId !== userId
      );
      if (!filteredParticipants?.length) {
        return undefined;
      } else {
        return filteredParticipants;
      }
    });
  };

  const handleExitAddUsers = () => {
    navigate(`${MEASUREMENTS_LIST_ROUTE}/${eventID}/1`);
  };

  const submitBtnHandler = async () => {
    if (tabs.bulk && !eventParticipants?.length) {
      setContinue(true);
    } else {
      if (!!eventParticipants?.length && eventID) {
        const participants = eventParticipants.map(
          (participant) => participant.endUserId
        );
        try {
          await createEventParticipants(dispatch, eventID, participants);
          handleExitAddUsers();
        } catch (error) {
          showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
          return Promise.reject(getFormattedError(error));
        }
      } else {
        handleExitAddUsers();
      }
    }
  };

  const handleBulkUploadStatus = (status: AsyncStatus) => {
    setBulkStatus(status);
  };

  const handleEventParticipants = (participants: IEndUser[]) => {
    setEventParticipants(participants);
  };

  const handleLoadedFile = (file: Blob | undefined) => {
    setLoadedFile(file);
  };

  const handleClarifyWindow = () => {
    setRedirectOpen(!redirectOpen);
  };

  const handleInterruptRoute = (route: string) => {
    setInterruptRoute(route);
  };

  const handleRedirect = () => {
    interruptRoute && navigate(interruptRoute);
  };

  const isBulkDisabled =
    tabs.bulk && bulkStatus !== "success" && bulkStatus !== "warning";

  return (
    <>
      <Header
        handleClarifyWindow={handleClarifyWindow}
        handleInterruptRoute={handleInterruptRoute}
      />
      <Notification
        type={notificationType}
        message={notificationMessage}
        isActive={isActive}
        closeNotification={closeNotification}
      />
      {redirectOpen && (
        <AlertModal
          type="delete"
          title={INTERRUPT_EVENT_TITLE}
          body={INTERRUPT_EVENT_BODY}
          handleOpen={handleClarifyWindow}
          handleConfirm={handleRedirect}
          id={eventID || ""}
          confirmBtn="Yes"
        />
      )}
      {eventData ? (
        <main className="main-container">
          <Breadcrumbs
            title={title}
            type={EVENTS_BREADCRUMBS}
            handleClarifyWindow={handleClarifyWindow}
            handleInterruptRoute={handleInterruptRoute}
          />
          <HeaderBarTable title={title} />
          <div className="event-form">
            <EventStepsHeader eventStepType="add_users" title={title} />
            <div className="step step-user">
              <div className="step-col">
                <ul className="link">
                  <li
                    className={`link-item ${tabs.manual && "isActive"} ${
                      tabs.bulk &&
                      (loadedFile ||
                        bulkStatus === "pending" ||
                        bulkStatus === "warning" ||
                        !!eventParticipants?.length) &&
                      "isDisabled"
                    }`}
                    onClick={() => {
                      if (isBulkDisabled && !eventParticipants?.length)
                        handleManualTab();
                    }}
                  >
                    Manual load
                  </li>
                  <li
                    className={`link-item ${tabs.bulk && "isActive"} ${
                      tabs.manual && !!eventParticipants?.length && "isDisabled"
                    }`}
                    onClick={() => {
                      if (tabs.manual && !eventParticipants?.length)
                        handleBulkTab();
                    }}
                  >
                    Bulk load
                  </li>
                  {((tabs.manual && !!eventParticipants?.length) ||
                    (tabs.bulk &&
                      (bulkStatus === "pending" ||
                        bulkStatus === "warning" ||
                        !!eventParticipants?.length))) && (
                    <Tooltip type="common" />
                  )}
                </ul>
                {tabs.manual ? (
                  <ManualAddUser
                    handleUserData={handleUserData}
                    participants={eventParticipants}
                    handleUserEventDelete={handleUserEventDelete}
                  />
                ) : (
                  <BulkAddUser
                    bulkStatus={bulkStatus}
                    handleBulkUploadStatus={handleBulkUploadStatus}
                    handleEventParticipants={handleEventParticipants}
                    isContinue={isContinue}
                    participants={eventParticipants}
                    handleUserEventDelete={handleUserEventDelete}
                    handleLoadedFile={handleLoadedFile}
                    handleUserData={handleUserData}
                  />
                )}
              </div>
            </div>
            <div className="event-footer">
              <Button
                text={
                  tabs.bulk && !eventParticipants?.length ? "Continue" : "Save"
                }
                className="event-footer-btn btn-main"
                onClick={submitBtnHandler}
                disable={isBulkDisabled}
              />
            </div>
          </div>
        </main>
      ) : (
        <Loader />
      )}
    </>
  );
};
