import {
  ADMIN_ROLE,
  DEALER_ROLE,
  END_USER_ROLE,
  FEMALE_USER_GENDER,
  MALE_USER_GENDER,
  OTHER_USER_GENDER,
  PARENT_ROLE,
  PHONE_NUMBER_START_CODE,
  SALES_REP_ROLE,
} from "@constants/commons";
import { RootState } from "@store/store";
import { arrayOfMonths } from "@constants/commons";
import { IUserTokens, UserGender, UserRole } from "@models/common/user";
import {
  EventLocation,
  EventStatusRequest,
  GenderToMeasurementParamRefs,
  IEventSalesRep,
  ProductType,
} from "@models/common/events";
import {
  CANCELED_STATUS,
  CANCELED_STATUS_REQUEST,
  COMPLETED_STATUS,
  COMPLETED_STATUS_REQUEST,
  IN_PERSON_EVENT_TYPE,
  IN_PROGRESS_STATUS,
  IN_PROGRESS_STATUS_REQUEST,
  PRODUCT_TYPE_TITLE,
  SCHEDULED_STATUS,
  SCHEDULED_STATUS_REQUEST,
} from "@constants/events";
import {
  ASSIGN_DEALER_TITLE,
  ASSIGN_PARENT_TITLE,
  ASSIGN_TO_TITLE,
} from "@constants/users";
import { IDealer } from "@models/common/dealer";
import { IParent } from "@models/common/parent";
import { ISalesRep } from "@models/common/salesRep";
import { ISortType } from "@models/common/app";
import {
  GenderMeasurementParameter,
  IMeasurementParameter,
} from "@models/common/measurements";
import { BulkUploadStatus } from "@models/components/main/bulkadduser";
import {
  BULK_ERROR_CONNECTION,
  BULK_ERROR_SIZE,
  BULK_ERROR_TIMEOUT,
} from "@constants/errors";
import _ from "lodash";

export const formateDuring = (seconds: number) => {
  const formMin = Math.floor((seconds % (60 * 60)) / 60);
  var formSec = Math.ceil((seconds % (60 * 60)) % 60);
  return formSec < 10 ? `${formMin}:0${formSec}` : `${formMin}:${formSec}`;
};

export const secondsToMilliseconds = (seconds: number) => seconds * 1000;
export const minutesToMilliseconds = (minutes: number) =>
  secondsToMilliseconds(minutes * 60);
export const hoursToMilliseconds = (hours: number) =>
  minutesToMilliseconds(hours * 60);

export const formateAMPM = (
  isoDateString: string,
  withoutSpaces: boolean = false
) => {
  const formattedISODate = withoutSpaces
    ? isoDateString
    : `${isoDateString}.000Z`;
  let hours = new Date(formattedISODate).getHours();
  const minutes = new Date(formattedISODate).getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours %= 12;
  hours = hours || 12;
  const convertMin = minutes < 10 ? `0${minutes}` : `${minutes}`;

  const strTime = withoutSpaces
    ? `${hours}:${convertMin}${ampm}`
    : `${hours}:${convertMin} ${ampm}`;

  return strTime;
};

export const formateDate = (
  isoDateString: string,
  withoutSpaces: boolean = false
) => {
  const formattedISODate = withoutSpaces
    ? isoDateString
    : `${isoDateString}.000Z`;
  const day = new Date(formattedISODate).getDate();
  const month = withoutSpaces
    ? new Date(formattedISODate).getMonth() + 1 < 10
      ? `0${new Date(formattedISODate).getMonth() + 1}`
      : new Date(formattedISODate).getMonth() + 1
    : arrayOfMonths[new Date(formattedISODate).getMonth()];
  const year = new Date(formattedISODate).getFullYear();

  const formateTime = formateAMPM(isoDateString, withoutSpaces);

  return withoutSpaces
    ? `${month}-${day}-${year}`
    : `${day} ${month} ${year}, ${formateTime}`;
};

export const formateCapitalizeStr = (str: string | null) => {
  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1).toLowerCase();
};

export const truncStr = (str: string, limit: number) => {
  if (str?.length > limit) {
    str = str.substring(0, limit) + "...";
  }
  return str;
};

export const loadLocalStorageState = () => {
  try {
    if (process.env.REACT_APP_STORE_STATE) {
      const serializedState = localStorage.getItem(
        process.env.REACT_APP_STORE_STATE
      );
      if (!serializedState) return undefined;
      return JSON.parse(serializedState);
    } else return undefined;
  } catch {
    return undefined;
  }
};

export const saveLocalStorageState = async (state: RootState) => {
  try {
    const serializedState = JSON.stringify(state);
    if (process.env.REACT_APP_STORE_STATE)
      localStorage.setItem(process.env.REACT_APP_STORE_STATE, serializedState);
  } catch {
    return undefined;
  }
};

export const unFocuseField = (
  fieldName: string,
  focused: { [fieldName: string]: boolean },
  handleFocuse: (focuseState: { [fieldName: string]: boolean }) => void
) => {
  handleFocuse({ ...focused, [fieldName]: false });
};

export const focuseField = (
  fieldName: string,
  focused: { [fieldName: string]: boolean },
  handleFocuse: (focuseState: { [fieldName: string]: boolean }) => void
) => {
  handleFocuse({ ...focused, [fieldName]: true });
};

export const waiting = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const getFormattedUserRole = (userRole: UserRole) => {
  if (userRole === ADMIN_ROLE) {
    return "Admin";
  } else if (userRole === PARENT_ROLE) {
    return "Parent";
  } else if (userRole === DEALER_ROLE) {
    return "Dealer";
  } else if (userRole === SALES_REP_ROLE) {
    return "Sales representatives";
  } else {
    return "User";
  }
};

const ROLES_HIERARCHY = [
  ADMIN_ROLE,
  PARENT_ROLE,
  DEALER_ROLE,
  SALES_REP_ROLE,
  END_USER_ROLE,
];

export const checkIfRoleHasAccess = (
  role: UserRole,
  accessRoles: UserRole[]
) => {
  return accessRoles.some((accessRole) =>
    checkIfRoleHigherOrEqual(role, accessRole)
  );
};

const checkIfRoleHigherOrEqual = (role: UserRole, accessRole: UserRole) => {
  const rolePriority = ROLES_HIERARCHY.indexOf(role);
  const accessRolePriority = ROLES_HIERARCHY.indexOf(accessRole);

  if (rolePriority === -1 || accessRolePriority === -1) {
    return false;
  }

  return rolePriority <= accessRolePriority;
};
const getHigherRole = (roles: UserRole[]): UserRole | undefined => {
  return roles?.reduce((prevRole, role) => {
    const prevRoleIndex = ROLES_HIERARCHY.indexOf(
      prevRole as unknown as UserRole
    );
    const currentRoleIndex = ROLES_HIERARCHY.indexOf(role);

    return (currentRoleIndex < prevRoleIndex ? role : prevRole) as UserRole;
  }, roles[0] as unknown as UserRole);
};

export const getUserRoleFromToken = (tokens: IUserTokens | undefined) =>
  getHigherRole(
    tokens?.idToken.payload["custom:user_role"]?.split(" ") as UserRole[]
  );

export const getEventType = (eventType: EventLocation) => {
  if (eventType === IN_PERSON_EVENT_TYPE) {
    return "In Person";
  } else {
    return "Remote";
  }
};

export const getEventStatus = (eventStatus: EventStatusRequest) => {
  if (eventStatus === COMPLETED_STATUS_REQUEST) {
    return COMPLETED_STATUS;
  } else if (eventStatus === CANCELED_STATUS_REQUEST) {
    return CANCELED_STATUS;
  } else if (eventStatus === SCHEDULED_STATUS_REQUEST) {
    return SCHEDULED_STATUS;
  } else if (eventStatus === IN_PROGRESS_STATUS_REQUEST) {
    return IN_PROGRESS_STATUS;
  }
};

export const getUserGender = (userGender: UserGender) => {
  if (userGender === MALE_USER_GENDER) {
    return "Male";
  } else if (userGender === FEMALE_USER_GENDER) {
    return "Female";
  } else if (userGender === OTHER_USER_GENDER) {
    return "N/A";
  }
};

export const setDropdownItems = (dropdownData: {
  title:
    | typeof PRODUCT_TYPE_TITLE
    | typeof ASSIGN_DEALER_TITLE
    | typeof ASSIGN_PARENT_TITLE
    | typeof ASSIGN_TO_TITLE;
  limit: number | undefined;
  productTypes?: ProductType[];
  dealers?: IDealer[];
  parents?: IParent[];
  salesReps?: IEventSalesRep;
  handleProductTypes?: (items: ProductType[]) => void;
  handleDealers?: (items: IDealer[]) => void;
  handleParents?: (items: IParent[]) => void;
  handleSalesReps?: (items: IEventSalesRep | undefined) => void;
  dropdownOpen?: (state: boolean) => void;
  value: ProductType | IDealer | IParent | ISalesRep;
  handleSearchedValue?: (value: string) => void;
}) => {
  const {
    title,
    limit,
    productTypes,
    dealers,
    parents,
    salesReps,
    handleProductTypes,
    handleDealers,
    handleParents,
    handleSalesReps,
    dropdownOpen,
    value,
    handleSearchedValue,
  } = dropdownData;

  if (title === PRODUCT_TYPE_TITLE && handleProductTypes) {
    if (
      productTypes?.some(
        (item) => item?.productTypeId === (value as ProductType)?.productTypeId
      )
    ) {
      handleProductTypes(
        productTypes?.filter(
          (item) =>
            item?.productTypeId !== (value as ProductType)?.productTypeId
        )
      );
      handleSearchedValue && handleSearchedValue("");
    } else {
      if ((limit && limit > 1) || !limit) {
        handleProductTypes([
          ...(productTypes || []),
          {
            productTypeId: (value as ProductType)?.productTypeId,
            name: (value as ProductType)?.name,
            genderToMeasurementParams: {
              MALE: undefined,
              FEMALE: undefined,
            },
          },
        ]);
        handleSearchedValue && handleSearchedValue("");
      } else {
        handleProductTypes([
          {
            productTypeId: (value as ProductType)?.productTypeId,
            name: (value as ProductType)?.name,
            genderToMeasurementParams: {
              MALE: undefined,
              FEMALE: undefined,
            },
          },
        ]);
        dropdownOpen && dropdownOpen(false);
        handleSearchedValue && handleSearchedValue("");
      }
    }
  } else if (title === ASSIGN_DEALER_TITLE && handleDealers) {
    if (
      dealers?.some((item) => item.dealerId === (value as IDealer)?.dealerId)
    ) {
      handleDealers(
        dealers?.filter(
          (item) => item?.dealerId !== (value as IDealer)?.dealerId
        )
      );
      handleSearchedValue && handleSearchedValue("");
    } else {
      if ((limit && limit > 1) || !limit) {
        handleDealers([...(dealers || []), value as IDealer]);
      } else {
        handleDealers([value as IDealer]);
        dropdownOpen && dropdownOpen(false);
      }
      handleSearchedValue && handleSearchedValue("");
    }
  } else if (title === ASSIGN_PARENT_TITLE && handleParents) {
    if (parents?.some((item) => item.userId === (value as IParent)?.userId)) {
      handleParents(
        parents?.filter((item) => item?.userId !== (value as IParent)?.userId)
      );
      handleSearchedValue && handleSearchedValue("");
    } else {
      if ((limit && limit > 1) || !limit) {
        handleParents([...(parents || []), value as IParent]);
      } else {
        handleParents([value as IParent]);
        dropdownOpen && dropdownOpen(false);
      }
      handleSearchedValue && handleSearchedValue("");
    }
  } else if (title === ASSIGN_TO_TITLE && handleSalesReps) {
    if (salesReps?.userId === (value as ISalesRep)?.salesRepId) {
      handleSalesReps(undefined);
      handleSearchedValue && handleSearchedValue("");
    } else {
      const salesRep = value as ISalesRep;
      handleSalesReps({
        userId: salesRep.userId,
        firstName: salesRep.firstName,
        lastName: salesRep.lastName,
        role: salesRep.role,
        status: salesRep.status,
        email: salesRep.email,
        phoneNumber: salesRep.phoneNumber,
        salesRepId: salesRep.salesRepId,
      });
      dropdownOpen && dropdownOpen(false);
      handleSearchedValue && handleSearchedValue("");
    }
  }
};

export const getSortTypes = (
  sortType: ISortType | undefined,
  filteredParameter: string | undefined,
  parameter: string
) => {
  let sortTypes: ISortType | undefined = {
    filteredParameter: "",
    parameter: "",
  };

  if (!sortType && filteredParameter) {
    sortTypes.filteredParameter = `${filteredParameter},asc`;
    sortTypes.parameter = `${parameter},asc`;
  } else if (
    sortType?.filteredParameter === `${filteredParameter},asc` &&
    filteredParameter
  ) {
    sortTypes.filteredParameter = `${filteredParameter},desc`;
    sortTypes.parameter = `${parameter},desc`;
  } else if (
    sortType?.filteredParameter === `${filteredParameter},desc` &&
    filteredParameter
  ) {
    sortTypes = undefined;
  } else {
    sortTypes.filteredParameter = `${filteredParameter},asc`;
    sortTypes.parameter = `${parameter},asc`;
  }
  return sortTypes;
};

export const getPage = (page: string | undefined) => {
  return !!page && +page > 0 ? +page - 1 : 0;
};

export const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: string, index: number) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const getProductTypeGender = (
  productTypeGender: GenderToMeasurementParamRefs | undefined
) => {
  if (
    !!productTypeGender?.FEMALE?.length &&
    !!productTypeGender?.MALE?.length
  ) {
    return "Unisex";
  }
  if (!!productTypeGender?.FEMALE?.length && !productTypeGender?.MALE?.length) {
    return "Female";
  }
  if (!productTypeGender?.FEMALE?.length && !!productTypeGender?.MALE?.length) {
    return "Male";
  }
};

export const getProductTypeMeasurements = (
  productTypeGender: GenderToMeasurementParamRefs
) => {
  const femaleMeasurements: { measurement: string }[] = [];
  const maleMeasurements: { measurement: string }[] = [];

  sortGenderMeasurementParameters(productTypeGender.FEMALE).forEach(
    (parameter) =>
      femaleMeasurements.push({ measurement: parameter.measurementParam.name })
  );
  sortGenderMeasurementParameters(productTypeGender.MALE).forEach((parameter) =>
    maleMeasurements.push({ measurement: parameter.measurementParam.name })
  );

  return {
    maleMeasurements: _.uniqBy(maleMeasurements, "measurement"),
    femaleMeasurements: _.uniqBy(femaleMeasurements, "measurement"),
  };
};

export const formateBulkStatus = (bulkStatus: BulkUploadStatus) => {
  if (bulkStatus === "COMPLETED") {
    return "success";
  }
  if (bulkStatus === "COMPLETED_WITH_ERRORS" || bulkStatus === "FAILED") {
    return "error";
  }
  if (bulkStatus === "COMPLETED_WITH_WARNINGS") {
    return "warning";
  }
  if (bulkStatus === "IN_PROGRESS") {
    return "pending";
  }
  return "idle";
};

export const formateBulkError = (bulkError: string) => {
  if (bulkError.includes("408")) {
    return BULK_ERROR_TIMEOUT;
  }
  if (bulkError.includes("413")) {
    return BULK_ERROR_SIZE;
  }
  if (bulkError.includes("400")) {
    return BULK_ERROR_CONNECTION;
  }
};

export const sortAlphabet = (x: string, y: string): number => {
  return x.localeCompare(y);
};

export const sortBy = <T>(items: T[], field: keyof T): T[] => {
  return [...items].sort((a, b) => {
    const aValue = a[field];
    const bValue = b[field];

    if (aValue < bValue) {
      return -1;
    }

    if (aValue > bValue) {
      return 1;
    }

    return 0;
  });
};

export const sortGenderMeasurementParameters = <
  T extends Pick<GenderMeasurementParameter, "displayOrder">
>(
  items: T[] = []
): T[] => {
  return sortBy(items || [], "displayOrder");
};

export const showWindowScroll = () => {
  document.body.classList.add("overflow-hidden");
};

export const hideWindowScroll = () => {
  document.body.classList.remove("overflow-hidden");
};

export const getRemainingSeconds = (date: number) =>
  Math.floor(date - Date.now() / 1000);

export const measurementParameterCheck = (
  param1: IMeasurementParameter,
  param2: IMeasurementParameter,
  type: "equal" | "not_equal"
) =>
  type === "equal"
    ? param1.measurementParamId === param2.measurementParamId
    : param1.measurementParamId !== param2.measurementParamId;

export const filterObjectFields = <
  T extends Record<string, unknown>,
  K extends keyof T
>(
  obj: T,
  filterBy: Array<string | number | null | undefined>
) => {
  const resultObj = { ...obj };
  Object.keys(resultObj).forEach((key) => {
    if (
      (typeof resultObj[key] === "string" ||
        typeof resultObj[key] === "number" ||
        resultObj[key] === null ||
        resultObj[key] === undefined) &&
      filterBy.includes(resultObj[key] as string | number | null | undefined)
    ) {
      delete resultObj[key];
    }
  });
  return resultObj as Record<K, T[K]>;
};

export const getPhoneNumberValue = (phoneNumber: string = ""): string => {
  return (phoneNumber ?? "")?.replace(PHONE_NUMBER_START_CODE, "");
};

export const serializePhoneNumber = (
  rawPhoneNumber: string = ""
): string | undefined => {
  const phoneNumber = rawPhoneNumber?.trim();
  return getPhoneNumberValue(phoneNumber) ? phoneNumber : undefined;
};

export const hasPhoneNumberValue = (phoneNumber: string = ""): boolean => {
  return Boolean(getPhoneNumberValue(phoneNumber));
};
